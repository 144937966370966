/** @format */

import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  TablePagination,
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Tooltip from '@mui/material/Tooltip';

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, marginLeft: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

const SingleGlobalTable = ({
  data,
  columns,
  totalCount,
  loading,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  onPageSizeChange,
}) => {
  const tableHeight = 517;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            p: 1,
            borderRadius: "8px",
            height: `${tableHeight + 62}px`,
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
            marginBottom:"2px",
          }}
        >
          <div
            style={{
              height: `${tableHeight}px`,
              overflowY: "auto",
            }}
          >
            {data && data?.length > 0 ? (
              <DataGrid
                disableColumnMenu
                rows={data}
                columns={columns}
                getRowId={(data) => data._id}
                getRowHeight={() => "auto"}
                hideFooter={true}
              />
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100% - 72px)",
                }}
              >
                No Data Found
              </Typography>
            )}
          </div>
          <Grid container justifyContent="center">
            <TablePagination
              sx={{
                bgcolor: "#F6F8FC",
                height: "55px",
                mb:"0px",
              }}
              rowsPerPageOptions={[5, 10, 25,40]}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              onPageSizeChange={onPageSizeChange}
              labelRowsPerPage={"Items per Page"}
              pagination={true}
              pageSize={rowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SingleGlobalTable;
