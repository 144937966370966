import { Grid, TablePagination, IconButton, Tooltip, tooltipClasses, Box, Stack, Button, Modal, FormControl, Select, MenuItem, Typography, TextField, Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { DeleteProductItemsInventoryApi, ListProductStoreApi, ProductItemsInventoryApi } from "../../redux/actions/Inventory";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "material-ui-core";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import { ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const downloadAllstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 500,
    height: "90vh",
    maxHeight: 600,
    overflowY: "auto",
    overflowX: "auto",
    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    pt: 4,
    display: "flex",
    flexDirection: "column",
    '@media (max-width: 600px)': {
        width: "90%",
        height: "80vh",
    }
};

const MenuProps = {
    disableScrollLock: true,
};

const ViewProductItems = () => {
    let productData = localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId"));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tableHeight = 517;

    const loader = useSelector(
        (state) =>
        (state.inventory &&
            state.inventory.loading)
    );

    const productItemsList = useSelector(
        (state) =>
        (state.inventory &&
            state.inventory.productItems &&
            state.inventory.productItems.data &&
            state.inventory.productItems.data.data)
    );

    // console.log(
    //     "productItemsList", productItemsList
    // );
    const storeData = useSelector(
        (state) =>
            (state.inventory &&
                state.inventory.store &&
                state.inventory.store.data &&
                state.inventory.store.data.data) ||
            []
    );

    const [serialNumber, setSerialNumber] = useState("");
    const [productName, setProductName] = useState("");
    const [productRate, setProductRate] = useState("");
    const [productWeight, setProductWeight] = useState("");
    const [productMeasurement, setProductMeasurement] = useState("");

    const [barcodeScanned, setBarcodeScanned] = useState("");
    const [barcodeGenerated, setBarcodeGenerated] = useState("");
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState("");
    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [indexHover, setIndexHover] = useState(0);
    const [hover, setHover] = useState({
        View: false,
    });
    const [start_date, setstart_date] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [end_date, setend_date] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

    const [openFilterModal, setOpenFilterModal] = useState(false);

    const payload = {
        product_id: productData._id,
        startAt: start_date,
        endAt: end_date,
        date: "createdAt",
        barcodeGenerated: barcodeGenerated === "Yes" ? true : false,
        barcodeScanned: barcodeScanned === "Yes" ? true : false,
        startAt: start_date,
        endAt: end_date,
        date: "createdAt"
    }

    if (productName || productRate || productWeight) {
        payload.questions = [];
    }

    if (productName) {
        const question = {
            "title": "Product name",
            "answer.answer.value": productName,
            "allowFilter": false
        }
        payload.questions.push(question)
    }
    if (productRate) {
        const question = {
            "title": "Product rate",
            "answer.answer.value": productRate,
            "allowFilter": false
        }
        payload.questions.push(question)
    }
    if (productWeight) {
        const question = {
            "title": "Product weight & product measurement",
            "answer.answer.value.weight": productWeight,
            "answer.answer.value.measurement": productMeasurement,
            "allowFilter": false
        }
        payload.questions.push(question)
    }

    const modalopenFilterhandleClose = () => {
        setOpenFilterModal(false);
    }

    const handleNewCreate = () => {
        localStorage.setItem("addTemplate", "");
        localStorage.setItem("questions", "");
        localStorage.setItem("userId", "");
    }

    const listStore = () => {
        const payloadRequest = {
            body: {
                page: 1,
                per_page: 200000,
                sort_order: -1,
                sort_by: "createdAt",
                type: "report",
            },
        };
        const id = localStorage.getItem("companyId");
        payloadRequest.url = BASE_URL + `stores/index?company_id=${id}`;
        dispatch(ListProductStoreApi(payloadRequest));
    };

    useEffect(() => {
        if (storeData?.stores) {
            setStores(storeData.stores);
        }
    }, [storeData]);

    const getProductItems = () => {
        const id = localStorage.getItem("companyId");

        const api = {
            url: BASE_URL + `inventory-product-items?company_id=${id}&id=${productData._id}&page=${pageNo}&perPage=${perPage}`,
            body: payload,
        }
        dispatch(ProductItemsInventoryApi(api));
    };

    useEffect(() => {
        getProductItems();
    }, [pageNo, perPage]);

    useEffect(() => {
        listStore();
    }, []);

    useEffect(() => {
        setItems(productItemsList?.finalData || []);
    }, [productItemsList]);

    useEffect(() => {
        const modifiedColumns = [
            {
                field: "S.No.",
                headerName: "S.No.",
                renderHeader: () => (
                    <LightTooltip title={"S.No"}>
                        <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
                    </LightTooltip>
                ),
                flex: 1,
                align: "center",
                headerAlign: "center",
                valueGetter: (params) => params.rowIndex + 1 + (pageNo - 1) * perPage,
                renderCell: (params) => {
                    const d = items || [];

                    const index =
                        d.map(e => e._id).indexOf(params.row._id) + 1 + (pageNo - 1) * perPage;

                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                minWidth: "50px",
                            }}
                        >
                            <p
                                className="text-short"
                                style={{
                                    margin: "0 auto",
                                    textAlign: "center",
                                    fontSize: "16px",
                                }}
                            >
                                {index}
                            </p>
                        </div>
                    );
                },
            },
            {
                field: "Serial Number",
                headerName: "Serial Number",
                renderHeader: () => <LightTooltip title={"Serial Number"}>
                    <strong>{"Serial Number"}</strong>
                </LightTooltip>,
                flex: 2,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => {
                    let result = [];
                    if (params?.row?.serialNumber) {
                        result.push(params?.row?.serialNumber);
                    } else {
                        result = ["N/A"];
                    }
                    let results = result.join(", ");
                    return (
                        <div
                            style={{
                                display: "flex",
                                width: "133px",
                            }}
                        >
                            <LightTooltip title={results}>
                                <p
                                    className="text-short"
                                    style={{ marginLeft: "10px", fontWeight: "normal" }}
                                >
                                    {results}
                                </p>
                            </LightTooltip>
                        </div>
                    );
                },
            },
            {
                field: "Barcode Generated",
                headerName: "Barcode Generated",
                renderHeader: () => <strong>{"Barcode Generated"}</strong>,
                align: "center",
                headerAlign: "center",
                flex: 1,
                maxWidth: 160,
                renderCell: (params) => {
                    const barcode = params?.row?.barcode;
                    const isImage =
                        barcode &&
                        (barcode.endsWith(".jpg") ||
                            barcode.endsWith(".png") ||
                            barcode.endsWith(".jpeg"));

                    const openImagePreview = (url) => {
                        const newWindow = window.open("", "_blank");
                        if (newWindow) {
                            newWindow.document.write(`
                                <html>
                                    <head>
                                    <title>Barcode Preview</title>
                                    <style>
                                        body {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100vh;
                                        margin: 0;
                                        background-color: #f9f9f9;
                                        }
                                        img {
                                        max-width: 100%;
                                        max-height: 100%;
                                        border: 1px solid #ddd;
                                        box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
                                        }
                                    </style>
                                    </head>
                                    <body>
                                    <img src="${url}" alt="Barcode Preview" />
                                    </body>
                                </html>
                            `);
                            newWindow.document.close();
                        }
                    };

                    return (
                        <div
                            style={{
                                display: "flex",
                                width: "133px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {params?.row.barcodeGenerated === true ? (
                                barcode ? (
                                    isImage ? (
                                        <img
                                            src={barcode}
                                            alt="Barcode"
                                            style={{
                                                width: 40,
                                                height: 40,
                                                objectFit: "cover",
                                                cursor: "pointer",
                                                borderRadius: "4px",
                                            }}
                                            onClick={() => openImagePreview(barcode)}
                                        />
                                    ) : (
                                        <a
                                            href={barcode}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: "none",
                                                color: "#007BFF",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Barcode Link
                                        </a>
                                    )
                                ) : (
                                    <span
                                        style={{
                                            color: "grey",
                                            fontSize: "14px",
                                        }}
                                    >
                                        N/A
                                    </span>
                                )
                            ) : (
                                <CloseIcon color="error" />
                            )}
                        </div>
                    );
                },
            },
            {
                field: "Barcode Scanned",
                headerName: "Barcode Scanned",
                renderHeader: () => <strong>{"Barcode Scanned"}</strong>,
                align: "center",
                headerAlign: "center",
                flex: 1,
                maxWidth: 160,
                renderCell: (params) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                width: "133px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {params?.row.barcodeScanned === true ? (
                                <DoneIcon color="success" />
                            ) : (
                                <CloseIcon color="error" />
                            )}
                        </div>
                    );
                },
            },
            {
                field: "CreatedAt",
                headerName: "CreatedAt",
                renderHeader: () => <LightTooltip title={"CreatedAt"}>
                    <strong>{"CreatedAt"}</strong>
                </LightTooltip>,
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => {
                    let result = [];
                    if (params?.row?.createdAt) {
                        result.push(params?.row?.createdAt);
                    } else {
                        result = ["0"];
                    }
                    let results = result.join(", ");
                    return (
                        <LightTooltip title={results}>
                            <p className="text-short"> {results}</p>
                        </LightTooltip>
                    );
                },
            },
            {
                field: "UpdatedAt",
                headerName: "UpdatedAt",
                renderHeader: () => <LightTooltip title={"UpdatedAt"}>
                    <strong>{"UpdatedAt"}</strong>
                </LightTooltip>,
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => {
                    let result = [];
                    if (params?.row?.updatedAt) {
                        result.push(params?.row?.updatedAt);
                    } else {
                        result = ["N/A"];
                    }
                    let results = result.join(", ");
                    return (
                        <LightTooltip title={results}>
                            <p className="text-short"> {results}</p>
                        </LightTooltip>
                    );
                },
            },

        ];

        if (items.length) {
            console.log("items", items);

            const questions = [
                "Product name",
                "Product rate",
                "Product weight & product measurement",
            ]

            items[0].productDetails.questions.map(item =>
                questions.includes(item.title) && modifiedColumns.push({
                    field: item.title,
                    headerName: item.title,
                    renderHeader: () => <LightTooltip title={item.title}>
                        <strong>{item.title}</strong>
                    </LightTooltip>,
                    flex: 1,
                    align: "center",
                    headerAlign: "center",
                    renderCell: (params) => {
                        let result = [];
                        if (params?.row?.productDetails?.questions?.length) {
                            const findQ = params?.row?.productDetails?.questions.filter(ques => params?.field === ques?.title);
                            const answer = findQ[0]?.answer?.answer?.value?.weight ? `${findQ[0]?.answer?.answer?.value?.weight + findQ[0]?.answer?.answer?.value?.measurement}` : findQ[0]?.answer?.answer?.value;
                            result.push(answer);
                        } else {
                            result = ["N/A"];
                        }
                        let results = result.join(", ");
                        return (
                            <LightTooltip title={results}>
                                <p className="text-short"> {results}</p>
                            </LightTooltip>
                        );
                    },
                })
            )
        }

        modifiedColumns.push({
            field: "Action",
            headerName: "Action",
            renderHeader: () => <LightTooltip title={"Action"}>
                <strong>{"Action"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const viewBtnStyle = {
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                    height: "32px"
                };

                return (
                    <div style={{
                        display: 'flex'
                    }}>
                        <Tooltip title="View">
                            <img
                                src={
                                    hover.View && indexHover === params?.row?._id
                                        ? ViewHover
                                        : View
                                }
                                alt="VIEW"
                                style={{
                                    ...viewBtnStyle,
                                }}
                                onClick={() => handleView(params?.row)}
                                onMouseOver={() => {
                                    setHover({ ...hover, View: true });
                                    setIndexHover(params?.row?._id);
                                }}
                                onMouseLeave={() => {
                                    setHover({ ...hover, View: false });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        })

        setColumns(modifiedColumns);
    }, [items])

    const handleChangePage = (event, newPage) => {
        setPageNo(newPage + 1);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setPageNo(1);
    };

    const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, marginLeft: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    <FirstPageIcon />
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    };

    const addProductItem = () => {
        navigate('/inventory-product/add-product-item');
    };

    const handleFilter = () => {
        if (serialNumber.length) {
            payload.serialNumber = serialNumber;
        }
        payload.barcodeGenerated = barcodeGenerated === "Yes" ? true : false;
        payload.barcodeScanned = barcodeScanned === "Yes" ? true : false;
        payload.startAt = start_date;
        payload.endAt = end_date;
        payload.date = "createdAt";

        if (productName || productRate || productWeight) {
            payload.questions = [];
        }

        if (productName) {
            const question = {
                "title": "Product name",
                "answer.answer.value": productName,
                "allowFilter": false
            }
            payload.questions.push(question)
        }
        if (productRate) {
            const question = {
                "title": "Product rate",
                "answer.answer.value": productRate,
                "allowFilter": false
            }
            payload.questions.push(question)
        }
        if (productWeight) {
            const question = {
                "title": "Product weight & product measurement",
                "answer.answer.value.weight": productWeight,
                "answer.answer.value.measurement": productMeasurement,
                "allowFilter": false
            }
            payload.questions.push(question)
        }
        getProductItems();
        setOpenFilterModal(false);
    };

    const handleSelection = (rowSelectionModel) => {
        setRowSelectionModel(rowSelectionModel);
    };

    const handleEdit = () => {
        let productData = localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId"));
        const findQuestions = items.filter(item => item._id === rowSelectionModel[0]);

        localStorage.setItem("userId", JSON.stringify({ ...productData, productDetails: { questions: findQuestions[0].productDetails.questions } }))

        localStorage.setItem("productItemIds", JSON.stringify(rowSelectionModel));
        navigate('/inventory-product/edit-product-item');
    };

    const handleDelete = () => {
        const getSerialNumbers = items.map(item => rowSelectionModel.includes(item._id) && item.serialNumber).filter(item => item);

        console.log("getSerialNumbers", getSerialNumbers);

        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do delete below products -" + getSerialNumbers.join(', '),
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        const id = localStorage.getItem("companyId");

                        const data = {
                            url: BASE_URL + `delete-inventory-product-items?company_id=${id}`,
                            body: {
                                ids: rowSelectionModel,
                            },
                        };

                        dispatch(DeleteProductItemsInventoryApi(data, getProductItems));
                        setTimeout(() => {
                        }, 500);
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    return (
        <>
            <ToastContainer autoClose={3000} />
            {loader && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <ProgressbarTransparent play />
                </div>)
            }
            <Modal
                open={openFilterModal}
                onClose={() => setOpenFilterModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={false}
            >
                <Box sx={{ ...downloadAllstyle, paddingX: 4 }}>
                    <Box
                        sx={{
                            position: "sticky",
                            right: "15px",
                            // top: "15px",
                            cursor: "pointer",
                            textAlign: "right",
                            padding: "0px 10px",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Box onClick={() => setOpenFilterModal(false)}>
                            <CloseIcon />
                        </Box>
                    </Box>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Serial number</label>
                        <TextField
                            size="small"
                            fullWidth
                            id="outlined-start-adornment"
                            value={serialNumber}
                            onChange={(e) => setSerialNumber(e.target.value)}
                            MenuProps={MenuProps}
                            sx={{
                                borderRadius: "8px",
                                textAlign: "left",
                                marginTop: "10px",
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Barcode generated?</label>
                        <Select
                            size="small"
                            name="issueStatus"
                            onChange={(e) => setBarcodeGenerated(e.target.value)}
                            sx={{ borderRadius: "8px" }}
                            value={barcodeGenerated}
                            MenuProps={MenuProps}
                            placeholder="Select type"
                        >
                            <MenuItem selected value="" className="select-item">
                                Select value
                            </MenuItem>
                            <MenuItem value="Yes" className="select-item">
                                Yes
                            </MenuItem>
                            <MenuItem value="No" className="select-item">
                                No
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Barcode scanned?</label>
                        <Select
                            size="small"
                            name="issueStatus"
                            onChange={(e) => setBarcodeScanned(e.target.value)}
                            sx={{ borderRadius: "8px" }}
                            value={barcodeScanned}
                            MenuProps={MenuProps}
                            placeholder="Select type"
                        >
                            <MenuItem selected value="" className="select-item">
                                Select value
                            </MenuItem>
                            <MenuItem value="Yes" className="select-item">
                                Yes
                            </MenuItem>
                            <MenuItem value="No" className="select-item">
                                No
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Store</label>
                        <Autocomplete
                            id="store-select"
                            sx={{ width: "100%", paddingX: "1px" }}
                            options={stores}
                            value={selectedStore}
                            // autoHighlight
                            onChange={(event, newValue) => setSelectedStore(newValue)}
                            getOptionLabel={(option) => option.name || ''}
                            renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <Box {...optionProps}>
                                        <Typography>
                                            {option.name}
                                        </Typography>
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{
                                        padding: "0px"
                                    }}
                                // label="Template"
                                />
                            )}
                            size="small"
                        />
                    </FormControl>
                    <label>Date</label>
                    <Box
                        sx={{ mb: 2 }}
                        display={"flex"}
                        justifyContent={"space-between"}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="DD/MM/YYYY"
                                size="small"
                                value={start_date}
                                onChange={(newValue) => {
                                    let dateSort = moment(newValue?.$d)
                                        .format("YYYY-MM-DD")
                                        .split("/")
                                        .join("");
                                    setstart_date(dateSort);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                className="date-pick date-pick-submision"
                                maxDate={new Date()}
                            />
                        </LocalizationProvider>
                        <Typography className="date-to">to</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                // label="End Date"
                                inputFormat="DD/MM/YYYY"
                                size="small"
                                value={end_date}
                                onChange={(newValue) => {
                                    let dateSort = moment(newValue?.$d)
                                        .format("YYYY-MM-DD")
                                        .split("/")
                                        .join("");
                                    setend_date(dateSort);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                className="date-pick date-pick-submision"
                                maxDate={new Date()}
                                minDate={start_date}
                            />
                        </LocalizationProvider>
                    </Box>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Product name?</label>
                        <TextField
                            size="small"
                            fullWidth
                            id="outlined-start-adornment"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                            MenuProps={MenuProps}
                            sx={{
                                borderRadius: "8px",
                                textAlign: "left",
                                marginTop: "10px",
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Product rate?</label>
                        <TextField
                            size="small"
                            fullWidth
                            id="outlined-start-adornment"
                            value={productRate}
                            onChange={(e) => setProductRate(e.target.value)}
                            MenuProps={MenuProps}
                            sx={{
                                borderRadius: "8px",
                                textAlign: "left",
                                marginTop: "10px",
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Product weight & product measurement?</label>
                        <Box sx={{
                            display: "flex",
                            marginTop: "10px",
                            width: "100%",
                            gap: "8px"
                        }}>
                            <TextField
                                size="small"
                                id="outlined-start-adornment"
                                value={productWeight}
                                onChange={(e) => setProductWeight(e.target.value)}
                                MenuProps={MenuProps}
                                sx={{
                                    borderRadius: "8px",
                                    textAlign: "left",
                                    width: "60%"
                                }}
                            />
                            <Select
                                size="small"
                                name="productMeasurement"
                                onChange={(e) => setProductMeasurement(e.target.value)}
                                sx={{ borderRadius: "8px", width: "40%" }}
                                value={productMeasurement}
                                MenuProps={MenuProps}
                                placeholder="Select type"
                            >
                                <MenuItem selected value="" className="select-item">
                                    Select measurement
                                </MenuItem>
                                <MenuItem value="Yes" className="select-item">
                                    L - Liter
                                </MenuItem>
                                <MenuItem value="No" className="select-item">
                                    K - kilogram
                                </MenuItem>
                            </Select>
                        </Box>
                    </FormControl>

                    <Box
                        sx={{
                            display: "flex",
                            position: "sticky",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            gap: "30px",
                            px: 3,
                            pt: 1,
                            pb: 4,
                            background: "#fff",
                        }}
                    >
                        <Button
                            className="cancle-button cancle-hover"
                            style={{
                                height: "46px",
                                padding: "12px 16px",
                                width: "47%",
                                background: "#fff",
                            }}
                            onClick={() => {
                                setSerialNumber("");
                                setProductName("");
                                setProductRate("");
                                setProductWeight("");
                                setProductMeasurement("");
                                setBarcodeScanned("");
                                setBarcodeGenerated("");
                                setSelectedStore("");
                                const dates = {
                                    startDate: moment(new Date()).format("YYYY-MM-DD"),
                                    endDate: moment(new Date()).format("YYYY-MM-DD"),
                                };
                                setstart_date(dates.startDate);
                                setend_date(dates.endDate);

                                // localStorage.setItem("storedDateData", JSON.stringify(dates));
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            className="header-add cancle-hover"
                            style={{
                                height: "46px",
                                padding: "12px 16px",
                                width: "47%",
                            }}
                            onClick={(e) => {
                                handleFilter();
                            }}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Grid sx={{
                bgcolor: "#FFF",
                p: 3,
                borderRadius: "8px",
                height: `${tableHeight + 62}px`,
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
                marginBottom: "2px",
                width: "100%"
            }}>
                <Grid sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Grid>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="left"
                            justifyContent="flex-start"
                        >
                            <Stack direction="row" alignItems="left">
                                <Button
                                    className="header-add cancle-hover"
                                    style={{ height: "42px", borderRadius: "8px", padding: "12px 16px" }}
                                    onClick={(e) => setOpenFilterModal(true)}
                                >
                                    <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                                    Filter
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "20px",
                        marginBottom: "20px"
                    }}>
                        <Link
                            to="/inventory-product/add-product-item"
                            className="submenu-item"
                        >
                            <Button
                                className="header-add cancle-hover"
                                sx={{
                                    whiteSpace: "nowrap",
                                    ml: 1,
                                    height: "42px",
                                    borderRadius: "8px",
                                }}
                            >
                                <AddOutlinedIcon sx={{ mr: 1 }} />
                                Add Product item
                            </Button>
                        </Link>
                        <Button
                            className="header-add cancle-hover"
                            style={{ height: "42px", borderRadius: "8px", padding: "12px 16px" }}
                            onClick={(e) => handleEdit()}
                            disabled={!rowSelectionModel.length}
                        >Edit Item</Button>
                        <Button
                            className="header-add cancle-hover"
                            style={{ height: "42px", borderRadius: "8px", padding: "12px 16px" }}
                            onClick={(e) => handleDelete()}
                            disabled={!rowSelectionModel.length}
                        >Delete Item</Button>
                    </Grid>
                </Grid>
                <Grid style={{
                    height: `${tableHeight}px`,
                    overflowY: "auto",
                    width: "100%"
                }}>
                    <DataGrid
                        disableColumnMenu
                        columns={columns}
                        rows={items || []}
                        getRowId={(data) => data._id}
                        getRowHeight={() => "auto"}
                        hideFooter={true}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onSelectionModelChange={(rowSelectionModel) => handleSelection(rowSelectionModel)}
                        rowSelectionModel={rowSelectionModel}
                    />
                </Grid>
                <Grid container justifyContent="center">
                    <TablePagination
                        sx={{
                            bgcolor: "#FFF",
                            height: "55px",
                            mb: "0px",
                        }}
                        rowsPerPageOptions={[20, 30, 40]}
                        count={productItemsList?.paginate?.total_item}
                        rowsPerPage={perPage}
                        page={pageNo - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        // onPageSizeChange={onPageSizeChange}
                        labelRowsPerPage={"Items per Page"}
                        pagination={true}
                        pageSize={perPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ViewProductItems;