/** @format */

import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Captcha from "demos-react-captcha";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  LoggedInUserDetailsApi,
  LoginApi,
} from "../../redux/actions/LogInAction";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

const LoginForm = () => {
  const location = useLocation();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [captcha, setCaptcha] = useState(null);
  // const [showError, setShowError] = useState(false);
  // const [showPasswordEye, setShowPasswordEye] = useState(false);

  // const token = useSelector(
  //   (state) => state && state.logIn && state.logIn.token
  // );

  // const currentUserDetails = useSelector(
  //   (state) =>
  //     state &&
  //     state.logIn &&
  //     state.logIn.currentUserDetails &&
  //     state.logIn.currentUserDetails.data &&
  //     state.logIn.currentUserDetails.data.data &&
  //     state.logIn.currentUserDetails.data.data.user
  // );
  // useEffect(() => {
  //   if (currentUserDetails) {
  //     localStorage.setItem(
  //       "CurrentUserDetails",
  //       JSON.stringify(currentUserDetails)
  //     );
  //   }
  // }, [currentUserDetails]);
  // // console.log("userDetails", currentUserDetails);

  // useEffect(() => {
  //   localStorage.setItem("token", token);
  //   if (token) {
  //     navigate("/company-list");
  //   }
  //   // eslint-disable-next-line
  // }, [token]);

  // const validationSchema = Yup.object({
  //   email: Yup.string("Enter your email")
  //     .email("Enter a valid email")
  //     .required("Email is required"),
  //   password: Yup.string("Enter your password")
  //     .min(8, "Password should be of minimum 8 characters length")
  //     .required("Password is required"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //     password: "",
  //     checkbox: false,
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     values.captcha = captcha;
  //     if (values?.captcha === null) {
  //       setShowError(true);
  //       setCaptcha(false);
  //     }
  //     if (values?.captcha === true) {
  //       const allData = {
  //         body: values,
  //       };
  //       allData.url = BASE_URL + `sessions/create`;
  //       dispatch(LoginApi(allData));
  //       dispatch(LoggedInUserDetailsApi(allData));
  //     }
  //   },
  // });

  // const onCaptcha = (value) => {
  //   setCaptcha(value);
  //   setShowError(false);
  // };

  // const handleClickShowPassword = () => {
  //   setShowPasswordEye(!showPasswordEye);
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  // Becow code is without Ceptcha()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [showPasswordEye, setShowPasswordEye] = useState(false);

  const token = useSelector(
    (state) => state && state.logIn && state.logIn.token
  );

  const currentUserDetails = useSelector(
    (state) =>
      state &&
      state.logIn &&
      state.logIn.currentUserDetails &&
      state.logIn.currentUserDetails.data &&
      state.logIn.currentUserDetails.data.data &&
      state.logIn.currentUserDetails.data.data.user
  );
  useEffect(() => {
    if (currentUserDetails) {
      localStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(currentUserDetails)
      );
    }
  }, [currentUserDetails]);
  // console.log("userDetails", currentUserDetails);

  useEffect(() => {
    localStorage.setItem("token", token);
    if (token) {
      let getRoute = location.search.includes("?nextURL=");
      if (getRoute) {
        let getPath = location.search.split("?nextURL=");
        navigate(getPath[1]);
      } else {
        navigate("/company-list");
      }
    }
    // eslint-disable-next-line
  }, [token]);

  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const passwordFromLocalStorage = localStorage.getItem("password");
  const initialValues = {
    email: "",
    password: "",
    checkbox: !!passwordFromLocalStorage,
    rememberMe: !!passwordFromLocalStorage,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.checkbox) {
        localStorage.setItem("password", values.password);
      } else {
        localStorage.removeItem("password");
      }

      const allData = {
        body: values,
      };
      allData.url = BASE_URL + `sessions/create`;
      dispatch(LoginApi(allData));
      dispatch(LoggedInUserDetailsApi(allData));
    },
  });

  const handleClickShowPassword = () => {
    setShowPasswordEye(!showPasswordEye);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {/* <Box className="Login-form">
        <ToastContainer autoClose={3000} />
        <Container>
          <Grid container spacing={1} sx={{ justifyContent: "end" }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card sx={{ p: 4, borderRadius: "8px" }}>
                <h1 style={{ textAlign: "center", color: "#64748B" }}>
                  <b className="label-name">Welcome Back</b>
                </h1>
                <form onSubmit={formik.handleSubmit}>
                  <FormControl sx={{ mb: 2 }} fullWidth>
                    <label className="label-name">
                      <b>User Name</b>
                    </label>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      placeholder="mamun@test.com"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      className="date-input"
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 2 }} fullWidth>
                    <label className="label-name">
                      <b>Password</b>
                    </label>
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      type={showPasswordEye ? "text" : "password"}
                      placeholder="Password123@"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPasswordEye ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className="date-input"
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 2 }} fullWidth disabled={captcha}>
                    <label className="label-name">
                      <b>Security Text</b>
                    </label>
                    <Box
                      className={
                        showError
                          ? "captcha box-error"
                          : captcha === false
                          ? "captcha box-error"
                          : ""
                      }
                    >
                      <Captcha
                        fullWidth
                        className="captcha"
                        placeholder="Enter the shown text"
                        value={captcha}
                        onChange={onCaptcha}
                        onRefresh={() => setCaptcha(null)}
                        error={
                          formik.touched.captcha &&
                          Boolean(formik.errors.captcha)
                        }
                        helperText={
                          formik.touched.captcha && formik.errors.captcha
                        }
                        // className="captcha"
                      />
                      <p className="error-msg">
                        {showError
                          ? "Text is required"
                          : captcha === false
                          ? "Please enter correct security text"
                          : ""}
                      </p>
                    </Box>
                  </FormControl>
                  <FormControl sx={{ mb: 2 }} fullWidth>
                    <FormGroup>
                      <FormControlLabel
                        className="label-name"
                        control={
                          <Checkbox
                            type="checkbox"
                            name="checkbox"
                            value={formik.values.checkbox}
                            onChange={formik.handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ color: "#196773" }}
                          />
                        }
                        label="Remember me on this computer"
                      />
                    </FormGroup>
                  </FormControl>
                  <Box>
                    <Button fullWidth type="submit" className="login-button">
                      Log in
                    </Button>
                    <Box
                      className="login-futter"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Box>
                        <Link to={"/signUp"} className="forgot-link">
                          Sign Up
                        </Link>
                      </Box>
                      <Box>
                        <Link to={"/forgotPassword"} className="forgot-link">
                          Forgot Password?
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box> */}

      <Box className="Login-form">
        <ToastContainer autoClose={3000} />
        <Container>
          <Grid container spacing={1} sx={{ justifyContent: "end" }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card sx={{ p: 4, borderRadius: "8px" }}>
                <h1 style={{ textAlign: "center", color: "#64748B" }}>
                  <b className="label-name">Welcome Back</b>
                </h1>
                <form onSubmit={formik.handleSubmit}>
                  <FormControl sx={{ mb: 2 }} fullWidth>
                    <label className="label-name">
                      <b>User Name</b>
                    </label>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      // placeholder="mamun@test.com"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      className="date-input"
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 2 }} fullWidth>
                    <label className="label-name">
                      <b>Password</b>
                    </label>
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      type={showPasswordEye ? "text" : "password"}
                      // placeholder="Password123@"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPasswordEye ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className="date-input"
                    />
                  </FormControl>
                  {/* <FormControl sx={{ mb: 2 }} fullWidth>
                    <FormGroup>
                      <FormControlLabel
                        className="label-name"
                        control={
                          <Checkbox
                            type="checkbox"
                            name="checkbox"
                            value={formik.values.checkbox}
                            onChange={formik.handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ color: "#196773" }}
                          />
                        }
                        label="Remember me on this computer"
                      />
                    </FormGroup>
                  </FormControl> */}
                  <Box>
                    <Button fullWidth type="submit" className="login-button">
                      Log in
                    </Button>
                    <Box
                      className="login-futter"
                      sx={{ justifyContent: "space-between" }}
                    >
                      <Box>
                        <Link to={"/signUp"} className="forgot-link">
                          Sign Up
                        </Link>
                      </Box>
                      <Box>
                        <Link to={"/forgotPassword"} className="forgot-link">
                          Forgot Password?
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default LoginForm;
