import React, { useEffect, useState } from "react";
import {
  Stack,
  Tabs,
  Tab,
  Button,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useSelector } from "react-redux";
import { DetailsStoreApi } from "../../redux/actions/Store";
import ToggleSwitchStore from "./ToggleSwitchStore";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from '@mui/material/Tooltip';

const TableHeader = ({
  searchOnChange,
  searchValue,
  handleKeyDown,
  permission,
}) => {
  const [value, setValue] = useState(0);
  const [enableDisableStore, SetEnableDisableStore] = useState(false);
  const [open, setOpen] = useState(false);

  const handleChangeTabs = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            {/* <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                label="Active"
                icon={<p className="active-tabs-content">28</p>}
                iconPosition="end"
                className="active-tabs tabs-content"
              />
              <Tab
                label="Archived"
                icon={<p className="active-tabs-content">8</p>}
                iconPosition="end"
                className="Archived-tabs tabs-content"
              />
            </Tabs> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <SearchAppBar
                searchValue={searchValue}
                searchOnChange={searchOnChange}
                handleKeyDown={handleKeyDown}
              />
              {permission?.store?.manageStore === true ? (
                <Link to="/store/add-store" className="submenu-item">
                  <Button className="header-button cancle-hover store-head" sx={{marginRight:{lg:1},height:"40px",marginTop:"2px"}}>
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    Add Store
                  </Button>
                </Link>
              ) : (
                ""
              )}
              <div style={{marginRight:"8px",marginTop:"6px"}}>
                <Tooltip
                       title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                    </Tooltip>
                    </div>
              {/* <Link to="/store/assign-user" className="submenu-item">
                <Button className="header-button cancle-hover store-head">
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Assign User
                </Button>
              </Link> */}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default TableHeader;

export const ViewHeader = (props) => {
  const { id } = useParams();
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const [detailedData, setDetailedData] = useState();
  const [checked, setChecked] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getStoreDetails = useSelector(
    (state) =>
      state.store &&
      state.store.data &&
      state.store.data.data &&
      state.store.data.data.data
  );

  const getStoreEnableDisable = useSelector(
    (state) =>
      state.store &&
      state.store.storeEnableDisableReducer &&
      state.store.storeEnableDisableReducer.data &&
      state.store.storeEnableDisableReducer.data.data
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (getStoreDetails) {
      setDetailedData(getStoreDetails);
    }
  }, [getStoreDetails]);

  useEffect(() => {
    setDetailedData(getStoreEnableDisable);
  }, [getStoreEnableDisable]);

  // useEffect(() => {
  //   if (detailedData?.approved === true) {
  //     setIsEnableDisable(false);
  //   } else {
  //     setIsEnableDisable(true);
  //   }
  // }, [detailedData]);
  // store enable disable ended

  // const handleClickEdit = (row) => {
  //   if (row) {
  //     navigate("store/editstore", { state: { data: row } });
  //   }
  // };

  const storeDetails = () => {
    const _id = localStorage.getItem("companyId");
    const storeId = id;
    const data = {
      url: BASE_URL + `stores/${storeId}?company_id=${_id}`,
    };
    dispatch(DetailsStoreApi(data));
  };

  useEffect(() => {
    storeDetails();
  }, []);

  return (
    <>
      <Grid container alignItems="center" className="header-card">
        <Grid item md={6} sx={12} sm={12} lg={6}>
          <Stack direction="row" spacing={1} sx={{ pl: 2 }}>
            <Typography>
              Created at :
              <strong>{moment(props?.storeDetails?.createdAt).format("lll")}</strong>
            </Typography>
            <Typography>
              Updated at :
              <strong>{moment(props?.storeDetails?.updatedAt).format("lll")}</strong>
            </Typography>
          </Stack>
        </Grid>

        <Grid item md={6}>
          <Stack direction="row" spacing={1} sx={{ float: "right", mr: 2 }}>
            {/* Enable disable Header UI Button  */}
            {/* <ToggleSwitchStore 
            check={checked}
            /> */}
            {/* <Button variant="outlined" className="search-bar" onClick={() => { handleChange }}>
              Enable <Switch {...label} />
            </Button> */}

            {/* Edit Store  */}
            {/* <Button variant="outlined" color="warning" className="cancle-hover"
            onClick={() => {
              handleClickEdit(getStoreDetails);
            }}
            >
              <BorderColorIcon sx={{ mr: 1 }} /> Edit
            </Button> */}
            {/* <Link to="/store/assign-user" className="submenu-item">
              <Button className="header-button cancle-hover">
                <AddIcon sx={{ mr: 1 }} />
                Assign User
              </Button>
            </Link> */}
            {/* <Link to="/store" className="submenu-item">
              <Button variant="outlined" className="done-btn cancle-hover">
                <CheckIcon sx={{ mr: 1 }} /> Done
              </Button>
            </Link> */}
            <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
