/** @format */

import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, TextField } from "material-ui-core";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { CreateRolesApi } from "../../redux/actions/Roles";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const RoleModel = (props) => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    name: Yup.string("Enter your Name")
      .required("Name is required")
      .matches(/^[^\s].*[^\s]$/, "Special characters or spaces not allowed at the beginning or end")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphanumeric characters and spaces are allowed"),
    rank: Yup.string("Enter your Rank").required("Rank is required"),
    mobile_number: Yup.string("Enter your Mobile Number")
      .required("Mobile Number is required")
      .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      rank: "",
      email: "",
      mobile_number: "",
    },
    enableReinitialize: true, 
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = {
        name: values.name,
        rank: values.rank,
        email: values.email,
        mobile: values.mobile_number,
      };
      const allData = {
        body: formData,
      };

      const callback = () => {
        resetForm(); // Clear form fields after submission
        props.handleClose();
      };

      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `createSubrole?company_id=${id}`;
        dispatch(CreateRolesApi(allData, callback));
      }
    },
  });

  const handleClose = () => {
    formik.resetForm(); 
    props.handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="cross-icon"
        >
          <Typography variant="p" component="div" className="heading">
            Add Sub Role
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <label className="rolemodel-lebel">
                    <b>Name</b>
                  </label>
                  <TextField
                    id="name"
                    variant="outlined"
                    name="name"
                    placeholder="Enter Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : " "
                    }
                    fullWidth
                  />
                  <label className="rolemodel-lebel">
                    <b>Email</b>
                  </label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <label className="rolemodel-lebel">
                    <b>Rank</b>
                  </label>
                  <TextField
                    variant="outlined"
                    id="rank"
                    name="rank"
                    placeholder="Enter rank"
                    value={formik.values.rank}
                    onChange={formik.handleChange}
                    error={formik.touched.rank && Boolean(formik.errors.rank)}
                    helperText={formik.touched.rank && formik.errors.rank}
                    fullWidth
                  />
                  <div style={{ marginTop: "24px" }}>
                    <label className="rolemodel-lebel">
                      <b>Mobile</b>
                    </label>
                    <TextField
                      variant="outlined"
                      id="mobile_number"
                      name="mobile_number"
                      placeholder="Enter contact number"
                      value={formik.values.mobile_number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mobile_number &&
                        Boolean(formik.errors.mobile_number)
                      }
                      helperText={
                        formik.touched.mobile_number &&
                        formik.errors.mobile_number
                      }
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              color="success"
              className="issue-modal-savebtn"
            >
              Save
            </Button>
            <Button
              variant="contained"
              className="issue-modal-cancelbtn"
              onClick={handleClose} 
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default RoleModel;

