import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Card,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import * as moment from "moment";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import ImageViewer from "react-simple-image-viewer";
import Progressbar from "../GlobalProgress/Progressbar";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ChecklistImageApi } from "../redux/actions/Checklist";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { RatingtApi } from "../redux/actions/Checklist";
import { CreateImageLogApi } from "../redux/actions/Checklist";
import ImageViewerModal from "./ChecklistImageView";
import { borderRadius } from "@mui/system";
import ProgressbarStatic from "../GlobalProgress/ProgressbarStatic";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { createRef } from "react";
import YellowStar from "../../assets/icons/png-icons/yellow-star.png";
import ThumbupIcon from "../../assets/icons/png-icons/thumbup.png";
import ThumbdownIcon from "../../assets/icons/png-icons/thumbdown.png";
import CommentIcon from "../../assets/icons/png-icons/comment.png";
import ErrorIcon from "../images/Blank.png";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#000000ba",
  border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  // marginTop: "30px",
};

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 400,
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const options = {
  format: "a4",
  orientation: "p",
  unit: "mm",
  userUnit: 300,
};

const AllCheckListImagesGallery = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [modalopen, setModalOpen] = React.useState(false);
  const modalopenhandleOpen = () => setModalOpen(true);
  const modalopenhandleClose = () => setModalOpen(false);
  const modalopenFilterhandleClose = () => setOpenFilterModal(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD").split("/").join("")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD").split("/").join("")
  );
  const [imageLogChecklist, setImageLogCheckList] = useState();
  const [imageLogApiData, setImageLogApiData] = useState();

  const [open, setOpen] = useState(false);
  const [secondopen, setSecondOpen] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isImages, setIsImages] = useState([]);
  const [isQusImg, setIsQusImg] = useState([]);
  const [openChild, setOpenChild] = useState(false);
  const [comment, setComment] = useState("");
  const [tags, setTags] = useState("");
  const [rating, setRating] = useState("");
  const [imageId, setImageId] = useState("");
  const [like, setLike] = useState("");
  const [disLike, setDisLike] = useState("");
  const [imageKey, setImagesKey] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [uniqueUserId, setUniqueUserId] = useState("");
  const [ImageFirstkey, setImageFirstkey] = useState("");
  const [commentFirst, setCommentFirst] = useState("");
  const [uiRender, setUiRender] = useState("");
  const [selectedImage, setSelectedImage] = useState({});
  const [openImgformIndex, setOpenImgformIndex] = useState("");
  const [openAllDownload, setOpenAllDownload] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openImgTitle, setOpenImgTitle] = useState("");
  const [downloadAllImgIndex, setDownloadAllImgIndex] = useState("");
  var ref = createRef();
  const [failToloadImgList, setFailToloadImgList] = useState([]);
  const [filterFormTitle, setFilterFormTitle] = useState("");
  const [filterQName, setFilterQName] = useState("");
  const [filterTag, setFilterTag] = useState("");
  const [filterRating, setFilterRating] = useState("");
  const [filterFlag, setFilterFlag] = useState("");
  const [selectedInspection, setSelectedInspection] = useState("");
  const [allImages, setAllImages] = useState("");
  // const doc = new jsPDF();
  const starData = [1, 2, 3, 4, 5];

  console.log("imageLogApiData", imageLogApiData);
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const Loader = useSelector(
    (state) => state && state.checklist && state.checklist.loading
  );
  const imageLogData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.checkListImageData &&
      state?.checklist?.checkListImageData?.data &&
      state?.checklist?.checkListImageData?.data?.data
  );

  const loader = useSelector(
    (state) => state && state?.checklist && state?.checklist?.loading
  );

  const updateImageLogData = useSelector(
    (state) =>
      state &&
      state?.checklist &&
      state?.checklist?.ratingData &&
      state?.checklist?.ratingData?.data &&
      state?.checklist?.ratingData?.data?.data
  );

  const createImagePayload = {
    body: {
      imageKey: imageKey,
      comment: comment,
      rating: rating,
      like: like,
      dislike: disLike,
    },
  };

  useEffect(() => {
    if (open === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "hidden";
    }
    console.log("open", open);
  }, [open]);

  useEffect(() => {
    const data = {};
    const id = localStorage.getItem("companyId");
    const checkListId = localStorage.getItem("checkListId");
    data.url =
      BASE_URL +
      `Imagegallery?company_id=${id}&_id=${checkListId}&startTimeFilter=${startDate}&endTimeFilter=${endDate}`;
    dispatch(ChecklistImageApi(data));
    console.log("updateImageLogData", updateImageLogData, uiRender);
  }, [updateImageLogData, uiRender]);

  const handleFilter = () => {
    const data = {};
    const id = localStorage.getItem("companyId");
    const checkListId = localStorage.getItem("checkListId");
    data.url =
      BASE_URL +
      `Imagegallery?company_id=${id}&_id=${checkListId}&startTimeFilter=${startDate}&endTimeFilter=${endDate}&name=${filterQName}&tag=${filterTag}&formName=${filterFormTitle}&flag=${filterFlag}&rating=${filterRating}`;
    dispatch(ChecklistImageApi(data));
  };

  const handleFilterFlag = (event) => {
    setFilterFlag(event.target.value);
  };

  const ratingPayload = {
    body: {},
  };

  useEffect(() => {
    setImageLogApiData(imageLogData);
    console.log("imageLogData", imageLogData);
  }, [imageLogData]);
  let imageName = localStorage.getItem("imageKey");

  useEffect(() => {
    if (imageName) {
      setImagesKey(imageName);
    }
    console.log("imageName", imageName);
  }, [imageName]);

  const handleSubmitfirst = () => {
    const id = localStorage.getItem("companyId");
    createImagePayload.url = BASE_URL + `CreateImagelog?company_id=${id}`;
    dispatch(CreateImageLogApi(createImagePayload));
    setOpenChild(false);
  };

  const openImageViewer = useCallback((url, index) => {
    let imageUrl = [];
    imageUrl.push(url);
    setIsImages(imageUrl);
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setIsImages([]);
  };

  useEffect(() => {
    if (imageLogApiData) {
      // const newImageArray = [];
      // for (let item of imageLogApiData) {
      //   newImageArray.push(item?.Forms);
      // }
      let newImgsArray = [];
      imageLogApiData.map((items, i) => {
        if(items?.Forms){
          items?.Forms?.map(formImgs => {
            formImgs.questionImages.map(img => {
              newImgsArray.push(img);
            })
          })
        }
      });

      setAllImages(newImgsArray);      

    }
    console.log("imageLogApiData", imageLogApiData);
  }, [imageLogApiData]);

  const openQusImageViewer = (
    qusImgUrl,
    index,
    userId,
    imageLogApiDataIndex,
    title
  ) => {
    setUniqueUserId(userId);
    setSelectedImageIndex(index);
    setOpen(true);
    let qusImageUrl;
    qusImageUrl = qusImgUrl?.questionImages;
    setIsQusImg(qusImageUrl);
    localStorage?.removeItem("localImageId");
    setOpenImgformIndex(imageLogApiDataIndex);
    setOpenImgTitle(title);
  };

  const handleImageSelection = (qusImg) => {
    // setOpenImgTitle(title)
    // setOpenImgformIndex(formDataIndex)
    setOpen(true);
    setSelectedImage(qusImg);
    setComment(qusImg.comment);
    setTags(qusImg.tag);
    setRating(qusImg.rating);
  };

  const imageUpdateApi = (ratingPayload, imageid, type) => {
    if (imageid !== "") {
      const id = localStorage.getItem("companyId");
      ratingPayload.url = BASE_URL + `CreateImagelog?company_id=${id}`;
      dispatch(RatingtApi(ratingPayload, type));
    }
  };

  const getImageId = (imageData, index) => {
    let id = imageData?.ImageId;
    let imageKey = imageData?.key;
    if (id) {
      localStorage.setItem("localImageId", id);
    }
    if (imageKey) {
      localStorage.setItem("imageKey", imageKey);
    }
  };

  // const updateRating = () => {
  //   ratingPayload.body.comment = comment;
  //   ratingPayload.body.like = like;
  //   ratingPayload.body.disLike = disLike;
  //   ratingPayload.body.rating = rating;
  //   ratingPayload.body.imageKey = imageKey;

  //   imageUpdateApi(ratingPayload, imageId, 'Comment/Rating');
  // };

  const updateComment = (imageData) => {
    ratingPayload.body.comment = comment;
    ratingPayload.body.like = imageData.like;
    ratingPayload.body.disLike = imageData?.dislike;
    ratingPayload.body.rating = rating;
    ratingPayload.body.tag = tags;
    ratingPayload.body.imageKey = imageData.key;

    imageUpdateApi(ratingPayload, imageId, "Comment/Rating/Tags");
    setSelectedImage({ ...selectedImage, rating: rating, comment: comment });
  };

  const updateDislike = (imageData, index) => {
    // if (imageData?.comment === null) {
    //   handleSubmitfirst();
    // } else {
    let id = imageData?.ImageId;
    if (imageData?.dislike) {
      ratingPayload.body.dislike = imageData?.dislike + 1;
    } else {
      ratingPayload.body.dislike = 1;
    }
    if (id) {
      localStorage.setItem("localImageId", id);
    }
    ratingPayload.body.like = imageData.like ? imageData.like : 0;
    ratingPayload.body.imageKey = imageData.key;
    ratingPayload.body.rating = imageData.rating ? imageData.rating : 0;
    ratingPayload.body.comment = imageData.comment ? imageData.comment : "";
    imageUpdateApi(ratingPayload, imageData?.ImageId, "DisLike");
    setSelectedImage({ ...selectedImage, dislike: ratingPayload.body.dislike });
    setDisLike(ratingPayload.body.disLike);
    // }
  };

  const updateLike = (imageData, index) => {
    // if (imageData?.comment === null) {
    //   handleSubmitfirst();
    // } else {
    if (imageData?.like) {
      ratingPayload.body.like = imageData?.like + 1;
    } else {
      ratingPayload.body.like = 1;
    }
    ratingPayload.body.dislike = imageData.disLike ? imageData.disLike : 0;
    ratingPayload.body.imageKey = imageData.key;
    ratingPayload.body.rating = imageData.rating ? imageData.rating : 0;
    ratingPayload.body.comment = imageData.comment ? imageData.comment : "";
    imageUpdateApi(ratingPayload, imageData?.ImageId, "Like");
    setSelectedImage({ ...selectedImage, like: ratingPayload.body.like });
    setLike(ratingPayload.body.like);
    // }
  };

  const getImageData = (data, index) => {
    if (data) {
      setImageId(data?.ImageId);
      setLike(data?.like ? data?.like : 0);
      setDisLike(data?.disLike ? data?.disLike : 0);
    }
  };

  useEffect(() => {
    // `Imagegallery?company_id=${id}&_id=${checkListId}&startTimeFilter=${startDate}&endTimeFilter=${endDate}`;
    // `Imagegallery?company_id=6336a2b2c352ed567e350bff&_id=63763234b4bc59378b5c91ac&startTimeFilter=2022-10-01&endTimeFilter=2023-01-18`;
    const data = {};
    const id = localStorage.getItem("companyId");
    const checkListId = localStorage.getItem("checkListId");
    data.url =
      BASE_URL +
      `Imagegallery?company_id=${id}&_id=${checkListId}&startTimeFilter=${startDate}&endTimeFilter=${endDate}`;
    dispatch(ChecklistImageApi(data));
    console.log("calleddddd");
  }, []);
  const imageChecklistDetail = JSON.parse(
    localStorage.getItem("imageLogChecklistDetail")
  );
  useEffect(() => {
    if (imageChecklistDetail) setImageLogCheckList(imageChecklistDetail);
      console.log("calleddddd 2222");
  }, []);

  const handleDateClick = (e) => {
    const data = {};
    const id = localStorage.getItem("companyId");
    const checkListId = localStorage.getItem("checkListId");
    data.url =
      BASE_URL +
      `Imagegallery?company_id=${id}&_id=${checkListId}&startTimeFilter=${startDate}&endTimeFilter=${endDate}`;
    dispatch(ChecklistImageApi(data));
  };

  const handleInspection = (event, newValue) => {
    if (newValue) {
      setSelectedInspection(newValue);
    }
  };

  const handleGenerateInspection = async (url, promptQuery) => {
    if(!promptQuery)
    return;


    let res = []
    // setLodingPrompt(true);
    // try {
    return new Promise((resolve) => {
      const body = {
        "model": "gpt-4-turbo",
        "messages": [
          {
            "role": "user",
            "content": [
              {
                "type": "text",
                "text": promptQuery
              },
              {
                "type": "image_url",
                "image_url": {
                  "url": url
                }
              }
            ]
          }
        ],
        "max_tokens": 300
      }
      axios.post('https://api.openai.com/v1/chat/completions', body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
      }).then((response) => {
        // console.log('response', response, response?.data?.choices[0]?.message?.content)
        if(response?.data?.choices[0]?.message?.content){
          // return response?.data?.choices[0]?.message?.content
          res = response?.data?.choices[0]?.message?.content;
          // setInspectionResp(response?.data?.choices[0]?.message?.content);
          resolve(res)
          return res;
        }else{
          // setInspectionResp('Responce Not Found');
        }
        // setLodingPrompt(false);
      })
    })
    // } catch (error) {
    //    console.log(error);
    //   //  setLodingPrompt(false);
    //   //  setInspectionResp('Responce Not Found');
    // }
  }


  const handleClickInspection = async () => {
    const res = [];
    // const promises = allImages.map(img => {
    //   handleGenerateInspection(img.url, selectedInspection.query);
    // });

    Promise.all(allImages.map(img => {
      // handleGenerateInspection(img.url, selectedInspection.query);
      return new Promise((resolve) => {
        const body = {
          "model": "gpt-4-turbo",
          "messages": [
            {
              "role": "user",
              "content": [
                {
                  "type": "text",
                  "text": selectedInspection.query
                },
                {
                  "type": "image_url",
                  "image_url": {
                    "url": img.url
                  }
                }
              ]
            }
          ],
          "max_tokens": 300
        }
        axios.post('https://api.openai.com/v1/chat/completions', body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,
          },
        }).then((response) => {
          // console.log('response', response, response?.data?.choices[0]?.message?.content)
          if(response?.data?.choices[0]?.message?.content){
            // return response?.data?.choices[0]?.message?.content
            // response?.data?.choices[0]?.message?.content;
            // setInspectionResp(response?.data?.choices[0]?.message?.content);
            resolve(response?.data?.choices[0]?.message?.content)
            return response?.data?.choices[0]?.message?.content;
          }else{
            // setInspectionResp('Responce Not Found');
          }
          // setLodingPrompt(false);
        })
      })
    })).then(result => {

      console.log("res =======>", result);
    })

  };

  return (
    <Box>
      <ToastContainer autoClose={1000} />
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}
        <>
          <Box
            sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
            className="header-card"
          >
            <Grid container spacing={1}>
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Stack
                  direction="row"
                  className="stack"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px",
                  }}
                >
                  <Box 
                    sx={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          size="small"
                          value={startDate}
                          onChange={(newValue) => {
                            let dateSort = moment(newValue?.$d)
                              .format("YYYY-MM-DD")
                              .split("/")
                              .join("");
                            setStartDate(dateSort);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          className="date-pick"
                          maxDate={new Date()}
                        />
                      </LocalizationProvider>
                      <Typography className="date-to">to</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          size="small"
                          value={endDate}
                          onChange={(newValue) => {
                            let dateSort = moment(newValue?.$d)
                              .format("YYYY-MM-DD")
                              .split("/")
                              .join("");
                            setEndDate(dateSort);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          className="date-pick"
                          maxDate={new Date()}
                          minDate={startDate}
                        />
                      </LocalizationProvider>
                      <Button
                        className="header-add cancle-hover"
                        style={{ height: "46px", padding: "12px 16px" }}
                        onClick={(e) => handleDateClick(e)}
                      >
                        <CheckOutlinedIcon sx={{ mr: 1 }} />
                        Apply
                      </Button>
                    </Box>
                    {location.pathname === "/checklist/all-checklist/imagesInspection" && <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        options={[
                          {
                            title: "Cleaning Inspection",
                            value: "cleaning_inspection",
                            query: "Describe attached images based on very-clean, clean, dirty category. Start with category in line one and then explain reason.",
                          }
                        ]}
                        style={{
                          width: "250px"
                        }}
                        getOptionLabel={(option) => option?.title || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            size="small"
                            sx={{ borderRadius: "8px" }}
                            onBlur={() => {
                              if (!params.inputProps.value) {
                                // handleClearSelection();
                              }
                            }}
                          />
                        )}
                        value={selectedInspection}
                        onChange={handleInspection}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {option.title}
                          </li>
                        )}
                        // disabled={reportType === "submission"}
                        clearable
                        // onClear={handleClearSelection}
                      />
                      <Button
                        className="header-add cancle-hover"
                        style={{ height: "46px", padding: "12px 16px" }}
                        onClick={() => handleClickInspection()}
                      >
                        Generate Inspection
                      </Button>
                    </Box>}
                  </Box>
                  <Box style={{
                    display: "flex",
                    alignItems: "center"
                  }}>
                    <Button
                      className="header-add cancle-hover"
                      style={{textAlign:"right"}}
                      onClick={(e) => setOpenFilterModal(true)}
                    >
                      <FilterAltOutlinedIcon />
                      Filter
                    </Button>
                    <div style={{ marginRight: "8px", marginLeft: "20px" }}>
                      <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion status,
                              completion percentage & average compliance scores for the
                              selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "80vh",
              mt: 1,
              p: 1,
              overflowX: "hidden",
              overflowY: "auto",
              borderRadius: "8px",
              "&::-webkit-scrollbar": {
                width: 10,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            <Box sx={{ bgcolor: "#F6F8FC", borderRadius: "8px" }}>
              <Grid
                container
                sx={{
                  bgcolor: "#F6F8FC",
                  width: "100%",
                  borderRadius: "8px",
                  mt: 1,
                  p: 1,
                }}
                alignItems="center"
              >
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <Stack
                    direction="row"
                    sx={{ pl: 2 }}
                    className="Checklist-Detail-direction"
                  >
                    <Typography>
                      <strong>Checklist Details:-</strong>
                    </Typography>
                    <Typography>
                      Category: <b>{imageLogChecklist?.title}</b>
                    </Typography>
                    <Typography>
                      Frequency:{" "}
                      <b>
                        {imageLogChecklist?.frequency === 3
                          ? "Daily"
                          : imageLogChecklist?.frequency === 2
                          ? " Hourly"
                          : imageLogChecklist?.frequency === 5
                          ? "Monthly"
                          : imageLogChecklist?.frequency === 1
                          ? "No frequency"
                          : imageLogChecklist?.frequency === 4
                          ? "Weekly"
                          : "N/A"}
                      </b>
                    </Typography>
                    <Typography>
                      Start Date:{" "}
                      <b>{moment(imageLogChecklist?.starts_at).format("ll")}</b>
                    </Typography>
                    <Typography>
                      Expire Date:{" "}
                      <b>
                        {moment(imageLogChecklist?.expires_at).format("ll")}
                      </b>
                    </Typography>
                    <Typography>
                      Assignee:{" "}
                      <b>
                        {imageLogChecklist && imageLogChecklist.role
                          ? imageLogChecklist.role
                          : imageLogChecklist?.assignedTo?.name}
                      </b>
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <hr></hr>
            </Box>
            {/*  */}
            {imageLogApiData && imageLogApiData?.length > 0 ? (
              <div>
                {imageLogApiData &&
                  imageLogApiData?.length > 0 &&
                  imageLogApiData?.map((item, imageLogApiDataIndex) => {
                    return (
                      <Card className="user-information" sx={{ mb: 2 }}>
                        <Grid container spacing={0} sx={{ mt: 4 }}>
                          <Grid item md={12} lg={12} xs={12} sm={12}>
                            {item?.Forms?.map((formData, index) => {
                              return (
                                <Grid
                                  display="flex"
                                  container
                                  sx={{
                                    bgcolor: "white",
                                    borderRadius: "8px",
                                    mt: 0,
                                  }}
                                  alignItems="center"
                                  justifyContent="center"
                                  className="imagelog-grid-padding"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={9}
                                    lg={9}
                                    display="flex"
                                    alignItems="center"
                                    flexDirection="row-reverse"
                                    justifyContent="space-around"
                                    sx={{
                                      border: "2px solid darkGray",
                                      borderRadius: "8px",
                                      padding: "10px",
                                      columnGap: "10px",
                                    }}
                                  >
                                    <Grid item>
                                      <Typography className="user-img-div">
                                        <div>
                                          <img
                                            src={formData?.userImages?.url}
                                            alt="userimage"
                                            align="center"
                                            height={150}
                                            width={150}
                                            onClick={() => {
                                              openImageViewer(
                                                formData?.userImages?.url,
                                                index
                                              );
                                            }}
                                          />
                                        </div>
                                        {isViewerOpen && (
                                          <ImageViewer
                                            src={isImages}
                                            currentIndex={index}
                                            onClose={closeImageViewer}
                                            disableScroll={false}
                                            backgroundStyle={{
                                              backgroundColor:
                                                "rgba(0,0,0,0.1)",
                                            }}
                                            closeOnClickOutside={true}
                                          />
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      xs={12}
                                      sm={12}
                                      md={9}
                                      lg={9}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="space-between"
                                      sx={{
                                        rowGap: "20px",
                                      }}
                                    >
                                      <Typography
                                        align="left"
                                        style={{
                                          fontSize: "20px",
                                        }}
                                      >
                                        <strong>User Name: </strong>
                                        {item?.userName}
                                      </Typography>
                                      <Stack
                                        sx={{
                                          border: "1px solid darkGray",
                                          borderRadius: "8px",
                                          padding: "10px",
                                        }}
                                      >
                                        <Typography align="left">
                                          <strong>Form Title: </strong>
                                          {formData?.title}
                                        </Typography>
                                        <Typography align="left">
                                          <strong>Weightage Score: </strong>
                                          {`${(
                                            (formData?.calculatedOptionScore /
                                              formData?.calculatedQuestionScore) *
                                            100
                                          ).toFixed(2)}% `}
                                        </Typography>
                                        <Typography align="left">
                                          <strong>Start Time: </strong>
                                          {moment(
                                            formData?.submittedTime
                                          ).format("ll")}
                                        </Typography>
                                        <Typography align="left">
                                          <strong>Submitted Time: </strong>
                                          {moment(
                                            formData?.submittedTime
                                          ).format("ll")}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                  <Grid item md={10} xs={12} sm={12} lg={10}>
                                    <h3
                                      style={{
                                        textAlign: "center",
                                        marginBottom: 0,
                                        fontSize: "20px",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      Question Images
                                    </h3>
                                    <Box className="user-img-div">
                                      {formData?.questionImages &&
                                      formData?.questionImages.length ? (
                                        <>
                                          {/* <div
                                              className="slider-container"
                                              style={{ overflowY: "hidden" }}
                                            >
                                              <Carousel
                                                width="auto"
                                                height="100px"
                                                showArrows={true}
                                                showThumbs={true}
                                                className="carousel-style"
                                                borderRadius="3px"
                                                backgroundColor="black"
                                                // onChange={onChange}
                                                // onClickItem={onClickItem}
                                                // onClickThumb={onClickThumb}
                                              >
                                                {formData?.questionImages?.map(
                                                  (qusImg, index) => {
                                                    return (
                                                      <div
                                                        className="qusImg-container"
                                                        style={{
                                                          width: "100%",
                                                          borderRadius: "3px",
                                                          height: "400px",
                                                          backgroundColor:
                                                            "#e2e8f0",
                                                        }}
                                                        onClick={() => {
                                                          openQusImageViewer(
                                                            formData,
                                                            index,
                                                            formData?.userImages
                                                              ?.ImageId
                                                          );
                                                        }}
                                                        onMouseEnter={(e) =>
                                                          getImageData(
                                                            qusImg,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={qusImg?.url}
                                                          style={{
                                                            // width: "auto",
                                                            objectFit:
                                                              "contain",
                                                            height: "400px",
                                                            borderRadius: "3px",
                                                          }}
                                                        />
                                                        <div class="top-right">
                                                          <Button
                                                            sx={{
                                                              // marginTop: "30px",
                                                              padding:
                                                                "6px 12px 6px 12px",
                                                              background:
                                                                "rgb(12 36 49)",
                                                              color: "#ffffff",
                                                            }}
                                                            onClick={() => {
                                                              setComment(
                                                                qusImg?.comment
                                                                  ? qusImg?.comment
                                                                  : ""
                                                              );
                                                              setOpenChild(
                                                                true
                                                              );
                                                              setImageFirstkey(
                                                                qusImg?.name
                                                              );
                                                              setCommentFirst(
                                                                qusImg?.comment
                                                              );
                                                              setRating(
                                                                qusImg?.rating
                                                                  ? qusImg?.rating
                                                                  : 0
                                                              );
                                                            }}
                                                          >
                                                            Add Comment/Rating
                                                          </Button>
                                                        </div>

                                                        <div className="content">
                                                          <div className="rating-contaier">
                                                            {" "}
                                                            <Typography
                                                              sx={{
                                                                marginLeft:
                                                                  "10px",
                                                                padding: "5px",
                                                              }}
                                                            >
                                                              {qusImg?.rating
                                                                ? qusImg?.rating
                                                                : 0}{" "}
                                                              <StarIcon
                                                                style={{
                                                                  marginBottom:
                                                                    "-5px",
                                                                  color:
                                                                    "#f26539",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              ></StarIcon>
                                                            </Typography>
                                                          </div>
                                                          <div className="comment-contaier">
                                                            <p className="text-para">
                                                              {qusImg?.comment}
                                                            </p>
                                                          </div>
                                                          <div
                                                            style={{
                                                              backgroundColor:
                                                                "black",
                                                              color: "white",
                                                              height: "49px",
                                                            }}
                                                          >
                                                            <div className="like-dislike-container">
                                                              <p
                                                                style={{
                                                                  width: "10%",
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginTop:
                                                                    "3px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                <ThumbUpIcon
                                                                  onClick={() => {
                                                                    updateLike(
                                                                      qusImg,
                                                                      index
                                                                    );
                                                                    setOpen(
                                                                      false
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    marginBottom:
                                                                      "-4px",
                                                                    color:
                                                                      "#33b249",
                                                                    marginRight:
                                                                      "1px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                ></ThumbUpIcon>{" "}
                                                                {qusImg?.like
                                                                  ? qusImg?.like
                                                                  : 0}
                                                              </p>
                                                              <p
                                                                style={{
                                                                  width: "10%",
                                                                  display:
                                                                    "flex",
                                                                  flexDirection:
                                                                    "row",
                                                                  marginTop:
                                                                    "3px",
                                                                  marginLeft:
                                                                    "15px",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                <ThumbDownIcon
                                                                  onClick={() => {
                                                                    updateDislike(
                                                                      qusImg,
                                                                      index
                                                                    );
                                                                    setOpen(
                                                                      false
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    marginBottom:
                                                                      "-4px",
                                                                    color:
                                                                      "#ff0000",
                                                                    marginRight:
                                                                      "1px",
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                ></ThumbDownIcon>{" "}
                                                                {qusImg?.dislike
                                                                  ? qusImg?.dislike
                                                                  : 0}
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </Carousel>
                                            </div> */}
                                          <Grid
                                            container
                                            spacing={2}
                                            justifyContent={
                                              formData?.questionImages.length <=
                                              3
                                                ? "center"
                                                : "left"
                                            }
                                          >
                                            {formData?.questionImages?.map(
                                              (qusImg, index) => {
                                                return (
                                                  <Grid
                                                    item
                                                    xs={2}
                                                    sm={4}
                                                    md={3}
                                                    key={index}
                                                    onClick={() => {
                                                      handleImageSelection(
                                                        qusImg
                                                      );
                                                      openQusImageViewer(
                                                        formData,
                                                        index,
                                                        formData?.userImages
                                                          ?.ImageId,
                                                        imageLogApiDataIndex,
                                                        formData?.title
                                                      );
                                                    }}
                                                    onMouseEnter={(e) =>
                                                      getImageData(
                                                        qusImg,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <div className="qusImg-container">
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <img
                                                          src={qusImg?.url}
                                                          onError={(e) => (
                                                            (e.target.src =
                                                              ErrorIcon),
                                                            (e.target.style.height =
                                                              "200px"),
                                                            (e.target.style.width =
                                                              "100%"),
                                                            (e.target.style.marginTop =
                                                              "20%")
                                                          )}
                                                        />
                                                      </div>

                                                      <div>
                                                        <div
                                                          className="rating-contaier"
                                                          style={{
                                                            bottom:
                                                              qusImg?.comment
                                                                ? "50px"
                                                                : "15px",
                                                          }}
                                                        >
                                                          {" "}
                                                          <Typography
                                                            sx={{
                                                              // marginLeft:
                                                              //   "10px",
                                                              padding:
                                                                "3px 7px",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            {qusImg?.rating
                                                              ? qusImg?.rating
                                                              : 0}{" "}
                                                            <StarIcon
                                                              style={{
                                                                marginBottom:
                                                                  "-6px",
                                                                color:
                                                                  "#f26539",
                                                                cursor:
                                                                  "pointer",
                                                                width: "15px",
                                                              }}
                                                            ></StarIcon>
                                                          </Typography>
                                                        </div>
                                                        <div
                                                          className="like-dislike-container"
                                                          style={{
                                                            bottom:
                                                              qusImg?.comment
                                                                ? "50px"
                                                                : "15px",
                                                          }}
                                                        >
                                                          <p
                                                            style={{
                                                              // width: "10%",
                                                              display: "flex",
                                                              flexDirection:
                                                                "row",
                                                              // marginTop: "3px",
                                                              cursor: "pointer",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <ThumbUpIcon
                                                              // onClick={() => {
                                                              //   updateLike(
                                                              //     qusImg,
                                                              //     index
                                                              //   );
                                                              //   setOpen(false);
                                                              // }}
                                                              style={{
                                                                // marginBottom:
                                                                //   "-4px",
                                                                color:
                                                                  "#33b249",
                                                                // marginRight:
                                                                //   "1px",
                                                                cursor:
                                                                  "pointer",
                                                                width: "15px",
                                                              }}
                                                            ></ThumbUpIcon>{" "}
                                                            {qusImg?.like
                                                              ? qusImg?.like
                                                              : 0}
                                                          </p>
                                                          <p
                                                            style={{
                                                              // width: "10%",
                                                              display: "flex",
                                                              flexDirection:
                                                                "row",
                                                              // marginTop: "3px",
                                                              marginLeft:
                                                                "15px",
                                                              cursor: "pointer",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <ThumbDownIcon
                                                              // onClick={() => {
                                                              //   updateDislike(
                                                              //     qusImg,
                                                              //     index
                                                              //   );
                                                              //   setOpen(false);
                                                              // }}
                                                              style={{
                                                                // marginBottom:
                                                                //   "-4px",
                                                                color:
                                                                  "#ff0000",
                                                                // marginRight:
                                                                //   "1px",
                                                                cursor:
                                                                  "pointer",
                                                                width: "15px",
                                                              }}
                                                            ></ThumbDownIcon>{" "}
                                                            {qusImg?.dislike
                                                              ? qusImg?.dislike
                                                              : 0}
                                                          </p>
                                                        </div>

                                                        {qusImg?.comment && (
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection:
                                                                "row",
                                                              columnGap: "10px",
                                                              alignItems:
                                                                "center",
                                                              width: "92%",
                                                              position:
                                                                "absolute",
                                                              bottom: "15px",
                                                              left: "10px",
                                                              backgroundColor:
                                                                "rgb(0, 0, 0, 0.5)",
                                                              padding:
                                                                "3px 7px",
                                                              borderRadius:
                                                                "3px",
                                                            }}
                                                          >
                                                            <>
                                                              <ChatBubbleOutlineIcon
                                                                style={{
                                                                  color: "#FFF",
                                                                  height:
                                                                    "15px",
                                                                }}
                                                              />
                                                              <p
                                                                style={{
                                                                  width: "90%",
                                                                  overflow:
                                                                    "hidden",
                                                                  textOverflow:
                                                                    "ellipsis",
                                                                  margin: "0px",
                                                                  fontSize:
                                                                    "15px",
                                                                  textAlign:
                                                                    "start",
                                                                }}
                                                              >
                                                                {
                                                                  qusImg?.comment
                                                                }
                                                              </p>
                                                            </>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                );
                                              }
                                            )}
                                          </Grid>
                                        </>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                          }}
                                        >
                                          No Images Captured.
                                        </div>
                                      )}
                                    </Box>
                                    {formData?.questionImages &&
                                    formData?.questionImages.length ? (
                                      <Button
                                        style={{
                                          color: "white",
                                          backgroundColor: "#337ab7",
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                          marginTop: "20px",
                                          textAlign: "center",
                                          fontSize: "10px",
                                          letterSpacing: "1px",
                                        }}
                                        onClick={() => {
                                          setOpenAllDownload(true),
                                            setDownloadAllImgIndex(
                                              imageLogApiDataIndex
                                            ),
                                            setOpenImgTitle(formData.title);
                                        }}
                                      >
                                        Download All
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Card>
                    );
                  })}
              </div>
            ) : (
              <Typography
                sx={{ fontWeight: "bold", alignSelf: "center", top: "50%" }}
              >
                No Submission Found
              </Typography>
            )}
          </Box>
          <div>
            {open && (
              <Modal
                open={open}
                onClose={modalopenhandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={false}
              >
                <Box sx={style}>
                  {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                  Text in a modal
                </Typography> */}
                  {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                 Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
               </Ty33pography> */}
                  <ImageViewerModal
                    uniqueUserId={uniqueUserId}
                    imageLogApiData={imageLogApiData}
                    selectedImage={selectedImage}
                    setOpen={setOpen}
                    setModalOpens={setOpen}
                    isQusImg={isQusImg}
                    updateLike={updateLike}
                    updateDislike={updateDislike}
                    updateComment={updateComment}
                    getImageId={getImageId}
                    imageUpdateApi={imageUpdateApi}
                    // updateRating={updateRating}
                    setOpenChild={setOpenChild}
                    openChild={openChild}
                    setComment={setComment}
                    tags={tags}
                    setTags={setTags}
                    comment={comment}
                    setImageId={setImageId}
                    setRating={setRating}
                    rating={rating}
                    setDisLike={setDisLike}
                    disLike={disLike}
                    setLike={setLike}
                    like={like}
                    selectedImageIndex={selectedImageIndex}
                    handleSubmitfirst={handleSubmitfirst}
                    ImageFirstkey={ImageFirstkey}
                    commentFirst={commentFirst}
                    setUiRender={setUiRender}
                    openImgformIndex={openImgformIndex}
                    openImgTitle={openImgTitle}
                  />
                </Box>
              </Modal>
            )}
            {openAllDownload && (
              <Modal
                open={openAllDownload}
                onClose={() => {
                  setOpenAllDownload(false);
                  setFailToloadImgList([]);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={downloadAllstyle}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div ref={(el) => (ref = el)}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "60px 0",
                        }}
                      >
                        <img
                          src="https://client.popprobe.com/assets/icons/newLogo.png"
                          alt=""
                          data-default="placeholder"
                          data-max-width="560"
                          height="100"
                        />
                      </div>
                      <Grid item xs={2} sm={4} md={3}>
                        {imageLogApiData?.map((item, index) => {
                          return (
                            index === downloadAllImgIndex &&
                            item.Forms.map((formData) => {
                              return (
                                formData.title === openImgTitle &&
                                formData.questionImages.map(
                                  (imgItem, imgIndex) => {
                                    return (
                                      <div
                                        className={`dowImg-container ${
                                          imgIndex !==
                                          formData.questionImages.length - 1
                                            ? "download-all-img"
                                            : ""
                                        }`}
                                      >
                                        {imgIndex === 0 && (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              marginBottom: "64px",
                                              color: "black",
                                            }}
                                          >
                                            <>
                                              <Typography
                                                align="left"
                                                style={{
                                                  fontSize: "15px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: "2px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <strong>
                                                    Checklist Name:
                                                  </strong>
                                                </span>
                                                <span
                                                  style={{
                                                    wordBreak: "break-alls",
                                                  }}
                                                >
                                                  {item.category}
                                                </span>
                                              </Typography>
                                              <Typography
                                                align="left"
                                                style={{
                                                  fontSize: "15px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: "2px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <strong>User name:</strong>
                                                </span>
                                                <span
                                                  style={{
                                                    wordBreak: "break-alls",
                                                  }}
                                                >
                                                  {item.userName || "-"}
                                                </span>
                                              </Typography>
                                              <Typography
                                                align="left"
                                                style={{
                                                  fontSize: "15px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: "2px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <strong>
                                                    Store Location:
                                                  </strong>
                                                </span>
                                                <span
                                                  style={{
                                                    wordBreak: "break-alls",
                                                  }}
                                                >
                                                  {item.storeName || "-"}
                                                </span>
                                              </Typography>
                                              <Typography
                                                align="left"
                                                style={{
                                                  fontSize: "15px",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  gap: "2px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  <strong>Submit Date:</strong>
                                                </span>
                                                <span
                                                  style={{
                                                    wordBreak: "break-alls",
                                                  }}
                                                >
                                                  {moment(
                                                    formData.submittedTime
                                                  ).format("lll")}
                                                </span>
                                              </Typography>
                                            </>
                                          </div>
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "16px",
                                            color: "black",
                                            marginTop:
                                              imgIndex !== 0 ? "60px" : "",
                                          }}
                                        >
                                          <>
                                            <Typography
                                              align="left"
                                              style={{
                                                fontSize: "15px",
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "2px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                <strong>Question:</strong>
                                              </span>
                                              <span
                                                style={{
                                                  wordBreak: "break-alls",
                                                }}
                                              >
                                                {imgItem.question.title}
                                              </span>
                                            </Typography>
                                            {/* <Typography
                                            align="left"
                                            style={{
                                              fontSize: "15px",
                                              display: "flex",
                                              flexDirection: "row",
                                              gap: "2px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              <strong>Answer:</strong>
                                            </span>
                                            <span
                                              style={{
                                                wordBreak: "break-alls",
                                              }}
                                            >
                                              test
                                            </span>
                                          </Typography> */}
                                            <Typography
                                              align="left"
                                              style={{
                                                fontSize: "15px",
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "2px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                <strong>Answer Rank:</strong>
                                              </span>
                                              <span
                                                style={{
                                                  wordBreak: "break-alls",
                                                }}
                                              >
                                                {
                                                  imgItem.question
                                                    .question_score
                                                }
                                              </span>
                                            </Typography>
                                          </>
                                        </div>
                                        <div>
                                          <img
                                            src={`https://popprobe-saas.s3.us-west-2.amazonaws.com/${imgItem.key}`}
                                            className="captured-img"
                                            onError={(e) => (
                                              (e.target.src = ErrorIcon),
                                              (e.target.style.height = "200px"),
                                              (e.target.style.width = "100%"),
                                              (e.target.style.marginTop =
                                                "15%"),
                                              (e.target.style.marginBottom =
                                                "15%"),
                                              setFailToloadImgList([
                                                ...failToloadImgList,
                                                imgIndex,
                                              ])
                                            )}
                                          />
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <div className="rating-contaier">
                                                {" "}
                                                <Typography
                                                  sx={{
                                                    padding: "4px 12px",
                                                    fontSize: "15px",
                                                  }}
                                                >
                                                  <strong>
                                                    {imgItem?.rating
                                                      ? imgItem?.rating
                                                      : 0}
                                                  </strong>
                                                  <img
                                                    src={YellowStar}
                                                    style={{
                                                      height: "17px",
                                                      width: "17px",
                                                      cursor: "pointer",
                                                      marginBottom: "-3px",
                                                    }}
                                                  />
                                                </Typography>
                                              </div>
                                              <div className="like-dislike-container">
                                                <p
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    cursor: "pointer",
                                                    fontSize: "15px",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    src={ThumbupIcon}
                                                    alt=""
                                                    style={{
                                                      height: "17px",
                                                      width: "17px",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <strong>
                                                    {imgItem?.like
                                                      ? imgItem?.like
                                                      : 0}
                                                  </strong>
                                                </p>
                                                <p
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "15px",
                                                    cursor: "pointer",
                                                    fontSize: "15px",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    src={ThumbdownIcon}
                                                    alt=""
                                                    style={{
                                                      height: "17px",
                                                      width: "17px",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <strong>
                                                    {imgItem?.dislike
                                                      ? imgItem?.dislike
                                                      : 0}
                                                  </strong>
                                                </p>
                                              </div>
                                            </div>
                                            {imgItem?.comment && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  columnGap: "10px",
                                                  alignItems: "center",
                                                  width: "100%",
                                                  backgroundColor:
                                                    "rgb(0, 0, 0, 0.5)",
                                                  padding: "4px 12px",
                                                  borderRadius: "3px",
                                                  alignContent: "center",
                                                }}
                                              >
                                                <>
                                                  <img
                                                    src={CommentIcon}
                                                    alt=""
                                                    style={{
                                                      height: "17px",
                                                      width: "17px",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <p
                                                    style={{
                                                      width: "90%",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      margin: "0px",
                                                      fontSize: "16px",
                                                      textAlign: "start",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    {imgItem?.comment}
                                                  </p>
                                                </>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              );
                            })
                          );
                        })}
                      </Grid>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            style={{
                              color: "white",
                              backgroundColor: "#337ab7",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              textAlign: "center",
                              fontSize: "12px",
                              letterSpacing: "1px",
                              width: "100%",
                            }}
                          >
                            Download
                          </Button>
                        )}
                        content={() => ref}
                        // documentTitle={selectedImage.question.title}
                      />
                    </div>
                  </div>
                </Box>
              </Modal>
            )}
            {openFilterModal && (
              <Modal
                open={openFilterModal}
                onClose={modalopenFilterhandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={false}
              >
                <Box sx={{ ...downloadAllstyle, p: 0 }}>
                  <Box>
                    <Box
                      sx={{
                        pt: 4,
                        px: 4,
                      }}
                    >
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Form Title</label>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          className="issueinput-fields"
                          multiline
                          name="FormTitle"
                          onChange={(e) => setFilterFormTitle(e.target.value)}
                          value={filterFormTitle}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Question Name</label>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          className="issueinput-fields"
                          multiline
                          name="QName"
                          onChange={(e) => setFilterQName(e.target.value)}
                          value={filterQName}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Tag</label>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          className="issueinput-fields"
                          multiline
                          name="tag"
                          onChange={(e) => setFilterTag(e.target.value)}
                          value={filterTag}
                        />
                      </FormControl>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingBottom: "16px",
                        }}
                      >
                        <label>Select Rating</label>
                        <div style={{ display: "flex" }}>
                          {starData.map((item) => {
                            return (
                              <Typography>
                                <StarIcon
                                  onClick={() => setFilterRating(item)}
                                  style={{
                                    marginBottom: "-5px",
                                    marginLeft: item !== 1 ? "10px" : "0px",
                                    color: filterRating >= item && "#f26539",
                                    cursor: "pointer",
                                  }}
                                ></StarIcon>
                              </Typography>
                            );
                          })}
                        </div>
                      </div>

                      <Stack direction="row" className="stack">
                        <FormControl
                          fullWidth
                          sx={{
                            background: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={filterFlag}
                            onChange={handleFilterFlag}
                            sx={{
                              flexDirection: "column",
                            }}
                          >
                            <FormControlLabel
                              value="like"
                              control={<Radio />}
                              label="Like"
                            />
                            <FormControlLabel
                              value="dislike"
                              control={<Radio />}
                              label="DisLike"
                            />
                            <FormControlLabel
                              value="both"
                              control={<Radio />}
                              label="Both"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      position: "sticky",
                      bottom: 0,
                      width: "100%",
                      gap: "30px",
                      px: 3,
                      pt: 1,
                      background: "#fff",
                    }}
                  >
                    <Button
                      className="cancle-button cancle-hover"
                      style={{
                        height: "46px",
                        padding: "12px 16px",
                        width: "47%",
                        background: "#fff",
                      }}
                      onClick={() => {
                        setFilterQName("");
                        setFilterTag("");
                        setFilterFlag("");
                        setFilterFormTitle("");
                        setFilterRating("");
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      className="header-add cancle-hover"
                      style={{
                        height: "46px",
                        padding: "12px 16px",
                        width: "47%",
                      }}
                      onClick={(e) => {
                        setOpenFilterModal(false);
                        handleFilter();
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Modal>
            )}
          </div>
        </>
        <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </Box>
  );
};

export default AllCheckListImagesGallery;
