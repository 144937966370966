import {
  Box,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import LogHeader from "./LogHeader";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import {
  DeleteLogEntryApi,
  ViewAllLogEntryListApi,
} from "../redux/actions/LogEntry";
import { useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import View from "../images/View.svg";
import Delete from "../images/Delete.svg";
import ViewHover from "../images/ViewHover.svg";
import DeleteHover from "../images/DeleteHover.svg";
import EditHover from "../images/EditHover.svg";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import Edit from "../images/Edit.svg";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../NoDataFound";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { makeStyles, useTheme } from "material-ui-core";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import ToggleSwitchLogEntry from "../../helpers/ToggleSwitchLogEtnry";

const label = { inputProps: { "aria-label": "Color switch demo" } };

const LogEntries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = localStorage.getItem("permissionData");

  const logEntryFilter = localStorage.getItem("logEntryFilter");
  const filterData = logEntryFilter && JSON.parse(logEntryFilter);

  const [page, setPage] = useState(filterData?.body?.pagination?.page || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    filterData?.body?.pagination?.per_page || 9
  );
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState(
    filterData?.body?.start_date || moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    filterData?.body?.end_date || moment(new Date()).format("YYYY-MM-DD")
  );
  const [hover, setHover] = useState({
    View: false,
    Delete: false,
  });
  const [indexHover, setIndexHover] = useState(0);
  const [permission, setPermission] = useState({});
  const [logEntryData, setLogEntryData] = useState({});
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [uniqueSlug, setUniqueSlug] = useState("");

  const logEntryLoader = useSelector(
    (state) => state.logEntry && state.logEntry.loading
  );
  const logEntry = useSelector(
    (state) =>
      state.logEntry &&
      state.logEntry.logEntryListData &&
      state.logEntry.logEntryListData.data &&
      state.logEntry.logEntryListData.data.data &&
      state.logEntry.logEntryListData.data.data
  );

  const logEntryListRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      search: title,
    },
  };

  const handleViewList = () => {
    const id = localStorage.getItem("companyId");

    const data = {
      url: BASE_URL + `logEntryList?company_id=${id}`,
      ...logEntryListRequest,
    };
    dispatch(ViewAllLogEntryListApi(data));
  };

  useEffect(() => {
    handleViewList();
  }, []);

  useEffect(() => {
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, [permissions]);

  useEffect(() => {
    setLogEntryData(logEntry?.logEntry);
    setTotalCount(logEntry?.paginate?.total_item);
  }, [logEntry]);

  const handleViewDetails = (row) => {
    localStorage.setItem("logEntryId", row._id);
    localStorage.setItem("selectedLogEntry", JSON.stringify(row));
    navigate("/log-entry/view-log-entries-submissions");
  };

  const handleEditDetails = (row) => {
    navigate("/log-entry/edit");
    localStorage.setItem("userId", JSON.stringify(row));
  };

  const callBack = () => {
    handleViewList();
  };

  const handleDelete = (values) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const logEntryId = values?._id;
            console.log("logEntryId", logEntryId);
            const data = {
              url: BASE_URL + `logEntry/${logEntryId}?company_id=${id}`,
            };
            dispatch(DeleteLogEntryApi(data, callBack));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    logEntryListRequest.body.pagination.page = newPage + 1;
    localStorage.setItem("logEntryFilter", JSON.stringify(logEntryListRequest));
    handleViewList(logEntryListRequest);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    logEntryListRequest.body.pagination.page = 1;
    // localStorage.removeItem("pageno");
    // localStorage.setItem("pageno", 1);
    logEntryListRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    localStorage.setItem("logEntryFilter", JSON.stringify(logEntryListRequest));
    handleViewList(logEntryListRequest);
  };

  const handleFilterData = (filterValues) => {
    if (filterValues?.status) {
      logEntryListRequest.body.status = filterValues.status;
    } else {
      delete logEntryListRequest.body.status;
    }

    if (filterValues?.search) {
      logEntryListRequest.body.search = filterValues.search;
    } else {
      delete logEntryListRequest.body.search;
    }

    // Update pagination and other properties
    logEntryListRequest.body.pagination.page = 1;

    // Update component state
    setTitle(filterValues?.search);
    setStatus(filterValues?.status);
    setPage(1);

    // Perform the API call or further processing
    handleViewList(logEntryListRequest);

    // Save the current filter in localStorage
    localStorage.setItem("logEntryFilter", JSON.stringify(logEntryListRequest));
  };

  console.log("permission?.logEntry", permission?.logEntry);
  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <LogHeader onFilter={handleFilterData} permission={permission} />
      {logEntryLoader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}

      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "calc(100vh - 280px)",
          mt: 1,
          borderRadius: "8px",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            padding: "20px",
          }}
        >
          {logEntryData && logEntryData.length > 0 ? (
            logEntryData.map((item, i) => {
              return (
                <Grid
                  key={item._id}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  display={"flex"}
                  alignItems={"flex-start"}
                >
                  <Grid sx={{ height: "100%" }} xs={11} sm={11} md={11} lg={10}>
                    <Card
                      sx={{ p: 2, height: "100%", overflow: "auto" }}
                      className="card"
                    >
                      <Table className="priroty-table">
                        <TableBody>
                          <TableRow></TableRow>
                          <TableRow>
                            <TableCell className="header-table">
                              Title:
                            </TableCell>
                            <TableCell>
                              <b>
                                {item.logEntryTitle ? item.logEntryTitle : "NA"}
                              </b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="header-table">
                              Token:
                            </TableCell>
                            <TableCell>
                              <b>
                                {item.logEntrySlug ? item.logEntrySlug : "NA"}
                              </b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="header-table">
                              Description:
                            </TableCell>
                            <TableCell
                              sx={{
                                wordBreak: "break-word",
                                wordWrap: "break-word",
                                textWrap: "wrap",
                              }}
                            >
                              <b>
                                {item.description ? item.description : "NA"}
                              </b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="header-table">
                              Created at:
                            </TableCell>
                            <TableCell>
                              <b>
                                {item.createdAt
                                  ? moment(item.createdAt).format(
                                      "MMMM D, YYYY, LTS"
                                    )
                                  : "N/A"}
                              </b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="header-table">
                              Updated at:
                            </TableCell>
                            <TableCell>
                              <b>
                                {item.updatedAt
                                  ? moment(item.updatedAt).format(
                                      "MMMM D, YYYY, LTS"
                                    )
                                  : "N/A"}
                              </b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="header-table">
                              Total Questions:
                            </TableCell>
                            <TableCell>
                              <b>
                                {item.questions ? item.questions.length : "N/A"}
                              </b>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Card>
                  </Grid>

                  <Grid xs={1} sm={1} md={1} lg={2}>
                    <Box
                      sx={{ display: "grid", ml: 1 }}
                      className="icon-button"
                    >
                      <div
                        style={{
                          display: "contents",
                        }}
                      >
                        <Tooltip title="View">
                          <img
                            src={
                              hover.View && indexHover === i ? ViewHover : View
                            }
                            alt="VIEW"
                            style={{
                              height: "35px",
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={() => handleViewDetails(item)}
                            onMouseOver={() => {
                              setHover({ View: true });
                              setIndexHover(i);
                            }}
                            onMouseLeave={() => {
                              setHover({ View: false });
                            }}
                          />
                        </Tooltip>
                        {permission?.logEntry?.manageLogEntry && (
                          <>
                            <Tooltip title="Edit">
                              <img
                                onClick={() => handleEditDetails(item)}
                                src={
                                  hover.Edit && indexHover === i
                                    ? EditHover
                                    : Edit
                                }
                                alt="Edit"
                                style={{
                                  height: "35px",
                                  cursor: "pointer",
                                  marginBottom: "5px",
                                }}
                                onMouseOver={() => {
                                  setHover({ Edit: true });
                                  setIndexHover(i);
                                }}
                                onMouseLeave={() => {
                                  setHover({ Edit: false });
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete">
                              <img
                                onClick={() => handleDelete(item)}
                                src={
                                  hover.Delete && indexHover === i
                                    ? DeleteHover
                                    : Delete
                                }
                                alt="Delete"
                                style={{
                                  height: "35px",
                                  cursor: "pointer",
                                  marginBottom: "5px",
                                }}
                                onMouseOver={() => {
                                  setHover({ Delete: true });
                                  setIndexHover(i);
                                }}
                                onMouseLeave={() => {
                                  setHover({ Delete: false });
                                }}
                              />
                            </Tooltip>
                            <ToggleSwitchLogEntry
                              {...label}
                              row={item._id}
                              check={item?.status}
                              disabled={!permission?.logEntry?.manageLogEntry}
                            />
                          </>
                        )}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Card sx={{ p: 1, width: "100%" }}>
              <NoDataFound />
            </Card>
          )}
        </Grid>
      </Box>

      <Card className="header-card" sx={{ mt: 1 }}>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[9, 18, 30]}
                count={totalCount}
                colSpan={7}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Card>
    </Box>
  );
};

export default LogEntries;
