/** @format */

import React, { useState } from "react";
import {
    FormControl,
    Box,
    Select,
    MenuItem,
    TextField,
    Grid,
    Stack,
    FormControlLabel,
    Typography,
    Tabs,
    Tab,
    RadioGroup,
    Radio,
    FormGroup,
    Checkbox,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import questiontype from "./QuestionType";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";

import {
    RulesAddedApi,
    UploadImgAddQSuccess,
} from "../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import { CompanyGetApi } from "../redux/actions/CreateCompany";
import { CategoryListInventoryApi } from "../redux/actions/Inventory";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles({
    select: {
        "& li": {
            display: "block",
        },
    },
    radio: {
        "&$checked": {
            color: "#F6F8FC",
        },
    },
    checked: {},
});

const AddTemplateQuestion = (props) => {
    const idqustion = localStorage.getItem("qustioneditid");
    const addQustionEditData = localStorage.getItem("addQustionEditData");
    const addQustionsEditData = addQustionEditData && JSON.parse(addQustionEditData) || "";
    // const editQustionEditData = localStorage.getItem("editQustionEditData");
    // const editQustionsEditData = JSON.parse(editQustionEditData);

    const location = useLocation();

    const pathName = location?.pathname;
    const [loader, setLoader] = useState(false);

    const classes = useStyles();

    const optionsAns = [
        {
            title: "Yes",
            value: addQustionsEditData?.type?.validations[0]?.answer[0]?.value || false,
        },
        {
            title: "No",
            value: addQustionsEditData?.type?.validations[0]?.answer[1]?.value || false,
        },
        {
            title: "Not Acceptable",
            value: addQustionsEditData?.type?.validations[0]?.answer[2]?.value || false,
        },
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const companyData = useSelector(
        (state) =>
            state.company &&
            state.company.companyData &&
            state.company.companyData.data &&
            state.company.companyData.data.data &&
            state.company.companyData.data.data.list
    );

    const categoryData = useSelector(
        (state) =>
            (state.inventory &&
                state.inventory.categoryDataInventory &&
                state.inventory.categoryDataInventory.data &&
                state.inventory.categoryDataInventory.data.data) ||
            []
    );

    const [newfield, setNewField] = useState({});

    const [value, setValue] = useState(0);
    const [val, setVal] = useState(false);
    const [require, setRequire] = useState(optionsAns);

    const [mediaFileType, setMediaFileType] = useState([]);
    const [allowFutureDates, setAllowFutureDates] = useState(false);
    const [allowPastDates, setAllowPastDates] = useState(false);
    const [isQuestionAllowFilter, setIsQuestionAllowFilter] = useState(false);
    const [isQuestionAllowFilterDisabled, setIsQuestionAllowFilterDisabled] = useState(false);
    const [singleandmultichoice, setSingleAndMultiChoice] = useState({});
    const [categoryList, setCategoryList] = useState();
    const [category, setcategory] = React.useState("");

    const [mediaFileTypeField, setMediaFileTypeField] = useState([
        { title: "Image", typeString: "image/*", value: addQustionsEditData?.type?.validations[1]?.answer[0]?.value || false },
        { title: "Video", typeString: "video/*", value: addQustionsEditData?.type?.validations[1]?.answer[1]?.value || false },
        { title: "Audio", typeString: "audio/*", value: addQustionsEditData?.type?.validations[1]?.answer[2]?.value || false },
        { title: "Document", typeString: ".txt,.xls,.xlsx,.doc,.docx,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document", value: addQustionsEditData?.type?.validations[1]?.answer[3]?.value || false },
    ]);

    const [allowFutureDatesField, setAllowFutureDatesField] = useState([
        { title: "Yes", value: addQustionsEditData?.type?.validations[1]?.answer[0]?.value || false },
        { title: "No", value: addQustionsEditData?.type?.validations[1]?.answer[1]?.value || false },
    ]);

    const [allowPastDatesField, setAllowPastDatesField] = useState([
        { title: "Yes", value: addQustionsEditData?.type?.validations[2]?.answer[0]?.value || false },
        { title: "No", value: addQustionsEditData?.type?.validations[2]?.answer[1]?.value || false },
    ]);

    const [validatarr, setValidatarr] = useState([]);

    const validationSchema = Yup.object({
        title: Yup.string("Enter your title").required("Title is required"),
        description: Yup.string("Enter your Description").required(
            "Description is required"
        ),
        questiontype: Yup.string("Select your type").required("Type is required"),
    });

    const ListCatagory = () => {
        setLoader(true);
        const id = localStorage.getItem("companyId");
        const data = {
            url: BASE_URL + `categories?company_id=${id}`,
        };
        dispatch(CategoryListInventoryApi(data));
    };

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setcategory(selectedCategory);
        const categoryObj = categoryList.find(
            (cat) => cat.title === selectedCategory
        );
        // setFilteredSubCategories(categoryObj?.subCategories || []);
    };


    useEffect(() => {
        ListCatagory();
    }, []);

    useEffect(() => {
        if(categoryData.length){
            setCategoryList(categoryData);
            setLoader(false);
        }
    }, [categoryData]);

    useEffect(() => {
        let oldQues = localStorage.getItem("questions");
        if (oldQues) {
            let oldD1 = JSON.parse(oldQues);
            const fetchFilterableQues = oldD1.filter(ques => ques.allowFilter);
            if (fetchFilterableQues.length >= 5) {
                setIsQuestionAllowFilterDisabled(true);
            }
        }
    }, [])

    const isValidationFieldSelected = val ||
        mediaFileType.length ||
        allowFutureDates ||
        allowPastDates;

    const formik = useFormik({
        initialValues: newfield || {
            title: "",
            description: "",
            questiontype: "",
            attachedlist: null,
        },
        // validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const qustionDataId = localStorage.getItem("qustionDataid");
            if (!values.title) {
                return toast.error("Title is required!");
            }
            if (!values.description) {
                return toast.error("Description is required!");
            }
            if (!values.questiontype) {
                return toast.error("Questions type required!");
            }

            if (values?.questiontype?.toLowerCase() === "single choice" || values?.questiontype?.toLowerCase() === "multiple choice") {
                if (!values.attachedlist) {
                    return toast.error("Atleat one Main list is required!");
                }
            }

            if (!isValidationFieldSelected) {
                toast.error("Validation fields are required");
                return;
            }

            const types = {
                validations: validatarr,
                key:
                    values?.questiontype?.toLowerCase() === "singlechoice"
                        ? "single_select"
                        : values?.questiontype?.toLowerCase() === "multiplechoice"
                            ? "multi_select"
                            : values?.questiontype?.toLowerCase() === "numeric"
                                ? "number"
                                : values?.questiontype?.toLowerCase() === "media"
                                    ? "file"
                                    : values?.questiontype?.toLowerCase(),
                title:
                    values?.questiontype?.toLowerCase() === "singlechoice"
                        ? "Single Choice"
                        : values?.questiontype?.toLowerCase() === "multiplechoice"
                            ? "Multiple Choice"
                            : values?.questiontype?.charAt(0)?.toUpperCase() +
                            values?.questiontype?.slice(1),
            };

            types.title_to_show =
                values?.questiontype?.toLowerCase() === "singlechoice"
                    ? "Single Choice"
                    : values?.questiontype?.toLowerCase() === "multiplechoice"
                        ? "Multiple Choice"
                        : values?.questiontype?.charAt(0)?.toUpperCase() +
                        values?.questiontype?.slice(1);
            types.id = Number(qustionDataId + 1);

            const data = {
                title: values.title,
                desc: values.description,
                allowFilter: isQuestionAllowFilter,
                type: types,
                category: category,
                order: Number(qustionDataId),
                id: uuidv4(),
                default: false,
            };

            if (values?.questiontype?.toLowerCase() === "single choice" || values?.questiontype?.toLowerCase() === "multiple choice") {
                data.attachedlist = values.attachedlist;
            }

            if (data) {
                let oldQues = localStorage.getItem("questions");
                const id = localStorage.getItem("companyId");


                let oldD1 = JSON.parse(oldQues);
                console.log("oldQues ===>", oldD1);
                if (oldD1.length) {
                    if (idqustion) {
                        types.id = oldD1[idqustion].type.id;

                        oldD1[idqustion] = {
                            ...oldD1[idqustion],
                            title: values.title,
                            desc: values.description,
                            allowFilter: isQuestionAllowFilter,
                            category: category,
                            type: types,
                        }
                        // const api = {
                        //     url: BASE_URL + "/questions/" + oldD1[idqustion].id + "?company_id=" + id,
                        //     body: {
                        //         title: values.title,
                        //         question: oldD1[idqustion],
                        //     }
                        // }
                        // dispatch(EditTemplateQuestionApi(api));
                    } else {
                        oldD1.push(data);
                        // const api = {
                        //     url: BASE_URL + "/questions?company_id=" + id,
                        //     body: {
                        //         title: values.title,
                        //         question: data,
                        //     }
                        // }
                        // dispatch(AddTemplateQuestionApi(api));
                    }
                    localStorage.setItem("questions", JSON.stringify(oldD1));
                    navigatioToQuestion();
                } else {
                    let qArray = [];
                    qArray.push(data);

                    // const api = {
                    //     url: BASE_URL + "/questions?company_id=" + id,
                    //     body: {
                    //         title: values.title,
                    //         question: data,
                    //     }
                    // }
                    // dispatch(AddTemplateQuestionApi(api));
                    localStorage.setItem("questions", JSON.stringify(qArray));
                    navigatioToQuestion();
                }
                localStorage.removeItem("qustioneditid");
                localStorage.removeItem("addQustionEditData");
                localStorage.removeItem("editQustionEditData");
            } else {
                toast.error("All fields are required");
            }
        },
    });

    useEffect(() => {
        if (addQustionEditData) {
            setNewField({
                title: addQustionsEditData?.title,
                description: addQustionsEditData?.desc,
                questiontype: addQustionsEditData?.type?.title,
                attachedlist: addQustionsEditData?.attachedlist,
            })
            if (addQustionsEditData?.category) {
                setcategory(addQustionsEditData?.category);
            };

            if (addQustionsEditData?.type?.key === "file") {
                setMediaFileType(addQustionsEditData?.type?.validations?.[1]?.answer?.map(item => item.value ? item.title : "") || false);
            };

            setIsQuestionAllowFilter(addQustionsEditData?.allowFilter || false);
            if (addQustionsEditData?.type?.validations) {
                let tmpRequired = addQustionsEditData?.type.validations.filter(
                    (val) => val.key === "required"
                );
                let required =
                    tmpRequired && tmpRequired.length > 0
                        ? tmpRequired[0]?.answer?.filter((ans) => ans.value === true)
                        : false;
                let futureDates =
                    addQustionsEditData?.type?.validations.length > 0
                        ? addQustionsEditData?.type?.validations[1]?.answer?.filter((ans) => ans.value === true)
                        : false;
                let pastDates =
                    addQustionsEditData?.type?.validations.length > 0
                        ? addQustionsEditData?.type?.validations[2]?.answer?.filter((ans) => ans.value === true)
                        : false;
                setVal(required && required.length > 0 ? required[0].title : "");
                setAllowFutureDates(futureDates && futureDates[0]?.title.toLowerCase());
                setAllowPastDates(pastDates && pastDates[0]?.title.toLowerCase());
            }
        }
    }, [])

    useEffect(() => {
        const id = localStorage.getItem("companyId");
        const data = { url: BASE_URL + `rule/template?type=2&company_id=${id}` };
        dispatch(RulesAddedApi(data));
    }, []);

    useEffect(() => {
        const validations = [
            {
                id: 1,
                key: "required",
                title: "Is Required?",
                type: "radio",
                answer: [...require],
            },
        ];

        if (formik.values.questiontype === "Media") {
            validations.push({
                id: validations.length + 1,
                key: "mediaFileType",
                title: "Type of Media File",
                type: "checkbox",
                answer: [...mediaFileTypeField],
            })
        }
        if (formik.values.questiontype === "Date") {
            validations.push({
                id: validations.length + 1,
                key: "allowFutureDates",
                title: "Allow Future Dates",
                type: "radio",
                answer: [...allowFutureDatesField],
            });
            validations.push({
                id: validations.length + 1,
                key: "allowPastDates",
                title: "Allow Past Dates",
                type: "radio",
                answer: [...allowPastDatesField],
            })
        }
        if (formik.values.questiontype === "DateTime") {
            validations.push({
                id: validations.length + 1,
                key: "allowFutureDates",
                title: "Allow Future Dates",
                type: "radio",
                answer: [...allowFutureDatesField],
            });
            validations.push({
                id: validations.length + 1,
                key: "allowPastDates",
                title: "Allow Past Dates",
                type: "radio",
                answer: [...allowPastDatesField],
            })
        }
        setValidatarr(validations);
    }, [
        require,
        mediaFileTypeField,
        allowFutureDatesField,
        allowPastDatesField,
        formik.values.questiontype
    ]);

    useEffect(() => {
        localStorage.removeItem("editdata");
        localStorage.removeItem("questiodata");
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleRequire = (event) => {
        let events = event.target.value;
        setVal(events);
        for (let item of require) {
            if (events == "Yes") {
                require[0].value = true;
                require[1].value = false;
                require[2].value = false;
            } else if (events == "No") {
                require[0].value = false;
                require[1].value = true;
                require[2].value = false;
            } else if (events == "Not Acceptable") {
                require[0].value = false;
                require[1].value = false;
                require[2].value = true;
            }
        }
        let options = require;
        setRequire(options);
    };

    const handleTypeFileUpload = (event) => {
        let events = event.target.value;
        // for (let item of mediaFileTypeField) {
        if (mediaFileType.includes(events)) {
            setMediaFileType([...mediaFileType.filter(item => item !== events)]);

            if (events == "Image") {
                mediaFileTypeField[0].value = false;
            } else if (events == "Video") {
                mediaFileTypeField[1].value = false;
            } else if (events == "Audio") {
                mediaFileTypeField[2].value = false;
            } else if (events == "Document") {
                mediaFileTypeField[3].value = false;
            }
        } else {
            setMediaFileType([...mediaFileType, event.target.value]);
            if (events == "Image") {
                mediaFileTypeField[0].value = true;
            } else if (events == "Video") {
                mediaFileTypeField[1].value = true;
            } else if (events == "Audio") {
                mediaFileTypeField[2].value = true;
            } else if (events == "Document") {
                mediaFileTypeField[3].value = true;
            }
        }
        // }
        const options = mediaFileTypeField;
        setMediaFileTypeField(options);
    };

    const handleFutureDates = (event) => {
        setAllowFutureDates(event.target.value);
        let events = event.target.value;
        for (let item of allowFutureDatesField) {
            if (events == "yes") {
                allowFutureDatesField[0].value = true;
                allowFutureDatesField[1].value = false;
            } else if (events == "no") {
                allowFutureDatesField[0].value = false;
                allowFutureDatesField[1].value = true;
            }
        }
        const options = allowFutureDatesField;
        setAllowFutureDatesField(options);
    };
    const handlePastDates = (event) => {
        setAllowPastDates(event.target.value);
        let events = event.target.value;
        for (let item of allowPastDatesField) {
            if (events == "yes") {
                allowPastDatesField[0].value = true;
                allowPastDatesField[1].value = false;
            } else if (events == "no") {
                allowPastDatesField[0].value = false;
                allowPastDatesField[1].value = true;
            }
        }
        const options = allowPastDatesField;
        setAllowPastDatesField(options);
    };

    const navigatioToQuestion = () => {
        setLoader(true);
        const isEdit = localStorage.getItem('userId');

        if (isEdit && JSON.parse(isEdit)) {
            setTimeout(() => {
                navigate("/template/edit");
                localStorage.removeItem("default");
            }, 1000);
        } else {
            setTimeout(() => {
                navigate("/template/create");
                localStorage.removeItem("default");
            }, 1000);
        }

        setTimeout(() => {
            dispatch(UploadImgAddQSuccess({ data: { data: [] } }));
            setLoader(false);
        }, 2000);
    };

    const handleReset = () => {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        mediaFileTypeField[0].value = false;
        mediaFileTypeField[1].value = false;
        mediaFileTypeField[2].value = false;
        mediaFileTypeField[3].value = false;
        allowFutureDatesField[0].value = false
        allowFutureDatesField[1].value = false
        allowPastDatesField[0].value = false
        allowPastDatesField[1].value = false
        setVal(false);
        setMediaFileType([]);
        setAllowFutureDates(false);
        setAllowPastDates(false);
    };
    console.log("mediaFiles", mediaFileType);

    const handleQuestionType = (e) => {
        formik.handleChange(e);
        console.log("e.target.value", e.target.value);
        if (
            e.target.value === "Media" ||
            e.target.value === "Date" ||
            e.target.value === "DateTime" ||
            e.target.value === "Time" ||
            e.target.value === "Timer"
        ) {
            setIsQuestionAllowFilter(false);
            setIsQuestionAllowFilterDisabled(true);
        } else {
            setIsQuestionAllowFilterDisabled(false);
        }
    }
    let noFieldsSelected = true;

    validatarr.forEach((obj) => {
        obj.answer.forEach((option) => {
            if (option.value) {
                noFieldsSelected = false;
            }
        });
    });

    const getCompanyData = () => {
        const cid = localStorage.getItem("companyId");
        const data = { url: BASE_URL + `companies/${cid}` };
        dispatch(CompanyGetApi(data));
    };
    useEffect(() => {
        getCompanyData();
    }, []);

    // useEffect(() => {
    //   if (
    //     formik?.values?.questiontype === "text" ||
    //     "media" ||
    //     "date " ||
    //     "time" ||
    //     "datetime" ||
    //     "timer"
    //   ) {
    //     setValue(0);
    //   }
    // }, [formik.values.questiontype]);

    return (
        <>
            <ToastContainer autoClose={3000} />
            <form onSubmit={formik.handleSubmit}>
                {/* header Start */}
                <Box
                    sx={{ bgcolor: "#F6F8FC", borderRadius: "8px", mb: 1 }}
                    className="header-card"
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                                <Button
                                    className="cancle-button cancle-hover"
                                    onClick={() => {
                                        // dispatch(UploadImgAddQSuccess({ data: { data: [] } }));
                                        navigate(-1);
                                        localStorage.removeItem("qustioneditid");
                                        localStorage.removeItem("addQustionEditData");
                                        localStorage.removeItem("editQustionEditData");
                                    }}
                                >
                                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                                    Cancel
                                </Button>

                                <Button
                                    type="submit"
                                    className="header-add cancle-hover"
                                // onClick={handleDone}
                                >
                                    <CheckOutlinedIcon sx={{ mr: 1 }} />
                                    Done
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                {/* header End */}
                {loader ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <ProgressbarTransparent play />
                    </div>
                ) : (
                    <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px" }}>
                        <Container
                            maxWidth="lg"
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                p: 1,
                                textAlign: "left",
                            }}
                        >

                            {pathName == "/checklist/all-form/add-form/add-question" && <FormControl fullWidth sx={{ mb: 2 }}>
                                <FormControlLabel
                                    className="label-name"
                                    control={
                                        <Checkbox
                                            type="checkbox"
                                            name="isQuestionToInspection"
                                            onChange={formik.handleChange}
                                            inputProps={{ "aria-label": "controlled" }}
                                            sx={{ color: "#196773" }}
                                            checked={formik?.values?.isQuestionToInspection}
                                        />
                                    }
                                    label="Add Question to Inspection"
                                />
                            </FormControl>}
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Title</label>
                                <TextField
                                    name="title"
                                    value={formik?.values?.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    className="date-input"
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Description</label>
                                <TextField
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.description &&
                                        Boolean(formik.errors.description)
                                    }
                                    helperText={
                                        formik.touched.description && formik.errors.description
                                    }
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    className="date-input"
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ mb: 2, flexDirection: 'row' }}>
                                <label>Allow Filter</label>
                                <Checkbox
                                    type="checkbox"
                                    name="isQuestionToInspection"
                                    onChange={(e) => setIsQuestionAllowFilter(e.target.checked)}
                                    inputProps={{ "aria-label": "controlled" }}
                                    sx={{ color: "#196773", padding: 0, marginLeft: '20px' }}
                                    checked={isQuestionAllowFilter}
                                    disabled={isQuestionAllowFilterDisabled}
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <label>Category</label>
                                <Select
                                    size="small"
                                    name="category"
                                    onChange={handleCategoryChange}
                                    placeholder="Select type"
                                    value={category}
                                    sx={{ borderRadius: "8px" }}
                                    MenuProps={{
                                        disableScrollLock: true,
                                        PaperProps: {
                                            sx: {
                                                bgcolor: "#FFFFFF",
                                                "& .MuiMenuItem-root": {
                                                    padding: 1,
                                                },
                                                height: "200px",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={""} className="select-item">
                                        Select Category
                                    </MenuItem>
                                    {categoryList?.map((option, i) => (
                                        <MenuItem
                                            key={i}
                                            value={option.title}
                                            className="select-item"
                                        >
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                            <>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <label>Select Question Type</label>
                                    <Select
                                        size="small"
                                        name="questiontype"
                                        value={formik?.values?.questiontype || ""}
                                        onChange={(e) => handleQuestionType(e)}
                                        disabled={idqustion}
                                        error={
                                            formik.touched.questiontype &&
                                            Boolean(formik.errors.questiontype)
                                        }
                                        helperText={
                                            formik.touched.questiontype &&
                                            formik.errors.questiontype
                                        }
                                        MenuProps={{
                                            disableScrollLock: true,
                                            classes: { paper: classes.select },
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },

                                            sx: {
                                                height: "500px",
                                                display: "block",
                                            },
                                        }}
                                        sx={{ borderRadius: "8px" }}
                                    >
                                        <MenuItem disabled value="" className="select-item">
                                            Type
                                        </MenuItem>
                                        {questiontype?.map((option, i) => {
                                            return (
                                                <MenuItem
                                                    key={i}
                                                    value={option.title}
                                                    className="select-item"
                                                >
                                                    {option.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </>

                            {formik &&
                                formik?.values &&
                                (formik?.values?.questiontype?.toLowerCase() === "single choice" || formik?.values?.questiontype?.toLowerCase() === "multiple choice") && !idqustion && (
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <label>Main List</label>
                                        <Select
                                            size="small"
                                            name="attachedlist"
                                            value={formik?.values?.attachedlist || ""}
                                            onChange={(event) => {
                                                console.log("event", event);
                                                formik.handleChange(event);
                                                let singleChoicesss = {};
                                                event.target.value?.items?.map(
                                                    (data) => (singleChoicesss[data.title] = 1)
                                                );
                                                setSingleAndMultiChoice(singleChoicesss);
                                            }}
                                            MenuProps={{
                                                disableScrollLock: true,
                                                classes: { paper: classes.select },
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                },

                                                sx: {
                                                    height: "500px",
                                                    display: "block",
                                                },
                                            }}
                                            sx={{
                                                borderRadius: "8px",
                                                textAlign: "left",
                                                pl: "10px",
                                            }}
                                        >
                                            <MenuItem disabled value="" className="select-item">
                                                Type
                                            </MenuItem>
                                            {companyData &&
                                                companyData?.map((option, i) => {
                                                    return (
                                                        <MenuItem
                                                            key={i}
                                                            value={option}
                                                            className="select-item"
                                                        >
                                                            {option.title ?? option.id}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                )}

                            {formik.values.questiontype ? (
                                <Box className="tab-box">
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                            background: "#F6F8FC",
                                        }}
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                        >
                                            <Tab
                                                label={"Validation"}
                                                {...a11yProps(0)}
                                                className="tab-item"
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <>
                                            <FormControl fullWidth>
                                                <label>1. Is Required?</label>
                                                <RadioGroup
                                                    onChange={handleRequire}
                                                    value={val ? val : ""}
                                                    row
                                                    name="row-radio-buttons-group"
                                                    sx={{ p: 0 }}
                                                    className="radio-btn"
                                                >
                                                    <FormControlLabel
                                                        value="Yes"
                                                        control={
                                                            <Radio
                                                                size="small"
                                                                sx={{
                                                                    "&, &.Mui-checked": {
                                                                        color: "#196773",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label="Yes"
                                                    />

                                                    <FormControlLabel
                                                        value="No"
                                                        control={
                                                            <Radio
                                                                size="small"
                                                                sx={{
                                                                    "&, &.Mui-checked": {
                                                                        color: "#196773",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                            {formik.values.questiontype === "Media" &&
                                                <FormControl fullWidth>
                                                    <label>2. Type of Media</label>

                                                    <FormGroup style={{ display: "flex", marginLeft: "5px", height: "38px" }} row>
                                                        <FormControlLabel control={<Checkbox style={{ padding: "4px" }} value='Image' checked={mediaFileType.includes("Image")} onChange={handleTypeFileUpload} />} label="Image" />
                                                        <FormControlLabel control={<Checkbox style={{ padding: "4px" }} value="Video" checked={mediaFileType.includes("Video")} onChange={handleTypeFileUpload} />} label="Video" />
                                                        <FormControlLabel control={<Checkbox style={{ padding: "4px" }} value="Audio" checked={mediaFileType.includes("Audio")} onChange={handleTypeFileUpload} />} label="Audio" />
                                                        <FormControlLabel control={<Checkbox style={{ padding: "4px" }} value="Document" checked={mediaFileType.includes("Document")} onChange={handleTypeFileUpload} />} label="Document" />
                                                    </FormGroup>
                                                </FormControl>
                                            }
                                            {
                                                (formik.values.questiontype === "Date" || formik.values.questiontype === "Date Time") &&
                                                <>
                                                    <FormControl fullWidth>
                                                        <label>2. Allow Future Dates</label>
                                                        <RadioGroup
                                                            value={allowFutureDates ? allowFutureDates : ""}
                                                            onChange={handleFutureDates}
                                                            row
                                                            name="row-radio-buttons-group"
                                                            sx={{ p: 0 }}
                                                            className="radio-btn"
                                                        >
                                                            <FormControlLabel
                                                                value="yes"
                                                                control={
                                                                    <Radio
                                                                        size="small"
                                                                        sx={{
                                                                            "&, &.Mui-checked": {
                                                                                color: "#196773",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label="Yes"
                                                            />
                                                            <FormControlLabel
                                                                value="no"
                                                                control={
                                                                    <Radio
                                                                        size="small"
                                                                        sx={{
                                                                            "&, &.Mui-checked": {
                                                                                color: "#196773",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label="No"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <FormControl fullWidth>
                                                        <label>3. Allow Past Dates</label>
                                                        <RadioGroup
                                                            value={allowPastDates ? allowPastDates : ""}
                                                            onChange={handlePastDates}
                                                            row
                                                            name="row-radio-buttons-group"
                                                            sx={{ p: 0 }}
                                                            className="radio-btn"
                                                        >
                                                            <FormControlLabel
                                                                value="yes"
                                                                control={
                                                                    <Radio
                                                                        size="small"
                                                                        sx={{
                                                                            "&, &.Mui-checked": {
                                                                                color: "#196773",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label="Yes"
                                                            />
                                                            <FormControlLabel
                                                                value="no"
                                                                control={
                                                                    <Radio
                                                                        size="small"
                                                                        sx={{
                                                                            "&, &.Mui-checked": {
                                                                                color: "#196773",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label="No"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            }
                                            <Button
                                                // type="submit"
                                                className="header-add cancle-hover"
                                                onClick={handleReset}
                                            >
                                                Reset All
                                            </Button>
                                        </>
                                    </TabPanel>
                                </Box>
                            ) : (
                                ""
                            )}

                        </Container>
                    </Box>
                )}
            </form>
        </>
    );
};

export default AddTemplateQuestion;
