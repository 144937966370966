import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import {
  CreateMasterQRCodeApi,
  CreateTemplateApi,
  EditMasterQRCodeApi,
  EditTemplateApi,
  ListProductStoreApi,
  ProductListInventoryApi,
  SubProductListInventoryApi,
  TemplateListInventoryApi,
} from "../../redux/actions/Inventory";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import AddNewQRCodeHeader from "./AddNewQRCodeHeader";
import { ListUserChecklistApi } from "../../redux/actions/User";
import { ArchivedIssueListApi } from "../../redux/actions/Issue";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@material-ui/core/styles";
import DropdownWithDetails from "../component/DropdownWithDetails";
import ProductDropdown from "./ProductDropdown";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const AddNewQRCode = () => {
  let templatedata =
    (localStorage.getItem("addMasterBarCodeLocal") &&
      JSON.parse(localStorage.getItem("addMasterBarCodeLocal"))) ||
    (localStorage.getItem("masterBarCodeLocalStorageData") &&
      JSON.parse(localStorage.getItem("masterBarCodeLocalStorageData")));
  let addQustionEditData =
    (localStorage.getItem("questions") &&
      JSON.parse(localStorage.getItem("questions"))) ||
    (localStorage.getItem("masterBarCodeLocalStorageData") &&
      JSON.parse(localStorage.getItem("masterBarCodeLocalStorageData"))
        .question);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questionData, setQuestionData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const validationSchema = Yup.object().shape({});
  const [productListData, setProductListData] = useState([]);
  const [subProductListData, setSubProductListData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState("");
  const [selectedSubProducts, setSelectedSubProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllSubProd, setSelectAllSubProd] = useState(false);
  const [selectedSubProductId, setSelectedSubProductId] = useState([]);
  const [selectedErrorProduct, setSelectedErrorProduct] = useState(false);
  const [selectedErrorSubProduct, setSelectedErrorSubProduct] = useState(false);
  const classes = useStyles();
  const [selectedTemplateQuestion, setSelectedTemplateQuestion] = useState();
  const [selectedStore, setSelectedStore] = useState("");
  const [template, setTemplate] = useState([]);
  const [stores, setStores] = useState([]);

  console.log("productListData", productListData);
  const transformedProducts = Array.isArray(productListData)
    ? productListData.map((item) => ({
      ...item,
      name: item.productDetails?.name || "",
    }))
    : [];

  const isClickAllProducts =
    selectAllSubProd?.length > 0 &&
    selectAllSubProd?.length === subProductListData?.length;

  const isAllSelectedSubProducts =
    selectAllSubProd?.length > 0 &&
    selectAllSubProd?.length === subProductListData?.length;

  const productList = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.productList &&
        state.inventory.productList.data &&
        state.inventory.productList.data.data) ||
      []
  );

  const subProductList = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.subproductList &&
        state.inventory.subproductList.data &&
        state.inventory.subproductList.data.data) ||
      []
  );
  console.log("subProductList", subProductList);

  const templateData = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.templateData &&
        state.inventory.templateData.data &&
        state.inventory.templateData.data.data) ||
      []
  );

  const storeData = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.store &&
        state.inventory.store.data &&
        state.inventory.store.data.data) ||
      []
  );

  const getTemplateList = () => {
    const id = localStorage.getItem("companyId");

    const api = {
      url: BASE_URL + `product-template?company_id=${id}`,
      body: {
        pagination: {
          page: 1,
          per_page: 500,
        },
      },
    };
    dispatch(TemplateListInventoryApi(api));
  };

  const listStore = () => {
    const payloadRequest = {
      body: {
        page: 1,
        per_page: 200000,
        sort_order: -1,
        sort_by: "createdAt",
        type: "report",
      },
    };
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `stores/index?company_id=${id}`;
    dispatch(ListProductStoreApi(payloadRequest));
  };

  useEffect(() => {
    getTemplateList();
    listStore();
  }, []);

  useEffect(() => {
    if (templateData.finalData) {
      setTemplate(templateData.finalData);
    }
  }, [templateData]);

  useEffect(() => {
    if (storeData.stores) {
      setStores(storeData.stores);
    }
  }, [storeData]);

  const getProductList = () => {
    const id = localStorage.getItem("companyId");
    const api = {
      url: BASE_URL + `inventory-product?company_id=${id}`,
    };
    dispatch(ProductListInventoryApi(api));
  };

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    if (productList?.finalData) {
      setProductListData(productList?.finalData);
    }
  }, [productList?.finalData]);

  useEffect(() => {
    if (subProductList?.finalData?.length) {
      setSubProductListData(subProductList);
    }
  }, [subProductList]);

  useEffect(() => {
    if (questionData)
      localStorage.setItem("qustionDataid", questionData?.length);
  }, [questionData]);

  useEffect(() => {
    if (addQustionEditData) {
      setQuestionData(addQustionEditData);
    }
  }, []);

  const navigatioToList = (data) => {
    if (data === 200) {
      setTimeout(() => {
        setLoader(false);
        navigate({ pathname: "/master-barcode-list" });
      }, 2000);
    }
  };
  console.log("filterStore", selectedStore);

  const formik = useFormik({
    initialValues: {
      title: templatedata?.title || "",
      description: templatedata?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFinalSubmit({
        ...values,
        store: {
          _id: selectedStore?._id,
          name: selectedStore?.name,
        },
        productItemIds: selectedProducts,
        subProductItemIds: selectedSubProducts,
      });
    },
  });

  const handleFinalSubmit = (values) => {
    const isEdit = localStorage.getItem("masterBarCodeLocalStorageData");

    if (!values.title) {
      return toast.error("Title is required!");
    }

    if (!values.description) {
      return toast.error("Description is required!");
    }

    // if (!filterStore) {
    //     return toast.error("Store is required!");
    //   }

    // if (!values.product) {
    //     return toast.error("Product is required!");
    //   }

    //   if (!values.subProduct) {
    //     return toast.error("Sub Product is required!");
    //   }

    if (values) {
      const formquestionValue = {
        title: values.title,
        description: values.description,
        store: {
          _id: selectedStore?._id,
          name: selectedStore?.name,
        },
        product_id: selectedProducts?._id,
        productItemIds: selectedSubProductId,
      };
      const allData = {
        body: formquestionValue,
      };
      console.log("allDataPreparedForSubmit", allData);
      const id = localStorage.getItem("companyId");
      setLoader(true);
      if (isEdit && JSON.parse(isEdit)) {
        allData.url =
          BASE_URL +
          `master-barcode/${JSON.parse(isEdit)?._id}?company_id=${id}`;
        dispatch(EditMasterQRCodeApi(allData, navigatioToList));
      } else {
        allData.url = BASE_URL + `master-barcode?company_id=${id}`;
        dispatch(CreateMasterQRCodeApi(allData, navigatioToList));
      }
    }
  };

  const handleCancel = () => {
    localStorage.removeItem("addMasterBarCodeLocal");
    localStorage.removeItem("questionsListLocal");
    localStorage.removeItem("masterBarCodeLocalStorageData");
  };

  useEffect(() => {
    if (selectedProducts) {
      const id = localStorage.getItem("companyId");
      const api = {
        url:
          BASE_URL +
          `inventory-product-items?company_id=${id}&id=${selectedProducts?._id}`,
      };
      dispatch(SubProductListInventoryApi(api));
    }
  }, [selectedProducts, dispatch]);

  // sub product
  const handleSubProductChange = (event) => {
    const value = event.target.value;
    const isClickAll = value.includes("all");

    if (selectedProducts?.length === 0) {
      setSelectedSubProducts([]);
      setSelectedSubProductId([]);
      return;
    }

    if (isClickAll) {
      const newSelectedSubProducts =
        selectedSubProducts.length === subProductListData.length
          ? []
          : subProductListData;

      setSelectedSubProducts(newSelectedSubProducts);
      setSelectedSubProductId(newSelectedSubProducts.map((item) => item._id));
      return;
    }

    const updatedSelections = value.filter(
      (item, index, self) => index === self.findIndex((t) => t._id === item._id)
    );

    setSelectedSubProducts(updatedSelections);
    setSelectedSubProductId(updatedSelections.map((item) => item._id));
  };

  const handleBlurSubProduct = () => {
    if (!selectedSubProducts?.length) {
      setSelectedErrorSubProduct(true);
    } else {
      setSelectedErrorSubProduct(false);
    }
  };
  useEffect(() => {
    if (stores.length && !selectedStore) {
      const storeIdFromProductItem = templatedata?.store?._id;
      if (storeIdFromProductItem) {
        const preSelectedStore = stores?.find(
          (store) => store?._id === storeIdFromProductItem
        );
        setSelectedStore(preSelectedStore || null);
      }
    }
  }, [templatedata, stores, selectedStore]);

  const preSelectProdRef = useRef(false);

  useEffect(() => {
    if (
      templatedata?.product?._id &&
      !preSelectProdRef.current &&
      transformedProducts.length > 0
    ) {
      const preSelectedProduct = transformedProducts.find(
        (product) => product._id === templatedata.product._id
      );
      if (preSelectedProduct) {
        setSelectedProducts(preSelectedProduct);
      }
      preSelectProdRef.current = true;
    }
  }, [templatedata, transformedProducts]);



  const preSelectRef = useRef(false);

  useEffect(() => {
    if (
      templatedata?.productItemIds?.length > 0 &&
      subProductListData?.length > 0 &&
      !preSelectRef.current
    ) {
      const preSelectedOptions = subProductListData.filter((option) =>
        templatedata.productItemIds.includes(option._id)
      );

      setSelectedSubProducts(preSelectedOptions);
      setSelectedSubProductId(preSelectedOptions.map((item) => item._id));

      preSelectRef.current = true;
    }
  }, [templatedata, subProductListData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ToastContainer autoClose={3000} />
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}

      <AddNewQRCodeHeader
        handleFinalSubmit={handleFinalSubmit}
        handleCancel={handleCancel}
        values={formik}
      />
      <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
        <Container
          maxWidth="xl"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            p: 1,
            textAlign: "left",
          }}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Title</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="title"
              onChange={formik.handleChange}
              // defaultValue={trainingdata?.name}
              value={formik.values.title}
              className="date-input"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Description</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="description"
              onChange={formik.handleChange}
              // defaultValue={trainingdata?.name}
              value={formik.values.description}
              className="date-input"
            />
          </FormControl>
          <FormControl sx={{ width: "100%", mb: 2 }}>
            <Box>Store</Box>
            <Autocomplete
              id="country-select-demo"
              sx={{ width: "100%", paddingX: "1px" }}
              options={stores || []}
              value={selectedStore && stores.length ? selectedStore : ""}
              onChange={(event, newValue) => setSelectedStore(newValue)}
              getOptionLabel={(option) => option?.name || ""}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box {...optionProps}>
                    <Typography>{option.name}</Typography>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ padding: "0px" }} />
              )}
              size="small"
            />
          </FormControl>
          <FormControl sx={{ width: "100%", mb: 2 }}>
            <Box>Product</Box>
            <ProductDropdown
              id="country-select-demo"
              options={transformedProducts}
              value={selectedProducts}
              handleChange={(newValue) => {
                const selectedProduct =
                  transformedProducts.find((product) => product._id === newValue?._id) ||
                  null;
                setSelectedProducts(selectedProduct);
              }}
              details={[
                {
                  title: "",
                  value: "updatedAt",
                  type: "date",
                  format: "ll",
                },
                {
                  title: "Desc",
                  value: "description",
                },
                {
                  title: "Count",
                  value: "count",
                },
              ]}
            />
          </FormControl>


          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Select Items</label>
            <Select
              labelId="mutiple-select-label"
              multiple
              sx={{ borderRadius: "8px" }}
              name="store"
              size="small"
              value={selectedSubProducts}
              onChange={handleSubProductChange}
              onClose={handleBlurSubProduct}
              error={selectedErrorSubProduct}
              disabled={selectedProducts?.length === 0}
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  sx: {
                    bgcolor: "#FFFFFF",
                    "& .MuiMenuItem-root": {
                      padding: 0,
                    },
                    height: "180px",
                  },
                },
              }}
              renderValue={(selectedSubProducts) =>
                selectedSubProducts
                  .map((item) => item?.productDetails?.name)
                  .join(", ")
              }
            >
              {/* Select All MenuItem */}
              <MenuItem
                value="all"
                className="select-item"
                classes={{
                  root:
                    selectedSubProducts?.length === subProductListData?.length
                      ? classes.selectedAll
                      : "",
                }}
                onClick={() => {
                  if (
                    selectedSubProducts?.length === subProductListData?.length
                  ) {
                    setSelectedSubProducts([]);
                  } else {
                    setSelectedSubProducts(subProductListData);
                  }
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={
                      selectedSubProducts?.length === subProductListData?.length
                    }
                    indeterminate={
                      selectedSubProducts?.length > 0 &&
                      selectedSubProducts?.length < subProductListData?.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>

              {subProductListData?.finalData?.map((option, i) => (
                option?.productDetails?.name && <MenuItem key={i} value={option} className="select-item">
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedSubProducts?.some(
                        (item) => item?._id === option?._id
                      )}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.productDetails.name} />
                </MenuItem>
              ))}
            </Select>

            <small className="error">
              {selectedErrorSubProduct ? "Sub Product is required" : null}
            </small>
          </FormControl>
        </Container>
      </Box>
    </form>
  );
};

export default AddNewQRCode;
