import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Container } from "@mui/system";
import Fab from "@material-ui/core/Fab";
import attechment from "../../../images/attechment.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  GetIssueDataApi,
  EditIssueApi,
  AddIssueImageApi,
  AddIssueMediaApi,
  IssueDepartmentListApi,
  DeptWiseCategoryListApi,
} from "../../../redux/actions/Issue";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";

const MenuProps = {
  disableScrollLock: true,
};

const EditIssue = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const issueFormData = useSelector(
    (state) =>
      state.issue &&
      state.issue.getIssueData &&
      state.issue.getIssueData.data &&
      state.issue.getIssueData.data.data
  );
  const editIssue = useSelector(
    (state) =>
      state.issue && state.issue.editIssue && state.issue.editIssue.data
  );
  const getImageKey = useSelector(
    (state) =>
      state.issue &&
      state.issue.addImage &&
      state.issue.addImage.data &&
      state.issue.addImage.data.data
  );
  const getMediaKey = useSelector(
    (state) =>
      (state.issue &&
        state.issue.addMedia &&
        state.issue.addMedia.data &&
        state.issue.addMedia.data.data) ||
      []
  );
  const categoryList = useSelector(
    (state) =>
      (state.issue &&
        state.issue.categoryDataDept &&
        state.issue.categoryDataDept.data &&
        state.issue.categoryDataDept.data.data) ||
      []
  );

  const errorImg = useSelector(
    (state) => state.issue && state.issue.error && state.issue.error.data
  );

  const issueDepartmentList = useSelector(
    (state) =>
      (state.issue &&
        state.issue.departmentData &&
        state.issue.departmentData.data &&
        state.issue.departmentData.data.data &&
        state.issue.departmentData.data.data &&
        state.issue.departmentData.data.data.departments) ||
      []
  );

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  // const [categoryList, setCategoryList] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [mapSelectData, setMapSelectData] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [newfield, setNewField] = useState();
  const [file, setFile] = useState([]);
  const [mediaKeys, setMediaKeys] = useState([]);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  // new code added from here
  const handleChangeDepartment = (event) => {
    const value = event.target.value;

    // Ensure that the selected department(s) is an array of objects
    const departmentArray = Array.isArray(value) ? value : [value];

    setSelectedDepartment(departmentArray);
    formik.setFieldValue("departments", departmentArray);
    formik.setFieldValue("category", "");
    ListCatagory(value._id);
  };


  const ListCatagory = async (deptId) => {
    try {
      const companyId = localStorage.getItem("companyId");
      const data = {
        url: `${BASE_URL}categories?company_id=${companyId}&departmentId=${deptId}&moduleName=issue-log`,
      };
      const response = await dispatch(DeptWiseCategoryListApi(data));

      if (response && response.payload) {
        setCategoryData(response.payload); // Assuming response payload has the categories
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    ListCatagory();
  }, []);

  const filteredCategoryList = categoryData.filter(
    (category) =>
      category.department &&
      category.department.some((dept) => dept._id === selectedDepartment?._id)
  );

  // useEffect(() => {
  //   return () => {
  //     setSelectedDepartment(null);
  //     formik.resetForm();
  //   };
  // }, []);
  // new code added till here

  useEffect(() => {
    if (editIssue && editIssue.status === 200) {
      setLoader(false);
      toast.success(editIssue.message, { autoClose: 3000 });
      setTimeout(() => {
        navigate("/issues/list");
      }, 2000);
    }

    if (errorImg && errorImg.status !== 200) {
      setLoader(false);
      setTimeout(() => {
        navigate("/issues/list");
      }, 2000);
    }
  }, [editIssue, errorImg]);

  // useEffect(() => {
  //   if (getImageKey && getImageKey[0].fileKey) {
  //     editAttachment(formik.values, getImageKey[0].fileKey);
  //   }
  // }, [getImageKey]);
  console.log("mediaKeys", mediaKeys, "getMediaKey", { getMediaKey });
  useEffect(() => {
    // yaha par aayega...
    console.log(image, file, mediaKeys);
    if (image && image.name && file && file.length) {
      if (
        getImageKey &&
        getImageKey[0].key &&
        mediaKeys.length === file.length
      ) {
        editAttachment(formik.values, getImageKey[0].key, mediaKeys);
      }
    } else if (file && file.length) {
      if (mediaKeys.length === file.length) {
        let imgKey = inputValue?.attachment?.key;
        editAttachment(formik.values, imgKey, mediaKeys);
      }
    } else if (image && image.name) {
      if (getImageKey && getImageKey[0].key) {
        editAttachment(formik.values, getImageKey[0].key, mediaKeys);
      }
    }
  }, [getImageKey, mediaKeys]);
  //Media Code is Here
  useEffect(() => {
    if (getMediaKey && getMediaKey.length && getMediaKey[0].key) {
      setMediaKeys((prev) => [...prev, getMediaKey[0].key]);
    }
  }, [getMediaKey]);

  useEffect(() => {
    if (image) {
      setPreview(URL.createObjectURL(image));
    }
  }, [image]);

  useEffect(() => {
    if (issueDepartmentList.length) {
      let arr = [];
      for (let item of issueDepartmentList) {
        let obj = {
          createdAt: item?.createdAt,
          deptAddr: item?.deptAddr,
          deptCity: item?.deptCity,
          deptCountry: item?.deptCountry,
          deptManager: item?.deptManager,
          deptName: item?.deptName,
          deptPinCode: item?.deptPinCode,
          deptState: item?.deptState,
          managerEmail: item?.managerEmail,
          managerMobileNumber: item?.managerMobileNumber,
          updatedAt: item?.updatedAt,
          _id: item?._id,
        };
        arr.push(obj);
      }
      setDepartmentList(arr);
    }
  }, [issueDepartmentList]);

  useEffect(() => {
    // const issueDepartmentList = JSON.parse(
    //   localStorage.getItem("departmentData")
    // );
    // const issueCategoryList = JSON.parse(localStorage.getItem("categoryData"));
    const issueStoreList = JSON.parse(localStorage.getItem("storeData"));
    // setDepartmentList(issueDepartmentList);
    // setCategoryList(issueCategoryList);
    setStoreList(issueStoreList);

    if (issueStoreList) {
      let arr = [];
      for (let item of issueStoreList) {
        let obj = { _id: item?._id, title: item?.name };
        arr.push(obj);
      }
      setMapSelectData(arr);
    }
  }, []);

  useEffect(() => {
    setInputValue(issueFormData);
  }, [issueFormData]);

  useEffect(() => {
    GetFormData();
  }, []);

  useEffect(() => {
    if (categoryList) {
      setCategoryData(categoryList);
    }
  }, [categoryList]);

  useEffect(() => {
    setInputValue(inputValue);
    if (inputValue) {
      setNewField({
        observation: inputValue && inputValue?.observation,
        severity: inputValue && inputValue?.severity,
        category: inputValue && inputValue?.category,
        subCategory: inputValue && inputValue?.subCategory,
        issueStatus: inputValue && inputValue?.issueStatus,
        departments: [selectedDepartment && selectedDepartment],
        store: selectedStore && selectedStore,
        action: inputValue && inputValue?.action,
      });
    }
  }, [inputValue]);

  useEffect(() => {
    if (inputValue && inputValue?.departments) {
      setSelectedDepartment(inputValue?.departments);
    }
  }, [inputValue]);

  useEffect(() => {
    if (inputValue && inputValue?.store) {
      setSelectedStore(inputValue?.store);
    }
  }, [inputValue]);

  const handleImageUpload = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
    }
  };

  const handleChangeStore = (event) => {
    const value = event.target.value;
    setSelectedStore(value);
  };

  const GetFormData = () => {
    const _id = localStorage.getItem("companyId");
    const issueId = location?.state?._id;
    const data = { url: BASE_URL + `issue/${issueId}?company_id=${_id}` };
    dispatch(GetIssueDataApi(data));
  };

  function getFilteredList() {
    // Avoid filter when selectedCategory is null
    if (!selectedCategory) {
      return [];
    }
    return categoryData.filter((item) => item.title === selectedCategory);
  }

  const filteredList = useMemo(getFilteredList, [
    selectedCategory,
    categoryData,
  ]);
  const uploadAttachmentandMedia = () => {
    setLoader(true);
    const imagefile = {
      files: image,
    };
    const imageData = {
      body: imagefile,
    };
    if (image && image.name && file && file.length) {
      file.forEach((fileData) => {
        const mediafile = {
          files: fileData,
        };
        const mediaData = {
          body: mediafile,
        };
        mediaData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
        dispatch(AddIssueMediaApi(mediaData));
      });
      imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
      dispatch(AddIssueImageApi(imageData));
    } else if (file && file.length) {
      file.forEach((fileData) => {
        const mediafile = {
          files: fileData,
        };
        const mediaData = {
          body: mediafile,
        };
        mediaData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
        dispatch(AddIssueMediaApi(mediaData));
      });
    } else if (image && image.name) {
      imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
      dispatch(AddIssueImageApi(imageData));
    } else {
      let imgKey = inputValue?.attachment?.key;
      editAttachment(formik.values, imgKey, mediaKeys);
    }
  };
  const formik = useFormik({
    initialValues: newfield || {
      observation: "",
      severity: "",
      category: "",
      subCategory: "",
      issueStatus: "",
      departments: [selectedDepartment],
      store: selectedStore,
      action: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const handleAddIssue = () => {
        confirmAlert({
          title: "Confirm to create",
          message: "Are you sure you want to create it?",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                uploadAttachmentandMedia();
              },
            },
            {
              label: "No",
              // Do nothing if 'No' is clicked
            },
          ],
        });
      };
      handleAddIssue();
    },
  });

  const editAttachment = (values, image) => {
    const formData = {
      action: values.action,
      category: values.category,
      categoryId: null,
      createdAt: inputValue.createdAt,
      deleted: inputValue.deleted,
      departments: selectedDepartment,
      formId: inputValue.formId,
      issueStatus: values.issueStatus,
      lastUpdatedBy: inputValue.lastUpdatedBy,
      loggedBy: inputValue.loggedBy,
      observation: values.observation,
      questionId: inputValue.questionId,
      reply: inputValue.reply,
      severity: values.severity,
      store: selectedStore,
      subCategory: values.subCategory,
      tokenNumber: inputValue.tokenNumber,
      updatedAt: inputValue.updatedAt,
      _id: inputValue._id,
    };
    if (image) {
      formData.attachment = image;
    }
    const oldMediaFiles = [];
    if (inputValue && inputValue.mediaFiles && inputValue.mediaFiles.length) {
      inputValue.mediaFiles.map((fileData) => {
        oldMediaFiles.push(fileData.key);
      });
    }
    if (file && file.length) {
      const finalArr = [...oldMediaFiles, ...mediaKeys];
      formData.mediaFiles = finalArr;
    } else {
      formData.mediaFiles = oldMediaFiles;
    }
    const allData = {
      body: formData,
    };

    if (allData) {
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `issue?company_id=${id}`;
        dispatch(EditIssueApi(allData));
      }
    }
  };

  useEffect(() => {
    setSelectedCategory(formik.values.category);
  }, [formik.values.category]);

  //Edie-Media code

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files;
    const selectedFiles = Array.from(event.target.files);
    const totalFiles = (file ? file.length : 0) + selectedFiles.length;
    console.log(selectedFile);
    [...event.target.files].forEach((file) => {
      setFile((prev) => [...prev, file]);
    });
  };
  //Edie-Meida End

  const handleRemoveFile = (indexToRemove) => {
    setFile(file.filter((_, index) => index !== indexToRemove));
  };
  useEffect(() => {
    if (file.length > 5) {
      setError("You can only upload up to 5 files.");
    } else {
      setError("");
    }
  }, [file]);

  const ListDepartment = (id) => {
    const data = {
      url: BASE_URL + `department/index?company_id=${id}`,
      body: {
        pagination: {
          page: 1,
          per_page: 500,
        },
      },
    };
    dispatch(IssueDepartmentListApi(data));
  };

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    ListDepartment(id);
  }, []);

  return (
    <>
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : null}
      <Box>
        <ToastContainer />
        <form onSubmit={formik.handleSubmit}>
          <Box className="header-card">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                  <Link to="/issues/list" className="submenu-item">
                    <Button className="cancle-button cancle-hover">
                      <CloseOutlinedIcon sx={{ mr: 1 }} />
                      Cancel
                    </Button>
                  </Link>
                  <Button className="header-add cancle-hover" type="submit">
                    <CheckOutlinedIcon sx={{ mr: 1 }} />
                    Confirm
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px", mt: 1 }}>
            <Container
              maxWidth="sm"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 1,
                textAlign: "left",
              }}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Issue</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="observation"
                  onChange={formik.handleChange}
                  className="issueinput-fields"
                  value={formik?.values?.observation}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Severity</label>
                <Select
                  size="small"
                  name="severity"
                  sx={{ borderRadius: "8px" }}
                  onChange={formik.handleChange}
                  renderValue={(selected) => selected}
                  value={formik.values.severity}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                >
                  <MenuItem value="Critical" className="select-item">
                    Critical
                  </MenuItem>
                  <MenuItem value="High" className="select-item">
                    High
                  </MenuItem>
                  <MenuItem value="Low" className="select-item">
                    Low
                  </MenuItem>
                  <MenuItem value="Medium" className="select-item">
                    Medium
                  </MenuItem>
                  <MenuItem value="Non-Critical" className="select-item">
                    Non-Critical
                  </MenuItem>
                </Select>
              </FormControl>

              {/* <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Department</label>
                <Select
                  size="small"
                  name="departments"
                  sx={{ borderRadius: "8px" }}
                  onChange={handleChangeDepartment}
                  value={selectedDepartment}
                  MenuProps={MenuProps}
                  renderValue={(selected) =>
                    selected.map((item) => item?.deptName)
                  }
                >
                  {departmentList &&
                    departmentList?.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option}
                          className="select-item"
                        >
                          {option.deptName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>


              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Category</label>
                <Select
                  size="small"
                  name="category"
                  sx={{ borderRadius: "8px" }}
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "200px",
                      },
                    },
                  }}
                  renderValue={(selected) => selected}
                >
                  {categoryList &&
                    categoryList?.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option.title}
                          className="select-item"
                        >
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl> */}

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Department</label>
                <Select
                  size="small"
                  name="departments"
                  sx={{ borderRadius: "8px" }}
                  onChange={handleChangeDepartment}
                  value={selectedDepartment}
                  renderValue={(selected) => {
                    if (selected.length === 0) return "Select Department";
                    return selected.map((dept) => dept.deptName).join(", ");
                  }}
                >
                  {departmentList &&
                    departmentList.map((option, i) => (
                      <MenuItem key={i} value={option} className="select-item">
                        {option.deptName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Category</label>
                <Select
                  size="small"
                  name="category"
                  sx={{ borderRadius: "8px" }}
                  onChange={formik.handleChange}
                  value={formik.values.category}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": { padding: 1 },
                        height: "200px",
                      },
                    },
                  }}
                  renderValue={(selected) => selected || "Select Category"}
                >
                  {categoryData && categoryData.length > 0 ? (
                    categoryData.map((option, i) => (
                      <MenuItem key={i} value={option.title} className="select-item">
                        {option.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled className="select-item">No categories available</MenuItem>
                  )}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Sub-Category</label>
                <Select
                  size="small"
                  name="subCategory"
                  sx={{ borderRadius: "8px" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.subCategory}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected}
                >
                  {filteredList &&
                    filteredList[0]?.subCategories &&
                    filteredList[0]?.subCategories.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option.title}
                          className="select-item"
                        >
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Status</label>
                <Select
                  size="small"
                  name="issueStatus"
                  sx={{ borderRadius: "8px" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.issueStatus}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected}
                >
                  <MenuItem value="Closed" className="select-item">
                    Closed
                  </MenuItem>
                  <MenuItem value="In-progress" className="select-item">
                    In-progress
                  </MenuItem>
                  <MenuItem value="Resolved" className="select-item">
                    Resolved
                  </MenuItem>
                  <MenuItem value="Open" className="select-item">
                    Open
                  </MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Store</label>
                <Select
                  size="small"
                  name="store"
                  onChange={handleChangeStore}
                  value={selectedStore}
                  sx={{ borderRadius: "8px" }}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFFFFF",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                        },
                        height: "200px",
                      },
                    },
                  }}
                  renderValue={(selected) => selected.title}
                >
                  {mapSelectData &&
                    mapSelectData.map((option, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={option}
                          className="select-item"
                        >
                          {option.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Action</label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="action"
                  multiline
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.action}
                  className="issueinput-fields"
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Attatchment</label>
                <Box className="attachment-file">
                  <input
                    name="attachment"
                    accept="image/*"
                    id="contained-button-image"
                    type="file"
                    hidden
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="contained-button-image">
                    <Fab component="span" className="icon-upload">
                      <AddOutlinedIcon />
                    </Fab>
                  </label>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {preview ? (
                      <img
                        className="image-multi"
                        src={preview}
                        alt="attachment"
                      />
                    ) : (
                      <Box>
                        {inputValue &&
                        inputValue.attachment &&
                        inputValue.attachment.url ? (
                          <img
                            className="image-multi"
                            src={inputValue?.attachment?.url}
                            alt="attachment"
                          />
                        ) : (
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            No attachment selected
                          </span>
                        )}
                      </Box>
                    )}
                  </Box>
                  {/* <Box>
                                        {preview ? (
                                            <img
                                                className="image-multi"
                                                src={preview}
                                                alt="attachment"
                                            />
                                        ) : (
                                            <img
                                                className="image-multi"
                                                src={attechment}
                                                alt="attachment"
                                            />
                                        )}
                                    </Box> */}
                </Box>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Media Files</label>
                <Box className="attachment-file">
                  <input
                    name="file"
                    accept="*"
                    id="contained-button-file"
                    type="file"
                    hidden
                    multiple
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="contained-button-file">
                    <Fab component="span" className="icon-upload">
                      <AddOutlinedIcon />
                    </Fab>
                  </label>
                  {/* <Box>
                    {file && file.length ? (
                      <>
                        <p>Files ({file.length}): </p>
                        {file.map((fileData, key) => fileData.name + ", ")}
                      </>
                    ) : (
                      <p>No file selected</p>
                    )}
                  </Box> */}
                  {/* <Box style={{ display: "flex", flexWrap: "wrap" }}>
                    {file && file.length ? (
                      file.map((fileData, index) => (
                        <div
                          key={index}
                          style={{ marginRight: "10px", marginBottom: "10px" }}
                        >
                          <div
                            className="file-box"
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: fileData.type.startsWith(
                                "image/"
                              )
                                ? "#cce5ff"
                                : "#f0f0f0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>{fileData.name.split(".").pop()}</span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No file selected</p>
                    )}
                  </Box> */}
                  <Box style={{ display: "flex", flexWrap: "wrap" }}>
                    {inputValue &&
                    inputValue.mediaFiles &&
                    inputValue.mediaFiles.length
                      ? inputValue.mediaFiles.map((fileData, index) => (
                          <div
                            key={index}
                            style={{
                              marginLeft: "10px",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              className="file-box"
                              style={{
                                width: "50px",
                                height: "55px",
                                backgroundColor:
                                  fileData.contentType.startsWith("image/")
                                    ? "#cce5ff"
                                    : "#f0f0f0",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative", // Position for the remove button
                              }}
                            >
                              {fileData.contentType.startsWith("image/") ? (
                                <img
                                src={fileData.url || fileData.preview}
                                alt={fileData.name}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "4px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <span>{fileData.name.split(".").pop()}</span>
                            )}
                            </div>
                          </div>
                        ))
                      : null}
                    {console.log("file", file)}
                    {file && file.length ? (
                      file.map((fileData, index) => (
                        <div
                          key={index}
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            className="file-box"
                            style={{
                              width: "50px",
                              height: "55px",
                              backgroundColor: fileData.type.startsWith(
                                "image/"
                              )
                                ? "#cce5ff"
                                : "#f0f0f0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative", // Position for the remove button
                            }}
                          >
                            {fileData.type.startsWith("image/") ? (
                              <img
                              src={URL.createObjectURL(fileData)}
                              alt={fileData.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "4px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (    
                            <span>{fileData.name.split(".").pop()}</span>
                          )}
                            <button
                              onClick={() => handleRemoveFile(index)}
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                                padding: "5px",
                                marginLeft: "10px",
                              }}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (inputValue &&
                        inputValue.mediaFiles &&
                        inputValue.mediaFiles.length) ||
                      (file && file.length) ? null : (
                      <p style={{ marginLeft: "10px" }}>No file selected</p>
                    )}
                  </Box>
                </Box>
                {error && (
                  <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
                )}
              </FormControl>
            </Container>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EditIssue;
