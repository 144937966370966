/** @format */

import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Card,
  Box,
  Table,
  TableBody,
  TableRow,
  TablePagination,
  TableCell,
  TableFooter,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  IssueListApi,
  DeleteIssueApi,
  AddIssueSuccess,
  EditIssueSuccess,
  AddIssueFailed,
  EditIssueFailed,
  AddIssueImageSuccess,
  AddIssueImageFailed,
  AllIssueListApi,
  CategoryListApi,
  IssueDepartmentListApi,
  DeptWiseCategoryListApi,
  ArchivedIssueListApi,
} from "../../redux/actions/Issue";
import moment from "moment";
import Header from "./pages/Header";
import { useLocation, useNavigate } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import { makeStyles, useTheme } from "material-ui-core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PropTypes from "prop-types";
import NoDataFound from "../../NoDataFound";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import Tooltip from "@mui/material/Tooltip";

const IssuesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const issuesScreenFilter = location.state;

  const [loader, setLoader] = useState(true);
  const issueFilter = localStorage.getItem("IssueFilterData") || "{}";
  const issueFilterData = JSON.parse(issueFilter);
  // console.log("issueFilterData", issueFilterData);

  const issueList = useSelector(
    (state) =>
      state.issue &&
      state.issue.data &&
      state.issue.data.data &&
      state.issue.data.data.data &&
      state.issue.data.data.data.issues
  );
  const departmentListData = useSelector(
    (state) =>
      (state.issue &&
        state.issue.departmetData &&
        state.issue.departmetData.data &&
        state.issue.departmetData.data.departments) ||
      []
  );

  const archievdIssueList = useSelector(
    (state) =>
      state &&
      state?.issue &&
      state?.issue?.issueArchivedDataList &&
      state?.issue?.issueArchivedDataList?.data &&
      state?.issue?.issueArchivedDataList?.data?.data &&
      state?.issue?.issueArchivedDataList?.data?.data?.issues
  );

  const totalitems = useSelector(
    (state) =>
      state.issue &&
      state.issue.data &&
      state.issue.data.data &&
      state.issue.data.data.data &&
      state.issue.data.data.data.paginate
  );

  const archievdIssueListTotalItems = useSelector(
    (state) =>
      state &&
      state?.issue &&
      state?.issue?.issueArchivedDataList &&
      state?.issue?.issueArchivedDataList?.data &&
      state?.issue?.issueArchivedDataList?.data?.data &&
      state?.issue?.issueArchivedDataList?.data?.data?.paginate
  );
  const newIssue = useSelector(
    (state) => state.issue && state.issue.addIssue && state.issue.addIssue.data
  );
  const editIssue = useSelector(
    (state) =>
      state.issue && state.issue.editIssue && state.issue.editIssue.data
  );
  const errorImg = useSelector(
    (state) => state.issue && state.issue.error && state.issue.error.data
  );
  const getImageKey = useSelector(
    (state) =>
      state.issue &&
      state.issue.addImage &&
      state.issue.addImage.data &&
      state.issue.addImage.data.data
  );
  const Loader = useSelector((state) => state.issue && state.issue.loading);

  const [issueData, setIssueData] = useState([]);
  const [isImages, setIsImages] = useState([]);
  // const [page, setPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  var rowPerPage = localStorage.getItem("issueRowPerPage");
  const [rowsPerPage, setRowsPerPage] = useState(
    (rowPerPage && parseInt(rowPerPage)) || 10
  );
  var pageno = localStorage.getItem("issuePageno");
  const [page, setPage] = useState((pageno && parseInt(pageno)) || 1);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [indexHover, setIndexHover] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });
  const [permission, setPermission] = useState({});
  const [searchData, setSearchData] = useState({});
  const [startDate, setStartDate] = useState(
    moment(
      issuesScreenFilter?.startDate || moment().subtract(1, "months")
    ).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(issuesScreenFilter?.endDate || new Date()).format("YYYY-MM-DD")
  );
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [issueStatus, setIssueStatus] = useState(
    issuesScreenFilter?.status || ""
  );
  const [tokenNumber, setTokenNumber] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setsubCategory] = useState("");
  const [severity, setSeverity] = useState("");
  const [storeId, setStoreId] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [priority, setPriority] = useState("");

  const paginationRequest = {
    body: {
      pagination: {
        page: page,
        per_page: rowsPerPage,
      },
      archived: false,
      priority: priority,
      // search: search,
      // issueStatus: !issuesScreenFilter?.status ? "" : issuesScreenFilter?.status === "Resolved" ? ["Resolved", "Closed"] : [`${issueStatus}`],
      // tokenNumber: tokenNumber,
      // category: category,
      // subCategory: subCategory,
      // severity: severity,
      // storeId: storeId,
      // departmentName: departmentName,
      // start_date: startDate,
      // end_date: endDate,
      search: issueFilterData?.searchQuery,
      // issueStatus: issueFilterData?.issueStatus,
      issueStatus: issueFilterData?.issueStatus
        ? Array.isArray(issueFilterData.issueStatus)
          ? issueFilterData.issueStatus
          : [issueFilterData.issueStatus]
        : [],
      tokenNumber: issueFilterData?.tokenNumber,
      category: issueFilterData?.category,
      subCategory: issueFilterData?.subCategory,
      severity: issueFilterData?.severity,
      storeId: issueFilterData?.storeId,
      departmentName: issueFilterData?.departmentName,
      start_date: issueFilterData?.start_date,
      end_date: issueFilterData?.end_date,
    },
  };
  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    const id = localStorage.getItem("companyId");

    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
    DeptListCatagory(id);
    ListCatagory(id);
    ListDepartment(id);
  }, []);

  useEffect(() => {
    dispatch(AddIssueSuccess(""));
    dispatch(EditIssueSuccess(""));
    dispatch(AddIssueFailed(""));
    dispatch(EditIssueFailed(""));
    dispatch(AddIssueImageSuccess(""));
    dispatch(AddIssueImageFailed(""));
  }, [newIssue, editIssue, errorImg, getImageKey]);

  const handleClick = (row) => {
    navigate(`/issues/list/view-details`, { state: row });
  };

  const handleClickEdit = (row) => {
    navigate(`/issues/list/edit`, { state: row });
  };
  const handleFilterData = (filterValues) => {
    console.log("Received data from header child class", filterValues);
    // Handle the data further as needed
    paginationRequest.body.search = filterValues?.searchQuery;
    paginationRequest.body.issueStatus = filterValues?.issueStatus
      ? [`${filterValues?.issueStatus}`]
      : "";
    paginationRequest.body.tokenNumber = filterValues?.tokenNumber;
    paginationRequest.body.category = filterValues?.category;
    paginationRequest.body.subCategory = filterValues?.subCategory;
    paginationRequest.body.severity = filterValues?.severity;
    paginationRequest.body.storeId = filterValues?.storeId;
    paginationRequest.body.departmentName = filterValues?.departmentName;
    paginationRequest.body.priority = filterValues?.priority;
    paginationRequest.body.start_date = filterValues?.start_date;
    paginationRequest.body.end_date = filterValues?.end_date;
    paginationRequest.body.pagination.page = 1;
    localStorage.setItem("issuePageno", 1);

    setPriority(filterValues?.priority);
    setDepartmentName(filterValues?.departmentName);
    setStoreId(filterValues?.storeId);
    setSeverity(filterValues?.severity);
    setCategory(filterValues?.category);
    setsubCategory(filterValues?.subCategory);
    setTokenNumber(filterValues?.tokenNumber);
    setIssueStatus(filterValues?.issueStatus);
    setSearch(filterValues?.searchQuery);
    setStartDate(filterValues?.start_date);
    setEndDate(filterValues?.end_date);
    setPage(1);
    // to do
    listIssue(paginationRequest);
  };
  const handleDelete = (dataId) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const issueId = dataId;
            const data = {
              url: BASE_URL + `issue/${issueId}?company_id=${id}`,
            };
            setLoader(true);
            dispatch(DeleteIssueApi(data));
            listIssue(paginationRequest);
            // setTimeout(() => {
            //   setLoader(false);
            // }, 2000);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const ListDepartment = (id) => {
    const data = {
      url: BASE_URL + `department/index?company_id=${id}`,
      body: {
        pagination: {
          page: 1,
          per_page: 500,
        },
      },
    };
    dispatch(IssueDepartmentListApi(data));
  };

  const ListCatagory = () => {
    const id = localStorage.getItem("companyId");
    const data = {
      url: BASE_URL + `categories?company_id=${id}&moduleName=${"issue-log"}`,
    };
    dispatch(CategoryListApi(data));
  };

  useEffect(() => {
    // setLoader(true);
    setIssueData(issueList);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [issueList]);

  useEffect(() => {
    if (checkedArchive) {
      setIssueData(archievdIssueList);
    } else {
      setIssueData(issueList);
    }
  }, [archievdIssueList, issueList, checkedArchive]);

  useEffect(() => {
    if (checkedArchive === true) {
      setTotalCount(archievdIssueListTotalItems?.total_item);
    } else {
      setTotalCount(totalitems?.total_item);
    }
  }, [archievdIssueListTotalItems, totalitems, checkedArchive]);

  const openImageViewer = useCallback((url) => {
    let imageUrl = [];
    imageUrl.push(url);
    setIsImages(imageUrl);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  useEffect(() => {
    if (searchValue == "") {
      listIssue(paginationRequest);
    }
  }, [searchValue]);
  // const paginationRequest = {
  //   body: {
  //     pagination: {
  //       page: page,
  //       per_page: rowsPerPage,
  //     },
  //     archived: false,
  //     search: "",
  //   },
  // };

  const IssueListArchivedApi = (paginationRequest) => {
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `ListArchivedIssue?company_id=${id}`;
    dispatch(ArchivedIssueListApi(paginationRequest));
  };

  const listIssue = (paginationRequest) => {
    setLoader(true);
    const id = localStorage.getItem("companyId");
    paginationRequest.url = BASE_URL + `issue/index?company_id=${id}`;
    dispatch(IssueListApi(paginationRequest));
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const getIssueForExport = () => {
    const request = {
      body: {
        pagination: {
          page: 1,
          per_page: totalCount || 10,
        },
        search: search,
        issueStatus: !issueStatus
          ? ""
          : issueStatus === "Resolved"
          ? ["Resolved", "Closed"]
          : [`${issueStatus}`],
        tokenNumber: tokenNumber,
        category: category,
        severity: severity,
        storeId: storeId,
        departmentName: departmentName,
        priority: priority,
        start_date: startDate,
        end_date: endDate,
        isExport: true,
      },
    };
    setLoader(true);
    const id = localStorage.getItem("companyId");
    request.url = BASE_URL + `issue/index?company_id=${id}`;
    dispatch(AllIssueListApi(request));
    setLoader(false);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  const DeptListCatagory = (id) => {
    const data = {
      url: BASE_URL + `categories?company_id=${id}&moduleName=${"issue-log"}`,
    };
    dispatch(DeptWiseCategoryListApi(data));
  };

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    localStorage.setItem("issuePageno", newPage + 1);
    // setLoader(true);
    // setPage(newPage + 1);
    setPage(newPage + 1);
    paginationRequest.body.pagination.page = newPage + 1;
    if (checkedArchive === false) {
      listIssue(paginationRequest);
    } else {
      IssueListArchivedApi(payloadRequest);
    }
    // listIssue(paginationRequest);
    // setTimeout(() => {
    //   setLoader(false);
    // }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    // setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("issueRowPerPage", parseInt(event.target.value, 10));
    setPage(1);
    // paginationRequest.body.pagination.page = 1;
    localStorage.removeItem("issuePageno");
    localStorage.setItem("issuePageno", 1);
    paginationRequest.body.pagination.per_page = parseInt(
      event.target.value,
      10
    );
    if (checkedArchive === false) {
      listIssue(paginationRequest);
    } else {
      IssueListArchivedApi(payloadRequest);
    }
    // listIssue(paginationRequest);
    // setTimeout(() => {
    //   setLoader(false);
    // }, 500);
  };

  const handleSearch = (value) => {
    var localPageno = localStorage.getItem("issuePageno");
    setSearchValue(value);
  };

  const openFile = (fileData) => {
    const { url } = fileData;
    window.open(url, "_blank");
  };

  //Media file open new Window code end
  return (
    <>
      <Header
        searchOnChange={handleSearch}
        // searchValue={searchValue}
        // handleKeyDown={handleKeyDown}
        checkedArchive={checkedArchive}
        setCheckedArchive={setCheckedArchive}
        totalitems={totalitems?.total_item}
        getIssueForExport={getIssueForExport}
        page={page}
        per_page={rowsPerPage}
        archievdIssueListTotalItems={archievdIssueListTotalItems}
        permission={permission}
        onFilter={handleFilterData}
      />
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "calc(100vh - 280px)",
          mt: 1,
          borderRadius: "8px",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <Grid sx={{ p: 1 }}>
            {issueData && issueData.length > 0 ? (
              issueData.map((item, i) => {
                return (
                  <Grid key={i}>
                    <Box sx={{ mb: 1, mt: 2 }}>
                      <Stack direction="row">
                        <Typography
                          className={
                            item.severity === "High" || item.severity === "high"
                              ? "Priority bg"
                              : item.severity === "Medium" ||
                                item.severity === "medium"
                              ? "Medium bg"
                              : item.severity === "Low" ||
                                item.severity === "low"
                              ? "low bg"
                              : item.severity === "Non-Critical" ||
                                item.severity === "Non-critical"
                              ? "NonCritical bg"
                              : item.severity === "Critical"
                              ? "Critical bg"
                              : ""
                          }
                        >
                          Priority: {item.severity}
                        </Typography>
                        <Typography
                          className={
                            item.issueStatus === "Open" ||
                            item.issueStatus === "open"
                              ? "Open bg"
                              : item.issueStatus === "In-progress" ||
                                item.issueStatus === "In-Progress"
                              ? "InProgress bg"
                              : item.issueStatus === "resolved" ||
                                item.issueStatus === "Resolved"
                              ? "Resolved bg"
                              : item.issueStatus === "closed" ||
                                item.issueStatus === "Closed"
                              ? "Closed bg"
                              : ""
                          }
                        >
                          Status: {item.issueStatus}
                        </Typography>
                      </Stack>
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Card
                          sx={{ p: 2, height: "100%", overflow: "auto" }}
                          className="card"
                        >
                          <Table className="priroty-table">
                            <TableBody>
                              <TableRow></TableRow>
                              <TableRow>
                                <TableCell className="header-table">
                                  Token:
                                </TableCell>
                                <TableCell>
                                  <b>
                                    {item.tokenNumber ? item.tokenNumber : "NA"}
                                  </b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="header-table">
                                  Created by:
                                </TableCell>
                                <TableCell>
                                  <b>
                                    {item.loggedBy.name
                                      ? item.loggedBy.name
                                      : "NA"}
                                  </b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="header-table">
                                  Created at:
                                </TableCell>
                                <TableCell>
                                  <b>
                                    {moment(item.createdAt).format(
                                      "MMMM D, YYYY, LTS"
                                    )}
                                  </b>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Card
                          sx={{ p: 2, height: "100%", overflow: "auto" }}
                          className="card"
                        >
                          <Table className="priroty-table">
                            {item.departments && item.departments.length > 0 ? (
                              item.departments.map((subitem, index) => {
                                return (
                                  <TableBody key={index}>
                                    <TableRow>
                                      <TableCell className="header-table">
                                        Department:
                                      </TableCell>
                                      <TableCell>
                                        <b>
                                          {subitem?.deptName
                                            ? subitem?.deptName
                                            : "NA"}
                                        </b>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="header-table">
                                        Manager:
                                      </TableCell>
                                      <TableCell>
                                        <b>
                                          {subitem?.deptManager
                                            ? subitem?.deptManager
                                            : "NA"}
                                        </b>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="header-table">
                                        Mobile:
                                      </TableCell>
                                      <TableCell>
                                        <b>
                                          {subitem?.managerMobileNumber
                                            ? subitem?.managerMobileNumber
                                            : "NA"}
                                        </b>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell className="header-table">
                                        Email:
                                      </TableCell>
                                      <TableCell>
                                        <b>
                                          {subitem?.managerEmail
                                            ? subitem?.managerEmail
                                            : "NA"}
                                        </b>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                );
                              })
                            ) : (
                              <b>No Data Found</b>
                            )}
                          </Table>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        sx={{ display: "flex", width: "100%" }}
                      >
                        <Card
                          sx={{
                            p: 2,
                            height: "100%",
                            width: "100%",
                            overflow: "auto",
                          }}
                          className="card"
                        >
                          <Table className="priroty-table">
                            <TableBody>
                              <TableRow>
                                <TableCell className="header-table">
                                  Category:
                                </TableCell>
                                <TableCell>
                                  <b>{item.category}</b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="header-table">
                                  Sub-Category:
                                </TableCell>
                                <TableCell>
                                  <b>{item.subCategory}</b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="header-table">
                                  Store:
                                </TableCell>
                                <TableCell>
                                  <b>{item?.store?.title}</b>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Card>
                        <Box
                          sx={{ display: "grid", ml: 1 }}
                          className="icon-button"
                        >
                          {checkedArchive === false ? (
                            <div
                              style={{
                                display: "contents",
                              }}
                            >
                              <Tooltip title="View">
                                <img
                                  src={
                                    hover.View && indexHover === i
                                      ? ViewHover
                                      : View
                                  }
                                  alt="VIEW"
                                  style={{
                                    height: "35px",
                                    cursor: "pointer",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => handleClick(item)}
                                  onMouseOver={() => {
                                    setHover({ View: true });
                                    setIndexHover(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHover({ View: false });
                                  }}
                                />
                              </Tooltip>
                              {permission?.issueLog?.manageIssues === true ? (
                                <>
                                  <Tooltip title="Edit">
                                    <img
                                      onClick={() => handleClickEdit(item)}
                                      src={
                                        hover.Edit && indexHover === i
                                          ? EditHover
                                          : Edit
                                      }
                                      alt="Edit"
                                      style={{
                                        height: "35px",
                                        cursor: "pointer",
                                        marginBottom: "5px",
                                      }}
                                      onMouseOver={() => {
                                        setHover({ Edit: true });
                                        setIndexHover(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHover({ Edit: false });
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                    <img
                                      onClick={() => handleDelete(item._id)}
                                      src={
                                        hover.Delete && indexHover === i
                                          ? DeleteHover
                                          : Delete
                                      }
                                      alt="Delete"
                                      style={{
                                        height: "35px",
                                        cursor: "pointer",
                                        marginBottom: "5px",
                                      }}
                                      onMouseOver={() => {
                                        setHover({ Delete: true });
                                        setIndexHover(i);
                                      }}
                                      onMouseLeave={() => {
                                        setHover({ Delete: false });
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ marginTop: 0.2 }}>
                      <Grid item xs={12} sm={12} md={5} lg={4}>
                        <Card sx={{ p: 1, overflow: "auto" }} className="card">
                          <Table className="priroty-table">
                            <TableBody>
                              <TableRow>
                                <TableCell className="header-table">
                                  Observation:
                                </TableCell>
                                <TableCell>
                                  <b>{item.observation}</b>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Card sx={{ p: 1 }} className="card">
                          <Table className="priroty-table">
                            <TableBody>
                              <TableRow>
                                <TableCell className="header-table">
                                  Action:
                                </TableCell>
                                <TableCell>
                                  <b>{item.action}</b>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Card sx={{ p: 1 }} className="card">
                          <Table className="priroty-table">
                            <TableBody>
                              {item.attachment &&
                              item.attachment.id !== null ? (
                                <Button
                                  variant="outlined"
                                  className="attach-button cancle-hover"
                                  onClick={() =>
                                    openImageViewer(item.attachment.url)
                                  }
                                >
                                  Attachment
                                </Button>
                              ) : (
                                <Typography
                                  variant="p"
                                  component="b"
                                  style={{
                                    display: "inline-block",
                                    padding: "8px 12px",
                                    backgroundColor: "#f5f5f5",
                                    color: "#333",
                                    borderRadius: "4px",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  }}
                                >
                                  No Attachment
                                </Typography>
                              )}
                              {isViewerOpen && (
                                <ImageViewer
                                  src={isImages}
                                  onClose={closeImageViewer}
                                  disableScroll={false}
                                  backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)",
                                  }}
                                  closeOnClickOutside={true}
                                />
                              )}
                            </TableBody>
                          </Table>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card sx={{ p: 1 }} className="card">
                          <Table className="priroty-table">
                            <TableBody>
                              <TableRow>
                                <TableCell className="header-table">
                                  Media Files:
                                </TableCell>
                                <TableCell>
                                  {item.mediaFiles && item.mediaFiles.length ? (
                                    item.mediaFiles.map((mFiles, keys) => (
                                      <Tooltip title={mFiles.name}>
                                        <Button
                                          variant="outlined"
                                          className="attach-button cancle-hover"
                                          sx={{
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                            marginBottom: "2px",
                                            padding: "1px",
                                          }}
                                          onClick={() => openFile(mFiles)}
                                        >
                                          File {keys + 1}
                                        </Button>
                                      </Tooltip>
                                    ))
                                  ) : (
                                    <Typography variant="p" component="b">
                                      No Media Files
                                    </Typography>
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Card sx={{ p: 1 }}>
                <NoDataFound />
              </Card>
            )}
          </Grid>
        )}
      </Box>
      <Card className="header-card" sx={{ mt: 1 }}>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                className="tablepagination"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: totalitems?.total_item },
                ]}
                count={totalCount}
                colSpan={7}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                SelectProps={{
                  inputProps: { "aria-label": "Items per Page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Items per Page"}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Card>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default IssuesList;
