import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";
import {
  ACCOUNT_DETAILS_ERROR,
  ACCOUNT_DETAILS_REQUEST,
  ACCOUNT_DETAILS_SUCCESS,
  PLAN_DETAILS_ERROR,
  PLAN_DETAILS_REQUEST,
  PLAN_DETAILS_SUCCESS,
  SUBMIT_ACCOUNT_DETAILS_ERROR,
  SUBMIT_ACCOUNT_DETAILS_REQUEST,
  SUBMIT_ACCOUNT_DETAILS_SUCCESS,
  UPDATE_COMPANY_EXP_DATE_ERROR,
  UPDATE_COMPANY_EXP_DATE_REQUEST,
  UPDATE_COMPANY_EXP_DATE_SUCCESS,
  UPDATE_COMPANY_STATUS_ERROR,
  UPDATE_COMPANY_STATUS_REQUEST,
  UPDATE_COMPANY_STATUS_SUCCESS,
} from "../types/Types";

export const PlanDetailsRequest = () => ({
  type: PLAN_DETAILS_REQUEST,
});
export const PlanDetailsSuccess = (type) => ({
  type: PLAN_DETAILS_SUCCESS,
  payload: type,
});
export const PlanDetailsFailed = (error) => ({
  type: PLAN_DETAILS_ERROR,
  payload: error,
});

export const PlanDetailsApi = (data) => (dispatch) => {
  try {
    dispatch(PlanDetailsRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(PlanDetailsSuccess(data));
      } else {
        dispatch(PlanDetailsFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(PlanDetailsFailed(error));
  }
};

export const submitAccountDetailsRequest = () => ({
  type: SUBMIT_ACCOUNT_DETAILS_REQUEST,
});
export const submitAccountDetailsSuccess = (type) => ({
  type: SUBMIT_ACCOUNT_DETAILS_SUCCESS,
  payload: type,
});
export const submitAccountDetailsFailed = (error) => ({
  type: SUBMIT_ACCOUNT_DETAILS_ERROR,
  payload: error,
});

export const submitAccountDetailsApi = (data, callback) => (dispatch) => {
  try {
    dispatch(submitAccountDetailsRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        console.log("response", response);
        const data = response && response.data && response.data.data;
        toast.success(response.data.message);
        dispatch(submitAccountDetailsSuccess(data));
        callback();
      } else {
        // toast.error(response.data.message);
        dispatch(submitAccountDetailsFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(submitAccountDetailsFailed(error));
  }
};

export const AccountDetailsRequest = () => ({
  type: ACCOUNT_DETAILS_REQUEST,
});
export const AccountDetailsSuccess = (type) => ({
  type: ACCOUNT_DETAILS_SUCCESS,
  payload: type,
});
export const AccountDetailsFailed = (error) => ({
  type: ACCOUNT_DETAILS_ERROR,
  payload: error,
});

export const AccountDetailsApi = (data) => (dispatch) => {
  try {
    dispatch(AccountDetailsRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(AccountDetailsSuccess(data));
      } else {
        dispatch(AccountDetailsFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AccountDetailsFailed(error));
  }
};

export const updateCompanyExpireDateRequest = () => ({
  type: UPDATE_COMPANY_EXP_DATE_REQUEST,
});
export const updateCompanyExpireDateSuccess = (type) => ({
  type: UPDATE_COMPANY_EXP_DATE_SUCCESS,
  payload: type,
});
export const updateCompanyExpireDateFailed = (error) => ({
  type: UPDATE_COMPANY_EXP_DATE_ERROR,
  payload: error,
});

export const updateCompanyExpireDateApi = (data) => (dispatch) => {
  try {
    dispatch(updateCompanyExpireDateRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        console.log("response", response);
        const data = response && response.data && response.data.data;
        toast.success(response.data.message);
        dispatch(updateCompanyExpireDateSuccess(data));
      } else {
        // toast.error(response.data.message);
        dispatch(updateCompanyExpireDateFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(updateCompanyExpireDateFailed(error));
  }
};

export const updateCompanyStatusRequest = () => ({
  type: UPDATE_COMPANY_STATUS_REQUEST,
});
export const updateCompanyStatusSuccess = (type) => ({
  type: UPDATE_COMPANY_STATUS_SUCCESS,
  payload: type,
});
export const updateCompanyStatusFailed = (error) => ({
  type: UPDATE_COMPANY_STATUS_ERROR,
  payload: error,
});

export const updateCompanyStatusApi = (data) => (dispatch) => {
  try {
    dispatch(updateCompanyStatusRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        console.log("response", response);
        const data = response && response.data && response.data.data;
        toast.success(response.data.message);
        dispatch(updateCompanyStatusSuccess(data));
      } else {
        // toast.error(response.data.message);
        dispatch(updateCompanyStatusFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(updateCompanyStatusFailed(error));
  }
};
