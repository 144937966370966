import { Box, Button, Card, CardContent, Container, FormControl, Grid, Stack, TextField, Typography } from "@mui/material"
import { ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateTemplateHeader from "./component/CreateTemplateHeader";
import AddIcon from "@mui/icons-material/Add";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Edit from "../images/Edit.svg";
import Delete from "../images/Delete.svg";
import DeleteHover from "../images/DeleteHover.svg";
import EditHover from "../images/EditHover.svg";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CreateTemplateApi, EditTemplateApi } from "../redux/actions/Inventory";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";

const CreateTemplate = () => {
    let templatedata = localStorage.getItem("addTemplate") && JSON.parse(localStorage.getItem("addTemplate")) || localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId"));
    let addQustionEditData = localStorage.getItem("questions") && JSON.parse(localStorage.getItem("questions")) || localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId")).question;

    const validationSchema = Yup.object().shape({});
    const dragOverItem = useRef();
    const dragItem = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const defaultQuestion = [
        {
            "title": "Product name",
            "desc": "Name of the product",
            "allowFilter": false,
            "type": {
                "validations": [
                    {
                        "id": 1,
                        "key": "required",
                        "title": "Is Required?",
                        "type": "radio",
                        "answer": [
                            {
                                "title": "Yes",
                                "value": true
                            },
                            {
                                "title": "No",
                                "value": false
                            }
                        ]
                    }
                ],
                "key": "text",
                "title": "Text",
                "title_to_show": "Text",
                "id": 11
            },
            "order": 1,
            "id": "1",
            "default": true
        },
        {
            "title": "Product rate",
            "desc": "Rate of the product",
            "allowFilter": false,
            "type": {
                "validations": [
                    {
                        "id": 1,
                        "key": "required",
                        "title": "Is Required?",
                        "type": "radio",
                        "answer": [
                            {
                                "title": "Yes",
                                "value": true
                            },
                            {
                                "title": "No",
                                "value": false
                            }
                        ]
                    }
                ],
                "key": "number",
                "title": "number",
                "title_to_show": "number",
                "id": 11
            },
            "order": 2,
            "id": "2",
            "default": true
        },
        {
            "title": "Product weight & product measurement",
            "desc": "Weigtht & measurement of the product",
            "allowFilter": false,
            "type": {
                "validations": [
                    {
                        "id": 1,
                        "key": "required",
                        "title": "Is Required?",
                        "type": "radio",
                        "answer": [
                            {
                                "title": "Yes",
                                "value": true
                            },
                            {
                                "title": "No",
                                "value": false
                            }
                        ]
                    }
                ],
                "key": "text",
                "title": "Text",
                "title_to_show": "Text",
                "id": 11
            },
            "order": 3,
            "id": "3",
            "default": true
        },
    ]

    const [indexHover, setIndexHover] = useState(0);
    const [questionData, setQuestionData] = useState(defaultQuestion);
    const [loader, setLoader] = useState(false);
    const [hover, setHover] = useState({
        Edit: false,
        Delete: false,
    });


    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };
    const drop = (e) => {
        const copyListItems = [...questionData];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setQuestionData(copyListItems);
    };

    const handleDelete = (index) => {
        const localdata = questionData.filter((item, i) => i !== index);
        setQuestionData(localdata);
        localStorage.setItem("questions", JSON.stringify(localdata));
    };

    useEffect(() => {
        if (questionData)
            localStorage.setItem("qustionDataid", questionData.length);
    }, [questionData]);

    useEffect(() => {
        if (addQustionEditData) {
            setQuestionData(addQustionEditData);
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            templateTitle: templatedata?.templateTitle || templatedata?.name || "",
            description: templatedata?.description || ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleFinalSubmit(values);
        },
    });

    const handleFinalSubmit = (values) => {
        const isEdit = localStorage.getItem('userId');

        if (!values.templateTitle) {
            return toast.error("Title is required!");
        }

        if (!values.description) {
            return toast.error("Description is required!");
        }

        if (!questionData.length) {
            return toast.error("Questions required!");
        }

        if (values) {
            const formquestionValue = {
                question: questionData,
                name: values.templateTitle,
                description: values.description,
            };
            const allData = {
                body: formquestionValue,
            };
            const id = localStorage.getItem("companyId");
            setLoader(true);
            if (isEdit && JSON.parse(isEdit)) {
                allData.url = BASE_URL + `product-template/${JSON.parse(isEdit)?._id}?company_id=${id}`;
                dispatch(EditTemplateApi(allData, navigatioToList));
            } else {
                allData.url = BASE_URL + `product-template?company_id=${id}`;
                dispatch(CreateTemplateApi(allData, navigatioToList));
            }
        }
    };

    const navigatioToList = (data) => {
        if (data === 200) {
            setTimeout(() => {
                setLoader(false);
                navigate({ pathname: "/template" });
                localStorage.removeItem("addLogEntry")
                localStorage.removeItem("questions")
                localStorage.removeItem("userId")
            }, 2000);
        }
    };

    const handleCancel = () => {
        localStorage.removeItem("addTemplate")
        localStorage.removeItem("questions")
        localStorage.removeItem("userId")
    };

    const handleClick = (item) => {
        let oldQues = localStorage.getItem("questions");
        if (oldQues) {
            let oldD1 = JSON.parse(oldQues);
            const fetchFilterableQues = oldD1.filter(ques => ques.allowFilter);
            if (fetchFilterableQues.length >= 10) {
                toast.warn("Max 10 Question you can add!")
                return;
            }
        }
        localStorage.setItem("addTemplate", JSON.stringify(formik.values));
        localStorage.setItem("default", true);
        localStorage.setItem("questions", JSON.stringify(questionData));
        navigate("/template/create/add-template-question");
    };


    const handleClickEditform = (item, i) => {
        navigate("/template/create/edit-template-question");
        localStorage.setItem("addQustionEditData", JSON.stringify(item));
        localStorage.setItem("qustioneditid", i);
        localStorage.setItem("questions", JSON.stringify(questionData));
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <ToastContainer autoClose={3000} />
            {loader && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <ProgressbarTransparent play />
                </div>)
            }

            <CreateTemplateHeader handleFinalSubmit={handleFinalSubmit} handleCancel={handleCancel} values={formik} />
            <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
                <Container
                    maxWidth="xl"
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        p: 1,
                        textAlign: "left",
                    }}
                >
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Title</label>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="templateTitle"
                            onChange={formik.handleChange}
                            // defaultValue={trainingdata?.name}
                            value={formik.values.templateTitle}
                            className="date-input"
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Description</label>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            name="description"
                            onChange={formik.handleChange}
                            // defaultValue={trainingdata?.name}
                            value={formik.values.description}
                            className="date-input"
                        />
                    </FormControl>
                    <Grid item md={12} xs={12} sm={12} lg={12}>
                        <Grid
                            container
                            spacing={1}
                            sx={{ justifyContent: "center", margin: "0 0 20px" }}
                        >
                            <Grid item md={12} sx={12} sm={12}>
                                <Typography variant="p" component="b" sx={{ mt: 2 }}>
                                    Question
                                </Typography>
                                <Button
                                    variant="outlined"
                                    className="add-button cancle-hover"
                                    sx={{ width: "99%", mt: 1 }}
                                    onClick={handleClick}
                                >
                                    <AddIcon sx={{ mx: 1 }} />
                                    Add Question
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box className="align-icon">
                        <Typography
                            variant="p"
                            component="h2"
                            className="top-heading"
                            align="left"
                        >
                            Available Question
                        </Typography>
                    </Box>
                    {questionData &&
                        questionData?.map((items, i) => {
                            return (
                                <Card sx={{ mb: 2, borderRadius: "8px" }} key={i}>
                                    <div
                                        style={{ cursor: "grab", textAlign: "center", marginTop: "5px" }}
                                        onDragStart={(e) => dragStart(e, i)}
                                        onDragEnter={(e) => dragEnter(e, i)}
                                        onDragEnd={drop}
                                        draggable
                                    >
                                        <DehazeIcon />
                                    </div>

                                    <CardContent sx={{ color: "#27364b" }}>
                                        <Stack direction="row" spacing={2}>
                                            <Typography
                                                variant="p"
                                                component="p"
                                                style={{ whiteSpace: "nowrap" }}
                                            >
                                                Q {i + 1}.
                                            </Typography>
                                            <Typography
                                                variant="p"
                                                component="b"
                                                style={{ textAlign: "left" }}
                                            >
                                                {items?.title}
                                            </Typography>
                                        </Stack>

                                        <Box className="align-icon" sx={{ margin: "0 0px" }}>
                                            <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                                                <Typography variant="p" component="p">
                                                    Description :
                                                </Typography>
                                                <Typography variant="p" component="b">
                                                    {items?.desc}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        <Box className="align-icon" sx={{ margin: "0px 0px" }}>
                                            <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                                                <Typography variant="p" component="p">
                                                    Category :
                                                </Typography>
                                                <Typography variant="p" component="b">
                                                    {items?.category || '-'}
                                                </Typography>
                                            </Stack>
                                        </Box>

                                        <Box className="align-icon" sx={{ margin: "0px 0px" }}>
                                            <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                                                <Typography variant="p" component="p">
                                                    Type :
                                                </Typography>
                                                <Typography variant="p" component="b">
                                                    {items?.type?.title}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={2}>
                                                <img
                                                    src={
                                                        hover.Edit && indexHover === i ? EditHover : Edit
                                                    }
                                                    alt="Edit"
                                                    onClick={(e) => handleClickEditform(items, i)}
                                                    onMouseOver={() => {
                                                        setHover({ Edit: true });
                                                        setIndexHover(i);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHover({ Edit: false });
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "33px",
                                                        height: "33px",
                                                    }}
                                                />
                                                <img
                                                    src={
                                                        hover.Delete && indexHover === i
                                                            ? DeleteHover
                                                            : Delete
                                                    }
                                                    alt="Delete"
                                                    onClick={() => handleDelete(i)}
                                                    onMouseOver={() => {
                                                        setHover({ Delete: true });
                                                        setIndexHover(i);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHover({ Delete: false });
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "33px",
                                                        height: "33px",
                                                    }}
                                                />
                                            </Stack>
                                        </Box>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            className="validation-div"
                                        >
                                            <Typography
                                                variant="p"
                                                component="b"
                                                sx={{ whiteSpace: "nowrap" }}
                                            >
                                                Validation :
                                            </Typography>
                                            {items?.type?.validations?.map((items, i) => {
                                                return (
                                                    <li key={i}>
                                                        {items?.answer
                                                            ?.filter((data) => data?.value === true)
                                                            ?.map((datas, i) => {
                                                                const answer = datas?.value === true;
                                                                return (
                                                                    <>
                                                                        {answer ? (
                                                                            <Typography
                                                                                className={
                                                                                    items.key === "required"
                                                                                        ? "Validations-box validation-font"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {items.key === "required" ? (
                                                                                    <>
                                                                                        {items?.title} :{" "}
                                                                                        <b>
                                                                                            <b>{datas?.title}</b>
                                                                                        </b>{" "}
                                                                                    </>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Typography>
                                                                        ) : (
                                                                            ""
                                                                        )}{" "}
                                                                    </>
                                                                );
                                                            })}

                                                        {items?.answer
                                                            ?.filter((data) => data?.value === true)
                                                            ?.map((datas, i) => {
                                                                const answer = datas?.value === true;
                                                                return (
                                                                    <>
                                                                        {answer ? (
                                                                            <Typography
                                                                                className={
                                                                                    items.key === "audio"
                                                                                        ? "Validations-box validation-font"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {items.key === "audio" ? (
                                                                                    <>
                                                                                        {items?.title} :{" "}
                                                                                        <b>
                                                                                            <b>{datas?.title}</b>
                                                                                        </b>{" "}
                                                                                    </>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Typography>
                                                                        ) : (
                                                                            ""
                                                                        )}{" "}
                                                                    </>
                                                                );
                                                            })}

                                                        {items?.answer
                                                            ?.filter((data) => data?.value === true)
                                                            ?.map((datas, i) => {
                                                                const answer = datas?.value === true;
                                                                return (
                                                                    <>
                                                                        {answer ? (
                                                                            <Typography
                                                                                className={
                                                                                    items.key === "allowFutureDates"
                                                                                        ? "Validations-box validation-font"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {items.key === "allowFutureDates" ? (
                                                                                    <>
                                                                                        {items?.title} :{" "}
                                                                                        <b>
                                                                                            <b>{datas?.title}</b>
                                                                                        </b>{" "}
                                                                                    </>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Typography>
                                                                        ) : (
                                                                            ""
                                                                        )}{" "}
                                                                    </>
                                                                );
                                                            })}

                                                        {items?.answer
                                                            ?.filter((data) => data?.value === true)
                                                            ?.map((datas, i) => {
                                                                const answer = datas?.value === true;
                                                                return (
                                                                    <>
                                                                        {answer ? (
                                                                            <Typography
                                                                                className={
                                                                                    items.key === "allowPastDates"
                                                                                        ? "Validations-box validation-font"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {items.key === "allowPastDates" ? (
                                                                                    <>
                                                                                        {items?.title} :{" "}
                                                                                        <b>
                                                                                            <b className="">{datas?.title}</b>
                                                                                        </b>{" "}
                                                                                    </>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Typography>
                                                                        ) : (
                                                                            ""
                                                                        )}{" "}
                                                                    </>
                                                                );
                                                            })}

                                                        <div style={{
                                                            display: 'flex'
                                                        }}>
                                                            {items?.answer
                                                                ?.filter((data) => data?.value === true)
                                                                ?.map((datas, i) => {
                                                                    const answer = datas?.value === true;
                                                                    return (
                                                                        <>
                                                                            {answer ? (
                                                                                <Typography
                                                                                    className={
                                                                                        items.key === "mediaFileType"
                                                                                            ? "Validations-box validation-font"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    {items.key === "mediaFileType" ? (
                                                                                        <>
                                                                                            {items?.title} :{" "}
                                                                                            <b>
                                                                                                <b>{datas?.title}</b>
                                                                                            </b>{" "}
                                                                                        </>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </Typography>
                                                                            ) : (
                                                                                ""
                                                                            )}{" "}
                                                                        </>
                                                                    );
                                                                })}
                                                        </div>

                                                        {items?.answer
                                                            ?.filter((data) => data?.value === true)
                                                            ?.map((datas, i) => {
                                                                const answer = datas?.value === true;
                                                                return (
                                                                    <>
                                                                        {answer ? (
                                                                            <Typography
                                                                                className={
                                                                                    items.key === "comment_required"
                                                                                        ? "Validations-box validation-font"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {items.key === "comment_required" ? (
                                                                                    <>
                                                                                        {items?.title} :{" "}
                                                                                        <b>
                                                                                            <b>{datas?.title}</b>
                                                                                        </b>{" "}
                                                                                    </>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Typography>
                                                                        ) : (
                                                                            ""
                                                                        )}{" "}
                                                                    </>
                                                                );
                                                            })}
                                                        {items?.answer
                                                            ?.filter((data) => data?.value === true)
                                                            ?.map((datas, i) => {
                                                                const answer = datas?.value === true;
                                                                return (
                                                                    <>
                                                                        {answer ? (
                                                                            <Typography
                                                                                className={
                                                                                    items.key === "image_optional"
                                                                                        ? "Validations-box validation-font"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {items.key === "image_optional" ? (
                                                                                    <>
                                                                                        {items?.title} :{" "}
                                                                                        <b>
                                                                                            <b>{datas?.title}</b>
                                                                                        </b>{" "}
                                                                                    </>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Typography>
                                                                        ) : (
                                                                            ""
                                                                        )}{" "}
                                                                    </>
                                                                );
                                                            })}
                                                        {items?.answer
                                                            ?.filter((data) => data?.value === true)
                                                            ?.map((datas, i) => {
                                                                const answer = datas?.value === true;
                                                                return (
                                                                    <>
                                                                        {answer ? (
                                                                            <Typography
                                                                                className={
                                                                                    items.key === "video_optional"
                                                                                        ? "Validations-box validation-font"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {items.key === "video_optional" ? (
                                                                                    <>
                                                                                        {items?.title} :{" "}
                                                                                        <b>
                                                                                            <b className="video-validation">{datas?.title}</b>
                                                                                        </b>{" "}
                                                                                    </>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </Typography>
                                                                        ) : (
                                                                            ""
                                                                        )}{" "}
                                                                    </>
                                                                );
                                                            })}
                                                    </li>
                                                );
                                            })}
                                        </Stack>
                                    </CardContent>
                                </Card>
                            );
                        })}
                </Container>
            </Box>
        </form>
    )
}

export default CreateTemplate;