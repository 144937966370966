import { Autocomplete, Box, TextField, Typography, Chip } from "@mui/material";
import moment from "moment";

const ProductDropdown = (props) => {
  const { handleChange, id, options, value, details } = props;

  return (
    <Autocomplete
      id={id}
      sx={{ width: "100%", paddingX: "1px" }}
      options={options}
      value={value}
      isOptionEqualToValue={(option, val) => option?._id === val?._id}
      onChange={(event, newValue) => handleChange(newValue)}
      getOptionLabel={(option) => option?.name || ""}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box {...optionProps}>
            <Box
              key={key}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
              pl={2}
              pr={2}
            >
              <Typography sx={{ fontWeight: "bold" }}>{option.name}</Typography>
              <Box sx={{ display: "flex", gap: "16px", mt: 1 }}>
                {details &&
                  details.map((item) => {
                    let value;
                    if (item.value === "description") {
                      value = option.productDetails?.description;
                    } else if (item.value === "count") {
                      value = option.productItems;
                    } else {
                      value = option[item.value];
                    }

                    // Format if it's a date
                    if (item.type === "date" && value) {
                      value = moment(value).format(item.format);
                    }

                    return (
                      <Typography
                        key={item.value}
                        sx={{ fontSize: "12px", color: "#64748b" }}
                      >
                        {item.title && `${item.title}:`} {value}
                      </Typography>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} sx={{ padding: "0px" }} />
      )}
      size="small"
    />
  );
};

export default ProductDropdown;
