import { Box, FormControl, Select, Tooltip, tooltipClasses } from "@mui/material"
import { ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import ProductHeader from "../component/ProductHeader";
import { useEffect, useState } from "react";
import { DeleteProductApi, ProductListInventoryApi } from "../../redux/actions/Inventory";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
import { styled } from "material-ui-core";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const ProductsList = () => {
    const [perPage, setPer_page] = useState(10);
    const [pageNo, setPage] = useState(1);

    const [data, setData] = useState([]);
    const [indexHover, setIndexHover] = useState(0);
    const [hover, setHover] = useState({
        View: false,
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loader = useSelector(
        (state) =>
            state.inventory &&
            state.inventory.loading
    );

    const productList = useSelector(
        (state) =>
            (state.inventory &&
                state.inventory.productList &&
                state.inventory.productList.data &&
                state.inventory.productList.data.data) ||
            []
    );

    const getProductList = () => {
        const id = localStorage.getItem("companyId");

        const api = {
            url: BASE_URL + `inventory-product?company_id=${id}&sort_as=updatedAt&sort_by=updatedAt&page=${pageNo}&limit=${perPage}`
        }
        dispatch(ProductListInventoryApi(api));
    };

    useEffect(() => {
        getProductList();
    }, []);

    useEffect(() => {
        setData(productList?.finalData);
    }, [productList?.finalData]);

    const handleChangePage = (event, newPage) => {
        // submissionPayload.body.pagination.page = newPage + 1;
        setPage(newPage + 1);
        // fetchLosEntryESubmission();
    }

    const handleChangeRowsPerPage = (event) => {
        // submissionPayload.body.pagination.page = 1;
        // submissionPayload.body.pagination.per_page = parseInt(event.target.value, 10);
        setPer_page(event.target.value);
        // fetchLosEntryESubmission();
    }

    const handleEditDetails = (row) => {
        navigate("/inventory-product/edit");
        localStorage.setItem("userId", JSON.stringify(row));
    };

    const handleView = (row) => {
        navigate('/inventory-product/view');
        localStorage.setItem("userId", JSON.stringify(row));
    };

    const handleDelete = (values) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        const id = localStorage.getItem("companyId");
                        const infoId = values?._id;
                        console.log("logEntryId", infoId);
                        const data = {
                            url: BASE_URL + `inventory-product/${infoId}?company_id=${id}`,
                        };
                        dispatch(DeleteProductApi(data, getProductList));
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className={classes.root}>
                {showFirstButton && (
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                )}
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    const columns = [
        {
            field: "S.No.",
            headerName: "S.No.",
            renderHeader: () => (
                <LightTooltip title={"S.No"}>
                    <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
                </LightTooltip>
            ),
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => params.rowIndex + 1 + (pageNo - 1) * perPage,
            renderCell: (params) => {
                const d = data || [];
                const index =
                    d.map(e => e._id).indexOf(params.row._id) + 1 + (pageNo - 1) * perPage;

                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            minWidth: "50px",
                        }}
                    >
                        <p
                            className="text-short"
                            style={{
                                margin: "0 auto",
                                textAlign: "center",
                                fontSize: "16px",
                            }}
                        >
                            {index}
                        </p>
                    </div>
                );
            },
        },

        {
            field: "Name",
            headerName: "Name",
            renderHeader: () => <LightTooltip title={"Name"}>
                <strong>{"Name"}</strong>
            </LightTooltip>,
            flex: 2,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.productDetails?.name) {
                    result.push(params?.row?.productDetails?.name);
                } else {
                    result = ["N/A"];
                }
                let results = result.join(", ");
                return (
                    <div
                        style={{
                            display: "flex",
                            width: "133px",
                        }}
                    >
                        <LightTooltip title={results}>
                            <p
                                className="text-short"
                                style={{ marginLeft: "10px", fontWeight: "normal" }}
                            >
                                {results}
                            </p>
                        </LightTooltip>
                    </div>
                );
            },
        },
        // {
        //     field: "Description",
        //     headerName: "Description",
        //     renderHeader: () => <LightTooltip title={"Description"}>
        //         <strong>{"Description"}</strong>
        //     </LightTooltip>,
        //     flex: 1,
        //     align: "center",
        //     headerAlign: "center",
        //     renderCell: (params) => {
        //         let result = [];
        //         if (params?.row?.productDetails?.description) {
        //             result.push(params?.row?.productDetails?.description);
        //         } else {
        //             result = ["Unknown"];
        //         }
        //         let results = result.join(", ");
        //         return (
        //             <LightTooltip title={results}>
        //                 <p className="text-short"> {results}</p>
        //             </LightTooltip>
        //         );
        //     },
        // },
        // {
        //     field: "Price",
        //     headerName: "Price",
        //     renderHeader: () => <LightTooltip title={"Price"}>
        //         <strong>{"Price"}</strong>
        //     </LightTooltip>,
        //     flex: 1,
        //     align: "center",
        //     headerAlign: "center",
        //     renderCell: (params) => {
        //         let result = [];
        //         if (params?.row?.productDetails?.price) {
        //             result.push(params?.row?.productDetails?.price);
        //         } else {
        //             result = ["Unknown"];
        //         }
        //         let results = result.join(", ");
        //         return (
        //             <LightTooltip title={results}>
        //                 <p className="text-short"> {results}</p>
        //             </LightTooltip>
        //         );
        //     },
        // },
        // {
        //     field: "Quentity",
        //     headerName: "Quentity",
        //     renderHeader: () => <LightTooltip title={"Quentity"}>
        //         <strong>{"Quentity"}</strong>
        //     </LightTooltip>,
        //     flex: 1,
        //     align: "center",
        //     headerAlign: "center",
        //     renderCell: (params) => {
        //         let result = [];
        //         if (params?.row?.productDetails?.quentity) {
        //             result.push(params?.row?.productDetails?.quentity);
        //         } else {
        //             result = ["Unknown"];
        //         }
        //         let results = result.join(", ");
        //         return (
        //             <LightTooltip title={results}>
        //                 <p className="text-short"> {results}</p>
        //             </LightTooltip>
        //         );
        //     },
        // },
        {
            field: "Product Items",
            headerName: "Product Items",
            renderHeader: () => <LightTooltip title={"Product Items"}>
                <strong>{"No of Product Items"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.productItems) {
                    result.push(params?.row?.productItems);
                } else {
                    result = ["Unknown"];
                }
                let results = result.join(", ");
                return (
                    <LightTooltip title={results}>
                        <p className="text-short"> {results}</p>
                    </LightTooltip>
                );
            },
        },
        {
            field: "CreatedAt",
            headerName: "CreatedAt",
            renderHeader: () => <LightTooltip title={"CreatedAt"}>
                <strong>{"CreatedAt"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.createdAt) {
                    result.push(moment(params?.row?.createdAt).format('ll'));
                } else {
                    result = ["0"];
                }
                let results = result.join(", ");
                return (
                    <LightTooltip title={results}>
                        <p className="" style={{
                            width: "max-content !important"
                        }}> {results}</p>
                    </LightTooltip>
                );
            },
        },
        {
            field: "UpdatedAt",
            headerName: "UpdatedAt",
            renderHeader: () => <LightTooltip title={"UpdatedAt"}>
                <strong>{"UpdatedAt"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.updatedAt) {
                    result.push(moment(params?.row?.updatedAt).format('ll'));
                } else {
                    result = ["N/A"];
                }
                let results = result.join(", ");
                return (
                    <LightTooltip title={results}>
                        <p className="" style={{
                            width: "max-content !important"
                        }}> {results}</p>
                    </LightTooltip>
                );
            },
        },
        {
            field: "Action",
            headerName: "Action",
            renderHeader: () => <LightTooltip title={"Action"}>
                <strong>{"Action"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const viewBtnStyle = {
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                    height: "32px"
                };

                return (
                    <div style={{
                        display: 'flex'
                    }}>
                        <Tooltip title="View">
                            <img
                                src={
                                    hover.View && indexHover === params?.row?._id
                                        ? ViewHover
                                        : View
                                }
                                alt="VIEW"
                                style={{
                                    ...viewBtnStyle,
                                }}
                                onClick={() => handleView(params?.row)}
                                onMouseOver={() => {
                                    setHover({ ...hover, View: true });
                                    setIndexHover(params?.row?._id);
                                }}
                                onMouseLeave={() => {
                                    setHover({ ...hover, View: false });
                                }}
                            />
                        </Tooltip>
                        {/* <Tooltip title="Edit">
                            <img
                                onClick={() => handleEditDetails(params?.row)}
                                src={
                                    hover.Edit && indexHover === params?.row?._id
                                        ? EditHover
                                        : Edit
                                }
                                alt="Edit"
                                style={{
                                    ...viewBtnStyle,
                                }}
                                onMouseOver={() => {
                                    setHover({ Edit: true });
                                    setIndexHover(params?.row?._id);
                                }}
                                onMouseLeave={() => {
                                    setHover({ Edit: false });
                                }}
                            />
                        </Tooltip> */}
                        <Tooltip title="Delete">
                            <img
                                onClick={() => handleDelete(params?.row)}
                                src={
                                    hover.Delete && indexHover === params?.row?._id
                                        ? DeleteHover
                                        : Delete
                                }
                                alt="Delete"
                                style={{
                                    height: "35px",
                                    cursor: "pointer",
                                    marginBottom: "5px",
                                }}
                                onMouseOver={() => {
                                    setHover({ Delete: true });
                                    setIndexHover(params?.row?._id);
                                }}
                                onMouseLeave={() => {
                                    setHover({ Delete: false });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        }
    ];

    return (
        <Box>
            <ToastContainer autoClose={3000} />
            <ProductHeader />

            {loader && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <ProgressbarTransparent play />
                </div>)
            }
            <Box
                sx={{
                    bgcolor: "#F6F8FC",
                    height: "calc(100vh - 280px)",
                    mt: 1,
                    borderRadius: "8px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                        width: 10,
                    },
                    "&::-webkit-scrollbar-track": {
                        padding: "12px 5px",
                        backgroundColor: "#CBD4E1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#64748B",
                        borderRadius: 8,
                    },
                }}
            >
                <SingleGlobalTable
                    data={data}
                    columns={columns}
                    totalCount={productList?.paginate?.total_item || 0}
                    loading={loader}
                    page={pageNo}
                    rowsPerPage={perPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Box>
        </Box>
    )
}

export default ProductsList;