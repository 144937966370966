/** @format */

import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import RadarOutlinedIcon from "@mui/icons-material/RadarOutlined";
import LiveTvOutlinedIcon from "@mui/icons-material/LiveTvOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import CenterFocusWeakOutlinedIcon from "@mui/icons-material/CenterFocusWeakOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import FiberSmartRecordOutlinedIcon from "@mui/icons-material/FiberSmartRecordOutlined";
import ViewSidebarOutlinedIcon from "@mui/icons-material/ViewSidebarOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import CabinIcon from "@mui/icons-material/Cabin";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

const navitems = [
  {
    id: 1,
    title: "Dashboard",
    dashboard: true,
    icon: <ViewComfyOutlinedIcon />,
    path: "/dashboard",
  },

  {
    id: 18,
    title: "Dashboard",
    auditorDashboard: true,
    icon: <ViewComfyOutlinedIcon />,
    path: "/auditor-home",
  },
  {
    id: 19,
    title: "Checklist",
    auditorDashboard: true,
    icon: <AssignmentOutlinedIcon />,
    path: "/checklist-home",
  },
  {
    id: 20,
    title: "LMS",
    auditorDashboard: true,
    icon: <RadarOutlinedIcon />,
    path: "/lms-home",
  },
  {
    id: 2,
    checklist: true,
    title: "Checklist",
    icon: <AssignmentOutlinedIcon />,
    items: [
      {
        id: 1,
        title: "Overview",
        overview: true,
        path: "/checklist",
      },
      {
        id: 2,
        title: "All Checklist",
        allchecklist: true,
        path: "/checklist/all-checklist",
      },
      {
        id: 3,
        title: "Submission",
        submissions: true,
        path: "/checklist/submission",
      },
      // {
      //   id: 4,
      //   title: "Department Checklist",
      //   path: '/checklist/department',
      // },
      // {
      //   id: 5,
      //   title: "Store Checklist",
      //   storechecklist: true,
      //   path: "/checklist/store-checklist",
      // },
      // {
      //   id: 6,
      //   title: "User Checklist",
      //   userchecklist: true,
      //   path: "/checklist/user-checklist",
      // },
      {
        id: 7,
        title: "All Forms",
        allform: true,
        path: "/checklist/all-form",
      },
      {
        id: 8,
        title: "Checklist Report",
        checklistreport: true,
        path: "/checklist/checklist-report",
      },
      {
        id: 9,
        title: "Download",
        checklistreport: true,
        path: "/download-list",
      },
    ],
  },
  {
    id: 3,
    lms: true,
    title: "LMS",
    icon: <RadarOutlinedIcon />,
    openInNewTab: true,
    items: [
      {
        id: 1,
        title: "Overview",
        lmsoverview: true,
        path: "/Overview",
      },
      {
        id: 2,
        title: "Training",
        training: true,
        path: "/training",
      },
      {
        id: 3,
        title: "Training Progress",
        trainingprogress: true,
        path: "/alltraining-report",
      },
      {
        id: 4,
        title: "Assessment List",
        assessment: true,
        path: "/assessment",
      },
      {
        id: 5,
        title: "Certificate List",
        certificate: true,
        path: "/certificate",
      },
      // {
      //   id: 6,
      //   title: "Store Training List",
      //   storelist: true,
      //   path: "/store-training",
      // },
      // {
      //   id: 7,
      //   title: "User Training List",
      //   userlist: true,
      //   path: "/user-training",
      // },
      {
        id: 8,
        title: "Submit LMS",
        userlist: true,
        path: "/lms-submision",
      },
      {
        id: 8,
        title: "LMS Report",
        checklistreport: true,
        path: "/lms/lms-report",
      },

      // new lms works starts from here
      // {
      //   id: 8,
      //   title: "Take Trainings",
      //   training: true,
      //   path: "/auditor-home/my-trainings",
      // },
      // {
      //   id: 9,
      //   title: "Take Assesments",
      //   assessment: true,
      //   path: "/auditor-home/my-assesments",
      // },
      // {
      //   id: 10,
      //   title: "My Certificates",
      //   certificate: true,
      //   path: "/auditor-home/my-certificates",
      // },

      // new lms works ended here
    ],
  },
  {
    id: 3,
    auditorLms: true,
    title: "LMS",
    icon: <RadarOutlinedIcon />,
    openInNewTab: true,
    items: [
      {
        id: 2,
        title: "New Training",
        training: true,
        path: "/auditor-home/my-trainings",
      },

      {
        id: 4,
        title: "New Assessment",
        assessment: true,
        path: "/auditor-home/my-assesments",
      },
      {
        id: 5,
        title: "My Certificates",
        certificate: true,
        path: "/auditor-home/my-certificates",
      },
    ],
  },
  {
    id: 4,
    title: "Issues",
    icon: <AnnouncementOutlinedIcon />,
    issueLog: true,
    // <img src={issues} alt="issues" height={40} />,
    openInNewTab: true,
    items: [
      {
        id: 1,
        title: "Overview",
        issueoverview: true,
        path: "/issues",
      },
      {
        id: 2,
        title: "Issues List",
        issuelist: true,
        path: "/issues/list",
      },
    ],
  },
  {
    id: 25,
    title: "Inventory",
    icon: <Inventory2OutlinedIcon />,
    inventory: true,
    items: [
      {
        id: 1,
        title: "Template",
        template: true,
        path: "/template",
      },
      {
        id: 2,
        title: "Product",
        template: true,
        path: "/inventory-product",
      },
      {
        id: 3,
        title: "Master BarCode ",
        template: true,
        path: "/master-barcode-list",
      }
    ],
  },
  // {
  //   id: 5,
  //   title: "Audit",
  //   audit: true,
  //   icon: <FiberSmartRecordOutlinedIcon />,
  //   openInNewTab: true,
  //   items: [
  //     {
  //       id: 1,
  //       title: "Overview",
  //       auditoverview: true,
  //       path: "/audit-overview",
  //     },
  //     {
  //       id: 2,
  //       title: "All Assignment",
  //       auditassisment: true,
  //       path: "/all-assignment",
  //     },
  //     {
  //       id: 3,
  //       title: "All Survey",
  //       auditsurvey: true,
  //       path: "/all-survey",
  //     },
  //   ],
  // },
  // {
  //   id: 6,
  //   title: 'Observation',
  //   icon: <AssessmentOutlinedIcon />,
  //   path: '/observation'
  // },
  {
    id: 7,
    chat: true,
    title: "Chats",
    icon: <TextsmsOutlinedIcon />,
    path: "/chat",
  },
  {
    id: 20,
    logEntries: true,
    title: "Log Entry",
    icon: <ListAltIcon />,
    path: "/log-entry",
  },
  {
    id: 21,
    title: "Attendance Log",
    icon: <AccessTimeIcon />,
    attendanceLog: true,
    items: [
      {
        id: 1,
        title: "Config",
        issueoverview: true,
        path: "attendance-log-config",
      },
      {
        id: 2,
        title: "Submission",
        issuelist: true,
        path: "/attendance-log-submission",
      },
    ],
  },
  {
    id: 8,
    boadcastMessage: true,
    title: "Broadcast Message",
    icon: <LiveTvOutlinedIcon />,
    path: "/broadcastMessage",
  },
  {
    id: 9,
    wastageLog: true,
    title: "Wastage-Logs",
    icon: <ViewSidebarOutlinedIcon />,
    // path: "/waste-management",
    items: [
      {
        id: 1,
        title: "Waste-Management",
        wastageLog: true,
        path: "/waste-management",
      },
      {
        id: 2,
        title: "Product",
        wastageLog: true,
        path: "/product",
      },
    ],
  },
  {
    id: 10,
    title: "User",
    user: true,
    icon: <PersonOutlineOutlinedIcon />,
    // path: "/user-list",
    items: [
      {
        id: 1,
        title: "Overview",
        useroverview: true,
        path: "/user-overview",
      },
      {
        id: 2,
        title: "User List",
        userlistuser: true,
        path: "/user-list",
      },
    ],
  },
  // {
  //   id: 10,
  //   title: 'User',
  //   icon: <PersonOutlineOutlinedIcon />,
  //   items:[
  //     {
  //       id: 1,
  //       title: "Overview",
  //       path: '/user-overview',
  //     },
  //     {
  //       id: 2,
  //       title: "User List",
  //       path: '/user-list',
  //     }
  //   ]
  // },
  // {
  //   id: 11,
  //   title: 'Customer',
  //   icon: <CenterFocusWeakOutlinedIcon />,
  //   path: '/customer'
  // },
  {
    id: 12,
    title: "Store",
    store: true,
    icon: <StorefrontOutlinedIcon />,
    path: "/store",
  },
  {
    id: 13,
    title: "Roles",
    roles: true,
    icon: <GroupsOutlinedIcon />,
    path: "/roles",
  },
  {
    id: 14,
    title: "Data Manager",
    datamanager: true,
    icon: <StorageOutlinedIcon />,
    items: [
      {
        id: 1,
        title: "Data Lists",
        datalist: true,
        path: "/data-list",
      },
      {
        id: 2,
        title: "Questions",
        questions: true,
        path: "/questions",
      },
      {
        id: 3,
        title: "Company Categories",
        companycategory: true,
        path: "/company-category",
      },
    ],
  },
  {
    id: 15,
    title: "Department",
    department: true,
    icon: <ViewComfyOutlinedIcon />,
    path: "/department",
  },
  {
    id: 16,
    title: "Customer Logs",
    customerLog: true,
    icon: <CabinIcon />,
    path: "/customer-Log",
  },
  {
    id: 17,
    title: "Scheduler",
    scheduler: true,
    icon: <ScheduleIcon />,
    path: "/scheduler",
  },
  {
    id: 18,
    title: "Mobile Access",
    mobileAccess: true,
    icon: <StayCurrentPortraitIcon />,
    path: "/mobile-Access",
  },
  {
    id: 10,
    title: "Account Details",
    superadmin: true,
    director: true,
    icon: <ManageAccountsOutlinedIcon />,
    path: "/account-details",
  },
  {
    id: 19,
    title: "Payment History",
    superadmin: true,
    director: true,
    icon: <ViewComfyOutlinedIcon />,
    path: "/payment_history",
  },
];

export default navitems;
