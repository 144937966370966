import React from 'react'
import { useDispatch } from "react-redux";
import { CompanyListApi } from "../../redux/actions/AnalyticDashboard";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { Box, Button, Card, CardContent, FormControl, Grid, InputBase, Modal, Pagination, Stack, Typography } from "@mui/material";
import Edit from "../../../assets/icons/svg-icons/Edit.svg";
import LogoImage from "../../../assets/images/logo-image.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import ProgressbarTransparent from '../../GlobalProgress/ProgressbarTransparent';
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SearchAppBar from "../../user/userList/pages/SearchButton";
import { styled } from "@mui/material/styles";

const downloadAllstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 500,
    height: "90vh",
    maxHeight: 600,
    overflowY: "auto",
    overflowX: "auto",
    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    '@media (max-width: 600px)': {
        width: "90%",
        height: "80vh",
    }
};

const StyledInputBase = styled(InputBase)(({ theme, width }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        background: '#ffffff',
        border: '2px solid #cbd4e1 !important',
        borderRadius: '8px',
        [theme.breakpoints.up('sm')]: {
            width: width || '12ch',
            // '&:focus': {
            //   width: '20ch',
            // },
        },
    },
}));

const AnalyticalCompanyList = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [companyListData, setCompanyListData] = useState([]);
    const [page, setPage] = React.useState(1);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [categoryValue, setCategoryValue] = useState('');

    let companyDetails = useSelector(
        (state) =>
            state.analyticDashboard &&
            state.analyticDashboard.companyList
    );

    let Loader = useSelector(
        (state) =>
            state.analyticDashboard &&
            state.analyticDashboard.loading
    );

    const payloadData = {
        pagination: {
            page: page,
            per_page: 12
        },
        status: location.state,
        search: "",
        type: "",
    }

    const fetchCompanyList = () => {
        const data = {
            url: BASE_URL + 'company/list',
            body: { ...payloadData }
        };

        dispatch(CompanyListApi(data));
    }

    useEffect(() => {
        fetchCompanyList(location.state);
    }, []);

    useEffect(() => {
        setCompanyListData(companyDetails?.companies);
    }, [companyDetails?.companies]);

    const handleClickOpen = (row) => {
        navigate(`/analytical-company-edit`, { state: row });
    };

    const handlePageChange = (event, value) => {
        payloadData.pagination.page = value;
        setPage(value);

        fetchCompanyList();
    };

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    const handleCategory = (value) => {
        setCategoryValue(value);
    };

    const handleApplyFilter = () => {
        payloadData.search = searchValue;
        payloadData.type = categoryValue;
        fetchCompanyList();
    };

    return <>
        {Loader && (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "auto",
                    width: "100%",
                }}
            >
                <ProgressbarTransparent play />
            </div>
        )}
        <Card className="header-card top-margin" sx={{
            marginBottom: "10px"
        }}>
            <Grid
                container
                spacing={1}
                className="userlist-head-maingrid"
                display={"flex"}
                justifyContent={"end"}
                sx={{
                    marginTop: "0"
                }}
            >
                <Stack direction="row" className="Assign-Checklist" justifyContent={"end"}>
                    <Button
                        className="header-add cancle-hover"
                        style={{
                            height: "40px",
                            borderRadius: "8px",
                            padding: "12px 16px",
                            float: "right",
                        }}
                        onClick={(e) => setOpenFilterModal(true)}
                    >
                        <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                        Filter
                    </Button>
                </Stack>
            </Grid>
        </Card>
        <Modal
            open={openFilterModal}
            onClose={() => setOpenFilterModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown={false}
        >
            <Box sx={{ ...downloadAllstyle, p: 0 }}>
                <Box
                    sx={{
                        position: "sticky",
                        right: "15px",
                        top: "15px",
                        cursor: "pointer",
                        textAlign: "right",
                        padding: "0px 10px",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Box onClick={() => setOpenFilterModal(false)}>
                        <CloseIcon />
                    </Box>
                </Box>
                <Box sx={{ pt: 2, px: 4 }}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Search</label>
                        <SearchAppBar
                            placeholder="Search by company name"
                            searchValue={searchValue}
                            searchOnChange={handleSearch}
                            sx={{ height: "42px", borderRadius: "8px" }}
                            width="100%"
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label>Category</label>
                        <StyledInputBase
                            name="category"
                            value={categoryValue}
                            onChange={(e) => handleCategory(e.target.value)}
                            size="small"
                            sx={{
                                marginLeft: "8px",
                            }}
                            width="100%"
                        />
                    </FormControl>

                </Box>
                <Box
                    sx={{
                        display: "flex",
                        position: "fixed",
                        bottom: 0,
                        width: "100%",
                        gap: "30px",
                        px: 3,
                        pt: 1,
                        pb: 2,
                        background: "#fff",
                    }}
                >
                    <Button
                        className="cancle-button cancle-hover"
                        style={{
                            height: "46px",
                            padding: "12px 16px",
                            width: "47%",
                            background: "#fff",
                        }}
                        onClick={() => {
                            setSearchValue("")
                            setCategoryValue("")
                        }}
                    >
                        Clear
                    </Button>
                    <Button
                        className="header-add cancle-hover"
                        style={{
                            height: "46px",
                            padding: "12px 16px",
                            width: "47%",
                        }}
                        onClick={() => handleApplyFilter()}
                    >
                        Apply
                    </Button>
                </Box>
            </Box>
        </Modal>
        <Grid container spacing={2}>
            {companyListData &&
                companyListData.length > 0 &&
                companyListData.map((item, i) => {
                    return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
                            <Card
                                sx={{ height: "100%" }}
                                className="card card-list"
                                key={i}
                            >
                                <CardContent className="zero-space">
                                    <img
                                        onClick={() => handleClickOpen(item)}
                                        src={Edit}
                                        className="company-edit"
                                        alt="edit"
                                    />
                                    <>
                                        <Grid container spacing={0} className="max-width">
                                            <Grid item xs={4}>
                                                <div className="company-image-div">
                                                    <img
                                                        src={
                                                            item?.logo?.key
                                                                ? 'https://popprobe-saas.s3.us-west-2.amazonaws.com/' + item?.logo?.key
                                                                : LogoImage
                                                        }
                                                        alt="logo"
                                                        className="company-image-logo"
                                                        style={{ marginTop: "10px" }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={8} className="text-content">
                                                <Typography
                                                    variant="p"
                                                    component="div"
                                                    sx={{
                                                        pt: 1,
                                                        textAlign: "start",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {item.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ pt: 8, textAlign: "start" }}
                                                >
                                                    {moment(item.createdAt).format("lll")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
        </Grid>
        <Box sx={{
            width: '100%',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: "6px",
            padding: "12px"
        }}>
            <Pagination color="standard" onChange={handlePageChange} page={page} count={companyDetails?.paginate?.last_page || 0} />
        </Box>
    </>
}

export default AnalyticalCompanyList;