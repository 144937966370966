/** @format */

import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Card, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import ViewLogEntryHead from "./ViewFormHead";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { GetLogEntrySubmissionApi } from "../redux/actions/LogEntry";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import LastPageIcon from "@mui/icons-material/LastPage";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "material-ui-core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PropTypes from "prop-types";
import View from "../images/View.svg";
import ViewHover from "../images/ViewHover.svg";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import EditHover from "../images/EditHover.svg";
import Edit from "../images/Edit.svg";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const ViewLogEntries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    View: false,
  });
  const [dataColumn, setDataColumn] = useState();

  const loading = useSelector(
    (state) =>
      state.logEntry &&
      state.logEntry.loading
  );

  const logEntrySubmissionData = useSelector(
    (state) =>
      state.logEntry &&
      state.logEntry.logEntrySubmissionData &&
      state.logEntry.logEntrySubmissionData.data &&
      state.logEntry.logEntrySubmissionData.data.data &&
      state.logEntry.logEntrySubmissionData.data.data
  );

  const [submissionPayload, setSubmissionPayload] = useState({
    body: {
      pagination: {
        page: pageNo,
        per_page: perPage,
      },
    },
  });

  useEffect(() => {
    localStorage.removeItem("logEntrySubmissionData");
  }, []);

  useEffect(() => {
    setListData(logEntrySubmissionData?.logEntrySubmissions);
  }, [logEntrySubmissionData])

  const handleView = (row) => {
    navigate("/log-entry/view-log-entry-submission");
    localStorage.setItem("logEntrySubmissionData", JSON.stringify(row))
  }

  useEffect(() => {
    const selectedLogEntry = localStorage.getItem("selectedLogEntry");
    const selectedLogEntryData = JSON.parse(selectedLogEntry);

    const columns = [
      {
        field: "S.No.",
        headerName: "S.No.",
        renderHeader: () => (
          <LightTooltip title={"S.No"}>
            <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
          </LightTooltip>
        ),
        flex: 1,
        align: "center",
        headerAlign: "center",
        valueGetter: (params) => params.rowIndex + 1 + (pageNo - 1) * perPage,
        renderCell: (params) => {
          const d = listData || [];
          const index =
            d.map(e => e._id).indexOf(params.row._id) + 1 + (pageNo - 1) * perPage;

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                minWidth: "50px",
              }}
            >
              <p
                className="text-short"
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                {index}
              </p>
            </div>
          );
        },
      },

      {
        field: "User",
        headerName: "User",
        renderHeader: () => <LightTooltip title={"User"}>
          <strong>{"User"}</strong>
        </LightTooltip>,
        flex: 2,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          let result = [];
          if (params?.row?.user?.name) {
            result.push(params?.row?.user?.name);
          } else {
            result = ["N/A"];
          }
          let results = result.join(", ");
          return (
            <div
              style={{
                display: "flex",
                width: "133px",
              }}
            >
              <LightTooltip title={results}>
                <p
                  className="text-short"
                  style={{ marginLeft: "10px", fontWeight: "normal" }}
                >
                  {results}
                </p>
              </LightTooltip>
            </div>
          );
        },
      },
      {
        field: "Role",
        headerName: "Role",
        renderHeader: () => <LightTooltip title={"Role"}>
          <strong>{"Role"}</strong>
        </LightTooltip>,
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          let result = [];
          if (params?.row?.user) {
            result.push(params?.row?.user?.subRole);
          } else {
            result = ["Unknown"];
          }
          let results = result.join(", ");
          return (
            <LightTooltip title={results}>
              <p className="text-short"> {results}</p>
            </LightTooltip>
          );
        },
      },
      {
        field: "Store	",
        headerName: "Store",
        renderHeader: () => <LightTooltip title={"Store"}>
          <strong>{"Store"}</strong>
        </LightTooltip>,
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          let result = [];
          if (params?.row?.store?.title) {
            result.push(params?.row?.store?.title);
          } else {
            result = ["0"];
          }
          let results = result.join(", ");
          return (
            <LightTooltip title={results}>
              <p className="text-short"> {results}</p>
            </LightTooltip>
          );
        },
      },
      {
        field: "Token Number",
        headerName: "Token Number",
        renderHeader: () => <LightTooltip title={"Token Number"}>
          <strong>{"Token Number"}</strong>
        </LightTooltip>,
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          let result = [];
          if (params?.row?.uniqueTokenNumber) {
            result.push(params?.row?.uniqueTokenNumber);
          } else {
            result = ["N/A"];
          }
          let results = result.join(", ");
          return (
            <LightTooltip title={results}>
              <p className="text-short"> {results}</p>
            </LightTooltip>
          );
        },
      },
      {
        field: "Submitted on",
        headerName: "Submitted on",
        renderHeader: () => <LightTooltip title={"Updated on"}><strong>{"Updated on"}</strong></LightTooltip>,

        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          let result = [];
          let fromateDate = moment(params?.row?.updatedAt).format(
            "h:mm A DD MMM YYYY"
          );
          if (fromateDate) {
            result.push(fromateDate);
          } else {
            result = ["0"];
          }
          let results = result.join(", ");
          return (
            <LightTooltip title={results}>
              <p className="my-list-w  !important">{results}</p>
            </LightTooltip>
          );
        },
      },
    ];

    const dColumn = [...columns];

    selectedLogEntryData.questions?.map((question) => {
      if (question?.allowFilter) {
        dColumn.push(
          {
            field: question.title.split().join().trim() + "_QID_" + question.id,
            headerName: question.title,
            renderHeader: () => <LightTooltip title={question.title}><strong>{question.title}</strong></LightTooltip>,
            flex: 2,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
              let result = "-";

              const que = params?.row?.questions?.filter(item => item.id === params.field.split('_QID_')[1])[0];
              if (que?.answer?.type != "boolean" && que?.answer?.answer?.value) {
                result = que?.answer?.answer?.value
              } else if (que?.answer?.answer?.title) {
                  result = que?.answer?.answer?.title;
              } else if (que?.answer?.answer?.length) {
                result = que.answer.answer.map(ans => ans.title);
              } else if (que?.answer?.answer.date && que?.answer?.answer?.time) {
                result = `${que.answer.answer.date} ${que.answer.answer.time}`
              }

              return (
                <div
                  style={{
                    display: "flex",
                    width: "133px",
                  }}
                >
                  {
                    Array.isArray(result) ?
                      <LightTooltip title={result.join(",")}>
                        <ul
                          className="text-short"
                          style={{ marginLeft: "10px", fontWeight: "normal", padding: 0 }}
                        >
                          {result.map((item, index) => {
                            return <li
                              key={index + item}
                              // className="text-short"
                              style={{
                                textAlign: "start",
                              }}
                            >
                              <CircleIcon
                                // sx={{ fontSize: 10 }}
                                className="font"
                                style={{ paddingRight: 4 }}
                              />
                              {item}
                            </li>
                          })}
                        </ul>
                      </LightTooltip>
                      :
                      <LightTooltip title={result}>
                        <p
                          className="text-short"
                          style={{ marginLeft: "10px", fontWeight: "normal" }}
                        >
                          {result}
                        </p>
                      </LightTooltip>
                  }
                </div>
              );
            },
          },
        )
      }
    });
    dColumn.push({
      field: "Action",
      headerName: "Action",
      renderHeader: () => <LightTooltip title={"Action"}>
        <strong>{"Action"}</strong>
      </LightTooltip>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const viewBtnStyle = {
          cursor: "pointer",
          margin: "0px 3px 0px 3px",
          height: "32px"
        };

        return (
          <div style={{
            display: 'flex'
          }}>
            <Tooltip title="View">
              <img
                src={
                  hover.View && indexHover === params?.row?._id
                    ? ViewHover
                    : View
                }
                alt="VIEW"
                style={{
                  ...viewBtnStyle,
                }}
                onClick={() => handleView(params?.row)}
                onMouseOver={() => {
                  setHover({ ...hover, View: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ ...hover, View: false });
                }}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <img
                onClick={() => handleEditDetails(params?.row)}
                src={
                  hover.Edit && indexHover === params?.row?._id
                    ? EditHover
                    : Edit
                }
                alt="Edit"
                style={{
                  ...viewBtnStyle,
                }}
                onMouseOver={() => {
                  setHover({ Edit: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Edit: false });
                }}
              />
            </Tooltip>
          </div>
        );
      },
    })
    setDataColumn(dColumn);
  }, [listData])

  /*const fetchLosEntryESubmission = (page = 1, rowsPerPage = 10) => {
    const logEntryId = localStorage.getItem("logEntryId");

    const id = localStorage.getItem("companyId");

    const data = {
      ...submissionPayload,
      url: BASE_URL + `logEntry/SubmisssionDetail/${logEntryId}?company_id=${id}`,
    };
    dispatch(GetLogEntrySubmissionApi(data));
  } */
    const fetchLosEntryESubmission = (page = 1, rowsPerPage = 10) => {
     // console.log("Fetching data with pagination:", { page, rowsPerPage });
      const logEntryId = localStorage.getItem("logEntryId");
      const companyId = localStorage.getItem("companyId");  
      const data = {
        body: {
          ...submissionPayload.body,
          pagination: {
            page, 
            per_page: rowsPerPage, 
          },
        },
        url: `${BASE_URL}logEntry/SubmisssionDetail/${logEntryId}?company_id=${companyId}`,
      };
    
      dispatch(GetLogEntrySubmissionApi(data));
    };
    
  const handleEditDetails = (row) => {
    navigate("/log-entry/edit-log-entry-submission");
    localStorage.setItem("logEntrySubmissionData", JSON.stringify(row))
  };

  useEffect(() => {
    fetchLosEntryESubmission(pageNo, perPage);
  }, [pageNo, perPage, submissionPayload]);  

  const handleChangePage = (event, newPage) => {
    // submissionPayload.body.pagination.page = newPage + 1;
    setPageNo(newPage + 1);
    // fetchLosEntryESubmission();
  }

  const handleChangeRowsPerPage = (event) => {
    //submissionPayload.body.pagination.page = 1;
    //submissionPayload.body.pagination.per_page = parseInt(event.target.value, 10);
    const newPerPage = parseInt(event.target.value, 10);
    setPerPage(newPerPage); // Trigger `useEffect` with the updated perPage.
    setPageNo(1); // Reset to page 1.
    //fetchLosEntryESubmission();
  };

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        {showFirstButton && (
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        )}
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleFilter = (logEntryPayload) => {
    const logEntryId = localStorage.getItem("logEntryId");
    const id = localStorage.getItem("companyId");

    submissionPayload.body.userId =
      logEntryPayload?.userId?.map((item) => item._id) || [];
    submissionPayload.body.storeId =
      logEntryPayload?.storeId?.map((item) => item._id) || [];
    submissionPayload.body.pagination.page = 1;
    submissionPayload.body.questions = logEntryPayload.questions;
    submissionPayload.body.start_date = logEntryPayload.start_date;
    submissionPayload.body.end_date = logEntryPayload.end_date;
    submissionPayload.body.pagination.per_page = (perPage && parseInt(perPage)) || 10;

    localStorage.setItem("submissionPageno", 1);

    submissionPayload.url =
      BASE_URL +
      `logEntry/SubmisssionDetail/${logEntryId}?company_id=${id}`;

    dispatch(GetLogEntrySubmissionApi(submissionPayload));
  }

  return (
    <>
      <ViewLogEntryHead listData={listData} handleFilter={handleFilter} />
      <ToastContainer autoClose={3000} />
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
        className="header-card"
      >
        <SingleGlobalTable
          data={listData}
          columns={dataColumn}
          totalCount={logEntrySubmissionData?.paginate?.total_item || 0}
          loading={loading}
          page={pageNo}
          rowsPerPage={perPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box >
      <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
    </>
  );
};

export default ViewLogEntries;
