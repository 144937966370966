import {
    Box,
    Grid,
    IconButton,
    Tooltip,
    tooltipClasses,
    Typography,
  } from "@mui/material";
  import { ToastContainer } from "react-toastify";
  import { useDispatch } from "react-redux";
  import { useEffect, useState } from "react";
  import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
  import { useSelector } from "react-redux";
  import { styled } from "material-ui-core";
  import LastPageIcon from "@mui/icons-material/LastPage";
  import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
  import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
  import FirstPageIcon from "@mui/icons-material/FirstPage";
  import PropTypes from "prop-types";
  import { useLocation, useNavigate } from "react-router-dom";
  import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
  import DoneIcon from "@mui/icons-material/Done";
  import CloseIcon from "@mui/icons-material/Close";
  import { confirmAlert } from "react-confirm-alert";
  
  import {
    DeleteItemApi,
    MasterQRCodeListInventoryApi,
    MasterQRCodeViewInventoryApi,
    MasterQRCodeViewItemViewInventoryApi,
    TemplateListInventoryApi,
  } from "../../redux/actions/Inventory";
  import MasterQRCodeViewHeader from "./MasterQRCodeViewHeader";
  import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
  import moment from "moment"
  import View from "../../images/View.svg";
  import ViewHover from "../../images/ViewHover.svg";
  import EditHover from "../../images/EditHover.svg";
  import Edit from "../../images/Edit.svg";
  import Delete from "../../images/Delete.svg";
  import DeleteHover from "../../images/DeleteHover.svg";
import MasterQRCodeViewItemViewHeader from "./MasterQRCodeViewItemViewHeader";
  
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  
  const QRCodeViewItemView = () => {
    const location = useLocation();
    const backScreenRowData = location.state?.rowData;
    // const loader = false;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log("backScreenRowData", backScreenRowData);
  
    const ItemView = useSelector(
      (state) =>
        (state.inventory &&
          state.inventory.QRCodeViewItemViewData &&
          state.inventory.QRCodeViewItemViewData.data &&
          state.inventory.QRCodeViewItemViewData.data.data) ||
        []
    );
    const loader = useSelector(
      (state) => state.inventory && state.inventory.loading
    );
  
    const [data, setData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [indexHover, setIndexHover] = useState(0);
    const [hover, setHover] = useState({
      View: false,
    });
  
    console.log("data", data);
    console.log("loader", loader);
  
    const columns = [
        {
          field: "S.No.",
          headerName: "S.No.",
          renderHeader: () => (
            <LightTooltip title={"S.No"}>
              <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
            </LightTooltip>
          ),
          flex: 1,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.rowIndex + 1 + (pageNo - 1) * perPage,
          renderCell: (params) => {
            const d = data || [];
            const index =
              d.map((e) => e._id).indexOf(params.row._id) +
              1 +
              (pageNo - 1) * perPage;
    
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  minWidth: "50px",
                }}
              >
                <p
                  className="text-short"
                  style={{
                    margin: "0 auto",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  {index}
                </p>
              </div>
            );
          },
        },
        {
          field: "Serial Number",
          headerName: "Serial Number",
          renderHeader: () => (
            <LightTooltip title={"Serial Number"}>
              <strong>{"Serial Number"}</strong>
            </LightTooltip>
          ),
          flex: 2,
          align: "center",
          headerAlign: "center",
          renderCell: (params) => {
            let result = [];
            if (params?.row?.serialNumber) {
              result.push(params?.row?.serialNumber);
            } else {
              result = ["N/A"];
            }
            let results = result.join(", ");
            return (
              <div
                style={{
                  display: "flex",
                  width: "133px",
                }}
              >
                <LightTooltip title={results}>
                  <p
                    className="text-short"
                    style={{ marginLeft: "10px", fontWeight: "normal" }}
                  >
                    {results}
                  </p>
                </LightTooltip>
              </div>
            );
          },
        },
        {
          field: "Barcode Generated",
          headerName: "Barcode Generated",
          renderHeader: () => <strong>{"Barcode Generated"}</strong>,
          align: "center",
          headerAlign: "center",
          flex: 1,
          maxWidth: 160,
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  width: "133px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {params?.row.barcodeGenerated === true ? (
                  <DoneIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </div>
            );
          },
        },
        {
          field: "Barcode Scanned",
          headerName: "Barcode Scanned",
          renderHeader: () => <strong>{"Barcode Scanned"}</strong>,
          align: "center",
          headerAlign: "center",
          flex: 1,
          maxWidth: 160,
          renderCell: (params) => {
            return (
              <div
                style={{
                  display: "flex",
                  width: "133px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {params?.row.barcodeScanned === true ? (
                  <DoneIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </div>
            );
          },
        },
        {
          field: "CreatedAt",
          headerName: "CreatedAt",
          renderHeader: () => (
            <LightTooltip title={"CreatedAt"}>
              <strong>{"CreatedAt"}</strong>
            </LightTooltip>
          ),
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: (params) => {
            let result = [];
            if (params?.row?.createdAt) {
              // Format the date
              result.push(
                moment(params?.row?.createdAt).format("MMMM D, YYYY, h:mm:ss A")
              );
            } else {
              result = ["0"];
            }
            let results = result.join(", ");
            return (
              <LightTooltip title={results}>
                <p className="text-short"> {results}</p>
              </LightTooltip>
            );
          },
        },
        {
          field: "UpdatedAt",
          headerName: "UpdatedAt",
          renderHeader: () => (
            <LightTooltip title={"UpdatedAt"}>
              <strong>{"UpdatedAt"}</strong>
            </LightTooltip>
          ),
          flex: 1,
          align: "center",
          headerAlign: "center",
          renderCell: (params) => {
            let result = [];
            if (params?.row?.updatedAt) {
              // Format the date
              result.push(
                moment(params?.row?.updatedAt).format("MMMM D, YYYY, h:mm:ss A")
              );
            } else {
              result = ["N/A"];
            }
            let results = result.join(", ");
            return (
              <LightTooltip title={results}>
                <p className="text-short"> {results}</p>
              </LightTooltip>
            );
          },
        },
    
        // {
        //   field: "Action",
        //   headerName: "Action",
        //   renderHeader: () => (
        //     <LightTooltip title={"Action"}>
        //       <strong>{"Action"}</strong>
        //     </LightTooltip>
        //   ),
        //   flex: 1,
        //   align: "center",
        //   headerAlign: "center",
        //   renderCell: (params) => {
        //     const viewBtnStyle = {
        //       cursor: "pointer",
        //       margin: "0px 3px 0px 3px",
        //       height: "32px",
        //     };
    
        //     return (
        //       <div
        //         style={{
        //           display: "flex",
        //         }}
        //       >
        //         <Tooltip title="View">
        //           <img
        //             src={
        //               hover.View && indexHover === params?.row?._id
        //                 ? ViewHover
        //                 : View
        //             }
        //             alt="VIEW"
        //             style={{
        //               ...viewBtnStyle,
        //             }}
        //             onClick={() => handleView(params?.row)}
        //             onMouseOver={() => {
        //               setHover({ ...hover, View: true });
        //               setIndexHover(params?.row?._id);
        //             }}
        //             onMouseLeave={() => {
        //               setHover({ ...hover, View: false });
        //             }}
        //           />
        //         </Tooltip>
        //         {/* <Tooltip title="Edit">
        //               <img
        //                   onClick={() => handleEditDetails(params?.row)}
        //                   src={
        //                       hover.Edit && indexHover === params?.row?._id
        //                           ? EditHover
        //                           : Edit
        //                   }
        //                   alt="Edit"
        //                   style={{
        //                       ...viewBtnStyle,
        //                   }}
        //                   onMouseOver={() => {
        //                       setHover({ Edit: true });
        //                       setIndexHover(params?.row?._id);
        //                   }}
        //                   onMouseLeave={() => {
        //                       setHover({ Edit: false });
        //                   }}
        //               />
        //           </Tooltip>*/}
        //         <Tooltip title="Delete">
        //           <img
        //             onClick={() => handleDelete(params?.row)}
        //             src={
        //               hover.Delete && indexHover === params?.row?._id
        //                 ? DeleteHover
        //                 : Delete
        //             }
        //             alt="Delete"
        //             style={{
        //               height: "32px",
        //               cursor: "pointer",
        //               marginBottom: "5px",
        //             }}
        //             onMouseOver={() => {
        //               setHover({ Delete: true });
        //               setIndexHover(params?.row?._id);
        //             }}
        //             onMouseLeave={() => {
        //               setHover({ Delete: false });
        //             }}
        //           />
        //         </Tooltip>
        //       </div>
        //     );
        //   },
        // },
      ];
  
    useEffect(() => {
      if (ItemView) {
        setData(ItemView);
      }
    }, [ItemView]);
  
    const getTemplateList = () => {
      const id = localStorage.getItem("companyId");
      const api = {
        url:
          BASE_URL +
          `inventory-product-item-detail?company_id=${id}&_id=${backScreenRowData?._id}`,
      };
      dispatch(MasterQRCodeViewItemViewInventoryApi(api));
    };
  
    useEffect(() => {
      getTemplateList();
      localStorage.setItem("masterBarCodeLocalStorageData", "");
    }, [perPage, pageNo]);
  
      const handleView = (row) => {
        console.log("view screen not yet made", row);
        // navigate("/master-barcode-list/view", { state: { rowData: row } });
      };
    
      const handleDelete = (values) => {
        confirmAlert({
          title: "Confirm to delete",
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                const id = localStorage.getItem("companyId");
                const infoId = values?._id;
                console.log("logEntryId", infoId);
      
                const data = {
                  url: BASE_URL + `delete-inventory-product-items?company_id=${id}&page=1&per_page=&sort_as=updatedAt&sort_by=desc`,
                  body: {
                    ids: [infoId]  
                  }
                };
      
                dispatch(DeleteItemApi(data, getTemplateList));
              },
            },
            {
              label: "No",
            },
          ],
        });
      };
      
  
    const handleChangePage = (event, newPage) => {
      // submissionPayload.body.pagination.page = newPage + 1;
      setPageNo(newPage + 1);
      // fetchLosEntryESubmission();
    };
    const handleChangeRowsPerPage = (event) => {
      // submissionPayload.body.pagination.page = 1;
      // submissionPayload.body.pagination.per_page = parseInt(event.target.value, 10);
      setPerPage(event.target.value);
      // fetchLosEntryESubmission();
    };
  
    function TablePaginationActions(props) {
      const classes = useStyles1();
      const theme = useTheme();
      const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;
  
      const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
      };
  
      const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
      };
  
      const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
      };
  
      const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      };
  
      return (
        <div className={classes.root}>
          {showFirstButton && (
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
          )}
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      );
    }
  
    TablePaginationActions.propTypes = {
      count: PropTypes.number.isRequired,
      onPageChange: PropTypes.func.isRequired,
      page: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
    };
  
    return (
      <Box>
        <ToastContainer autoClose={3000} />
        <MasterQRCodeViewItemViewHeader />
        {loader && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        )}
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "calc(100vh - 280px)",
            mt: 1,
            borderRadius: "8px",
            overflowX: "hidden",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: 8,
            },
          }}
        >
          {data.length > 0 && (
            <Box
              key={data[0]._id || 0}
              sx={{
                marginBottom: 3,
                padding: 2,
                border: "1px solid #ddd",
                borderRadius: 2,
                // backgroundColor: "#fff",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "99%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginBottom: 3,
                  fontWeight: "bold",
                }}
              >
                Item Information
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Name: <strong>{data[0].title}</strong>
                </Typography>
  
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Description: <strong>{data[0].description}</strong>
                </Typography>
              </Box>
  
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Store: <strong>{data[0].store?.name}</strong>
                </Typography>
  
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Product Name:{" "}
                  <strong>
                    {data[0].productItems?.[0]?.productDetails?.name || "N/A"}
                  </strong>
                </Typography>
              </Box>
  
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Created At:{" "}
                  <strong>
                    {moment(data[0].createdAt).format("MMMM D, YYYY, h:mm:ss A")}
                  </strong>
                </Typography>
  
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Updated At:{" "}
                  <strong>
                    {moment(data[0].updatedAt).format("MMMM D, YYYY, h:mm:ss A")}
                  </strong>
                </Typography>
              </Box>
  
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Serial Number: <strong>{data[0].serialNumber}</strong>
                </Typography>
  
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 45%",
                    gap: 2,
                  }}
                >
                  <Typography variant="body2" sx={{ textAlign: "left" }}>
                    Barcode:
                  </Typography>
                  {data[0].barcode && (
                    <a
                      href={data[0].barcode}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={data[0].barcode}
                        alt="Barcode"
                        style={{
                          height: 100,
                          width: "auto",
                          cursor: "pointer",
                        }}
                      />
                    </a>
                  )}
                </Box>
              </Box>
            </Box>
          )}
            <SingleGlobalTable
          data={data}
        //   data={extractedProductItems}
          columns={columns}
        //   totalCount={ItemView?.paginate?.total_item || 0}
        totalCount={data?.length || 0}
          loading={loader}
          page={pageNo}
          rowsPerPage={perPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        </Box>
      </Box>
    );
  };
  
  export default QRCodeViewItemView;
  