import React, { useState, useEffect } from "react";
import { Stack, Tabs, Tab, Button, Card, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SearchAppBar from "../../checklist/tableHeader/SearchButton";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { ArchivedListDepartmentApi } from "../../redux/actions/Department";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const TableHeader = ({
  searchOnChange,
  searchValue,
  handleKeyDown,
  totalitems,
  checkedArchive,
  setCheckedArchive,
  page,
  per_page,
  archivedTotalItems,
  setPage,
  permission,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChangeTabs = (newValue) => {
    setValue(newValue);
  };

  const payloadRequest = {
    body: {
      pagination: {
        page: page,
        per_page: per_page,
      },
      archived: false,
    },
  };

  const DepartmentListArchivedApi = (payloadRequest) => {
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `ListArchivedDepartment?company_id=${id}`;
    dispatch(ArchivedListDepartmentApi(payloadRequest));
  };
  useEffect(() => {
    if (checkedArchive === true) {
      payloadRequest.body.pagination.page = 1;
      setPage(1);
      DepartmentListArchivedApi(payloadRequest);
    }
  }, [checkedArchive]);
  return (
    <>
      <Card className="header-card">
        <Grid container spacing={1} sx={{ flexWrap: {xs: "wrap", sm: "nowrap", md: "nowrap"}}}>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                label="Active"
                sx={{marginLeft: { xs: "0px", sm: "0px", md : "0px", lg: "8px"}}} // Adds a left margin of 8px for large (lg) screens and above.
                icon={<p className="active-tabs-content">{totalitems}</p>}
                iconPosition="end"
                className={
                  checkedArchive === false
                    ? "active-bg"
                    : "active-tabs tabs-content"
                }
                onClick={() => setCheckedArchive(false)}
              />
              <Tab
                label="Archived"
                icon={
                  <p className="active-tabs-content">{archivedTotalItems}</p>
                }
                iconPosition="end"
                className={
                  checkedArchive === true
                    ? "active-bg"
                    : "Archived-tabs tabs-content"
                }
                onClick={() => setCheckedArchive(true)}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="colomn-padding">
            <Stack
              direction="row"
              sx={{ float: "right", marginTop: { sm: "20px", md: "0px" } }} // Adds a top margin of 20px for small screens.
              className="Assign-Checklist"
            >
              <SearchAppBar
                searchOnChange={searchOnChange}
                handleKeyDown={handleKeyDown}
                searchValue={searchValue}
              />
              {permission?.department?.manageDepartment === true ? (
                <Link to="/new-department" className="submenu-item">
                  <Button className="header-add cancle-hover" style={{height:"40px",marginTop:"2px"}}>
                    <AddOutlinedIcon sx={{ mr: 1 }} />
                    New Department
                  </Button>
                </Link>
              ) : (
                ""
              )}
              <div style={{marginTop:"8px",marginRight:"8px"}}>
                 <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Department:</strong>
                            <p>
                             Shows the List of department can be used during creating issue log
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                  </div>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default TableHeader;
