import { Box, Button, Grid, Stack, Switch } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { CompanyEditApi } from "../../redux/actions/AnalyticDashboard";
import { useDispatch } from "react-redux";

const AnalyticalCompanyEdit = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const [enable, setEnable] = useState(location?.state?.isActive || false);

    console.log("location.state", location?.state?.isActive, enable);
    const handleSubmit = () => {
        const data = {
            url: BASE_URL + 'company/status?company_id=' + location.state._id,
            body: {
                isActive: enable
            }
        }
        dispatch(CompanyEditApi(data));
    }

    const handleEnableDisable = (e) => {
        console.log("e.target.value", e.target.checked);
        setEnable(e.target.checked);
    }

    return (
        <Box>
            <ToastContainer />
            <Box
                sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", padding: "8px" }}
                className="header-card"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{
                        marginTop: "0"
                    }}>
                        <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                            <Link to="/analytical-company-list" className="submenu-item">
                                <Button className="cancle-button cancle-hover">
                                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                                    Cancel
                                </Button>
                            </Link>
                            <Button className="header-add cancle-hover" onClick={() => handleSubmit()}>
                                <TaskAltIcon sx={{ mr: 1 }} />
                                Update
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px", mt: 1 }}>
                Enable/Disable: <Switch inputProps={{ 'aria-label': 'Enable/Disable' }} name="status" checked={enable} onChange={handleEnableDisable} />
            </Box>
        </Box>
    )
};

export default AnalyticalCompanyEdit;