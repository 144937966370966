/** @format */

import React, { useState, useEffect, useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Stack, TextField, Grid, Card, CardContent, Box } from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import { SubmissionViewApi } from "../../redux/actions/Checklist";
import { useParams } from "react-router-dom";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import moment from "moment";
import ImageViewer from "react-simple-image-viewer";
import Tooltip from "@mui/material/Tooltip";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import CloseIcon from "@mui/icons-material/Close";
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(0);
  const [isImages, setIsImages] = React.useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  let count = 1;

  const handleClick = (index) => {
    if (open === index) {
      setOpen("");
    } else {
      setOpen(index);
    }
  };

  const openImageViewer = useCallback(
    (index, url) => {
      let imageUrl = [];
      imageUrl.push(url);
      setIsImages(imageUrl);
      setCurrentImage(index);
      setIsViewerOpen(true);
      setRotationAngle(0);
    },
    [setCurrentImage, setIsViewerOpen, setRotationAngle]
  );

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setRotationAngle(0);
  };
  const rotateImage = () => {
    setRotationAngle((prevAngle) => (prevAngle + 90) % 360);
  };

  const ImageClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <React.Fragment>
      {row &&
        row.questions &&
        row.questions.map((subrow) => {
          return (
            <>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  {count++}.
                </TableCell>
                <TableCell align="center">
                  <Typography variant="p">{subrow?.title}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {moment(subrow?.answer?.startTime).format("LTS")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="p"
                    component="p"
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {moment(subrow?.answer?.endTime).format("LTS")}
                  </Typography>
                </TableCell>

                {/* media file  */}
                <TableCell align="center">
                  {subrow?.answer?.answer === "No" ? (
                    <CancelOutlinedIcon color="error" />
                  ) : subrow?.answer?.answer?.title === "Yes" ? (
                    <Tooltip
                      title={subrow?.answer?.answer?.title}
                      arrow
                      placement="top-start"
                    >
                      <TaskAltOutlinedIcon color="success" />
                    </Tooltip>
                  ) : subrow?.answer?.answer &&
                    Array.isArray(subrow?.answer?.answer) ? (
                    <>
                      {subrow?.answer?.answer?.map((data) => {
                        return (
                          <div>
                            <p>{data.title}</p>
                          </div>
                        );
                      })}
                    </>
                  ) : subrow?.answer?.type === "datetime" ? (
                    <p>
                      {subrow?.answer?.answer?.date}
                      <p>{subrow?.answer?.answer?.time}</p>
                    </p>
                  ) : (
                    subrow?.answer?.answer?.value ||
                    subrow?.answer?.answer?.title
                  )}
                  {subrow?.answer?.type === "file" &&
                    (subrow?.answer?.answer &&
                    subrow?.answer?.answer?.length > 0 &&
                    Array.isArray(subrow?.answer?.answer) ? (
                      <>
                        {subrow?.answer?.answer?.map((data) => (
                          <div>
                            <Tooltip
                              title={data.name}
                              arrow
                              placement="top-start"
                            >
                              {/* <p className="text-short">{data.name}</p> */}
                              <a
                                href={data.url}
                                target="_blank"
                                className="text-short urlColor"
                              >
                                {data.name}
                              </a>
                            </Tooltip>
                          </div>
                        ))}
                      </>
                    ) : (
                      ""
                    ))}
                  {subrow?.answer?.type === "file" &&
                    (subrow?.answer?.answer?.answer &&
                    subrow?.answer?.answer?.answer?.length > 0 &&
                    Array.isArray(subrow?.answer?.answer?.answer) ? (
                      <>
                        {subrow?.answer?.answer?.answer.map((data) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Tooltip
                              title={data.name}
                              arrow
                              placement="top-start"
                            >
                              {/* <p className="text-short">{data.name}</p> */}
                              <a
                                href={data.url}
                                target="_blank"
                                className="text-short urlColor"
                              >
                                {data.name}
                              </a>
                            </Tooltip>
                          </div>
                        ))}
                      </>
                    ) : (
                      ""
                    ))}
                </TableCell>
                <TableCell align="center">
                  {subrow?.question_score ? subrow?.question_score : "N/A"}
                </TableCell>
                {/* <TableCell align="center">
                  {subrow?.answer?.answer?.option_score ?
                    subrow?.answer?.answer?.option_score : "N/A"}
                  {subrow?.answer?.answer &&
                    Array.isArray(subrow?.answer?.answer) && (
                      <>
                        {subrow?.answer?.answer?.map((data) => (
                          <div>
                            <p>{data.option_score}</p>
                          </div>
                        ))}
                      </>
                    )}
                </TableCell> */}
                <TableCell align="center">
                  {subrow?.answer?.answer?.option_score}
                  {subrow?.answer?.answer &&
                    Array.isArray(subrow?.answer?.answer) && (
                      <>
                        {subrow?.answer?.answer?.map((data) => (
                          <div>
                            <p>{data.option_score}</p>
                          </div>
                        ))}
                      </>
                    )}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      handleClick(subrow?.id);
                    }}
                    // onClick={() => setOpen(subrow?.id)}
                  >
                    {open === subrow?.id ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    backgroundColor: "#F6F8FC",
                  }}
                  colSpan={6}
                >
                  <Collapse
                    in={open === subrow?.id}
                    timeout="auto"
                    unmountOnExit={true}
                  >
                    <Box sx={{ margin: 1 }}>
                      <Stack>
                        <Typography align="center">Comment</Typography>
                        {/* {!subrow?.comment == "" ? ( */}
                        {subrow?.comment !== "" ? (
                          <TextField
                            id="standard-basic"
                            className="bg"
                            value={subrow?.comment}
                            size="small"
                            disabled
                          />
                        ) : (
                          <TextField
                            fullWidth
                            size="small"
                            value="No Comment"
                            disabled
                            className="bg"
                            sx={{ mb: 2, mt: 1, textAlign: "center" }} // Add textAlign: 'center' to center align the text
                          />
                        )}
                      </Stack>

                      <Table size="small" aria-label="purchases">
                        <TableBody>
                          <Grid container spacing={1}>
                            <Grid item xs={8} md={6} lg={6}>
                              <Card sx={{ height: "100%" }} className="card">
                                <CardContent>
                                  <Typography
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    Recorded audios for this question
                                  </Typography>
                                  <Typography sx={{ fontSize: 14 }}>
                                    No Audio
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={4} md={6} lg={6}>
                              {/* <Card sx={{ height: "100%" }} className="card">
                                <CardContent>
                                  <Typography
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    Images for this question
                                  </Typography>
                                  {subrow && subrow.images
                                    ? subrow &&
                                      subrow.images &&
                                      subrow.images.map((item, index) => {
                                        return (
                                          <>
                                            {item.fileType === "IMAGE" && (
                                              <img
                                                id="image"
                                                src={item?.url}
                                                onClick={() =>
                                                  openImageViewer(
                                                    index,
                                                    item?.url
                                                  )
                                                }
                                                key={index}
                                                alt="user image"
                                                width="100"
                                                style={{ margin: "2px" }}
                                              />
                                            )}
                                          </>
                                        );
                                      })
                                    : "No Images Captured"}
                                  <Typography
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    Videos for this question
                                  </Typography>
                                  {subrow && subrow.images
                                    ? subrow &&
                                      subrow.images &&
                                      subrow.images.map((item, index) => {
                                        return (
                                          <>
                                            {item.fileType === "VIDEO" && (
                                              <video
                                                width="320"
                                                height="240"
                                                controls
                                                id="video"
                                                src={item?.url}
                                                // onClick={() =>
                                                //   openImageViewer(
                                                //     index,
                                                //     item?.url
                                                //   )
                                                // }
                                                key={index}
                                                alt="user video"
                                                style={{ margin: "2px" }}
                                              />
                                            )}
                                          </>
                                        );
                                      })
                                    : "No Video Captured"}
                                  {isViewerOpen && (
                                    <ImageViewer
                                      src={isImages}
                                      currentIndex={currentImage}
                                      onClose={closeImageViewer}
                                      disableScroll={false}
                                      backgroundStyle={{
                                        backgroundColor: "rgba(0,0,0,0.9)",
                                      }}
                                      closeOnClickOutside={true}
                                    />
                                  )}
                                </CardContent>
                              </Card> */}
                              <Card sx={{ height: "100%" }} className="card">
                                <CardContent>
                                  <Typography
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    Images for this question
                                  </Typography>
                                  {subrow && subrow?.images?.length ? (
                                    subrow.images.map((item, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          {item.fileType === "IMAGE" && (
                                            <img
                                              id={`image_${index}`}
                                              src={item?.url}
                                              onClick={() =>
                                                openImageViewer(
                                                  index,
                                                  item?.url
                                                )
                                              }
                                              alt="user image"
                                              width="100"
                                              style={{ margin: "2px" }}
                                            />
                                          )}
                                        </React.Fragment>
                                      );
                                    })
                                  ) : (
                                    <Typography color="text.secondary">
                                      No Images Captured
                                    </Typography>
                                  )}
                                  <Typography
                                    color="text.secondary"
                                    gutterBottom
                                  >
                                    Videos for this question
                                  </Typography>
                                  {subrow && subrow?.videos?.length ? (
                                    subrow.videos.map((item, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <video
                                            id={`video_${index}`}
                                            width="320"
                                            height="240"
                                            controls
                                            // src={`https://popprobe-saas.s3.us-west-2.amazonaws.com/${item}`}
                                            src={item?.url}
                                            alt="user video"
                                            style={{ margin: "2px" }}
                                            onError={() =>
                                              console.error(
                                                `Failed to load video: ${item?.url}`
                                              )
                                            }
                                          >
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        </React.Fragment>
                                      );
                                    })
                                  ) : (
                                    <Typography color="text.secondary">
                                      No Video Captured
                                    </Typography>
                                  )}
                                  <div>
                                    {isViewerOpen && (
                                      <div
                                        style={{
                                          position: "fixed",
                                          top: 0,
                                          left: 0,
                                          width: "100vw",
                                          height: "100vh",
                                          backgroundColor: "rgba(0, 0, 0, 0.9)",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          zIndex: 1000,
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            maxWidth: isFullScreen
                                              ? "100%"
                                              : "80%",
                                            maxHeight: isFullScreen
                                              ? "100%"
                                              : "70%",
                                            overflow: "hidden",
                                            backgroundColor: "transparent",
                                            borderRadius: "10px",
                                          }}
                                        >
                                          <img
                                            src={
                                              subrow.images
                                                .filter(
                                                  (item) =>
                                                    item.fileType === "IMAGE"
                                                )
                                                .map((item) => item.url)[
                                                currentImage
                                              ]
                                            }
                                            alt="Preview"
                                            style={{
                                              width: isFullScreen
                                                ? "100%"
                                                : "auto",
                                              height: isFullScreen
                                                ? "100%"
                                                : "auto",
                                              transform: `rotate(${rotationAngle}deg) scale(1)`, // Initial scale
                                              transition:
                                                "transform 0.3s ease-in-out",
                                              objectFit: "contain",
                                              maxHeight: "100%",
                                              cursor: "zoom-in",

                                              transformOrigin: isFullScreen
                                                ? "center center"
                                                : "center center", // Zoom from the center of the image
                                            }}
                                            onClick={ImageClick}
                                            onWheel={(e) => {
                                              e.preventDefault();
                                              const scale =
                                                parseFloat(
                                                  e.currentTarget.style.transform.split(
                                                    "scale("
                                                  )[1]
                                                ) || 1;
                                              const newScale =
                                                scale + e.deltaY * -0.001; // Adjust zoom sensitivity here
                                              e.currentTarget.style.transform = `rotate(${rotationAngle}deg) scale(${Math.min(
                                                Math.max(newScale, 0.5),
                                                3
                                              )})`; // Limits zoom between 0.5x and 3x
                                            }}
                                          />

                                          <IconButton
                                            onClick={rotateImage}
                                            style={{
                                              position: "absolute",
                                              bottom: "15px",
                                              right: "15px",
                                              backgroundColor: "white",
                                              color: "black",
                                              zIndex: 1002,
                                              boxShadow:
                                                "0 2px 10px rgba(0, 0, 0, 0.3)",
                                              borderRadius: "50%",
                                              padding: "10px",
                                            }}
                                          >
                                            <RotateLeftIcon />
                                          </IconButton>
                                          <IconButton
                                            onClick={closeImageViewer}
                                            style={{
                                              position: "absolute",
                                              top: "15px",
                                              right: "15px",
                                              backgroundColor: "white",
                                              color: "black",
                                              zIndex: 1002,
                                              boxShadow:
                                                "0 2px 10px rgba(0, 0, 0, 0.3)",
                                              borderRadius: "50%",
                                              padding: "10px",
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          );
        })}
    </React.Fragment>
  );
}

export default function ChecklistAuditorDT({ buttonsvalues, selectedValueVersion }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dispatch = useDispatch();
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [detailedData, setDetailedData] = React.useState([]);

  const getChecklistDetails = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.submissionView &&
      state.checklist.submissionView.data &&
      state.checklist.submissionView.data.data
  );

  useEffect(() => {
    setDetailedData(getChecklistDetails);
  }, [getChecklistDetails]);

  const subMissionHistory = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.historyRes &&
      state.checklist.historyRes.data &&
      state.checklist.historyRes.data.data
  );

  // useEffect(() => {
  //   submissionDetails();
  // }, []);

//   useEffect(() => {
//     if (selectedValueVersion === "current") {
//         setDetailedData(getChecklistDetails);
//     } else {
//         setDetailedData(subMissionHistory);
//     }
// }, [selectedValueVersion, getChecklistDetails, subMissionHistory]);

  const handleChangeTabs = (newValue) => {
    setValue(newValue);
  };

  const forms = 
  Array.isArray(detailedData) && detailedData.length > 0
    ? detailedData[0].forms 
    : detailedData?.forms; 
const datalists = forms ? forms.filter((row, i) => i === buttonsvalues) : []; 
  // const datalists =
  //   detailedData &&
  //   detailedData.forms &&
  //   detailedData.forms.filter((row, i) => i == buttonsvalues);
    
  return (
    <div style={{ maxWidth: "100%", margin: "auto" }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" align="left">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No.</TableCell>
              <TableCell align="center" sx={{ width: "300px" }}>
                Question
              </TableCell>
              <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                Start Time
              </TableCell>
              <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                End Time
              </TableCell>
              <TableCell align="center">Answer</TableCell>
              <Tooltip title="Question Score">
                <TableCell align="center">QA's</TableCell>
              </Tooltip>
              <Tooltip title="Option Score">
                <TableCell align="center">OP's</TableCell>
              </Tooltip>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datalists &&
              datalists.map((row, i) => <Row key={i} row={row} i={i} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
