/** @format */

import React, { useState } from "react";
import {
  Stack,
  Tabs,
  Tab,
  Button,
  Card,
  Grid,
  Radio,
  Typography,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchAppBar from "../../checklist/tableHeader/SearchButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Header = (props) => {
  const [selectedValue, setSelectedValue] = useState("a");
  const [value, setValue] = useState(0);

  const broadcastList = useSelector(
    (state) =>
      state.broadcast &&
      state.broadcast.data &&
      state.broadcast.data.data &&
      state.broadcast.data.data.data &&
      state.broadcast.data.data.data.broadcast
  );

  // console.log("eeeeee", broadcastList);

  const totalitems = useSelector(
    (state) =>
      state.broadcast &&
      state.broadcast.data &&
      state.broadcast.data.data &&
      state.broadcast.data.data.data &&
      state.broadcast.data.data.data.paginate
  );

  // console.log("ttttttt", totalitems?.total_item);
  const handleBackClick = () => {
    window.history.back();
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Card
        className="header-card"
        sx={{ display: { xs: "block", sm: "flex", md: "flex"}, alignItems: "center" }}
      >
        {/* <IconButton
          onClick={handleBackClick}
          sx={{ fontSize: "20px", color: "#333" }}
        >
          <ArrowBackIcon sx={{ fontSize: "24px" }} />
          <Typography variant="button" sx={{ marginLeft: "8px" }}>
            Back
          </Typography>
        </IconButton> */}
        <div style={{marginLeft:"2%"}}>
          <Typography
            variant="h5"
            component="h1"
            sx={{
              marginLeft:  "2%",
              fontWeight: "bold",
              letterSpacing: "0.03em",
              marginBottom: { xs: "5px"},
              textAlign: {xs: "center", sm: "left", md: "left", lg: "left"}
            }}
          >
            All Broadcast Messages
         </Typography>
        </div>
        <Box sx={{ ml: "auto" , mb: { xs: "5px"} }}>
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "20px",
              backgroundColor: "#F0F0F0",
              padding: "0.5rem 1rem",
              height:"42px",
            }}
          >
            <Typography
              variant="h6"
              component="h1"
              align="left"
              sx={{
                marginLeft: "auto",
                marginTop: "0",
                letterSpacing: "0.03em",
                height: "40px",
                fontSize: {xs:"1.25rem", sm: "0.8rem", md: "0.8rem", lg: "1.25rem"},
              }}
            >
              Total <strong>{totalitems?.total_item}</strong> Messages
            </Typography>
          </Box>
        </Box>

        <Stack
          direction="row"
          className="Assign-Checklist"
          spacing={1}
          sx={{ ml: "0" }}
        >
          <SearchAppBar
            searchOnChange={props.searchOnChange}
            handleKeyDown={props.handleKeyDown}
            searchValue={props.searchValue}
          />
          {props?.permission?.boadcastMessage?.enable === true ? (
            <Link to="/broadcastMessage/add-message" className="submenu-item">
              <Button className="header-add cancle-hover" style={{height:"40px",marginTop:"2px"}}>
                <AddOutlinedIcon sx={{ mr: 1,}} />
                New Message
              </Button>
            </Link>
          ) : (
            ""
          )}
          <div style={{marginRight:"10px",marginTop:"8px"}}>
          <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                           <strong> Broadcast:</strong>
                           <p> Displays the list of all the broadcast messages sent to the respective role and dates</p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                    </Tooltip>
            </div>
        </Stack>
      </Card>
    </>
  );
};

export default Header;
