import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { styled } from "material-ui-core";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { confirmAlert } from "react-confirm-alert";

import {
  DeleteItemApi,
  MasterQRCodeListInventoryApi,
  MasterQRCodeViewInventoryApi,
  TemplateListInventoryApi,
} from "../../redux/actions/Inventory";
import MasterQRCodeViewHeader from "./MasterQRCodeViewHeader";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
import moment from "moment";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const MasterQRCodeView = () => {
  const location = useLocation();
  const backScreenRowData = location.state?.rowData;
  const [rowData, setRowData] = useState(null);
  // const loader = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("backScreenRowData", backScreenRowData);
  console.log("rowData", rowData);

  const masterBarCodeView = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.QRCodeViewData &&
        state.inventory.QRCodeViewData.data &&
        state.inventory.QRCodeViewData.data.data) ||
      []
  );
  console.log("masterBarCodeView", masterBarCodeView);
  const loader = useSelector(
    (state) => state.inventory && state.inventory.loading
  );

  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    View: false,
  });

  console.log("data", data);
  console.log("loader", loader);

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: () => (
        <LightTooltip title={"S.No"}>
          <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        return (
          params.api.getRowIndex(params.row?._id) + 1 + (pageNo - 1) * perPage
        );
      },
      renderCell: (params) => {
        const currentIndex =
          params.api.getRowIndex(params.row?._id) + 1 + (pageNo - 1) * perPage;

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              minWidth: "50px",
            }}
          >
            <p
              className="text-short"
              style={{
                margin: "0 auto",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              {currentIndex}
            </p>
          </div>
        );
      },
    },

    {
      field: "Serial Number",
      headerName: "Serial Number",
      renderHeader: () => (
        <LightTooltip title={"Serial Number"}>
          <strong>{"Serial Number"}</strong>
        </LightTooltip>
      ),
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.serialNumber) {
          result.push(params?.row?.serialNumber);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <LightTooltip title={results}>
              <p
                className="text-short"
                style={{ marginLeft: "10px", fontWeight: "normal" }}
              >
                {results}
              </p>
            </LightTooltip>
          </div>
        );
      },
    },
    {
      field: "Barcode Generated",
      headerName: "Barcode Generated",
      renderHeader: () => <strong>{"Barcode Generated"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 160,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params?.row.barcodeGenerated === true ? (
              <DoneIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </div>
        );
      },
    },
    {
      field: "Barcode Scanned",
      headerName: "Barcode Scanned",
      renderHeader: () => <strong>{"Barcode Scanned"}</strong>,
      align: "center",
      headerAlign: "center",
      flex: 1,
      maxWidth: 160,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params?.row.barcodeScanned === true ? (
              <DoneIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </div>
        );
      },
    },
    {
      field: "CreatedAt",
      headerName: "CreatedAt",
      renderHeader: () => (
        <LightTooltip title={"CreatedAt"}>
          <strong>{"CreatedAt"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.createdAt) {
          // Format the date
          result.push(
            moment(params?.row?.createdAt).format("MMMM D, YYYY, h:mm:ss A")
          );
        } else {
          result = ["0"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short"> {results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "UpdatedAt",
      headerName: "UpdatedAt",
      renderHeader: () => (
        <LightTooltip title={"UpdatedAt"}>
          <strong>{"UpdatedAt"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.updatedAt) {
          // Format the date
          result.push(
            moment(params?.row?.updatedAt).format("MMMM D, YYYY, h:mm:ss A")
          );
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short"> {results}</p>
          </LightTooltip>
        );
      },
    },

    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => (
        <LightTooltip title={"Action"}>
          <strong>{"Action"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const viewBtnStyle = {
          cursor: "pointer",
          margin: "0px 3px 0px 3px",
          height: "32px",
        };

        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Tooltip title="View">
              <img
                src={
                  hover.View && indexHover === params?.row?._id
                    ? ViewHover
                    : View
                }
                alt="VIEW"
                style={{
                  ...viewBtnStyle,
                }}
                onClick={() => handleView(params?.row)}
                onMouseOver={() => {
                  setHover({ ...hover, View: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ ...hover, View: false });
                }}
              />
            </Tooltip>
            {/* <Tooltip title="Edit">
                  <img
                      onClick={() => handleEditDetails(params?.row)}
                      src={
                          hover.Edit && indexHover === params?.row?._id
                              ? EditHover
                              : Edit
                      }
                      alt="Edit"
                      style={{
                          ...viewBtnStyle,
                      }}
                      onMouseOver={() => {
                          setHover({ Edit: true });
                          setIndexHover(params?.row?._id);
                      }}
                      onMouseLeave={() => {
                          setHover({ Edit: false });
                      }}
                  />
              </Tooltip>*/}
            <Tooltip title="Delete">
              <img
                onClick={() => handleDelete(params?.row)}
                src={
                  hover.Delete && indexHover === params?.row?._id
                    ? DeleteHover
                    : Delete
                }
                alt="Delete"
                style={{
                  height: "32px",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
                onMouseOver={() => {
                  setHover({ Delete: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Delete: false });
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const extractedProductItems = data?.productItems?.paginatedResults || [];

  console.log("extractedProductItems", extractedProductItems);

  useEffect(() => {
    const storedRowData = localStorage.getItem("rowData");
    if (storedRowData) {
      setRowData(JSON.parse(storedRowData));
    } else {
      console.warn("No rowData found in localStorage");
    }
  }, []);

  useEffect(() => {
    if (masterBarCodeView?.data) {
      setData(masterBarCodeView?.data);
    }
  }, [masterBarCodeView]);

  const getTemplateList = () => {
    const storedRowData = localStorage.getItem("rowData");
    const currentRowdata = JSON.parse(storedRowData);
    if (currentRowdata || rowData) {
      let qrCodeId = rowData?._id;

      const id = localStorage.getItem("companyId");
      const api = {
        url:
          BASE_URL +
          `master-barcode-wise-product-items?company_id=${id}&_id=${
            currentRowdata?._id || qrCodeId
          }&per_page=${perPage}&sort_as=updatedAt&sort_by=desc&page=${pageNo}`,
      };
      dispatch(MasterQRCodeViewInventoryApi(api));
    }
  };

  useEffect(() => {
    getTemplateList();
    localStorage.setItem("masterBarCodeLocalStorageData", "");
  }, [perPage, pageNo]);

  const handleView = (row) => {
    console.log("view screen not yet made", row);
    navigate("/master-barcode-list/view/item-view", {
      state: { rowData: row },
    });
  };

  const handleDelete = (values) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const infoId = values?._id;
            console.log("logEntryId", infoId);

            const data = {
              url:
                BASE_URL +
                `delete-inventory-product-items?company_id=${id}&page=1&per_page=&sort_as=updatedAt&sort_by=desc`,
              body: {
                ids: [infoId],
              },
            };

            dispatch(DeleteItemApi(data, getTemplateList));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleChangePage = (event, newPage) => {
    // submissionPayload.body.pagination.page = newPage + 1;
    setPageNo(newPage + 1);
    // fetchLosEntryESubmission();
  };

  const handleChangeRowsPerPage = (event) => {
    // submissionPayload.body.pagination.page = 1;
    // submissionPayload.body.pagination.per_page = parseInt(event.target.value, 10);
    setPerPage(event.target.value);
    // fetchLosEntryESubmission();
  };

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        {showFirstButton && (
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        )}
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <MasterQRCodeViewHeader />
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "calc(100vh - 280px)",
          mt: 1,
          borderRadius: "8px",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        {data && (
          <Box
            key={data?._id || 0}
            sx={{
              marginBottom: 3,
              padding: 2,
              border: "1px solid #ddd",
              borderRadius: 2,
              // backgroundColor: "#fff",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "99%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginBottom: 3,
                fontWeight: "bold",
              }}
            >
              Barcode Information
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{ flex: "1 1 45%", textAlign: "left" }}
              >
                Name: <strong>{data?.title}</strong>
              </Typography>

              <Typography
                variant="body2"
                sx={{ flex: "1 1 45%", textAlign: "left" }}
              >
                Description: <strong>{data?.description}</strong>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{ flex: "1 1 45%", textAlign: "left" }}
              >
                Store: <strong>{data?.store?.name}</strong>
              </Typography>

              <Typography
                variant="body2"
                sx={{ flex: "1 1 45%", textAlign: "left" }}
              >
                Created At:{" "}
                <strong>
                  {moment(data?.createdAt).format("MMMM D, YYYY, h:mm:ss A")}
                </strong>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{ flex: "1 1 45%", textAlign: "left" }}
              >
                Updated At:{" "}
                <strong>
                  {moment(data?.updatedAt).format("MMMM D, YYYY, h:mm:ss A")}
                </strong>
              </Typography>

              <Typography
                variant="body2"
                sx={{ flex: "1 1 45%", textAlign: "left" }}
              >
                Serial Number: <strong>{data?.serialNumber}</strong>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  flex: "1 1 45%",
                  textAlign: "left",
                  cursor: "pointer",
                  color: "blue",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                onClick={async () => {
                  try {
                    const barcodeUrl = data?.barcode;
                    if (!barcodeUrl) {
                      console.warn("No Barcode URL available");
                      return;
                    }

                    const response = await fetch(barcodeUrl);
                    const blob = await response.blob();
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = barcodeUrl.split("/").pop();
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } catch (error) {
                    console.error("Download failed:", error);
                  }
                }}
              >
                <DownloadIcon fontSize="small" />
                Download BarCode
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: "1 1 45%",
                  gap: 2,
                }}
              >
                <Typography variant="body2" sx={{ textAlign: "left" }}>
                  View Barcode:
                </Typography>
                {data?.barcode && (
                  <a
                    href={data?.barcode}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={data?.barcode}
                      alt="Barcode"
                      style={{
                        height: 100,
                        width: "auto",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                )}
              </Box>
            </Box>

            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginBottom: 3,
                marginTop: 3,
                fontWeight: "bold",
              }}
            >
              Product Information
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                marginBottom: 2,
              }}
            >
              {data?.product?.map((item, index) => (
                <React.Fragment key={item._id || index}>
                  {/* First Row: Name and Description */}
                  <Typography
                    variant="body2"
                    sx={{ flex: "1 1 45%", textAlign: "left" }}
                  >
                    Name: <strong>{item.productDetails?.name}</strong>
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ flex: "1 1 45%", textAlign: "left" }}
                  >
                    Description:{" "}
                    <strong>{item.productDetails?.description}</strong>
                  </Typography>

                  {/* Second Row: Category and Total Items */}
                  <Typography
                    variant="body2"
                    sx={{ flex: "1 1 45%", textAlign: "left" }}
                  >
                    Store: <strong>{item?.store?.name}</strong>
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ flex: "1 1 45%", textAlign: "left" }}
                  >
                    Category: <strong>{item?.productDetails?.category}</strong>
                  </Typography>

                  {/* Third Row: Category and Total Items */}
                  <Typography
                    variant="body2"
                    sx={{ flex: "1 1 45%", textAlign: "left" }}
                  >
                    Created At:{" "}
                    <strong>
                      {moment(item?.createdAt).format(
                        "MMMM D, YYYY, h:mm:ss A"
                      )}
                    </strong>
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ flex: "1 1 45%", textAlign: "left" }}
                  >
                    Updated At:{" "}
                    <strong>
                      {moment(item?.updatedAt).format(
                        "MMMM D, YYYY, h:mm:ss A"
                      )}
                    </strong>
                  </Typography>
                  {/* Fourth Row: Category and Total Items */}
                  {/* <Typography
                    variant="body2"
                    sx={{ flex: "1 1 45%", textAlign: "left" }}
                  >
                    Deleted: <strong>{item?.deleted ? "Yes" : "No"}</strong>
                  </Typography> */}

                  <Typography
                    variant="body2"
                    sx={{ flex: "1 1 45%", textAlign: "left" }}
                  >
                    Total Items: <strong>{item.productItems}</strong>
                  </Typography>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        )}
        <SingleGlobalTable
          // data={data}
          data={extractedProductItems}
          columns={columns}
          totalCount={data?.productItems?.totalCount?.count || 0}
          loading={loader}
          page={pageNo}
          rowsPerPage={perPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
    </Box>
  );
};

export default MasterQRCodeView;
