import { ANALYTIC_COMPANY_LIST_ERROR, ANALYTIC_COMPANY_LIST_REQUEST, ANALYTIC_COMPANY_LIST_SUCCESS, ANALYTIC_ERROR, ANALYTIC_REQUEST, ANALYTIC_SUCCESS, ANALYTIC_USER_LIST_ERROR, ANALYTIC_USER_LIST_REQUEST, ANALYTIC_USER_LIST_SUCCESS } from "../types/Types";

const initialState = {
    loading: false,
    analyticDetails: {},
    companyList: {},
    userList: {},
    error: "",
};

const analyticDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ANALYTIC_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }
        case ANALYTIC_SUCCESS: {
            return {
                ...state,
                analyticDetails: action.payload,
                loading: false,
            };
        }
        case ANALYTIC_ERROR: {
            return {
                ...state,
                loading: false,
                analyticDetails: {},
                error: action.error,
            };
        }
        case ANALYTIC_COMPANY_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }
        case ANALYTIC_COMPANY_LIST_SUCCESS: {
            return {
                ...state,
                companyList: action.payload,
                loading: false,
            };
        }
        case ANALYTIC_COMPANY_LIST_ERROR: {
            return {
                ...state,
                loading: false,
                companyList: {},
                error: action.error,
            };
        }
        case ANALYTIC_USER_LIST_REQUEST: {
            return {
                ...state,
                loading: true,
                error: "",
            };
        }
        case ANALYTIC_USER_LIST_SUCCESS: {
            return {
                ...state,
                userList: action.payload,
                loading: false,
            };
        }
        case ANALYTIC_USER_LIST_ERROR: {
            return {
                ...state,
                loading: false,
                userList: {},
                error: action.error,
            };
        }
        default: {
            return state;
        }
    }
};

export default analyticDashboardReducer;
