import JwtAuthService from "../apiConfig/ApiConfig";
import { ANALYTIC_COMPANY_EDIT_ERROR, ANALYTIC_COMPANY_EDIT_REQUEST, ANALYTIC_COMPANY_EDIT_SUCCESS, ANALYTIC_COMPANY_LIST_ERROR, ANALYTIC_COMPANY_LIST_REQUEST, ANALYTIC_COMPANY_LIST_SUCCESS, ANALYTIC_ERROR, ANALYTIC_REQUEST, ANALYTIC_SUCCESS, ANALYTIC_USER_LIST_ERROR, ANALYTIC_USER_LIST_REQUEST, ANALYTIC_USER_LIST_SUCCESS } from "../types/Types";

export const AnalyticRequest = () => ({
    type: ANALYTIC_REQUEST,
});
export const AnalyticSuccess = (type) => ({
    type: ANALYTIC_SUCCESS,
    payload: type,
});
export const AnalyticFailed = (error) => ({
    type: ANALYTIC_ERROR,
    payload: error,
});

export const AnalyticApi = (data) => (dispatch) => {
    try {
        dispatch(AnalyticRequest(data));
        JwtAuthService.GetApiService(data).then((response) => {
            if (response?.status === 200) {
                const data = response && response.data && response.data.data;
                dispatch(AnalyticSuccess(data));
            } else {
                dispatch(AnalyticFailed(response?.statusText));
            }
        });
    } catch (error) {
        dispatch(AnalyticFailed(error));
    }
};

export const CompanyListRequest = () => ({
    type: ANALYTIC_COMPANY_LIST_REQUEST,
});
export const CompanyListSuccess = (type) => ({
    type: ANALYTIC_COMPANY_LIST_SUCCESS,
    payload: type,
});
export const CompanyListFailed = (error) => ({
    type: ANALYTIC_COMPANY_LIST_ERROR,
    payload: error,
});

export const CompanyListApi = (data) => (dispatch) => {
    try {
        dispatch(CompanyListRequest(data));
        JwtAuthService.PostApiService(data).then((response) => {
            if (response?.status === 200) {
                const data = response && response.data && response.data.data;
                dispatch(CompanyListSuccess(data));
            } else {
                dispatch(CompanyListFailed(response?.statusText));
            }
        });
    } catch (error) {
        dispatch(CompanyListFailed(error));
    }
};

export const UsersListRequest = () => ({
    type: ANALYTIC_USER_LIST_REQUEST,
});
export const UsersListSuccess = (type) => ({
    type: ANALYTIC_USER_LIST_SUCCESS,
    payload: type,
});
export const UsersListFailed = (error) => ({
    type: ANALYTIC_USER_LIST_ERROR,
    payload: error,
});

export const UsersListApi = (data) => (dispatch) => {
    try {
        dispatch(UsersListRequest(data));
        JwtAuthService.PostApiService(data).then((response) => {
            if (response?.status === 200) {
                const data = response && response.data && response.data.data;
                dispatch(UsersListSuccess(data));
            } else {
                dispatch(UsersListFailed(response?.statusText));
            }
        });
    } catch (error) {
        dispatch(UsersListFailed(error));
    }
};

export const CompanyEditRequest = () => ({
    type: ANALYTIC_COMPANY_EDIT_REQUEST,
});
export const CompanyEditSuccess = (type) => ({
    type: ANALYTIC_COMPANY_EDIT_SUCCESS,
    payload: type,
});
export const CompanyEditFailed = (error) => ({
    type: ANALYTIC_COMPANY_EDIT_ERROR,
    payload: error,
});

export const CompanyEditApi = (data) => (dispatch) => {
    try {
        dispatch(CompanyEditRequest(data));
        JwtAuthService.PutApiService(data).then((response) => {
            if (response?.status === 200) {
                const data = response && response.data && response.data.data;
                dispatch(CompanyEditSuccess(data));
            } else {
                dispatch(CompanyEditFailed(response?.statusText));
            }
        });
    } catch (error) {
        dispatch(CompanyEditFailed(error));
    }
};