import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { Typography } from "material-ui-core";

const MasterQRCodeViewHeader = () => {
    return (
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
        className="header-card"
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          sx={{
            padding: "0px 8px",
          }}
        >
          <Grid
            item
            xs={12}
            lg={7}
            md={4}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start", 
            }}
          >
            <Typography
             variant="h6"
            component="h6"
              sx={{
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Master Barcode View
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };
  

export default MasterQRCodeViewHeader;
