/** @format */

import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const CustomDialog = ({
  isSelfieDialogOpen,
  closeSelfieDialog,
  handleAttachSelfieImage,
  fileInputRef,
  setReviewModalOpen,
  setSelfieDialogOpen,
  captureImage,
  handleFormImage,
  handleFormVideo,
  isVideoIconClicked,
  isSubmissionInitiated,
}) => {
  const navigate = useNavigate();
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCameraActive, setCameraActive] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [isImageCaptured, setIsImageCaptured] = useState(false);
  const [capturedPhotoURL, setCapturedPhotoURL] = useState(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoURL, setVideoURL] = useState(null);
  const [remainingTime, setRemainingTime] = useState(15);
  const mediaWebcamRef = useRef(null);
  const webcamRef = useRef(null);
  const profileData = useSelector(
    (state) => state.ProfileDetailData && state.ProfileDetailData.ProfileData
  );

  const handleCameraActivation = () => {
    setCameraActive(!isCameraActive);
    if (!isCameraActive) {
      setCapturedImage(null);
      setIsImageCaptured(false);
      setRecordedChunks([]);
    }
  };

  const handleCapturePhoto = () => {
    if (isCameraActive && webcamRef.current) {
      const dataUri = webcamRef.current.getScreenshot();
      setCapturedImage(dataUri);
      setCameraActive(false);
      setIsImageCaptured(true);
      setCapturedPhotoURL(dataUri);
      // toast.success("Photo captured successfully!", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });

      const countdownInterval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
        if (remainingTime === 1) {
          clearInterval(countdownInterval);
          handleStopRecording();
        }
      }, 1000);
    } else if (!isCameraActive) {
      toast.error("Camera is not active.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Webcam is not available.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleStopRecording = React.useCallback(() => {
    if (mediaWebcamRef.current) {
      mediaWebcamRef.current.stop();
    }
    setRecording(false);
    setCameraActive(false);
    setRemainingTime(15);

    setRecordedChunks([]);
    setVideoURL(null);
  }, [
    mediaWebcamRef,
    setRecording,
    setCameraActive,
    setRemainingTime,
    setRecordedChunks,
    setVideoURL,
  ]);

  useEffect(() => {
    if (!recording && recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      setVideoURL(url);
    }
  }, [recording, recordedChunks]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleCaptureVideo = () => {
    if (isCameraActive && webcamRef.current) {
      const mediaRecorderInstance = new MediaRecorder(
        webcamRef.current.stream,
        { mimeType: "video/webm" }
      );

      mediaWebcamRef.current = mediaRecorderInstance;

      mediaWebcamRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaWebcamRef.current.start();

      setRecording(true);
    } else if (!isCameraActive) {
      toast.error("Camera is not active.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("Webcam is not available.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // const handleUploadPhoto = () => {
  //   if ((capturedPhotoURL || capturedImage || videoURL) && !recording) {
  //     const indivisualFormData = localStorage.getItem("IndivisualFormData");

  //     let formInfo;
  //     try {
  //       formInfo = JSON.parse(indivisualFormData);
  //       // if (!Array.isArray(formInfo)) {
  //       //   formInfo = [formInfo];
  //       //   localStorage.setItem(
  //       //     "IndivisualFormData",
  //       //     JSON.stringify(JSON.parse(indivisualFormData))
  //       //   );
  //       // }
  //     } catch (error) {
  //       console.error("Error parsing IndivisualFormData:", error);
  //       formInfo = null;
  //     }

  //     const companyId = localStorage.getItem("companyId");

  //     // Initialize fileName with a default value
  //     let fileName = "";

  //     if (profileData?.data?.first_name && formInfo?.title) {
  //       fileName = `IMGPOPPROBE_${profileData.data.first_name
  //         .substring(0, 3)
  //         .trim()}_${formInfo.title.substring(0, 3).trim()}_${moment().format(
  //         "YYYY-MM-DD-HH-mm-ss"
  //       )}-${Date.now()}`;
  //     }

  //     let fileData;

  //     if (capturedPhotoURL) {
  //       if (capturedPhotoURL.startsWith("data:image/jpeg;base64,")) {
  //         var base64str = capturedPhotoURL.substring(
  //           capturedPhotoURL.indexOf(",") + 1
  //         );
  //         var decoded = atob(base64str);

  //         fileData = {
  //           path: capturedPhotoURL,
  //           fileName: fileName,
  //           originalname: fileName,
  //           key: fileName,
  //           name: fileName,
  //           fileType: "IMAGE",
  //           contentType: "image/jpeg",
  //           addedBy: profileData?.data?._id,
  //           company: companyId,
  //           size: decoded.length,
  //         };
  //       } else {
  //         toast.error("Invalid data URL for image.", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         return;
  //       }
  //     } else if (videoURL) {
  //       fileData = {
  //         path: videoURL,
  //         fileName: fileName,
  //         originalname: fileName,
  //         key: fileName,
  //         name: fileName,
  //         fileType: "VIDEO",
  //         contentType: "video/webm",
  //         addedBy: profileData?.data?._id,
  //         company: companyId,
  //         size: recordedChunks.length,
  //       };
  //     } else if (capturedImage){
  //       console.log("capturedImage", capturedImage);
  //       if (capturedImage.startsWith("data:image/jpeg;base64,")) {
  //         var base64str = capturedImage.substring(
  //           capturedImage.indexOf(",") + 1
  //         );
  //         var decoded = atob(base64str);

  //         fileData = {
  //           path: capturedImage,
  //           fileName: fileName,
  //           originalname: fileName,
  //           key: fileName,
  //           name: fileName,
  //           fileType: "IMAGE",
  //           contentType: "image/jpeg",
  //           addedBy: profileData?.data?._id,
  //           company: companyId,
  //           size: decoded.length,
  //         };
  //       } else {
  //         toast.error("Invalid data URL for image.", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //         return;
  //       }
  //     }

  //     // if (Array.isArray(formInfo)) {
  //     //   formInfo.map((item) => {
  //     toast.success("File uploaded successfully!", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     setSelfieDialogOpen();
  //     if (!captureImage && !videoURL) {
  //       localStorage.setItem("formSelfie", JSON.stringify(fileData));
  //       setReviewModalOpen();
  //     } else {
  //       if (capturedImage) {
  //         handleFormImage(capturedImage);
  //       }
  //       if (videoURL) {
  //         handleFormVideo(fileData);
  //       }
  //     }
  //     console.log("Captured Photo URL:", capturedPhotoURL);
  //     // });
  //     // } else {
  //     //   // console.error("IndivisualFormData is not an array");
  //     // }

  //     setCapturedImage(null);
  //     setCameraActive(false);
  //     setIsImageCaptured(false);
  //     setCapturedPhotoURL(null);
  //     setRecording(false);
  //     setRecordedChunks([]);
  //     setVideoURL(null);
  //     closeSelfieDialog();
  //   } else {
  //     toast.error("No photo or video to upload.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  const handleUploadPhoto = () => {
    if ((capturedPhotoURL || capturedImage || videoURL) && !recording) {
      const indivisualFormData = localStorage.getItem("IndivisualFormData");
  
      let formInfo;
      try {
        formInfo = JSON.parse(indivisualFormData);
      } catch (error) {
        console.error("Error parsing IndivisualFormData:", error);
        formInfo = null;
      }
  
      const companyId = localStorage.getItem("companyId");
  
      // Initialize fileName with a default value
      let fileName = "";
  
      if (profileData?.data?.first_name && formInfo?.title) {
        fileName = `IMGPOPPROBE_${profileData.data.first_name
          .substring(0, 3)
          .trim()}_${formInfo.title.substring(0, 3).trim()}_${moment().format(
          "YYYY-MM-DD-HH-mm-ss"
        )}-${Date.now()}`;
      }
  
      let fileData;
  
      // Handle image capture from base64
      if (capturedPhotoURL) {
        const regex = /^data:image\/([a-zA-Z]*);base64,/;
        const match = capturedPhotoURL.match(regex);
        if (match) {
          const imageType = match[1]; // Get image type (jpg, png, etc.)
          const base64str = capturedPhotoURL.substring(capturedPhotoURL.indexOf(",") + 1);
          const decoded = atob(base64str);
          const size = base64str.length * (3 / 4); // Correct way to calculate size from base64
  
          fileData = {
            path: capturedPhotoURL,
            fileName: fileName,
            originalname: fileName,
            key: fileName,
            name: fileName,
            fileType: "IMAGE",
            contentType: `image/${imageType}`, // Dynamically set content type
            addedBy: profileData?.data?._id,
            company: companyId,
            size: size, // Set the correct size
          };
        } else {
          toast.error("Invalid image URL for capture.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      } else if (capturedImage) {
        const regex = /^data:image\/([a-zA-Z]*);base64,/;
        const match = capturedImage.match(regex);
        if (match) {
          const imageType = match[1]; // Get image type (jpg, png, etc.)
          const base64str = capturedImage.substring(capturedImage.indexOf(",") + 1);
          const decoded = atob(base64str);
          const size = base64str.length * (3 / 4); // Correct way to calculate size from base64
  
          fileData = {
            path: capturedImage,
            fileName: fileName,
            originalname: fileName,
            key: fileName,
            name: fileName,
            fileType: "IMAGE",
            contentType: `image/${imageType}`, // Dynamically set content type
            addedBy: profileData?.data?._id,
            company: companyId,
            size: size, // Set the correct size
          };
        } else {
          toast.error("Invalid data URL for image.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      } else if (videoURL) {
        fileData = {
          path: videoURL,
          fileName: fileName,
          originalname: fileName,
          key: fileName,
          name: fileName,
          fileType: "VIDEO",
          contentType: "video/webm",
          addedBy: profileData?.data?._id,
          company: companyId,
          size: recordedChunks.length,
        };
      }
  
      // Upload the photo/video
      toast.success("File uploaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
  
      setSelfieDialogOpen();
      if (!captureImage && !videoURL) {
        localStorage.setItem("formSelfie", JSON.stringify(fileData));
        setReviewModalOpen();
      } else {
        if (capturedImage) {
          handleFormImage(capturedImage);
        }
        if (videoURL) {
          handleFormVideo(fileData);
        }
      }
  
      // Reset camera and form states
      setCapturedImage(null);
      setCameraActive(false);
      setIsImageCaptured(false);
      setCapturedPhotoURL(null);
      setRecording(false);
      setRecordedChunks([]);
      setVideoURL(null);
      closeSelfieDialog();
    } else {
      toast.error("No photo or video to upload.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  
  
  
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
        toast.error("Invalid file type. Please select an image or video.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast.error("Only up to 5 MB files are allowed.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileSrc = e.target.result;
        setCapturedImage(fileSrc);
        setIsImageCaptured(true);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(videoDevices);
      if (videoDevices.length === 1) {
        setSelectedDevice(videoDevices[0].deviceId);
      }
    });
  }, []);

  const handleCameraChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  const renderCameraDropdown = () => {
    if (devices.length > 1) {
      return (
        <FormControl variant="outlined" sx={{ minWidth: 120, marginTop: "1%" }}>
          <InputLabel id="camera-select-label">Camera Type</InputLabel>
          <Select
            labelId="camera-select-label"
            id="camera-select"
            value={selectedDevice}
            onChange={handleCameraChange}
            label="Camera Type"
            MenuProps={{ sx: { maxHeight: "150px" } }}
          >
            {devices?.map((device) => (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera Type ${devices.indexOf(device) + 1}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  const renderRecordingButtons = () => {
    if (isVideoIconClicked && isCameraActive && !isImageCaptured) {
      return (
        <div>
          {recording ? (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={handleStopRecording}
            >
              Stop Recording
            </Button>
          ) : (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={handleCaptureVideo}
            >
              Start Recording
            </Button>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <Box>
      <ToastContainer />
      <Dialog
        open={isSelfieDialogOpen}
        onClose={() => {
          setCapturedImage(null);
          setCameraActive(false);
          setIsImageCaptured(false);
          setRecording(false);
          setRecordedChunks([]);
          closeSelfieDialog();
        }}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            height: "85%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              Please{" "}
              {!captureImage && (
                <>
                  <strong>attach an image </strong> or{" "}
                </>
              )}
              <strong>capture photo </strong>or <strong>record video</strong>{" "}
              and upload it.
            </Typography>
            {renderCameraDropdown()}
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", overflow: "hidden" }}>
          {/* <Typography
            variant="h6"
            sx={{ position: "absolute", top: 0, right: 0, padding: 2 }}
          >
            Remaining Time: {remainingTime}s
          </Typography> */}
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isCameraActive && (
              <Webcam
                ref={webcamRef}
                videoConstraints={{ deviceId: selectedDevice }}
                screenshotFormat="image/jpeg"
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  border: "2px solid #196773",
                }}
              />
            )}
            {!isCameraActive && videoURL && (
              <video
                id="recorded-video"
                src={videoURL}
                controls
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            )}
            {capturedImage && !videoURL && (
              <img
                src={capturedImage}
                alt="Captured"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            )}
          </div>
        </DialogContent>
        {/* <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          {!captureImage && (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={() => {
                handleAttachSelfieImage();
                setCameraActive(false);
                setIsImageCaptured(false);
              }}
            >
              <AttachFileIcon />
              Attach Image
            </Button>
          )}
          {isCameraActive && !isImageCaptured && !isSubmissionInitiated && (
            <>
              {!isVideoIconClicked && (
                <Button
                  variant="contained"
                  className="cancle-hover"
                  sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
                  onClick={handleCapturePhoto}
                >
                  <PhotoCameraIcon />
                  Capture Photo
                </Button>
              )}
              {isVideoIconClicked && renderRecordingButtons()}
            </>
          )}
          {(isImageCaptured || videoURL) && (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={handleUploadPhoto}
            >
              <FileUploadIcon />
              Upload Photo/Video
            </Button>
          )} */}

        {/* {!captureImage && (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={() => {
                handleAttachSelfieImage();
                setCameraActive(false);
                setIsImageCaptured(false);
              }}
            >
              <AttachFileIcon />
              Attach Image
            </Button>
          )}
          {isCameraActive && !isImageCaptured && (
            <>
              {!isVideoIconClicked && (
                <Button
                  variant="contained"
                  className="cancle-hover"
                  sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
                  onClick={handleCapturePhoto}
                >
                  <PhotoCameraIcon />
                  Capture Photo
                </Button>
              )}
              {isVideoIconClicked && renderRecordingButtons()}
            </>
          )}
          {(isImageCaptured || videoURL) && (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={handleUploadPhoto}
            >
              <FileUploadIcon />
              Upload Photo/Video
            </Button>
          )} */}

        {/* <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              backgroundColor: "#196773",
              "&:hover": {
                backgroundColor: "#ff0000",
              },
            }}
            onClick={handleCameraActivation}
          >
            {isCameraActive ? (
              <>
                <CloseIcon />
                Close Camera
              </>
            ) : (
              <>
                <PhotoCameraIcon />
                Open Camera
              </>
            )}
          </Button>
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              backgroundColor: "#196773",
              "&:hover": {
                backgroundColor: "#ff0000",
              },
            }}
            onClick={() => {
              handleStopRecording();
              setCapturedImage(null);
              setCameraActive(false);
              setIsImageCaptured(false);
              setRecording(false);
              setRecordedChunks([]);
              closeSelfieDialog();
            }}
          >
            <CloseIcon />
            Cancel
          </Button>
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            accept="image/*,video/*"
            onChange={handleFileInputChange}
          />
        </DialogActions> */}
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          {/* Render "Attach Image" button only when the camera is active */}
          {!isVideoIconClicked && !isCameraActive && (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={() => {
                handleAttachSelfieImage();
              }}
            >
              <AttachFileIcon />
              Attach Image
            </Button>
          )}

          {/* Render "Capture Photo" button when the camera is active */}
          {!isVideoIconClicked && isCameraActive && (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={handleCapturePhoto}
            >
              <PhotoCameraIcon />
              Capture Photo
            </Button>
          )}

          {/* Render "Start Recording" button if video recording is enabled */}
          {isVideoIconClicked && renderRecordingButtons()}

          {/* Render "Close Camera" button */}
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              backgroundColor: "#196773",
              "&:hover": {
                backgroundColor: "#ff0000",
              },
            }}
            onClick={handleCameraActivation}
          >
            {isCameraActive ? (
              <>
                <CloseIcon />
                Close Camera
              </>
            ) : (
              <>
                <PhotoCameraIcon />
                Open Camera
              </>
            )}
          </Button>

          {/* Render "Cancel" button */}
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              backgroundColor: "#196773",
              "&:hover": {
                backgroundColor: "#ff0000",
              },
            }}
            onClick={() => {
              handleStopRecording();
              setCapturedImage(null);
              setCameraActive(false);
              setIsImageCaptured(false);
              setRecording(false);
              setRecordedChunks([]);
              closeSelfieDialog();
            }}
          >
            <CloseIcon />
            Cancel
          </Button>

          {/* Render "Upload Photo / Video" button */}
          {(isImageCaptured || videoURL) && (
            <Button
              variant="contained"
              className="cancle-hover"
              sx={{ mt: 2, mb: 2, backgroundColor: "#196773" }}
              onClick={handleUploadPhoto}
            >
              <FileUploadIcon />
              Upload Photo/Video
            </Button>
          )}

          {/* Input for file selection */}
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            accept="image/*,video/*"
            onChange={handleFileInputChange}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomDialog;
