/** @format */

// START SIGNUP TYPES //
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";

export const VERIFICATION_REQUEST = "VERIFICATION_REQUEST";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_ERROR = "VERIFICATION_ERROR";

export const SIGN_UP_FORM_REQUEST = "SIGN_UP_FORM_REQUEST";
export const SIGN_UP_FORM_SUCCESS = "SIGN_UP_FORM_SUCCESS";
export const SIGN_UP_FORM_ERROR = "SIGN_UP_FORM_ERROR";
// END SIGNUP TYPES //

// START LOGIN TYPES //
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
// END LOGIN TYPES //

export const PRIVATE_ROUTE_REQUEST = "PRIVATE_ROUTE_REQUEST";
export const PRIVATE_ROUTE_REQUEST_SUCCESS = "PRIVATE_ROUTE_REQUEST_SUCCESS";
export const PRIVATE_ROUTE_REQUEST_ERROR = "PRIVATE_ROUTE_REQUEST_ERROR";

export const PRIVATE_USER_REQUEST = "PRIVATE_USER_REQUEST";
export const PRIVATE_USER_REQUEST_SUCCESS = "PRIVATE_USER_REQUEST_SUCCESS";
export const PRIVATE_USER_REQUEST_ERROR = "PRIVATE_USER_REQUEST_ERROR";

// START COMPANIES TYPES //
export const COMPANY_TYPE_REQUEST = "COMPANY_TYPE_REQUEST";
export const COMPANY_TYPE_SUCCESS = "COMPANY_TYPE_SUCCESS";
export const COMPANY_TYPE_ERROR = "COMPANY_TYPE_ERROR";

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_ERROR = "CREATE_COMPANY_ERROR";

export const COMPANY_LIST_REQUEST = "COMPANY_LIST_REQUEST";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_LIST_ERROR = "COMPANY_LIST_ERROR";

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";

export const EDIT_COMPANY_REQUEST = "EDIT_COMPANY_REQUEST";
export const EDIT_COMPANY_SUCCESS = "EDIT_COMPANY_SUCCESS";
export const EDIT_COMPANY_ERROR = "EDIT_COMPANY_ERROR";

// END COMPANIES TYPES //

// START ROLES TYPES //
export const LIST_ROLES_REQUEST = "LIST_ROLES_REQUEST";
export const LIST_ROLES_SUCCESS = "LIST_ROLES_SUCCESS";
export const LIST_ROLES_ERROR = "LIST_ROLES_ERROR";

export const CREATE_ROLES_REQUEST = "CREATE_ROLES_REQUEST";
export const CREATE_ROLES_SUCCESS = "CREATE_ROLES_SUCCESS";
export const CREATE_ROLES_ERROR = "CREATE_ROLES_ERROR";

export const EDIT_ROLES_REQUEST = "EDIT_ROLES_REQUEST";
export const EDIT_ROLES_SUCCESS = "EDIT_ROLES_SUCCESS";
export const EDIT_ROLES_ERROR = "EDIT_ROLES_ERROR";

export const ROLE_DETAILS_REQUEST = "ROLE_DETAILS_REQUEST";
export const ROLE_DETAILS_SUCCESS = "ROLE_DETAILS_SUCCESS";
export const ROLE_DETAILS_ERROR = "ROLE_DETAILS_ERROR";

export const LIST_SUBROLE_REQUEST = "LIST_SUBROLE_REQUEST";
export const LIST_SUBROLE_SUCCESS = "LIST_SUBROLE_SUCCESS";
export const LIST_SUBROLE_ERROR = "LIST_SUBROLE_ERROR";

export const MAP_ROLES_REQUEST = "MAP_ROLES_REQUEST";
export const MAP_ROLES_SUCCESS = "MAP_ROLES_SUCCESS";
export const MAP_ROLES_ERROR = "MAP_ROLES_ERROR";

export const DELETE_ROLES_REQUEST = "DELETE_ROLES_REQUEST";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_ERROR = "DELETE_ROLES_ERROR";
// END ROLES TYPES //

// LMS Module
export const LIST_TRAINING_REQUEST = "LIST_TRAINING_REQUEST";
export const LIST_TRAINING_SUCCESS = "LIST_TRAINING_SUCCESS";
export const LIST_TRAINING_ERROR = "LIST_TRAINING_ERROR";

export const PROGRESS_LIST_REQUEST = "PROGRESS_LIST_REQUEST";
export const PROGRESS_LIST_SUCCESS = "PROGRESS_LIST_SUCCESS";
export const PROGRESS_LIST_ERROR = "PROGRESS_LIST_ERROR";

export const RESET_LIST_REQUEST = "RESET_LIST_REQUEST";
export const RESET_LIST_SUCCESS = "RESET_LIST_SUCCESS";
export const RESET_LIST_ERROR = "RESET_LIST_ERROR";

export const CREATE_NEW_TRAINING_REQUEST = "CREATE_NEW_TRAINING_REQUEST";
export const CREATE_NEW_TRAINING_SUCCESS = "CREATE_NEW_TRAINING_SUCCESS";
export const CREATE_NEW_TRAINING_ERROR = "CREATE_NEW_TRAINING_ERROR";

export const UPDATE_TRAINING_REQUEST = "UPDATE_TRAINING_REQUEST";
export const UPDATE_TRAINING_SUCCESS = "UPDATE_TRAINING_SUCCESS";
export const UPDATE_TRAINING_ERROR = "UPDATE_TRAINING_ERROR";

export const USER_TRAINING_LIST_REQUEST = "USER_TRAINING_LIST_REQUEST";
export const USER_TRAINING_LIST_SUCCESS = "USER_TRAINING_LIST_SUCCESS";
export const USER_TRAINING_LIST_ERROR = "USER_TRAINING_LIST_ERROR";

export const STORE_TRAINING_LIST_REQUEST = "STORE_TRAINING_LIST_REQUEST";
export const STORE_TRAINING_LIST_SUCCESS = "STORE_TRAINING_LIST_SUCCESS";
export const STORE_TRAINING_LIST_ERROR = "STORE_TRAINING_LIST_ERROR";

export const STORE_TRAINING_VIEW_LIST_REQUEST =
  "STORE_TRAINING_VIEW_LIST_REQUEST";
export const STORE_TRAINING_VIEW_LIST_SUCCESS =
  "STORE_TRAINING_VIEW_LIST_SUCCESS";
export const STORE_TRAINING_VIEW_LIST_ERROR = "STORE_TRAINING_VIEW_LIST_ERROR";

export const INDIVIDUAL_STORE_TRAINING_VIEW_LIST_REQUEST =
  "INDIVIDUAL_STORE_TRAINING_VIEW_LIST_REQUEST";
export const INDIVIDUAL_STORE_TRAINING_VIEW_LIST_SUCCESS =
  "INDIVIDUAL_STORE_TRAINING_VIEW_LIST_SUCCESS";
export const INDIVIDUAL_STORE_TRAINING_VIEW_LIST_ERROR =
  "INDIVIDUAL_STORE_TRAINING_VIEW_LIST_ERROR";

// View in All Training
export const TRAINING_DETAILS_REQUEST = "TRAINING_DETAILS_REQUEST";
export const TRAINING_DETAILS_SUCCESS = "TRAINING_DETAILS_SUCCESS";
export const TRAINING_DETAILS_ERROR = "TRAINING_DETAILS_ERROR";

// START CHECKLIST TYPES //
export const ALLCHECKLIST_LIST_REQUEST = "ALLCHECKLIST_LIST_REQUEST";
export const ALLCHECKLIST_LIST_SUCCESS = "ALLCHECKLIST_LIST_SUCCESS";
export const ALLCHECKLIST_LIST_ERROR = "ALLCHECKLIST_LIST_ERROR";

export const EDIT_ALL_FORM_REQUEST = "EDIT_ALL_FORM_REQUEST";
export const EDIT_ALL_FORM_SUCCESS = "EDIT_ALL_FORM_SUCCESS";
export const EDIT_ALL_FORM_ERROR = "EDIT_ALL_FORM_ERROR";

export const ALLFORM_CHECKLIST_REQUEST = "ALLFORM_CHECKLIST_REQUEST";
export const ALLFORM_CHECKLIST_SUCCESS = "ALLFORM_CHECKLIST_SUCCESS";
export const ALLFORM_CHECKLIST_ERROR = "ALLFORM_CHECKLIST_ERROR";

export const VIEW_ALLFORM_CHECKLIST_REQUEST = "VIEW_ALLFORM_CHECKLIST_REQUEST";
export const VIEW_ALLFORM_CHECKLIST_SUCCESS = "VIEW_ALLFORM_CHECKLIST_SUCCESS";
export const VIEW_ALLFORM_CHECKLIST_ERROR = "VIEW_ALLFORM_CHECKLIST_ERROR";

export const EDIT_ALLFORM_CHECKLIST_REQUEST = "EDIT_ALLFORM_CHECKLIST_REQUEST";
export const EDIT_ALLFORM_CHECKLIST_SUCCESS = "EDIT_ALLFORM_CHECKLIST_SUCCESS";
export const EDIT_ALLFORM_CHECKLIST_ERROR = "EDIT_ALLFORM_CHECKLIST_ERROR";

export const DELETE_ALLFORM_REQUEST = "DELETE_ALLFORM_REQUEST";
export const DELETE_ALLFORM_SUCCESS = "DELETE_ALLFORM_SUCCESS";
export const DELETE_ALLFORM_ERROR = "DELETE_ALLFORM_ERROR";

export const CREATE_ALLFORM_REQUEST = "CREATE_ALLFORM_REQUEST";
export const CREATE_ALLFORM_SUCCESS = "CREATE_ALLFORM_SUCCESS";
export const CREATE_ALLFORM_ERROR = "CREATE_ALLFORM_ERROR";

export const SUBMISSION_VIEW_REQUEST = "SUBMISSION_VIEW_REQUEST";
export const SUBMISSION_VIEW_SUCCESS = "SUBMISSION_VIEW_SUCCESS";
export const SUBMISSION_VIEW_ERROR = "SUBMISSION_VVIEW_ERROR";

export const DISAPPROVED_FORM_REQUEST = "DISAPPROVED_FORM_REQUEST";
export const DISAPPROVED_FORM_SUCCESS = "DISAPPROVED_FORM_SUCCESS";
export const DISAPPROVED_FORM_ERROR = "DISAPPROVED_FORM_ERROR";

export const OVERVIEW_CHART_REQUEST = "OVERVIEW_CHART_REQUEST";
export const OVERVIEW_CHART_SUCCESS = "OVERVIEW_CHART_SUCCESS";
export const OVERVIEW_CHART_ERROR = "OVERVIEW_CHART_ERROR";

export const OVERVIEW_LIST_REQUEST = "OVERVIEW_LIST_REQUEST";
export const OVERVIEW_LIST_SUCCESS = "OVERVIEW_LIST_SUCCESS";
export const OVERVIEW_LIST_ERROR = "OVERVIEW_LIST_ERROR";

export const RECENT_STORE_LIST_REQUEST = "RECENT_STORE_LIST_REQUEST";
export const RECENT_STORE_LIST_SUCCESS = "RECENT_STORE_LIST_SUCCESS";
export const RECENT_STORE_LIST_ERROR = "RECENT_STORE_LIST_ERROR";

// Log Entry

export const CREATE_LOG_ENTRY_REQUEST = "CREATE_LOG_ENTRY_REQUEST";
export const CREATE_LOG_ENTRY_SUCCESS = "CREATE_LOG_ENTRY_SUCCESS";
export const CREATE_LOG_ENTRY_ERROR = "CREATE_LOG_ENTRY_ERROR";

export const VIEW_ALLLOG_ENTRY_REQUEST = "VIEW_ALLLOG_ENTRY_REQUEST";
export const VIEW_ALLLOG_ENTRY_SUCCESS = "VIEW_ALLLOG_ENTRY_SUCCESS";
export const VIEW_ALLLOG_ENTRY_ERROR = "VIEW_ALLLOG_ENTRY_ERROR";

export const DELETE_LOG_ENTRY_REQUEST = "DELETE_LOG_ENTRY_REQUEST";
export const DELETE_LOG_ENTRY_SUCCESS = "DELETE_LOG_ENTRY_SUCCESS";
export const DELETE_LOG_ENTRY_ERROR = "DELETE_LOG_ENTRY_ERROR";

export const UPLOAD_IMG_EDIT_LOG_QUES_REQUEST =
  "UPLOAD_IMG_EDIT_LOG_QUES_REQUEST";
export const UPLOAD_IMG_EDIT_LOG_QUES_SUCCESS =
  "UPLOAD_IMG_EDIT_LOG_QUES_SUCCESS";
export const UPLOAD_IMG_EDIT_LOG_QUES_ERROR = "UPLOAD_IMG_EDIT_LOG_QUES_ERROR";

export const GET_LOG_ENTRY_SUBMISSION_REQUEST =
  "GET_LOG_ENTRY_SUBMISSION_REQUEST";
export const GET_LOG_ENTRY_SUBMISSION_SUCCESS =
  "GET_LOG_ENTRY_SUBMISSION_SUCCESS";
export const GET_LOG_ENTRY_SUBMISSION_ERROR = "GET_LOG_ENTRY_SUBMISSION_ERROR";

// Submission list
export const SUBMISSION_LIST_REQUEST = "SUBMISSION_LIST_REQUEST";
export const SUBMISSION_LIST_SUCCESS = "SUBMISSION_LIST_SUCCESS";
export const SUBMISSION_LIST_ERROR = "SUBMISSION_LIST_ERROR";

export const DEPARTMENT_LIST_REQUEST = "DEPARTMENT_LIST_REQUEST";
export const DEPARTMENT_LIST_SUCCESS = "DEPARTMENT_LIST_SUCCESS";
export const DEPARTMENT_LIST_ERROR = "DEPARTMENT_LIST_ERROR";

export const EDIT_STORE_REQUEST = "EDIT_STORE_REQUEST";
export const EDIT_STORE_SUCCESS = "EDIT_STORE_SUCCESS";
export const EDIT_STORE_ERROR = "EDIT_STORE_ERROR";

export const STORE_BY_ID_EDIT_REQUEST = "STORE_BY_ID_EDIT_REQUEST";
export const STORE_BY_ID_EDIT_SUCCESS = "STORE_BY_ID_EDIT_SUCCESS";
export const STORE_BY_ID_EDIT_ERROR = "STORE_BY_ID_EDIT_ERROR";

export const STORE_LIST_REQUEST = "STORE_LIST_REQUEST";
export const STORE_LIST_SUCCESS = "STORE_LIST_SUCCESS";
export const STORE_LIST_ERROR = "STORE_LIST_ERROR";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_ERROR = "USER_LIST_ERROR";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR";

export const PENDING_CHECKLIST_REQUEST = "PENDING_CHECKLIST_REQUEST";
export const PENDING_CHECKLIST_SUCCESS = "PENDING_CHECKLIST_SUCCESS";
export const PENDING_CHECKLIST_ERROR = "PENDING_CHECKLIST_ERROR";

export const RULES_ADDED_REQUEST = "RULES_ADDED_REQUEST";
export const RULES_ADDED_SUCCESS = "RULES_ADDED_SUCCESS";
export const RULES_ADDED_ERROR = "RULES_ADDED_ERROR";

export const ASSIGN_USER_REQUEST = "ASSIGN_USER_REQUEST";
export const ASSIGN_USER_SUCCESS = "ASSIGN_USER_SUCCESS";
export const ASSIGN_USER_ERROR = "ASSIGN_USER_ERROR";

// Submission View
export const ADD_ALLCHECKLIST_REQUEST = "ADD_ALLCHECKLIST_REQUEST";
export const ADD_ALLCHECKLIST_SUCCESS = "ADD_ALLCHECKLIST_SUCCESS";
export const ADD_ALLCHECKLIST_ERROR = "ADD_ALLCHECKLIST_ERROR";

export const UPDATE_ALLCHECKLIS_REQUEST = "UPDATE_ALLCHECKLIS_REQUEST";
export const UPDATE_ALLCHECKLIS_SUCCESS = "UPDATE_ALLCHECKLIS_SUCCESS";
export const UPDATE_ALLCHECKLIS_ERROR = "UPDATE_ALLCHECKLIS_ERROR";

export const SWITCH_ALLCHECKLIST_REQUEST = "SWITCH_ALLCHECKLIST_REQUEST";
export const SWITCH_ALLCHECKLIST_SUCCESS = "SWITCH_ALLCHECKLIST_SUCCESS";
export const SWITCH_ALLCHECKLIST_ERROR = "SWITCH_ALLCHECKLIST_ERROR";

export const SUBMISSION_SELECT_SEARCH_REQUEST =
  "SUBMISSION_SELECT_SEARCH_REQUEST";
export const SUBMISSION_SELECT_SEARCH_SUCCESS =
  "SUBMISSION_SELECT_SEARCH_SUCCESS";
export const SUBMISSION_SELECT_SEARCH_ERROR = "SUBMISSION_SELECT_SEARCH_ERROR";

// END CHECKLIST TYPES //

// Store Checklist
export const STORE_CHECKLIST_LIST_REQUEST = "STORE_CHECKLIST_LIST_REQUEST";
export const STORE_CHECKLIST_LIST_SUCCESS = "STORE_CHECKLIST_LIST_SUCCESS";
export const STORE_CHECKLIST_LIST_ERROR = "STORE_CHECKLIST_LIST_ERROR";

// User Checklist
export const USER_CHECKLIST_LIST_REQUEST = "USER_CHECKLIST_LIST_REQUEST";
export const USER_CHECKLIST_LIST_SUCCESS = "USER_CHECKLIST_LIST_SUCCESS";
export const USER_CHECKLIST_LIST_ERROR = "USER_CHECKLIST_LIST_ERROR";

// START STORE TYPES //
export const LIST_STORE_REQUEST = "LIST_STORE_REQUEST";
export const LIST_STORE_SUCCESS = "LIST_STORE_SUCCESS";
export const LIST_STORE_ERROR = "LIST_STORE_ERROR";
// END STORE TYPES //

// START STORE VIEW //
export const VIEW_STORE_REQUEST = "VIEW_STORE_REQUEST";
export const VIEW_STORE_SUCCESS = "VIEW_STORE_SUCCESS";
export const VIEW_STORE_ERROR = "VIEW_STORE_ERROR";

export const STORE_DETAILS_REQUEST = "STORE_DETAILS_REQUEST";
export const STORE_DETAILS_SUCCESS = "STORE_DETAILS_SUCCESS";
export const STORE_DETAILS_ERROR = "STORE_DETAILS_ERROR";
// END STORE VIEW //

export const CHECKLIST_BY_STORE_REQUEST = "CHECKLIST_BY_STORE_REQUEST";
export const CHECKLIST_BY_STORE_SUCCESS = "CHECKLIST_BY_STORE_SUCCESS";
export const CHECKLIST_BY_STORE_ERROR = "CHECKLIST_BY_STORE_ERROR";

// ADD STORE
export const ADD_STORE_REQUEST = "ADD_STORE_REQUEST";
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS";
export const ADD_STORE_ERROR = "ADD_STORE_ERROR";

export const STORE_ENABLE_DISABLE_REQUEST = "STORE_ENABLE_DISABLE_REQUEST";
export const STORE_ENABLE_DISABLE_SUCCESS = "STORE_ENABLE_DISABLE_SUCCESS";
export const STORE_ENABLE_DISABLE_ERROR = "STORE_ENABLE_DISABLE_ERROR";

// START DATA MANAGER TYPES //
export const DATA_LIST_REQUEST = "DATA_LIST_REQUEST";
export const DATA_LIST_SUCCESS = "DATA_LIST_SUCCESS";
export const DATA_LIST_ERROR = "DATA_LIST_ERROR";

export const DATA_MANAGER_UPDATE_REQUEST = "DATA_MANAGER_UPDATE_REQUEST";
export const DATA_MANAGER_UPDATE_SUCCESS = "DATA_MANAGER_UPDATE_SUCCESS";
export const DATA_MANAGER_UPDATE_ERROR = "DATA_MANAGER_UPDATE_ERROR";
// END DATA MANAGER TYPES //

// START USER TYPES //
export const LIST_USER_REQUEST = "LIST_USER_REQUEST";
export const LIST_USER_SUCCESS = "LIST_USER_SUCCESS";
export const LIST_USER_ERROR = "LIST_USER_ERROR";

export const LIST_USER_CHECKLIST_REQUEST = "LIST_USER_CHECKLIST_REQUEST";
export const LIST_USER_CHECKLIST_SUCCESS = "LIST_USER_CHECKLIST_SUCCESS";
export const LIST_USER_CHECKLIST_ERROR = "LIST_USER_CHECKLIST_ERROR";
export const LIST_USER_CHECKLIST_CLEAR = "LIST_USER_CHECKLIST_CLEAR";

export const SUBROLE_USER_REQUEST = "SUBROLE_USER_REQUEST";
export const SUBROLE_USER_SUCCESS = "SUBROLE_USER_SUCCESS";
export const SUBROLE_USER_ERROR = "SUBROLE_USER_ERROR";

export const INSPECTION_REQUEST = "INSPECTION_REQUEST";
export const INSPECTION_SUCCESS = "INSPECTION_SUCCESS";
export const INSPECTION_ERROR = "INSPECTION_ERROR";

export const DELETE_USERS_REQUEST = "DELETE_USERS_REQUEST";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR";

export const STORE_USER_REQUEST = "STORE_USER_REQUEST";
export const STORE_USER_SUCCESS = "STORE_USER_SUCCESS";
export const STORE_USER_ERROR = "STORE_USER_ERROR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR";

export const USER_PERMISSION_UPDATE_REQUEST = "USER_PERMISSION_UPDATE_REQUEST";
export const USER_PERMISSION_UPDATE_SUCCESS = "USER_PERMISSION_UPDATE_SUCCESS";
export const USER_PERMISSION_UPDATE_ERROR = "USER_PERMISSION_UPDATE_ERROR";

export const USER_ACTIVITY_REQUEST = "USER_ACTIVITY_REQUEST";
export const USER_ACTIVITY_SUCCESS = "USER_ACTIVITY_SUCCESS";
export const USER_ACTIVITY_ERROR = "USER_ACTIVITY_ERROR";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const UPDATE_USERS_REQUEST = "UPDATE_USERS_REQUEST";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_ERROR = "UPDATE_USERS_ERROR";

export const ADD_IMAGE_REQUEST = "ADD_IMAGE_REQUEST";
export const ADD_IMAGE_SUCCESS = "ADD_IMAGE_SUCCESS";
export const ADD_IMAGE_ERROR = "ADD_IMAGE_ERROR";

export const ADD_DOC_IMAGE_REQUEST = "ADD_DOC_IMAGE_REQUEST";
export const ADD_DOC_IMAGE_SUCCESS = "ADD_DOC_IMAGE_SUCCESS";
export const ADD_DOC_IMAGE_ERROR = "ADD_DOC_IMAGE_ERROR";
export const ADD_DOC_IMAGE_DEFAULT = "ADD_DOC_IMAGE_DEFAULT";
export const DELETE_DOC_IMAGE = "DELETE_DOC_IMAGE";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

export const APPROVE_USER_REQUEST = "APPROVE_USER_REQUEST";
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS";
export const APPROVE_USER_ERROR = "APPROVE_USER_ERROR";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
// END USER TYPES //

// START BROADCAST TYPES //
export const LIST_BROADCAST_REQUEST = "LIST_BROADCAST_REQUEST";
export const LIST_BROADCAST_SUCCESS = "LIST_BROADCAST_SUCCESS";
export const LIST_BROADCAST_ERROR = "LIST_BROADCAST_ERROR";
// END BROADCAST TYPES //

// START WASTE MANAGEMENT TYPES //
export const LIST_WASTE_MANAGEMENT_REQUEST = "LIST_WASTE_MANAGEMENT_REQUEST";
export const LIST_WASTE_MANAGEMENT_SUCCESS = "LIST_WASTE_MANAGEMENT_SUCCESS";
export const LIST_WASTE_MANAGEMENT_ERROR = "LIST_WASTE_MANAGEMENT_ERROR";

export const VIEW_WASTE_MANAGEMENT_REQUEST = "VIEW_WASTE_MANAGEMENT_REQUEST";
export const VIEW_WASTE_MANAGEMENT_SUCCESS = "VIEW_WASTE_MANAGEMENT_SUCCESS";
export const VIEW_WASTE_MANAGEMENT_ERROR = "VIEW_WASTE_MANAGEMENT_ERROR";

export const WASTE_PRODUCT_LIST_REQUEST = "WASTE_PRODUCT_LIST_REQUEST";
export const WASTE_PRODUCT_LIST_SUCCESS = "WASTE_PRODUCT_LIST_SUCCESS";
export const WASTE_PRODUCT_LIST_ERROR = "WASTE_PRODUCT_LIST_ERROR";

export const WASTE_CATEGORY_LIST_REQUEST = "WASTE_CATEGORY_LIST_REQUEST";
export const WASTE_CATEGORY_LIST_SUCCESS = "WASTE_CATEGORY_LIST_SUCCESS";
export const WASTE_CATEGORY_LIST_ERROR = "WASTE_CATEGORY_LIST_ERROR";

export const WASTE_UOM_LIST_REQUEST = "WASTE_UOM_LIST_REQUEST";
export const WASTE_UOM_LIST_SUCCESS = "WASTE_UOM_LIST_SUCCESS";
export const WASTE_UOM_LIST_ERROR = "WASTE_UOM_LIST_ERROR";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";

export const EDIT_CATEGORY_WASTE_REQUEST = "EDIT_CATEGORY_WASTE_REQUEST";
export const EDIT_CATEGORY_WASTE_SUCCESS = "EDIT_CATEGORY_WASTE_SUCCESS";
export const EDIT_CATEGORY_WASTE_ERROR = "EDIT_CATEGORY_WASTE_ERROR";

export const ADD_UOM_REQUEST = "ADD_UOM_REQUEST";
export const ADD_UOM_SUCCESS = "ADD_UOM_SUCCESS";
export const ADD_UOM_ERROR = "ADD_UOM_ERROR";

export const EDIT_UOM_REQUEST = "EDIT_UOM_REQUEST";
export const EDIT_UOM_SUCCESS = "EDIT_UOM_SUCCESS";
export const EDIT_UOM_ERROR = "EDIT_UOM_ERROR";

export const EXPORT_PRODUCT_REQUEST = "EXPORT_PRODUCT_REQUEST";
export const EXPORT_PRODUCT_SUCCESS = "EXPORT_PRODUCT_SUCCESS";
export const EXPORT_PRODUCT_ERROR = "EXPORT_PRODUCT_ERROR";

// END WASTE MANAGEMENT TYPES //

// START ISSUE TYPES //
export const ALL_ISSUE_LIST_REQUEST = "ALL_ISSUE_LIST_REQUEST";
export const ALL_ISSUE_LIST_SUCCESS = "ALL_ISSUE_LIST_SUCCESS";
export const ALL_ISSUE_LIST_ERROR = "ALL_ISSUE_LIST_ERROR";

export const ISSUE_LIST_REQUEST = "ISSUE_LIST_REQUEST";
export const ISSUE_LIST_SUCCESS = "ISSUE_LIST_SUCCESS";
export const ISSUE_LIST_ERROR = "ISSUE_LIST_ERROR";

export const LINECHART_ISSUE_REQUEST = "LINECHART_ISSUE_REQUEST";
export const LINECHART_ISSUE_SUCCESS = "LINECHART_ISSUE_SUCCESS";
export const LINECHART_ISSUE_ERROR = "LINECHART_ISSUE_ERROR";

export const ADD_ISSUE_REQUEST = "ADD_ISSUE_REQUEST";
export const ADD_ISSUE_SUCCESS = "ADD_ISSUE_SUCCESS";
export const ADD_ISSUE_ERROR = "ADD_ISSUE_ERROR";

export const ADD_ISSUE_IMAGE_REQUEST = "ADD_ISSUE_IMAGE_REQUEST";
export const ADD_ISSUE_IMAGE_SUCCESS = "ADD_ISSUE_IMAGE_SUCCESS";
export const ADD_ISSUE_IMAGE_ERROR = "ADD_ISSUE_IMAGE_ERROR";

export const CATEGORY_LIST_ISSUE_REQUEST = "CATEGORY_LIST_ISSUE_REQUEST";
export const CATEGORY_LIST_ISSUE_SUCCESS = "CATEGORY_LIST_ISSUE_SUCCESS";
export const CATEGORY_LIST_ISSUE_ERROR = "CATEGORY_LIST_ISSUE_ERROR";

export const CATEGORY_LIST_ISSUE_DEPT_REQUEST =
  "CATEGORY_LIST_ISSUE_DEPT_REQUEST";
export const CATEGORY_LIST_ISSUE_DEPT_SUCCESS =
  "CATEGORY_LIST_ISSUE_DEPT_SUCCESS";
export const CATEGORY_LIST_ISSUE_DEPT_ERROR = "CATEGORY_LIST_ISSUE_DEPT_ERROR";
//Media code here
export const ADD_ISSUE_MEDIA_REQUEST = "ADD_ISSUE_MEDIA_REQUEST";
export const ADD_ISSUE_MEDIA_SUCCESS = "ADD_ISSUE_MEDIA_SUCCESS";
export const ADD_ISSUE_MEDIA_ERROR = "ADD_ISSUE_MEDIA_ERROR";
//Media code end

export const GET_ISSUE_FORMDATA_REQUEST = "GET_ISSUE_FORMDATA_REQUEST";
export const GET_ISSUE_FORMDATA_SUCCESS = "GET_ISSUE_FORMDATA_SUCCESS";
export const GET_ISSUE_FORMDATA_ERROR = "GET_ISSUE_FORMDATA_ERROR";

export const EDIT_ISSUE_REQUEST = "EDIT_ISSUE_REQUEST";
export const EDIT_ISSUE_SUCCESS = "EDIT_ISSUE_SUCCESS";
export const EDIT_ISSUE_ERROR = "EDIT_ISSUE_ERROR";

export const ISSUE_DETAILS_REQUEST = "ISSUE_DETAILS_REQUEST";
export const ISSUE_DETAILS_SUCCESS = "ISSUE_DETAILS_SUCCESS";
export const ISSUE_DETAILS_ERROR = "ISSUE_DETAILS_ERROR";

export const ISSUE_DELETE_REQUEST = "ISSUE_DELETE_REQUEST";
export const ISSUE_DELETE_SUCCESS = "ISSUE_DELETE_SUCCESS";
export const ISSUE_DELETE_ERROR = "ISSUE_DELETE_ERROR";

export const ISSUE_COUNT_REQUEST = "ISSUE_COUNT_REQUEST";
export const ISSUE_COUNT_SUCCESS = "ISSUE_COUNT_SUCCESS";
export const ISSUE_COUNT_ERROR = "ISSUE_COUNT_ERROR";

export const ISSUE_CATEGORY_REQUEST = "ISSUE_CATEGORY_REQUEST";
export const ISSUE_CATEGORY_SUCCESS = "ISSUE_CATEGORY_SUCCESS";
export const ISSUE_CATEGORY_ERROR = "ISSUE_CATEGORY_ERROR";

export const ISSUE_CREATED_REQUEST = "ISSUE_CREATED_REQUEST";
export const ISSUE_CREATED_SUCCESS = "ISSUE_CREATED_SUCCESS";
export const ISSUE_CREATED_ERROR = "ISSUE_CREATED_ERROR";

export const ISSUE_USER_REQUEST = "ISSUE_USER_REQUEST";
export const ISSUE_USER_SUCCESS = "ISSUE_USER_SUCCESS";
export const ISSUE_USER_ERROR = "ISSUE_USER_ERROR";

export const ISSUE_AVERAGE_REQUEST = "ISSUE_AVERAGE_REQUEST";
export const ISSUE_AVERAGE_SUCCESS = "ISSUE_AVERAGE_SUCCESS";
export const ISSUE_AVERAGE_ERROR = "ISSUE_AVERAGE_ERROR";

// END ISSUE TYPES //

// START DEPARTMENT TYPES //
export const LIST_DEPARTMENT_REQUEST = "LIST_DEPARTMENT_REQUEST";
export const LIST_DEPARTMENT_SUCCESS = "LIST_DEPARTMENT_SUCCESS";
export const LIST_DEPARTMENT_ERROR = "LIST_DEPARTMENT_ERROR";

export const DEPARTMENT_DETAILS_REQUEST = "DEPARTMENT_DETAILS_REQUEST";
export const DEPARTMENT_DETAILS_SUCCESS = "DEPARTMENT_DETAILS_SUCCESS";
export const DEPARTMENT_DETAILS_ERROR = "DEPARTMENT_DETAILS_ERROR";

export const CREATE_DEPARTMENT_REQUEST = "CREATE_DEPARTMENT_REQUEST";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_ERROR = "CREATE_DEPARTMENT_ERROR";

export const GET_DEPARTMENT_REQUEST = "GET_DEPARTMENT_REQUEST";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";

export const EDIT_DEPARTMENT_REQUEST = "EDIT_DEPARTMENT_REQUEST";
export const EDIT_DEPARTMENT_SUCCESS = "EDIT_DEPARTMENT_SUCCESS";
export const EDIT_DEPARTMENT_ERROR = "EDIT_DEPARTMENT_ERROR";

export const Top_PERFORMER_REQUEST = "Top_PERFORMER_REQUEST";
export const Top_PERFORMER_SUCCESS = "Top_PERFORMER_SUCCESS";
export const Top_PERFORMER_ERROR = "Top_PERFORMER_ERROR";

export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";
// END DEPARTMENT TYPES //

export const ADD_GROUP_REQUEST = "ADD_GROUP_REQUEST";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_ERROR = "ADD_GROUP_ERROR";

export const ADD_ALLFORM_REQUEST = "ADD_ALLFORM_REQUEST";
export const ADD_ALLFORM_SUCCESS = "ADD_ALLFORM_SUCCESS";
export const ADD_ALLFORM_ERROR = "ADD_ALLFORM_ERROR";

export const ADD_ALLFORMEDIT_REQUEST = "ADD_ALLFORMEDIT_REQUEST";
export const ADD_ALLFORMEDIT_SUCCESS = "ADD_ALLFORMEDIT_SUCCESS";
export const ADD_ALLFORMEDIT_ERROR = "ADD_ALLFORMEDIT_ERROR";

export const ADD_GROUP_DELETE_REQUEST = "ADD_GROUP_DELETE_REQUEST";
export const ADD_GROUP_DELETE_SUCCESS = "ADD_GROUP_DELETE_SUCCESS";
export const ADD_GROUP_DELETE_ERROR = "ADD_GROUP_DELETE_ERROR";

export const ALLFORM_DELETE_REQUEST = "ALLFORM_DELETE_REQUEST";
export const ALLFORM_DELETE_SUCCESS = "ALLFORM_DELETE_SUCCESS";
export const ALLFORM_DELETE_ERROR = "ALLFORM_DELETE_ERROR";

export const ALLFORM_EDITSTORES_UPDATE_REQUEST =
  "ALLFORM_EDITSTORES_UPDATE_REQUEST";
export const ALLFORM_EDITSTORES_UPDATE_SUCCESS =
  "ALLFORM_EDITSTORES_UPDATE_SUCCESS";
export const ALLFORM_EDITSTORES_UPDATE_ERROR =
  "ALLFORM_EDITSTORES_UPDATE_ERROR";

export const ALLFORM_UPDATE_REQUEST = "ALLFORM_UPDATE_REQUEST";
export const ALLFORM_UPDATE_SUCCESS = "ALLFORM_UPDATE_SUCCESS";
export const ALLFORM_UPDATE_ERROR = "ALLFORM_UPDATE_ERROR";

export const ALLFORM_UPDATE_QUESTION_SUCCESS =
  "ALLFORM_UPDATE_QUESTION_SUCCESS";

export const ASSESSMENT_EDITSTORES_UPDATE_REQUEST =
  "ASSESSMENT_EDITSTORES_UPDATE_REQUEST";
export const ASSESSMENT_EDITSTORES_UPDATE_SUCCESS =
  "ASSESSMENT_EDITSTORES_UPDATE_SUCCESS";
export const ASSESSMENT_EDITSTORES_UPDATE_ERROR =
  "ASSESSMENT_EDITSTORES_UPDATE_ERROR";

export const ADD_ASSESSTMENTEDIT_REQUEST = "ADD_ASSESSTMENTEDIT_REQUEST";
export const ADD_ASSESSTMENTEDIT_SUCCESS = "ADD_ASSESSTMENTEDIT_SUCCESS";
export const ADD_ASSESSTMENTEDIT_ERROR = "ADD_ASSESSTMENTEDIT_ERROR";

export const FORM_RULES_UPDATE_ADDED_REQUEST =
  "FORM_RULES_UPDATE_ADDED_REQUEST";
export const FORM_RULES_UPDATE_ADDED_SUCCESS =
  "FORM_RULES_UPDATE_ADDED_SUCCESS";
export const FORM_RULES_UPDATE_ADDED_ERROR = "FORM_RULES_UPDATE_ADDED_ERROR";

export const ADD_GROUP_UPDATE_REQUEST = "ADD_GROUP_UPDATE_REQUEST";
export const ADD_GROUP_UPDATE_SUCCESS = "ADD_GROUP_UPDATE_SUCCESS";
export const ADD_GROUP_UPDATE_ERROR = "ADD_GROUP_UPDATE_ERROR";

export const ADDALLFORMS_UPDATE_REQUEST = "ADDALLFORMS_UPDATE_REQUEST";
export const ADDALLFORMS_UPDATE_SUCCESS = "ADDALLFORMS_UPDATE_SUCCESS";
export const ADDALLFORMS_RESET_SUCCESS = "ADDALLFORMS_RESET_SUCCESS";
export const ADDALLFORMS_UPDATE_ERROR = "ADDALLFORMS_UPDATE_ERROR";

export const ADD_DATA_LIST_REQUEST = "ADD_DATA_LIST_REQUEST";
export const ADD_DATA_LIST_SUCCESS = "ADD_DATA_LIST_SUCCESS";
export const ADD_DATA_LIST_ERROR = "ADD_DATA_LIST_ERROR";

export const DATA_LIST_DELETE_REQUEST = "DATA_LIST_DELETE_REQUEST";
export const DATA_LIST_DELETE_SUCCESS = "DATA_LIST_DELETE_SUCCESS";
export const DATA_LIST_DELETE_ERROR = "DATA_LIST_DELETE_ERROR";

// export const ADD_GROUP_UPDATE_REQUEST = 'ADD_GROUP_UPDATE_REQUEST';
// export const ADD_GROUP_UPDATE_SUCCESS = 'ADD_GROUP_UPDATE_SUCCESS';
// export const ADD_GROUP_UPDATE_ERROR = 'ADD_GROUP_UPDATE_ERROR';
// START WASTE MANAGEMENT TYPES //
export const COMPANY_CATEGORY_LIST_REQUEST = "COMPANY_CATEGORY_LIST_REQUEST";
export const COMPANY_CATEGORY_LIST_SUCCESS = "COMPANY_CATEGORY_LIST_SUCCESS";
export const COMPANY_CATEGORY_LIST_ERROR = "COMPANY_CATEGORY_LIST_ERROR";

export const CREATE_COMPANY_CATEGORY_REQUEST =
  "CREATE_COMPANY_CATEGORY_REQUEST";
export const CREATE_COMPANY_CATEGORY_SUCCESS =
  "CREATE_COMPANY_CATEGORY_SUCCESS";
export const CREATE_COMPANY_CATEGORY_ERROR = "CREATE_COMPANY_CATEGORY_ERROR";

export const ADD_COMPANY_SUBCATEGORY_REQUEST =
  "ADD_COMPANY_SUBCATEGORY_REQUEST";
export const ADD_COMPANY_SUBCATEGORY_SUCCESS =
  "ADD_COMPANY_SUBCATEGORY_SUCCESS";
export const ADD_COMPANY_SUBCATEGORY_ERROR = "ADD_COMPANY_SUBCATEGORY_ERROR";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";

export const EDIT_CATEGORY_REQUEST = "EDIT_CATEGORY_REQUEST";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_ERROR = "EDIT_CATEGORY_ERROR";

export const UPDATE_EDITALLFORMS_REQUEST = "UPDATE_EDITALLFORMS_REQUEST";
export const UPDATE_EDITALLFORMS_SUCCESS = "UPDATE_EDITALLFORMS_SUCCESS";
export const UPDATE_EDITALLFORMS_ERROR = "UPDATE_EDITALLFORMS_ERROR";

export const MODULE_REQUEST = "MODULE_REQUEST";
export const MODULE_SUCCESS = "MODULE_SUCCESS";
export const MODULE_ERROR = "MODULE_ERROR";

export const SUB_MODULE_REQUEST = "SUB_MODULE_REQUEST";
export const SUB_MODULE_SUCCESS = "SUB_MODULE_SUCCESS";
export const SUB_MODULE_ERROR = "SUB_MODULE_ERROR";

// END WASTE MANAGEMENT TYPES //

// Dasboard Types //
export const CHECKLIST_COMPLETED_REQUEST = "CHECKLIST_COMPLETED_REQUEST";
export const CHECKLIST_COMPLETED_SUCCESS = "CHECKLIST_COMPLETED_SUCCESS";
export const CHECKLIST_COMPLETED_ERROR = "CHECKLIST_COMPLETED_ERROR";

export const ISSUE_RAISED_REQUEST = "ISSUE_RAISED_REQUEST";
export const ISSUE_RAISED_SUCCESS = "ISSUE_RAISED_SUCCESS";
export const ISSUE_RAISED_ERROR = "ISSUE_RAISED_ERROR";

export const BROADCAST_MESSAGE_REQUEST = "BROADCAST_MESSAGE_REQUEST";
export const BROADCAST_MESSAGE_SUCCESS = "BROADCAST_MESSAGE_SUCCESS";
export const BROADCAST_MESSAGE_ERROR = "BROADCAST_MESSAGE_ERROR";

export const ADD_BROADCAST_REQUEST = "ADD_BROADCAST_REQUEST";
export const ADD_BROADCAST_SUCCESS = "ADD_BROADCAST_SUCCESS";
export const ADD_BROADCAST_ERROR = "ADD_BROADCAST_ERROR";

export const UPCOMING_SCHEDULES_REQUEST = "UPCOMING_SCHEDULES_REQUEST";
export const UPCOMING_SCHEDULES_SUCCESS = "UPCOMING_SCHEDULES_SUCCESS";
export const UPCOMING_SCHEDULES_ERROR = "UPCOMING_SCHEDULES_ERROR";

export const DUE_SCHEDULES_REQUEST = "DUE_SCHEDULES_REQUEST";
export const DUE_SCHEDULES_SUCCESS = "DUE_SCHEDULES_SUCCESS";
export const DUE_SCHEDULES_ERROR = "DUE_SCHEDULES_ERROR";

export const CHECKLIST_REPORTDATA_REQUEST = "CHECKLIST_REPORTDATA_REQUEST";
export const CHECKLIST_REPORTDATA_SUCCESS = "CHECKLIST_REPORTDATA_SUCCESS";
export const CHECKLIST_REPORTDATA_ERROR = "CHECKLIST_REPORTDATA_ERROR";

export const CHECKLIST_CHART_REQUEST = "CHECKLIST_CHART_REQUEST";
export const CHECKLIST_CHART_SUCCESS = "CHECKLIST_CHART_SUCCESS";
export const CHECKLIST_CHART_ERROR = "CHECKLIST_CHART_ERROR";

// END DEPARTMENT TYPES //

// Dasboard Types //
export const CATEGORY_DATA_REQUEST = "CATEGORY_DATA_REQUEST";
export const CATEGORY_DATA_SUCCESS = "CATEGORY_DATA_SUCCESS";
export const CATEGORY_DATA_ERROR = "CATEGORY_DATA_ERROR";

// END DEPARTMENT TYPES //

export const RECENTLY_DATA_REQUEST = "RECENTLY_DATA_REQUEST";
export const RECENTLY_DATA_SUCCESS = "RECENTLY_DATA_SUCCESS";
export const RECENTLY_DATA_ERROR = "RECENTLY_DATA_ERROR";

export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";

export const PROFILE_DATA_REQUEST = "PROFILE_DATA_REQUEST";
export const PROFILE_DATA_SUCCESS = "PROFILE_DATA_SUCCESS";
export const PROFILE_DATA_ERROR = "PROFILE_DATA_ERROR";

export const DELETE_FORM_REQUEST = "DELETE_FORM_REQUEST";
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS";
export const DELETE_FORM_ERROR = "DELETE_FORM_ERROR";

export const SUBROLE_FORM_REQUEST = "SUBROLE_FORM_REQUEST";
export const SUBROLE_FORM_SUCCESS = "SUBROLE_FORM_SUCCESS";
export const SUBROLE_FORM_ERROR = "SUBROLE_FORM_ERROR";

export const MISSEDCHECKLIST_FORM_REQUEST = "MISSEDCHECKLIST_FORM_REQUEST";
export const MISSEDCHECKLIST_SUCCESS = "MISSEDCHECKLIST_SUCCESS";
export const MISSEDCHECKLIST_ERROR = "MISSEDCHECKLIST_ERROR";

export const MONTHWISE_DATA_REQUEST = "MONTHWISE_DATA_REQUEST";
export const MONTHWISE_DATA_SUCCESS = "MONTHWISE_DATA__SUCCESS";
export const MONTHWISE_DATA_ERROR = "MONTHWISE_DATA_ERROR";

export const ALLCHECKLIST_ARCHIVED_REQUEST = "ALLCHECKLIST_ARCHIVED_REQUEST";
export const ALLCHECKLIST_ARCHIVED_SUCCESS = "ALLCHECKLIST_ARCHIVED_SUCCESS";
export const ALLCHECKLIST_ARCHIVED_ERROR = "ALLCHECKLIST_ARCHIVED_ERROR";

// CUSTOMER LOGS
export const LIST_CUSTOMER_LOG_REQUEST = "LIST_CUSTOMER_LOG_REQUEST";
export const LIST_CUSTOMER_LOG_SUCCESS = "LIST_CUSTOMER_LOG_SUCCESS";
export const LIST_CUSTOMER_LOG_ERROR = "LIST_CUSTOMER_LOG_ERROR";

export const CUSTOMER_LOG_VIEW_REQUEST = "CUSTOMER_LOG_VIEW_REQUEST";
export const CUSTOMER_LOG_VIEW_SUCCESS = "CUSTOMER_LOG_VIEW_SUCCESS";
export const CUSTOMER_LOG_VIEW_ERROR = "CUSTOMER_LOG_VIEW_ERROR";

export const DELETE_CUSTOMER_LOG_REQUEST = "DELETE_CUSTOMER_LOG_REQUEST";
export const DELETE_CUSTOMER_LOG_SUCCESS = "DELETE_CUSTOMER_LOG_SUCCESS";
export const DELETE_CUSTOMER_LOG_ERROR = "DELETE_CUSTOMER_LOG_ERROR";

export const CUSTOMER_LOG_STATUS_REQUEST = "CUSTOMER_LOG_STATUS_REQUEST";
export const CUSTOMER_LOG_STATUS_SUCCESS = "CUSTOMER_LOG_STATUS_SUCCESS";
export const CUSTOMER_LOG_STATUS_ERROR = "CUSTOMER_LOG_STATUS_ERROR";

export const CUSTOMER_LOG_NO_PAGI_REQUEST = "CUSTOMER_LOG_NO_PAGI_REQUEST";
export const CUSTOMER_LOG_NO_PAGI_SUCCESS = "CUSTOMER_LOG_NO_PAGI_SUCCESS";
export const CUSTOMER_LOG_NO_PAGI_ERROR = "CUSTOMER_LOG_NO_PAGI_ERROR";

export const CSV_SUBMISSION_REQUEST = "CSV_SUBMISSION_REQUEST";
export const CSV_SUBMISSION_SUCCESS = "CSV_SUBMISSION_SUCCESS";
export const CSV_SUBMISSION_ERROR = "CSV_SUBMISSION_ERROR";

export const CSV_NAM_SUBMISSION_REQUEST = "CSV_NAM_SUBMISSION_REQUEST";
export const CSV_NAM_SUBMISSION_SUCCESS = "CSV_NAM_SUBMISSION_SUCCESS";
export const CSV_NAM_SUBMISSION_ERROR = "CSV_NAM_SUBMISSION_ERROR";

export const CSV_USER_SUBMISSION_REQUEST = "CSV_USER_SUBMISSION_REQUEST";
export const CSV_USER_SUBMISSION_SUCCESS = "CSV_USER_SUBMISSION_SUCCESS";
export const CSV_USER_SUBMISSION_ERROR = "CSV_USER_SUBMISSION_ERROR";

export const CSV_NAM_SUBMISSION_USER_WISE_REQUEST =
  "CSV_NAM_SUBMISSION_USER_WISE_REQUEST";
export const CSV_NAM_SUBMISSION_USER_WISE_SUCCESS =
  "CSV_NAM_SUBMISSION_USER_WISE_SUCCESS";
export const CSV_NAM_SUBMISSION_USER_WISE_ERROR =
  "CSV_NAM_SUBMISSION_USER_WISE_ERROR";

export const INTENT_CSV_NAM_SUBMISSION_REQUEST =
  "INTENT_CSV_NAM_SUBMISSION_REQUEST";
export const INTENT_CSV_NAM_SUBMISSION_SUCCESS =
  "INTENT_CSV_NAM_SUBMISSION_SUCCESS";
export const INTENT_CSV_NAM_SUBMISSION_ERROR =
  "INTENT_CSV_NAM_SUBMISSION_ERROR";

export const EXCEL_CHECKLIST_DETAIL_REQUEST = "EXCEL_CHECKLIST_DETAIL_REQUEST";
export const EXCEL_CHECKLIST_DETAIL_SUCCESS = "EXCEL_CHECKLIST_DETAIL_SUCCESS";
export const EXCEL_CHECKLIST_DETAIL_ERROR = "EXCEL_CHECKLIST_DETAIL_ERROR";

export const USER_ARCHIVED_REQUEST = "USER_ARCHIVED_REQUEST";
export const USER_ARCHIVED_SUCCESS = "USER_ARCHIVED_SUCCESS";
export const USER_ARCHIVED_ERROR = "USER_ARCHIVED_ERROR";

export const FORMLIST_ARCHIVED_REQUEST = "FORMLIST_ARCHIVED_REQUEST";
export const FORMLIST_ARCHIVED_SUCCESS = "FORMLIST_ARCHIVED_SUCCESS";
export const FORMLIST_ARCHIVED_ERROR = "FORMLIST_ARCHIVED_ERROR";

export const DEPARTMENT_ARCHIVED_REQUEST = "DEPARTMENT_ARCHIVED_REQUEST";
export const DEPARTMENT_ARCHIVED_SUCCESS = "DEPARTMENT_ARCHIVED_SUCCESS";
export const DEPARTMENT_ARCHIVED_ERROR = "DEPARTMENTFORMLIST_ARCHIVED_ERROR";

export const ISSUE_ARCHIVED_REQUEST = "ISSUE_ARCHIVED_REQUEST";
export const ISSUE_ARCHIVED_SUCCESS = "ISSUE_ARCHIVED_SUCCESS";
export const ISSUE_ARCHIVED_ERROR = "ISSUE_ARCHIVED_ERROR";

export const ISSUE_DEPARTMENT_LIST_REQUEST = "ISSUE_DEPARTMENT_LIST_REQUEST";
export const ISSUE_DEPARTMENT_LIST_SUCCESS = "ISSUE_DEPARTMENT_LIST_SUCCESS";
export const ISSUE_DEPARTMENT_LIST_ERROR = "ISSUE_DEPARTMENT_LIST_ERROR";

// LMS MODULE
export const LMS_LISTING_DETAILS_REQUEST = "LMS_LISTING_DETAILS_REQUEST";
export const LMS_LISTING_DETAILS_SUCCESS = "LMS_LISTING_DETAILS_SUCCESS";
export const LMS_LISTING_DETAILS_ERROR = "LMS_LISTING_DETAILS_ERROR";

export const LMS_LISTING_DETAILS_REQUEST1 = "LMS_LISTING_DETAILS_REQUEST1";
export const LMS_LISTING_DETAILS_SUCCESS1 = "LMS_LISTING_DETAILS_SUCCESS1";
export const LMS_LISTING_DETAILS_ERROR1 = "LMS_LISTING_DETAILS_ERROR1";

//LMS CERTIFICATE
export const LIST_CERTIFICATE_REQUEST = "LIST_CERTIFICATE_REQUEST";
export const LIST_CERTIFICATE_SUCCESS = "LIST_CERTIFICATE_SUCCESS";
export const LIST_CERTIFICATE_ERROR = "LIST_CERTIFICATE_ERROR";

export const ADD_CERTIFICATE_REQUEST = "ADD_CERTIFICATE_REQUEST";
export const ADD_CERTIFICATE_SUCCESS = "ADD_CERTIFICATE_SUCCESS";
export const ADD_CERTIFICATE_ERROR = "ADD_CERTIFICATE_ERROR";

export const ADD_CERTIFICATE_IMAGE_REQUEST_RESET =
  "ADD_CERTIFICATE_IMAGE_REQUEST_RESET";
export const ADD_CERTIFICATE_IMAGE_REQUEST = "ADD_CERTIFICATE_IMAGE_REQUEST";
export const ADD_CERTIFICATE_IMAGE_SUCCESS = "ADD_CERTIFICATE_IMAGE_SUCCESS";
export const ADD_CERTIFICATE_IMAGE_ERROR = "ADD_CERTIFICATE_IMAGE_ERROR";

export const ADD_TRAINING_IMAGE_REQUEST = "ADD_TRAINING_IMAGE_REQUEST";
export const ADD_TRAINING_IMAGE_SUCCESS = "ADD_TRAINING_IMAGE_SUCCESS";
export const ADD_TRAINING_IMAGE_ERROR = "ADD_TRAINING_IMAGE_ERROR";

export const GET_CERTIFICATE_DATA_REQUEST = "GET_CERTIFICATE_DATA_REQUEST";
export const GET_CERTIFICATE_DATA_SUCCESS = "GET_CERTIFICATE_DATA_SUCCESS";
export const GET_CERTIFICATE_DATA_ERROR = "GET_CERTIFICATE_DATA_ERROR";

export const EDIT_CERTIFICATE_REQUEST = "EDIT_CERTIFICATE_REQUEST";
export const EDIT_CERTIFICATE_SUCCESS = "EDIT_CERTIFICATE_SUCCESS";
export const EDIT_CERTIFICATE_ERROR = "EDIT_CERTIFICATE_ERROR";

export const DELETE_CERTIFICATE_REQUEST = "DELETE_CERTIFICATE_REQUEST";
export const DELETE_CERTIFICATE_SUCCESS = "DELETE_CERTIFICATE_SUCCESS";
export const DELETE_CERTIFICATE_ERROR = "DELETE_CERTIFICATE_ERROR";

export const PROFILE_ME_REQUEST = "PROFILE_ME_REQUEST";
export const PROFILE_ME_SUCCESS = "PROFILE_ME_SUCCESS";
export const PROFILE_ME_ERROR = "PROFILE_ME_ERROR";

export const UPDATE_MY_PROFILE_REQUEST = "UPDATE_MY_PROFILE_REQUEST";
export const UPDATE_MY_PROFILE_SUCCESS = "UPDATE_MY_PROFILE_SUCCESS";
export const UPDATE_MY_PROFILE_ERROR = "UPDATE_MY_PROFILE_ERROR";

export const CHANGE_MY_PROFILE_PASSWORD_REQUEST =
  "CHANGE_MY_PROFILE_PASSWORD_REQUEST";
export const CHANGE_MY_PROFILE_PASSWORD_SUCCESS =
  "CHANGE_MY_PROFILE_PASSWORD_SUCCESS";
export const CHANGE_MY_PROFILE_PASSWORD_ERROR =
  "CHANGE_MY_PROFILE_PASSWORD_ERROR";

export const UPDATE_MY_PROFILE_PICTURE_REQUEST =
  "UPDATE_MY_PROFILE_PICTURE_REQUEST";
export const UPDATE_MY_PROFILE_PICTURE_SUCCESS =
  "UPDATE_MY_PROFILE_PICTURE_SUCCESS";
export const UPDATE_MY_PROFILE_PICTURE_ERROR =
  "UPDATE_MY_PROFILE_PICTURE_ERROR";

export const UPDATE_PREFERED_COMPANY_REQUEST =
  "UPDATE_PREFERED_COMPANY_REQUEST";
export const UPDATE_PREFERED_COMPANY_SUCCESS =
  "UPDATE_PREFERED_COMPANY_SUCCESS";
export const UPDATE_PREFERED_COMPANY_ERROR = "UPDATE_PREFERED_COMPANY_ERROR";

export const MOBILE_ACCESS_REQUEST = "MOBILE_ACCESS_REQUEST";
export const MOBILE_ACCESS_SUCCESS = "MOBILE_ACCESS_SUCCESS";
export const MOBILE_ACCESS_ERROR = "MOBILE_ACCESS_ERROR";

export const CHECKLIST_IMAGE_REQUEST = "CHECKLIST_IMAGE_REQUEST";
export const CHECKLIST_IMAGE_SUCCESS = "CHECKLIST_IMAGE_SUCCESS";
export const CHECKLIST_IMAGE_ERROR = "CHECKLIST_IMAGE_ERROR";

export const ADD_CHECKLIST_IMAGE_REQUEST = "ADD_CHECKLIST_IMAGE_REQUEST";
export const ADD_CHECKLIST_IMAGE_SUCCESS = "ADD_CHECKLIST_IMAGE_SUCCESS";
export const ADD_CHECKLIST_IMAGE_ERROR = "ADD_CHECKLIST_IMAGE_ERROR";

export const ADD_ASSESSMENT_IMAGE_REQUEST = "ADD_ASSESSMENT_IMAGE_REQUEST";
export const ADD_ASSESSMENT_IMAGE_SUCCESS = "ADD_ASSESSMENT_IMAGE_SUCCESS";
export const ADD_ASSESSMENT_IMAGE_ERROR = "ADD_ASSESSMENT_IMAGE_ERROR";
export const ADD_ASSESSMENT_IMAGE_CLEAR = "ADD_ASSESSMENT_IMAGE_CLEAR";
export const DELETE_ASSESSMENT_IMAGE = "DELETE_ASSESSMENT_IMAGE";

export const ASSESSMENT_LIST_REQUEST = "ASSESSMENT_LIST_REQUEST";
export const ASSESSMENT_LIST_SUCCESS = "ASSESSMENT_LIST_SUCCESS";
export const ASSESSMENT_LIST_ERROR = "ASSESSMENT_LIST_ERROR";

export const ADD_ASSESSMENT_REQUEST = "ADD_ASSESSMENT_REQUEST";
export const ADD_ASSESSMENT_SUCCESS = "ADD_ASSESSMENT_SUCCESS";
export const ADD_ASSESSMENT_ERROR = "ADD_ASSESSMENT_ERROR";

export const UPDATE_ASSESSMENT_REQUEST = "UPDATE_ASSESSMENT_REQUEST";
export const UPDATE_ASSESSMENT_SUCCESS = "UPDATE_ASSESSMENT_SUCCESS";
export const UPDATE_ASSESSMENT_ERROR = "UPDATE_ASSESSMENT_ERROR";

export const ASSESSMENT_DELETE_REQUEST = "ASSESSMENT_DELETE_REQUEST";
export const ASSESSMENT_DELETE_SUCCESS = "ASSESSMENT_DELETE_SUCCESS";
export const ASSESSMENT_DELETE_ERROR = "ASSESSMENT_DELETE_ERROR";

export const RATING_REQUEST = "RATING_REQUEST";
export const RATING_SUCCESS = "RATING_SUCCESS";
export const RATING_ERROR = "RATING_ERROR";

export const CREATE_IMAGELOG_REQUEST = "CREATE_IMAGELOG_REQUEST";
export const CREATE_IMAGELOG_SUCCESS = "CREATE_IMAGELOG_SUCCESS";
export const CREATE_IMAGELOG_ERROR = "CREATE_IMAGELOG_ERROR";

export const LMS_REPORT_REQUEST = "LMS_REPORT_REQUEST";
export const LMS_REPORT_SUCCESS = "LMS_REPORT_SUCCESS";
export const LMS_REPORT_ERROR = "LMS_REPORT_ERROR";

export const TRAINING_BY_USER_REQUEST = "TRAINING_BY_USER_REQUEST";
export const TRAINING_BY_USER_SUCCESS = "TRAINING_BY_USER_SUCCESS";
export const TRAINING_BY_USER_ERROR = "TRAINING_BY_USER_ERROR";

export const ASSESSMENTLIST_UPDATE_REQUEST = "ASSESSMENTLIST_UPDATE_REQUEST";
export const ASSESSMENTLIST_UPDATE_SUCCESS = "ASSESSMENTLIST_UPDATE_SUCCESS";
export const ASSESSMENTLIST_UPDATE_ERROR = "ASSESSMENTLIST_UPDATE_ERROR";
export const CERTIFICATE_ARCHIVED_REQUEST = "CERTIFICATE_ARCHIVED_REQUEST";
export const CERTIFICATE_ARCHIVED_ERROR = "CERTIFICATE_ARCHIVED_ERROR";
export const CERTIFICATE_ARCHIVED_SUCCESS = "CERTIFICATE_ARCHIVED_SUCCESS";

export const ASSESSMENT_ARCHIVED_REQUEST = "ASSESSMENT_ARCHIVED_REQUEST";
export const ASSESSMENT_ARCHIVED_ERROR = "ASSESSMENT_ARCHIVED_ERROR";
export const ASSESSMENT_ARCHIVED_SUCCESS = "ASSESSMENT_ARCHIVED_SUCCESS";

export const TRAINING_ARCHIVED_REQUEST = "TRAINING_ARCHIVED_REQUEST";
export const TRAINING_ARCHIVED_ERROR = "TRAINING_ARCHIVED_ERROR";
export const TRAINING_ARCHIVED_SUCCESS = "TRAINING_ARCHIVED_SUCCESS";

export const TRAINING_BY_STORE_REQUEST = "TRAINING_BY_STORE_REQUEST";
export const TRAINING_BY_STORE_SUCCESS = "TRAINING_BY_STORE_SUCCESS";
export const TRAINING_BY_STORE_ERROR = "TRAINING_BY_STORE_ERROR";

export const LMS_TRAINING_CHART_REQUEST = "LMS_TRAINING_CHART_REQUEST";
export const LMS_TRAINING_CHART_SUCCESS = "LMS_TRAINING_CHART_SUCCESS";
export const LMS_TRAINING_CHART_ERROR = "LMS_TRAINING_CHART_ERROR";

export const LMS_RECENT_COURSE_REQUEST = "LMS_RECENT_COURSE_REQUEST";
export const LMS_RECENT_COURSE_SUCCESS = "LMS_RECENT_COURSE_SUCCESS";
export const LMS_RECENT_COURSE_ERROR = "LMS_RECENT_COURSE_ERROR";

export const LMS_TRAINING_REQUEST = "LMS_TRAINING_REQUEST";
export const LMS_TRAINING_SUCCESS = "LMS_TRAINING_SUCCESS";
export const LMS_TRAINING_FAILED = "LMS_TRAINING_FAILED";

export const LMS_DETAIL_VIEW_REQUEST = "LMS_DETAIL_VIEW_REQUEST";
export const LMS_DETAIL_VIEW_SUCCESS = "LMS_DETAIL_VIEW_SUCCESS";
export const LMS_DETAIL_VIEW_ERROR = "LMS_DETAIL_VIEW_ERROR";

export const C_R_LEFT_GRAPH_REQUEST = "C_R_LEFT_GRAPH_REQUEST";
export const C_R_LEFT_GRAPH_SUCCESS = "C_R_LEFT_GRAPH_SUCCESS";
export const C_R_LEFT_GRAPH_ERROR = "C_R_LEFT_GRAPH_ERROR";

export const C_R_GRAPH_REQUEST = "C_R_GRAPH_REQUEST";
export const C_R_GRAPH_SUCCESS = "C_R_GRAPH_SUCCESS";
export const C_R_GRAPH_ERROR = "C_R_GRAPH_ERROR";

export const UPC_REQUEST = "UPC_REQUEST";
export const UPC_SUCCESS = "UPC_SUCCESS";
export const UPC_ERROR = "UPC_ERROR";

export const CSC_REQUEST = "CSC_REQUEST";
export const CSC_SUCCESS = "CSC_SUCCESS";
export const CSC_ERROR = "CSC_ERROR";

export const S_W_U_C_R_REQUEST = "S_W_U_C_R_REQUEST";
export const S_W_U_C_R_SUCCESS = "S_W_U_C_R_SUCCESS";
export const S_W_U_C_R_ERROR = "S_W_U_C_R_ERROR";

export const S_W_S_U_C_R_REQUEST = "S_W_S_U_C_R_REQUEST";
export const S_W_S_U_C_R_SUCCESS = "S_W_S_U_C_R_SUCCESS";
export const S_W_S_U_C_R_ERROR = "S_W_S_U_C_R_ERROR";

export const S_W_U_C_G_C_REQUEST = "S_W_U_C_G_C_REQUEST";
export const S_W_U_C_G_C_SUCCESS = "S_W_U_C_G_C_SUCCESS";
export const S_W_U_C_G_C_ERROR = "S_W_U_C_G_C_ERROR";

export const S_W_S_U_G_C_R_REQUEST = "S_W_S_U_G_C_R_REQUEST";
export const S_W_S_U_G_C_R_SUCCESS = "S_W_S_U_G_C_R_SUCCESS";
export const S_W_S_U_G_C_R_ERROR = "S_W_S_U_G_C_R_ERROR";

export const DELETE_BROADCAST_REQUEST = "DELETE_BROADCAST_REQUEST";
export const DELETE_BROADCAST_SUCCESS = "DELETE_BROADCAST_SUCCESS";
export const DELETE_BROADCAST_ERROR = "DELETE_BROADCAST_ERROR";

export const VIEW_BROADCAST_REQUEST = "VIEW_BROADCAST_REQUEST";
export const VIEW_BROADCAST_SUCCESS = "VIEW_BROADCAST_SUCCESS";
export const VIEW_BROADCAST_ERROR = "VIEW_BROADCAST_ERROR";
export const LOGGEDIN_USER_REQUEST = "LOGGEDIN_USER_REQUEST";
export const LOGGEDIN_USER_SUCCESS = "LOGGEDIN_USER_SUCCESS";
export const LOGGEDIN_USER_ERROR = "LOGGEDIN_USER_ERROR";

export const GET_STOREWISECHECKLISTREPORT_SUCCESS =
  "GET_STOREWISECHECKLISTREPORT_SUCCESS";
export const GET_STOREWISECHECKLISTREPORT_ERROR =
  "GET_STOREWISECHECKLISTREPORT_ERROR";
export const GET_STOREWISECHECKLISTREPORT_REQUEST =
  "GET_STOREWISECHECKLISTREPORT_REQUEST";

export const ALLACTIVECHECKLIST_LIST_REQUEST =
  "ALLACTIVECHECKLIST_LIST_REQUEST";
export const ALLACTIVECHECKLIST_LIST_SUCCESS =
  "ALLACTIVECHECKLIST_LIST_SUCCESS";
export const ALLACTIVECHECKLIST_LIST_ERROR = "ALLACTIVECHECKLIST_LIST_ERROR";

export const GENERATE_AUDIT_REQUEST = "GENERATE_AUDIT_REQUEST";
export const GENERATE_AUDIT_SUCCESS = "GENERATE_AUDIT_SUCCESS";
export const GENERATE_AUDIT_ERROR = "GENERATE_AUDIT_ERROR";

export const TDR_VIDEO_TYPE_REQUEST = "TDR_VIDEO_TYPE_REQUEST";
export const TDR_VIDEO_TYPE_SUCCESS = "TDR_VIDEO_TYPE_SUCCESS";
export const TDR_VIDEO_TYPE_ERROR = "TDR_VIDEO_TYPE_ERROR";

export const TDR_VIDEO_TYPE_REQUEST1 = "TDR_VIDEO_TYPE_REQUEST1";
export const TDR_VIDEO_TYPE_SUCCESS1 = "TDR_VIDEO_TYPE_SUCCESS1";
export const TDR_VIDEO_TYPE_ERROR1 = "TDR_VIDEO_TYPE_ERROR1";

export const ON_GOING_REQUEST = "ON_GOING_REQUEST";
export const ON_GOING_SUCCESS = "ON_GOING_SUCCESS";
export const ON_GOING_ERROR = "ON_GOING_ERROR";

export const COMPLETED_COURSE_REQUEST = "COMPLETED_COURSE_REQUEST";
export const COMPLETED_COURSE_SUCCESS = "COMPLETED_COURSE_SUCCESS";
export const COMPLETED_COURSE_ERROR = "COMPLETED_COURSE_ERROR";

export const ACTIVELMSREPORT_REQUEST = "ACTIVELMSREPORT_REQUEST";
export const ACTIVELMSREPORT_SUCCESS = "ACTIVELMSREPORT_SUCCESS";
export const ACTIVELMSREPORT_ERROR = "ACTIVELMSREPORT_ERROR";

export const CATEGORY_LIST_LMS_REQUEST = "CATEGORY_LIST_LMS_REQUEST";
export const CATEGORY_LIST_LMS_SUCCESS = "CATEGORY_LIST_LMS_SUCCESS";
export const CATEGORY_LIST_LMS_ERROR = "CATEGORY_LIST_LMS_ERROR";

export const UPLOAD_IMG_ADD_QUES_REQUEST = "UPLOAD_IMG_ADD_QUES_REQUEST";
export const UPLOAD_IMG_ADD_QUES_SUCCESS = "UPLOAD_IMG_ADD_QUES_SUCCESS";
export const UPLOAD_IMG_ADD_QUES_ERROR = "UPLOAD_IMG_ADD_QUES_ERROR";

export const UPLOAD_MEDIA_REQUEST = "UPLOAD_MEDIA_REQUEST";
export const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
export const UPLOAD_MEDIA_ERROR = "UPLOAD_MEDIA_ERROR";

export const UPLOAD_IMG_EDIT_QUES_REQUEST = "UPLOAD_IMG_EDIT_QUES_REQUEST";
export const UPLOAD_IMG_EDIT_QUES_SUCCESS = "UPLOAD_IMG_EDIT_QUES_SUCCESS";
export const UPLOAD_IMG_EDIT_QUES_ERROR = "UPLOAD_IMG_EDIT_QUES_ERROR";

export const SCHEDULAR_LIST_REQUEST = "SCHEDULAR_LIST_REQUEST";
export const SCHEDULAR_LIST_SUCCESS = "SCHEDULAR_LIST_SUCCESS";
export const SCHEDULAR_LIST_ERROR = "SCHEDULAR_LIST_ERROR";

export const SCHEDULAR_ADD_REQUEST = "SCHEDULAR_ADD_REQUEST";
export const SCHEDULAR_ADD_SUCCESS = "SCHEDULAR_ADD_SUCCESS";
export const SCHEDULAR_ADD_ERROR = "SCHEDULAR_ADD_ERROR";

export const SCHEDULAR_EDIT_REQUEST = "SCHEDULAR_EDIT_REQUEST";
export const SCHEDULAR_EDIT_SUCCESS = "SCHEDULAR_EDIT_SUCCESS";
export const SCHEDULAR_EDIT_ERROR = "SCHEDULAR_EDIT_ERROR";

export const SCHEDULAR_DELETE_REQUEST = "SCHEDULAR_DELETE_REQUEST";
export const SCHEDULAR_DELETE_SUCCESS = "SCHEDULAR_DELETE_SUCCESS";
export const SCHEDULAR_DELETE_ERROR = "SCHEDULAR_DELETE_ERROR";

export const GENERATE_PDF_REQUEST = "GENERATE_PDF_REQUEST";
export const GENERATE_PDF_SUCCESS = "GENERATE_PDF_SUCCESS";
export const GENERATE_PDF_ERROR = "GENERATE_PDF_ERROR";

export const DELATE_ALLCHECKLIST_REQUEST = "DELATE_ALLCHECKLIST_REQUEST";
export const DELATE_ALLCHECKLIST_SUCCESS = "DELATE_ALLCHECKLIST_SUCCESS";
export const DELATE_ALLCHECKLIST_ERROR = "DELATE_ALLCHECKLIST_ERROR";

export const ACTIVE_ALLCHECKLIST_REQUEST = "ACTIVE_ALLCHECKLIST_REQUEST";
export const ACTIVE_ALLCHECKLIST_SUCCESS = "ACTIVE_ALLCHECKLIST_SUCCESS";
export const ACTIVE_ALLCHECKLIST_ERROR = "ACTIVE_ALLCHECKLIST_ERROR";

export const PENDING_ALLCHECKLIST_REQUEST = "PENDING_ALLCHECKLIST_REQUEST";
export const PENDING_ALLCHECKLIST_SUCCESS = "PENDING_ALLCHECKLIST_SUCCESS";
export const PENDING_ALLCHECKLIST_ERROR = "PENDING_ALLCHECKLIST_ERROR";

export const CHECKLIST_HOME_REQUEST = "CHECKLIST_HOME_REQUEST";
export const CHECKLIST_HOME_SUCCESS = "CHECKLIST_HOME_SUCCESS";
export const CHECKLIST_HOME_ERROR = "CHECKLIST_HOME_ERROR";

export const UPCOMING_REQUEST = "UPCOMING_REQUEST";
export const UPCOMING_SUCCESS = "UPCOMING_SUCCESS";
export const UPCOMING_ERROR = "UPCOMING_ERROR";

export const REMAINING_REQUEST = "REMAINING_REQUEST";
export const REMAINING_SUCCESS = "REMAINING_SUCCESS";
export const REMAINING_ERROR = "REMAINING_ERROR";

export const COMPLETED_REQUEST = "COMPLETED_REQUEST";
export const COMPLETED_SUCCESS = "COMPLETED_SUCCESS";
export const COMPLETED_ERROR = "COMPLETED_ERROR";

export const MISSED_REQUEST = "MISSED_REQUEST";
export const MISSED_SUCCESS = "MISSED_SUCCESS";
export const MISSED_ERROR = "MISSED_ERROR";

export const QUEUE_REQUEST = "QUEUE_REQUEST";
export const QUEUE_SUCCESS = "QUEUE_SUCCESS";
export const QUEUE_ERROR = "QUEUE_ERROR";

export const AUDITOR_CHECKLIST_SUBMISSION_REQUEST =
  "AUDITOR_CHECKLIST_SUBMISSION_REQUEST";
export const AUDITOR_CHECKLIST_SUBMISSION_SUCCESS =
  "AUDITOR_CHECKLIST_SUBMISSION_SUCCESS";
export const AUDITOR_CHECKLIST_SUBMISSION_ERROR =
  "AUDITOR_CHECKLIST_SUBMISSION_ERROR";

export const DELETE_STORE_REQUEST = "DELETE_STORE_REQUEST";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_ERROR = "DELETE_STORE_ERROR";

export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";

export const PAYMENT_DONE_REQUEST = "PAYMENT_DONE_REQUEST";
export const PAYMENT_DONE_SUCCESS = "PAYMENT_DONE_SUCCESS";
export const PAYMENT_DONE_ERROR = "PAYMENT_DONE_ERROR";

export const COMPANY_INFO_DETAIL_REQUEST = "COMPANY_INFO_DETAIL_REQUEST";
export const COMPANY_INFO_DETAIL_SUCCESS = "COMPANY_INFO_DETAIL_SUCCESS";
export const COMPANY_INFO_DETAIL_ERROR = "COMPANY_INFO_DETAIL_ERROR";

export const PAYMENT_HISTORY_LIST_REQUEST = "PAYMENT_HISTORY_LIST_REQUEST";
export const PAYMENT_HISTORY_LIST_SUCCESS = "PAYMENT_HISTORY_LIST_SUCCESS";
export const PAYMENT_HISTORY_LIST_ERROR = "PAYMENT_HISTORY_LIST_ERROR";

export const PAYMENT_HISTORY_REQUEST = "PAYMENT_HISTORY_REQUEST";
export const PAYMENT_HISTORY_SUCCESS = "PAYMENT_HISTORY_SUCCESS";
export const PAYMENT_HISTORY_ERROR = "PAYMENT_HISTORY_ERROR";

export const MANUAL_PAYMENT_REQUEST = "MANUAL_PAYMENT_REQUEST";
export const MANUAL_PAYMENT_SUCCESS = "MANUAL_PAYMENT_SUCCESS";
export const MANUAL_PAYMENT_ERROR = "MANUAL_PAYMENT_ERROR";

export const PLAN_DETAILS_REQUEST = "PLAN_DETAILS_REQUEST";
export const PLAN_DETAILS_SUCCESS = "PLAN_DETAILS_SUCCESS";
export const PLAN_DETAILS_ERROR = "PLAN_DETAILS_ERROR";

export const SUBMIT_ACCOUNT_DETAILS_REQUEST = "SUBMIT_ACCOUNT_DETAILS_REQUEST";
export const SUBMIT_ACCOUNT_DETAILS_SUCCESS = "SUBMIT_ACCOUNT_DETAILS_SUCCESS";
export const SUBMIT_ACCOUNT_DETAILS_ERROR = "SUBMIT_ACCOUNT_DETAILS_ERROR";

export const ACCOUNT_DETAILS_REQUEST = "ACCOUNT_DETAILS_REQUEST";
export const ACCOUNT_DETAILS_SUCCESS = "ACCOUNT_DETAILS_SUCCESS";
export const ACCOUNT_DETAILS_ERROR = "ACCOUNT_DETAILS_ERROR";

export const ALL_PLANS_REQUEST = "ALL_PLANS_REQUEST";
export const ALL_PLANS_SUCCESS = "ALL_PLANS_SUCCESS";
export const ALL_PLANS_ERROR = "ALL_PLANS_ERROR";

export const UPDATE_COMPANY_EXP_DATE_REQUEST =
  "UPDATE_COMPANY_EXP_DATE_REQUEST";
export const UPDATE_COMPANY_EXP_DATE_SUCCESS =
  "UPDATE_COMPANY_EXP_DATE_SUCCESS";
export const UPDATE_COMPANY_EXP_DATE_ERROR = "UPDATE_COMPANY_EXP_DATE_ERROR";

export const UPDATE_COMPANY_STATUS_REQUEST = "UPDATE_COMPANY_STATUS_REQUEST";
export const UPDATE_COMPANY_STATUS_SUCCESS = "UPDATE_COMPANY_STATUS_SUCCESS";
export const UPDATE_COMPANY_STATUS_ERROR = "UPDATE_COMPANY_STATUS_ERROR";

export const GET_ACTIVE_ADD_ON_REQUEST = "GET_ACTIVE_ADD_ON_REQUEST";
export const GET_ACTIVE_ADD_ON_SUCCESS = "GET_ACTIVE_ADD_ON_SUCCESS";
export const GET_ACTIVE_ADD_ON_ERROR = "GET_ACTIVE_ADD_ON_ERROR";

export const CHAT_ATTACHMENT_REQUEST = "CHAT_ATTACHMENT_REQUEST";
export const CHAT_ATTACHMENT_SUCCESS = "CHAT_ATTACHMENT_SUCCESS";
export const CHAT_ATTACHMENT_ERROR = "CHAT_ATTACHMENT_ERROR";

export const CHAT_USER_REQUEST = "CHAT_USER_REQUEST";
export const CHAT_USER_SUCCESS = "CHAT_USER_SUCCESS";
export const CHAT_USER_ERROR = "CHAT_USER_ERROR";

export const CHAT_HISTORY_REQUEST = "CHAT_HISTORY_REQUEST";
export const CHAT_HISTORY_SUCCESS = "CHAT_HISTORY_SUCCESS";
export const CHAT_HISTORY_ERROR = "CHAT_HISTORY_ERROR";

export const GROUP_CHAT_REQUEST = "GROUP_CHAT_REQUEST";
export const GROUP_CHAT_SUCCESS = "GROUP_CHAT_SUCCESS";
export const GROUP_CHAT_ERROR = "GROUP_CHAT_ERROR";

export const GROUP_CHAT_EDIT_REQUEST = "GROUP_CHAT_EDIT_REQUEST";
export const GROUP_CHAT_EDIT_SUCCESS = "GROUP_CHAT_EDIT_SUCCESS";
export const GROUP_CHAT_EDIT_ERROR = "GROUP_CHAT_EDIT_ERROR";

export const GET_CHAT_NOTIFICATION_REQUEST = "GET_CHAT_NOTIFICATION_REQUEST";
export const GET_CHAT_NOTIFICATION_SUCCESS = "GET_CHAT_NOTIFICATION_SUCCESS";
export const GET_CHAT_NOTIFICATION_ERROR = "GET_CHAT_NOTIFICATION_ERROR";

export const DELETE_CHAT_NOTIFICATION_REQUEST =
  "DELETE_CHAT_NOTIFICATION_REQUEST";
export const DELETE_CHAT_NOTIFICATION_SUCCESS =
  "DELETE_CHAT_NOTIFICATION_SUCCESS";
export const DELETE_CHAT_NOTIFICATION_ERROR = "DELETE_CHAT_NOTIFICATION_ERROR";

export const CHECKLIST_D_APPROVEL_REQUEST = "CHECKLIST_D_APPROVEL_REQUEST";
export const CHECKLIST_D_APPROVEL_SUCCESS = "CHECKLIST_D_APPROVEL_SUCCESS";
export const CHECKLIST_D_APPROVEL_ERROR = "CHECKLIST_D_APPROVEL_ERROR";

export const ATTENDENCE_CREATE_REQUEST = "ATTENDENCE_CREATE_REQUEST";
export const ATTENDENCE_CREATE_SUCCESS = "ATTENDENCE_CREATE_SUCCESS";
export const ATTENDENCE_CREATE_ERROR = "ATTENDENCE_CREATE_ERROR";

export const ATTENDENCE_CONFIGLIST_REQUEST = "ATTENDENCE_CONFIGLIST_REQUEST";
export const ATTENDENCE_CONFIGLIST_SUCCESS = "ATTENDENCE_CONFIGLIST_SUCCESS";
export const ATTENDENCE_CONFIGLIST_ERROR = "ATTENDENCE_CONFIGLIST_ERROR";

export const ATTENDENCE_SUBMISSIONLIST_REQUEST =
  "ATTENDENCE_SUBMISSIONLIST_REQUEST";
export const ATTENDENCE_SUBMISSIONLIST_SUCCESS =
  "ATTENDENCE_SUBMISSIONLIST_SUCCESS";
export const ATTENDENCE_SUBMISSIONLIST_ERROR =
  "ATTENDENCE_SUBMISSIONLIST_ERROR";

export const ATTENDENCE_DELETE_REQUEST = "ATTENDENCE_DELETE_REQUEST";
export const ATTENDENCE_DELETE_SUCCESS = "ATTENDENCE_DELETE_SUCCESS";
export const ATTENDENCE_DELETE_ERROR = "ATTENDENCE_DELETE_ERROR";

export const ATTENDENCE_EDIT_REQUEST = "ATTENDENCE_EDIT_REQUEST";
export const ATTENDENCE_EDIT_SUCCESS = "ATTENDENCE_EDIT_SUCCESS";
export const ATTENDENCE_EDIT_ERROR = "ATTENDENCE_EDIT_ERROR";

export const ATTENDENCE_EXPORT_REQUEST = "ATTENDENCE_EXPORT_REQUEST";
export const ATTENDENCE_EXPORT_SUCCESS = "ATTENDENCE_EXPORT_SUCCESS";
export const ATTENDENCE_EXPORT_ERROR = "ATTENDENCE_EXPORT_ERROR";

export const SCHEDULAR_VIEW_REQUEST = "SCHEDULAR_VIEW_REQUEST";
export const SCHEDULAR_VIEW_SUCCESS = "SCHEDULAR_VIEW_SUCCESS";
export const SCHEDULAR_VIEW_ERROR = "SCHEDULAR_VIEW_ERROR";

export const SUBMISSION_VERSION_REQUEST = "SUBMISSION_VERSION_REQUEST";
export const SUBMISSION_VERSION_SUCCESS = "SUBMISSION_VERSION_SUCCESS";
export const SUBMISSION_VERSION_ERROR = "SUBMISSION_VERSION_ERROR";

export const ANALYTIC_REQUEST = "ANALYTIC_REQUEST";
export const ANALYTIC_SUCCESS = "ANALYTIC_SUCCESS";
export const ANALYTIC_ERROR = "ANALYTIC_ERROR";

export const ANALYTIC_COMPANY_LIST_REQUEST = "ANALYTIC_COMPANY_LIST_REQUEST";
export const ANALYTIC_COMPANY_LIST_SUCCESS = "ANALYTIC_COMPANY_LIST_SUCCESS";
export const ANALYTIC_COMPANY_LIST_ERROR = "ANALYTIC_COMPANY_LIST_ERROR";

export const ANALYTIC_USER_LIST_REQUEST = "ANALYTIC_USER_LIST_REQUEST";
export const ANALYTIC_USER_LIST_SUCCESS = "ANALYTIC_USER_LIST_SUCCESS";
export const ANALYTIC_USER_LIST_ERROR = "ANALYTIC_USER_LIST_ERROR";

export const ANALYTIC_COMPANY_EDIT_REQUEST = "ANALYTIC_COMPANY_EDIT_REQUEST";
export const ANALYTIC_COMPANY_EDIT_SUCCESS = "ANALYTIC_COMPANY_EDIT_SUCCESS";
export const ANALYTIC_COMPANY_EDIT_ERROR = "ANALYTIC_COMPANY_EDIT_ERROR";

export const TEMPLATE_QUESTION_ADD_REQUEST = "TEMPLATE_QUESTION_ADD_REQUEST";
export const TEMPLATE_QUESTION_ADD_SUCCESS = "TEMPLATE_QUESTION_ADD_SUCCESS";
export const TEMPLATE_QUESTION_ADD_ERROR = "TEMPLATE_QUESTION_ADD_ERROR";

export const CATEGORY_LIST_INVENTORY_REQUEST =
  "CATEGORY_LIST_INVENTORY_REQUEST";
export const CATEGORY_LIST_INVENTORY_SUCCESS =
  "CATEGORY_LIST_INVENTORY_SUCCESS";
export const CATEGORY_LIST_INVENTORY_ERROR = "CATEGORY_LIST_INVENTORY_ERROR";

export const TEMPLATE_LIST_INVENTORY_REQUEST =
  "TEMPLATE_LIST_INVENTORY_REQUEST";
export const TEMPLATE_LIST_INVENTORY_SUCCESS =
  "TEMPLATE_LIST_INVENTORY_SUCCESS";
export const TEMPLATE_LIST_INVENTORY_ERROR = "TEMPLATE_LIST_INVENTORY_ERROR";

export const TEMPLATE_DELETE_REQUEST = "TEMPLATE_DELETE_REQUEST";
export const TEMPLATE_DELETE_SUCCESS = "TEMPLATE_DELETE_SUCCESS";
export const TEMPLATE_DELETE_ERROR = "TEMPLATE_DELETE_ERROR";

export const PRODUCT_STORE_REQUEST = "PRODUCT_STORE_REQUEST";
export const PRODUCT_STORE_SUCCESS = "PRODUCT_STORE_SUCCESS";
export const PRODUCT_STORE_ERROR = "PRODUCT_STORE_ERROR";

export const CREATE_INVENTORY_PRODUCT_REQUEST =
  "CREATE_INVENTORY_PRODUCT_REQUEST";
export const CREATE_INVENTORY_PRODUCT_SUCCESS =
  "CREATE_INVENTORY_PRODUCT_SUCCESS";
export const CREATE_INVENTORY_PRODUCT_ERROR = "CREATE_INVENTORY_PRODUCT_ERROR";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_ERROR = "PRODUCT_LIST_ERROR";

export const PRODUCT_ITEMS_REQUEST = "PRODUCT_ITEMS_REQUEST";
export const PRODUCT_ITEMS_SUCCESS = "PRODUCT_ITEMS_SUCCESS";
export const PRODUCT_ITEMS_ERROR = "PRODUCT_ITEMS_ERROR";

export const DELETE_PRODUCT_ITEMS_REQUEST = "DELETE_PRODUCT_ITEMS_REQUEST";
export const DELETE_PRODUCT_ITEMS_SUCCESS = "DELETE_PRODUCT_ITEMS_SUCCESS";
export const DELETE_PRODUCT_ITEMS_ERROR = "DELETE_PRODUCT_ITEMS_ERROR";

export const EDIT_PRODUCT_ITEMS_REQUEST = "EDIT_PRODUCT_ITEMS_REQUEST";
export const EDIT_PRODUCT_ITEMS_SUCCESS = "EDIT_PRODUCT_ITEMS_SUCCESS";
export const EDIT_PRODUCT_ITEMS_ERROR = "EDIT_PRODUCT_ITEMS_ERROR";

export const ADD_NEW_PRODUCT_ITEMS_REQUEST = "ADD_NEW_PRODUCT_ITEMS_REQUEST";
export const ADD_NEW_PRODUCT_ITEMS_SUCCESS = "ADD_NEW_PRODUCT_ITEMS_SUCCESS";
export const ADD_NEW_PRODUCT_ITEMS_ERROR = "ADD_NEW_PRODUCT_ITEMS_ERROR";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";

export const UPLOAD_PRODUCT_MEDIA_QUES_REQUEST =
  "UPLOAD_PRODUCT_MEDIA_QUES_REQUEST";
export const UPLOAD_PRODUCT_MEDIA_QUES_SUCCESS =
  "UPLOAD_PRODUCT_MEDIA_QUES_SUCCESS";
export const UPLOAD_PRODUCT_MEDIA_QUES_ERROR =
  "UPLOAD_PRODUCT_MEDIA_QUES_ERROR";

export const SIGNED_URL_REQUEST = "SIGNED_URL_REQUEST";
export const SIGNED_URL_SUCCESS = "SIGNED_URL_SUCCESS";
export const SIGNED_URL_ERROR = "SIGNED_URL_ERROR";

export const MASTERQRCODE_LIST_INVENTORY_REQUEST =
  "MASTERQRCODE_LIST_INVENTORY_REQUEST";
export const MASTERQRCODE_LIST_INVENTORY_SUCCESS =
  "MASTERQRCODE_LIST_INVENTORY_SUCCESS";
export const MASTERQRCODE_LIST_INVENTORY_ERROR =
  "MASTERQRCODE_LIST_INVENTORY_ERROR";

export const LISTQRCODE_DELETE_REQUEST = "LISTQRCODE_DELETE_REQUEST";
export const LISTQRCODE_DELETE_SUCCESS = "LISTQRCODE_DELETE_SUCCESS";
export const LISTQRCODE_DELETE_ERROR = "LISTQRCODE_DELETE_ERROR";

export const MASTERQRCODE_CREATE_REQUEST = "MASTERQRCODE_CREATE_REQUEST";
export const MASTERQRCODE_CREATE_SUCCESS = "MASTERQRCODE_CREATE_SUCCESS";
export const MASTERQRCODE_CREATE_ERROR = "MASTERQRCODE_CREATE_ERROR";

export const INVENTORY_PRODUCT_ITEM_REQUEST = "INVENTORY_PRODUCT-ITEM_REQUEST";
export const INVENTORY_PRODUCT_ITEM_SUCCESS = "INVENTORY_PRODUCT-ITEM_SUCCESS";
export const INVENTORY_PRODUCT_ITEM_ERROR = "INVENTORY_PRODUCT-ITEM_ERROR";

export const MASTERBARCODEVIEW_REQUEST = "MASTERBARCODEVIEW_REQUEST";
export const MASTERBARCODEVIEW_SUCCESS = "MASTERBARCODEVIEW_SUCCESS";
export const MASTERBARCODEVIEW_ERROR = "MASTERBARCODEVIEW_ERROR";

export const DELETEITEM_REQUEST = "DELETEITEM_REQUEST";
export const DELETEITEM_SUCCESS = "DELETEITEM_SUCCESS";
export const DELETEITEM_ERROR = "DELETEITEM_ERROR";

export const MASTERBARCODEVIEW_ITEMVIEW_REQUEST = "MASTERBARCODEVIEW_ITEMVIEW_REQUEST";
export const MASTERBARCODEVIEW_ITEMVIEW_SUCCESS = "MASTERBARCODEVIEW_ITEMVIEW_SUCCESS";
export const MASTERBARCODEVIEW_ITEMVIEW_ERROR = "MASTERBARCODEVIEW_ITEMVIEW_ERROR";

export const GFR_REQUEST = "GFR_REQUEST";
export const GFR_SUCCESS = "GFR_SUCCESS";
export const GFR_ERROR = "GFR_ERROR";