/** @format */

import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  Grid,
  Typography,
  Box,
  Modal,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Autocomplete,
  Checkbox,
  Chip,
  Paper,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Csv from "../submissionTabs/Csv";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as moment from "moment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { ListUserChecklistApi } from "../../redux/actions/User";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { date } from "yup";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto", 
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const MenuProps = {
  disableScrollLock: true,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const Header = (props) => {
  const storedDateString = localStorage.getItem("storedDateData");
  let storedDateObject;
  const searchInputRef = useRef(null);

  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );

  const storeList = useSelector(
    (state) =>
      state.store &&
      state.store.data &&
      state.store.data.data &&
      state.store.data.data.data &&
      state.store.data.data.data.stores
  );

  const storeListBasedOnRole = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.storeData
  );

  const storeListBasedLoader = useSelector(
    (state) => state?.user && state?.user?.loading
  );

  const userListBasedOnStore = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.userIds
  );

  try {
    storedDateObject = storedDateString
      ? JSON.parse(storedDateString)
      : { startDate: null, endDate: null };
  } catch (error) {
    storedDateObject = { startDate: null, endDate: null };
  }

  const [startDate, setStartDate] = React.useState(
    storedDateObject.startDate || new Date()
  );
  const [endDate, setEndDate] = React.useState(
    storedDateObject.endDate || new Date()
  );
  const [newDate, setNewDate] = React.useState(
    storedDateObject.startDate && storedDateObject.endDate
      ? {}
      : { startDate: new Date(), endDate: new Date() }
  );

  const [showSubmission, setShowSubmission] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filterType, setFilterType] = React.useState(0);
  const [filterRole, setFilterRole] = React.useState(0);
  const [filterUser, setFilterUser] = React.useState([]);
  const [filterStore, setFilterStore] = React.useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [frequency, setFrequency] = useState(0);
  const [subRole, setSubRole] = useState([]);
  const dispatch = useDispatch();
  const storedSearchString = localStorage.getItem("searchedData");
  const submissionFilter = localStorage.getItem("submissionFilterData") || "{}";
  const submissionFilterData = JSON.parse(submissionFilter);

  const [searchQuery, setSearchQuery] = React.useState(
    storedSearchString || ""
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    if (submissionFilterData) {
      setFilterType(submissionFilterData.checklistType);
      setStartDate(submissionFilterData.startDate);
      setEndDate(submissionFilterData.endDate);
      setFrequency(submissionFilterData.frequency);
      setFilterStore(submissionFilterData.storeId || []);
      setFilterUser(submissionFilterData.userId || []);
    }
  }, [submissionFilter]);

  const handleDateClick = () => {
    const dates = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    setNewDate(dates);
    setShowSubmission(true);

    localStorage.setItem("storedDateData", JSON.stringify(dates));
    localStorage.setItem("searchedData", searchQuery);
  };

  useEffect(() => {
    if (!searchQuery) {
      const storedSearchString = localStorage.getItem("searchedData");
      setSearchQuery(storedSearchString || "");
    }
  }, [searchQuery]);

  useEffect(() => {
    localStorage.setItem("searchedData", searchQuery);
    props.setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    if (Object.keys(newDate).length > 0) {
      setShowSubmission(false);
    }
  }, [newDate]);

  useEffect(() => {
    setSearchQuery(props.searchValue || "");
  }, [props.searchValue]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const searchedData = JSON.stringify(searchQuery);
      localStorage.setItem("searchedData", searchedData);
    } else if (event.keyCode === 8 && searchQuery === "") {
      localStorage.removeItem("searchedData");
    }
  };

  useEffect(() => {
    const searchInput = searchInputRef.current;
    if (searchInput) {
      searchInput.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (searchInput) {
        searchInput.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [searchQuery]);

  const handleGeneratePDF = () => {
    if (props.onGeneratePDF) {
      props.onGeneratePDF();
    }
  };

  const modalopenFilterhandleClose = () => {
    setOpenFilterModal(false);
  };

  const handleFilter = () => {
    handleDateClick();
    const filterObject = {
      checklistType: filterType,
      userId: filterUser,
      storeId: filterStore,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      frequency: frequency,
      // Role: filterRole
    };

    localStorage.setItem("submissionFilterData", JSON.stringify(filterObject));

    localStorage.setItem("searchedData", searchQuery);
    props.handleFilter(filterObject);
    setOpenFilterModal(false);
  };

  const listUser = (data, key) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `report/dropdown?company_id=${id}`;
    dispatch(ListUserChecklistApi(data, key));
  };

  useEffect(() => {
    listUser(
      {
        body: {
          storeIds: filterStore.map((item) => item._id),
          userIds: [],
          subRole: subRole,
          startDate: startDate,
          endDate: endDate,
        },
      },
      "user"
    );
  }, [subRole]);

  const handleStoreClear = () => {
    listUser(
      {
        body: {
          storeIds: [],
          userIds: [],
          subRole: subRole,
          startDate: startDate,
          endDate: endDate,
        },
      },
      "user"
    );
  };

  const handleStoreClose = () => {
    if (filterStore && filterStore.length) {
      listUser(
        {
          body: {
            storeIds: filterStore.map((item) => item._id),
            userIds: [],
            subRole: subRole,
            startDate: startDate,
            endDate: endDate,
          },
        },
        "user"
      );
    }
  };

  const handleStoreChange = (event, newValue) => {
    if (newValue) {
      setFilterStore(newValue);
      setFilterUser([]);
    }
    if (!newValue.length) {
      listUser(
        {
          body: {
            storeIds: [],
            userIds: [],
            subRole: subRole,
            startDate: startDate,
            endDate: endDate,
          },
        },
        "user"
      );
    }
  };

  const handleUserChange = (event, newValue) => {
    if (newValue) {
      setFilterUser(newValue);
    }
  };

  const handleFreqChange = (e) => {
    setFrequency(e.target.value);
  };

  return (
    <>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
        className="header-card"
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} lg={7} md={4} sm={12} alignItems="left">
            <Stack
              direction="row"
              spacing={1}
              alignItems="left"
              justifyContent= {{ xs: "center", sm: "flex-start" }}
              sx={{
                marginRight: "1px",
                marginLeft: { xs: "0px", sm: "10px"},
              }}
            >
              <Stack direction="row" alignItems="left">
                <Button
                  className="header-add cancle-hover"
                  style={{
                    height: "42px",
                    borderRadius: "8px",
                    padding: "12px 16px",
                  }}
                  sx={{
                    width: { xs: "168px !important", sm: "auto !important" },
                  }}
                  onClick={(e) => setOpenFilterModal(true)}
                >
                  <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                  Filter
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={5} md={8} sm={12}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-end" }}
              sx={{
                marginRight: "1px",
              }}
            >
              <Stack direction="row" alignItems="center">
                {props?.permission?.checklist?.manageChecklist === true && (
                  <Link
                    to="/checklist/all-checklist/add"
                    state={props.submissionCancle}
                    className="submenu-item"
                  >
                    <Button
                      className="header-add cancle-hover"
                      sx={{
                        whiteSpace: "nowrap",
                        ml: 1,
                        height: "42px",
                        borderRadius: "8px",
                        width: { xs: "100%", sm: "auto", md: "175px" },
                        marginLeft: { xs: "0px", sm: "auto" },
                      }}
                    >
                      <AddOutlinedIcon sx={{ mr: 1 }} />
                      Assign Checklist
                    </Button>
                  </Link>
                )}
              </Stack>
              {/* <Button
                className="cancle-hover"
                sx={{
                  backgroundColor: "#196773",
                  color: "white",
                  height: "42px",
                  borderRadius: "8px",
                  width: "168px",
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleGeneratePDF}
                // disabled={true}
              >
                Generate Today's pdf
              </Button> */}

              <Csv
                submissionListData={props.submissionListData}
                newDate={newDate}
              />
              <div
                align="right"
                style={{ marginRight: "8px", marginLeft: "-1px" }}
              >
                <Tooltip
                  title={
                    <div style={{ maxWidth: "300px" }}>
                      <strong>Submission</strong>
                      <p>
                        Here we display all the submissions details as per the
                        selected dates, store, user, Checklist Type, Frequency
                        selected in filter
                      </p>
                      <strong>Generate Today's pdf:</strong>
                      <p>
                        Its used to generate pdf in case if any pdf is not
                        generated
                      </p>
                      <strong>Export excel:</strong>
                      <p>
                        An option to export all the submissions as per the
                        fields selected in filter
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      
      <Modal
        open={openFilterModal}
        onClose={modalopenFilterhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box sx={{ ...downloadAllstyle, p: 0, width: { xs: '90%', sm: '80%', md: '60%', lg: '40%' }, margin: '0 auto', }}>
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setOpenFilterModal(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                pt: 2,
                px: 4,
              }}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Store Location</label>

                <Autocomplete
                  options={
                    storeListBasedOnRole && storeListBasedOnRole.length
                      ? storeListBasedOnRole
                      : storeList && storeList.length
                      ? storeList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select Store"
                      sx={{ borderRadius: "8px" }}
                      onBlur={() => {
                        if (!params.inputProps.value) {
                        }
                      }}
                    />
                  )}
                  value={filterStore}
                  onClose={handleStoreClose}
                  onChange={handleStoreChange}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Checkbox
                        checked={
                          filterStore
                            ? filterStore
                                .map((fs) => fs._id)
                                .includes(option._id)
                            : false
                        }
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newSelected = filterStore
                            ? [...filterStore]
                            : [];
                          const selectedIndex = newSelected
                            .map((fs) => fs._id)
                            .indexOf(option._id);

                          if (selectedIndex === -1) {
                            newSelected.push(option);
                          } else {
                            newSelected.splice(selectedIndex, 1);
                          }

                          setFilterUser([]);

                          setFilterStore(newSelected);
                        }}
                      />
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Chip
                              key={option.name}
                              label={option.name}
                              {...getTagProps({ index })}
                              color="primary"
                              sx={{ mr: 1, mb: 1 }}
                            />
                            {value.length > 1 ? (
                              <Chip
                                key={`${value.length - 1} more selected`}
                                label={`${value.length - 1} more selected`}
                                color="primary"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    })
                  }
                  clearable
                  onClear={handleStoreClear}
                  multiple
                  disableCloseOnSelect
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()}
                          pl={3}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                filterStore.length > 0 &&
                                filterStore.length ===
                                  (storeListBasedOnRole.length
                                    ? storeListBasedOnRole.length
                                    : storeList.length)
                              ) {
                                handleStoreChange("", []);
                              } else {
                                handleStoreChange(
                                  "",
                                  storeListBasedOnRole.length
                                    ? storeListBasedOnRole
                                    : storeList
                                );
                              }
                            }}
                            label="Select all"
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                id="select-all-checkbox"
                                checked={
                                  filterStore.length > 0 &&
                                  filterStore.length ===
                                    (storeListBasedOnRole.length
                                      ? storeListBasedOnRole.length
                                      : storeList.length)
                                }
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    );
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>User</label>

                <Autocomplete
                  options={
                    storeListBasedLoader
                      ? []
                      : userListBasedOnStore && userListBasedOnStore.length
                      ? userListBasedOnStore
                      : userList && userList.length
                      ? userList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select User"
                      sx={{ borderRadius: "8px" }}
                      onBlur={() => {
                        if (!params.inputProps.value) {
                          // handleClearSelection();
                        }
                      }}
                    />
                  )}
                  value={filterUser}
                  loading={storeListBasedLoader}
                  loadingText={"Users Loading..."}
                  // onClose={handleUserClose}
                  onChange={handleUserChange}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Checkbox
                        checked={
                          filterUser
                            ? filterUser
                                .map((fs) => fs._id)
                                .includes(option._id)
                            : false
                        }
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newSelected = filterUser ? [...filterUser] : [];
                          const selectedIndex = newSelected
                            .map((fs) => fs._id)
                            .indexOf(option._id);

                          if (selectedIndex === -1) {
                            newSelected.push(option);
                          } else {
                            newSelected.splice(selectedIndex, 1);
                          }

                          setFilterUser(newSelected);
                        }}
                      />
                      {option.name}
                    </li>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <Chip
                              key={option.name}
                              label={option.name}
                              {...getTagProps({ index })}
                              color="primary"
                              sx={{ mr: 1, mb: 1 }}
                            />
                            {value.length > 1 ? (
                              <Chip
                                key={`${value.length - 1} more selected`}
                                label={`${value.length - 1} more selected`}
                                color="primary"
                              />
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    })
                  }
                  clearable
                  // onClear={handleUserClear}
                  multiple
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                      <Paper {...restPaperProps}>
                        <Box
                          onMouseDown={(e) => e.preventDefault()}
                          pl={3}
                          py={0.5}
                        >
                          <FormControlLabel
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                filterUser.length > 0 &&
                                filterUser.length ===
                                  (userListBasedOnStore &&
                                  userListBasedOnStore.length
                                    ? userListBasedOnStore.length
                                    : userList.length)
                              ) {
                                handleUserChange("", []);
                              } else {
                                handleUserChange(
                                  "",
                                  userListBasedOnStore &&
                                    userListBasedOnStore.length
                                    ? userListBasedOnStore
                                    : userList
                                );
                              }
                            }}
                            label="Select all"
                            control={
                              <Checkbox
                                sx={{ mr: 1 }}
                                id="select-all-checkbox"
                                checked={
                                  filterUser.length > 0 &&
                                  filterUser.length ===
                                    (userListBasedOnStore &&
                                    userListBasedOnStore.length
                                      ? userListBasedOnStore.length
                                      : userList.length)
                                }
                              />
                            }
                          />
                        </Box>
                        <Divider />
                        {children}
                      </Paper>
                    );
                  }}
                />
              </FormControl>

              {/* <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Checklist Type</label>

                <Select
                  name="type"
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  size="small"
                  MenuProps={MenuProps}
                  sx={{ borderRadius: "8px" }}
                  placeholder="Select type"
                >
                  <MenuItem value={0}>Select type</MenuItem>
                  <MenuItem value={1}>Checklist</MenuItem>
                  <MenuItem value={2}>Audit</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Frequency</label>
                <Select
                  name="frequency"
                  sx={{
                    borderRadius: "8px",
                    textAlign: "left",
                    marginTop: "10px",
                  }}
                  value={frequency || 0}
                  onChange={handleFreqChange}
                  helperText="select frequency"
                  size="small"
                  MenuProps={MenuProps}
                >
                  <MenuItem value={0}>All Frequency</MenuItem>
                  <MenuItem value={1}>No Frequency</MenuItem>
                  <MenuItem value={2}>Hourly</MenuItem>
                  <MenuItem value={3}>Daily</MenuItem>
                  <MenuItem value={4}>Weekly</MenuItem>
                  <MenuItem value={5}>Monthly </MenuItem>
                </Select>
              </FormControl> */}

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2, flexWrap:{ xs:"wrap", sm:"nowrap"}}}
              >
                <FormControl fullWidth sx={{ mr: {xs : 0, sm: "16px"} }}>
                  <label>Checklist Type</label>
                  <Select
                    name="type"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px" }}
                    placeholder="Select type"
                  >
                    <MenuItem value={0}>Select type</MenuItem>
                    <MenuItem value={1}>Checklist</MenuItem>
                    <MenuItem value={2}>Audit</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ ml: {xs : 0, sm: "16px"} }}>
                  <label>Frequency</label>
                  <Select
                    name="frequency"
                    sx={{
                      borderRadius: "8px",
                      textAlign: "left",
                    }}
                    value={frequency || 0}
                    onChange={handleFreqChange}
                    helperText="select frequency"
                    size="small"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={0}>All Frequency</MenuItem>
                    <MenuItem value={1}>No Frequency</MenuItem>
                    <MenuItem value={2}>Hourly</MenuItem>
                    <MenuItem value={3}>Daily</MenuItem>
                    <MenuItem value={4}>Weekly</MenuItem>
                    <MenuItem value={5}>Monthly</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <label>Date</label>
              <Box
                sx={{ mb: 2, display:"flex", justifyContent:"space-between", flexWrap:  { xs: "nowrap", sm:"nowrap"} }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={startDate}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setStartDate(dateSort);
                    }}
                    renderInput={(params) => ( <TextField {...params} sx= {{  mr: {xs:1, sm:1} }}/>)}
                    className="date-pick date-pick-submision date-width"
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
                <Typography className="date-to" sx={{ alignSelf: "center" }}>to</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={endDate}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setEndDate(dateSort);
                    }}
                    renderInput={(params) => ( <TextField {...params} sx={{ ml: { xs: 1, sm: 1 } }}/>)}
                    className="date-pick date-pick-submision date-width"
                    maxDate={new Date()}
                    minDate={startDate}
                  />
                </LocalizationProvider>
              </Box>
              <Stack alignItems="left" sx={{ mb: 2 }}>
                <label>Search</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  value={searchQuery}
                  placeholder="Search User, Store, Category, Role, Form"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    props.searchOnChange(e.target.value);
                    localStorage.setItem("searchedData", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "sticky",
              bottom: 0,
              width: "100%",
              gap: "30px",
              px: 3,
              pt: 1,
              pb: 2,
              background: "#fff",
            }}
          >
            <Button
              className="cancle-button cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
                background: "#fff",
              }}
              onClick={() => {
                setFilterType(0);
                setFilterStore([]);
                setFilterUser([]);
                setSearchQuery("");
                setFrequency(0);
                props.searchOnChange("");
                localStorage.setItem("searchedData", "");
                const dates = {
                  startDate: moment(new date()).format("YYYY-MM-DD"),
                  endDate: moment(new date()).format("YYYY-MM-DD"),
                };
                setStartDate(dates.startDate);
                setEndDate(dates.endDate);

                setNewDate(dates);
                setShowSubmission(true);
                handleStoreClear();

                localStorage.setItem("storedDateData", JSON.stringify(dates));
              }}
            >
              Clear
            </Button>
            <Button
              className="header-add cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
              }}
              onClick={(e) => {
                handleFilter();
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
