import { Box, Button, Grid, Stack } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useState } from "react";
import { Link } from "react-router-dom";

const ProductHeader = () => {
    const [openFilterModal, setOpenFilterModal] = useState(false);

    const modalopenFilterhandleClose = () => {
        setOpenFilterModal(false);
    }

    const handleNewCreate = () => {
        localStorage.setItem("addTemplate", "");
        localStorage.setItem("questions", "");
        localStorage.setItem("userId", "");
    }

    return (
        <Box
            sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
            className="header-card"
        >
            <Grid container spacing={1} alignItems="center" sx={{
                padding: "0px 8px"
            }}>
                <Grid item xs={12} lg={7} md={4} sm={12} alignItems="left">
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="left"
                        justifyContent="flex-start"
                    >
                        <Stack direction="row" alignItems="left">
                            <Button
                                className="header-add cancle-hover"
                                style={{ height: "42px", borderRadius: "8px", padding: "12px 16px" }}
                                onClick={(e) => setOpenFilterModal(true)}
                            >
                                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                                Filter
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={5} md={8} sm={12}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Stack direction="row" alignItems="center">
                            <Link
                                to="/inventory-product/create"
                                className="submenu-item"
                            >
                                <Button
                                    className="header-add cancle-hover"
                                    sx={{
                                        whiteSpace: "nowrap",
                                        ml: 1,
                                        height: "42px",
                                        borderRadius: "8px",
                                    }}
                                    onClick={() => handleNewCreate()}
                                >
                                    <AddOutlinedIcon sx={{ mr: 1 }} />
                                    Add Product
                                </Button>
                            </Link>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Box>

    )
};

export default ProductHeader;