import { Box, Grid, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { styled } from "material-ui-core";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import DownloadIcon from "@mui/icons-material/Download";

import {
  DeleteQRCodeListApi,
  MasterQRCodeListInventoryApi,
} from "../../redux/actions/Inventory";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
import MasterQRCodeListHeader from "./MasterQRCodeListHeader";
import moment from "moment";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const MasterQRCodeList = () => {
  // const loader = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const templateData = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.QRCodeListData &&
        state.inventory.QRCodeListData.data &&
        state.inventory.QRCodeListData.data.data) ||
      []
  );
  console.log("QRCodeListData", templateData);

  const loader = useSelector(
    (state) => state.inventory && state.inventory.loading
  );

  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [indexHover, setIndexHover] = useState(0);
  const [hover, setHover] = useState({
    View: false,
  });

  console.log("data", data);
  console.log("loader", loader);

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: () => (
        <LightTooltip title={"S.No"}>
          <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (pageNo - 1) * perPage,
      renderCell: (params) => {
        const d = data || [];
        const index =
          d.map((e) => e._id).indexOf(params.row._id) +
          1 +
          (pageNo - 1) * perPage;

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              minWidth: "50px",
            }}
          >
            <p
              className="text-short"
              style={{
                margin: "0 auto",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              {index}
            </p>
          </div>
        );
      },
    },

    {
      field: "Name",
      headerName: "Name",
      renderHeader: () => (
        <LightTooltip title={"Name"}>
          <strong>{"Name"}</strong>
        </LightTooltip>
      ),
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.title) {
          result.push(params?.row?.title);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <LightTooltip title={results}>
              <p
                className="text-short"
                style={{ marginLeft: "10px", fontWeight: "normal" }}
              >
                {results}
              </p>
            </LightTooltip>
          </div>
        );
      },
    },
    {
      field: "Description",
      headerName: "Description",
      renderHeader: () => (
        <LightTooltip title={"Description"}>
          <strong>{"Description"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.description) {
          result.push(params?.row?.description);
        } else {
          result = ["N/A"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short"> {results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Created At",
      headerName: "Created At",
      renderHeader: () => (
        <LightTooltip title={"Created At"}>
          <strong>{"Created At"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = "0";
        if (params?.row?.createdAt) {
          result = moment(params.row.createdAt).format(
            "MMMM D, YYYY, h:mm:ss A"
          );
        }

        return (
          <LightTooltip title={result}>
            <p className="text-short"> {result}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Updated At",
      headerName: "Updated At",
      renderHeader: () => (
        <LightTooltip title={"Updated At"}>
          <strong>{"Updated At"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = "N/A";
        if (params?.row?.updatedAt) {
          result = moment(params.row.updatedAt).format(
            "MMMM D, YYYY, h:mm:ss A"
          );
        }

        return (
          <LightTooltip title={result}>
            <p className="text-short"> {result}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      renderHeader: () => (
        <LightTooltip title={"Status"}>
          <strong>{"Status"}</strong>
        </LightTooltip>
      ),
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const isManualScanned = params?.row?.barcodeScanned;
        const icon = isManualScanned ? (
          <span
            style={{
              color: "green",
              fontSize: "16px",
              marginRight: "5px",
            }}
          >
            ✔️
          </span>
        ) : (
          <span
            style={{
              color: "red",
              fontSize: "16px",
              marginRight: "5px",
            }}
          >
            ❌
          </span>
        );

        const text = isManualScanned ? "Scanned" : "Not Scanned";

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "120px",
            }}
          >
            <LightTooltip title={text}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  fontSize: "12px",
                  color: isManualScanned ? "green" : "red",
                  fontWeight: "500",
                }}
              >
                {icon}
                <span>{text}</span>
              </div>
            </LightTooltip>
          </div>
        );
      },
    },
    {
      field: "Barcode Link",
      headerName: "Barcode Link",
      renderHeader: () => <strong>{"Barcode Link"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const barcode = params?.row?.barcode;
        const isImage =
          barcode &&
          (barcode.endsWith(".jpg") ||
            barcode.endsWith(".png") ||
            barcode.endsWith(".jpeg"));

        const openImagePreview = (url) => {
          const newWindow = window.open("", "_blank");
          if (newWindow) {
            newWindow.document.write(`
              <html>
                <head>
                  <title>Barcode Preview</title>
                  <style>
                    body {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100vh;
                      margin: 0;
                      background-color: #f9f9f9;
                    }
                    img {
                      max-width: 100%;
                      max-height: 100%;
                      border: 1px solid #ddd;
                      box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
                    }
                  </style>
                </head>
                <body>
                  <img src="${url}" alt="Barcode Preview" />
                </body>
              </html>
            `);
            newWindow.document.close();
          }
        };

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {barcode ? (
              isImage ? (
                <img
                  src={barcode}
                  alt="Barcode"
                  style={{
                    width: 40,
                    height: 40,
                    objectFit: "cover",
                    cursor: "pointer",
                    borderRadius: "4px",
                  }}
                  onClick={() => openImagePreview(barcode)}
                />
              ) : (
                <a
                  href={barcode}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#007BFF",
                    fontWeight: "500",
                  }}
                >
                  Barcode Link
                </a>
              )
            ) : (
              <span
                style={{
                  color: "grey",
                  fontSize: "14px",
                }}
              >
                N/A
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: 'barcode',
      headerName: 'Download BarCode',
      renderHeader: () => (
        <Tooltip title="Download BarCode">
          <strong>Download BarCode</strong>
        </Tooltip>
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const barcodeUrl = params?.row?.barcode;
    
        const handleDownload = async (url) => {
          try {
            const response = await fetch(url);
            const blob = await response.blob();
                const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);  
            link.download = url.split("/").pop();   
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
          } catch (error) {
            console.error("Download failed:", error);
          }
        };
    
        return barcodeUrl ? (
          <Tooltip title="Download Barcode">
            <IconButton
              onClick={() => handleDownload(barcodeUrl)}  
              style={{ padding: 8 }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        ) : (
          "No Barcode"
        );
      },
    },

    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => (
        <LightTooltip title={"Action"}>
          <strong>{"Action"}</strong>
        </LightTooltip>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const viewBtnStyle = {
          cursor: "pointer",
          margin: "0px 3px 0px 3px",
          height: "32px",
        };

        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Tooltip title="View">
              <img
                src={
                  hover.View && indexHover === params?.row?._id
                    ? ViewHover
                    : View
                }
                alt="VIEW"
                style={{
                  ...viewBtnStyle,
                }}
                onClick={() => handleView(params?.row)}
                onMouseOver={() => {
                  setHover({ ...hover, View: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ ...hover, View: false });
                }}
              />
            </Tooltip>
            <Tooltip title="Edit">
              <img
                onClick={() => handleEditDetails(params?.row)}
                src={
                  hover.Edit && indexHover === params?.row?._id
                    ? EditHover
                    : Edit
                }
                alt="Edit"
                style={{
                  ...viewBtnStyle,
                }}
                onMouseOver={() => {
                  setHover({ Edit: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Edit: false });
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <img
                onClick={() => handleDelete(params?.row)}
                src={
                  hover.Delete && indexHover === params?.row?._id
                    ? DeleteHover
                    : Delete
                }
                alt="Delete"
                style={{
                  height: "35px",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
                onMouseOver={() => {
                  setHover({ Delete: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ Delete: false });
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleEditDetails = (row) => {
    navigate("/master-barcode-list/edit");
    localStorage.setItem("masterBarCodeLocalStorageData", JSON.stringify(row));
  };

  // const handleView = (row) => {
  //   navigate("/master-barcode-list/view");
  //   localStorage.setItem("masterBarCodeLocalStorageData", JSON.stringify(row));
  // };

  const handleView = (row) => {
    localStorage.removeItem("rowData");
    if (row) {
      localStorage.setItem("rowData", JSON.stringify(row));
      navigate("/master-barcode-list/view", { state: { rowData: row } });
    } else {
      console.error("Row data is not available.");
    }
  };  

  const handleDelete = (values) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const infoId = values?._id;
            console.log("logEntryId", infoId);
            const data = {
              url: BASE_URL + `master-barcode/${infoId}?company_id=${id}`,
            };
            dispatch(DeleteQRCodeListApi(data, getMasterBarCodeList));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  useEffect(() => {
    if (templateData.finalData) {
      setData(templateData.finalData);
    }
  }, [templateData]);

  const handleFilterData = (filterValues) => {
    // Handle the data further as needed
    if (filterValues?.status) {
      // logEntryListRequest.body.status = filterValues.status;
    } else {
      // delete logEntryListRequest.body.status;
    }
    // logEntryListRequest.body.pagination.page = 1;
    // logEntryListRequest.body.status = filterValues?.status;
    // setTitle(filterValues?.search);
    // setStatus(filterValues?.status);
    // setPage(1);
    // // to do
    // handleViewList(logEntryListRequest);

    // localStorage.setItem("logEntryFilter", JSON.stringify(logEntryListRequest));
  };

  const getMasterBarCodeList = () => {
    const id = localStorage.getItem("companyId");
    const api = {
      url:
        BASE_URL +
        `master-barcode?company_id=${id}&per_page=${perPage}&sort_as=updatedAt&sort_by=desc&page=${pageNo}`,
    };
    dispatch(MasterQRCodeListInventoryApi(api));
  };
  //   url: BASE_URL + `inventory-product?company_id=${id}&sort_as=updatedAt&sort_by=updatedAt&page=${pageNo}&limit=${perPage}`

  useEffect(() => {
    getMasterBarCodeList();
    localStorage.setItem("masterBarCodeLocalStorageData", "");
  }, []);

  const handleChangePage = (event, newPage) => {
    // submissionPayload.body.pagination.page = newPage + 1;
    setPageNo(newPage + 1);
    // fetchLosEntryESubmission();
  };

  const handleChangeRowsPerPage = (event) => {
    // submissionPayload.body.pagination.page = 1;
    // submissionPayload.body.pagination.per_page = parseInt(event.target.value, 10);
    setPerPage(event.target.value);
    // fetchLosEntryESubmission();
  };

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        {showFirstButton && (
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        )}
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <MasterQRCodeListHeader onFilter={handleFilterData} />
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "calc(100vh - 280px)",
          mt: 1,
          borderRadius: "8px",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        {/* <Grid container spacing={1} style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    padding: '20px'
                }}> */}
        <SingleGlobalTable
          data={data}
          columns={columns}
          totalCount={templateData?.paginate?.total_item || 0}
          loading={loader}
          page={pageNo}
          rowsPerPage={perPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        {/* </Grid> */}
      </Box>
    </Box>
  );
};

export default MasterQRCodeList;
