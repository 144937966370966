import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import moment from "moment";

const DropdownWithDetails = (props) => {

    const { handleChange, id, options, value, details, disabled } = props;

    return (
        <Autocomplete
            id={id}
            sx={{ width: "100%", paddingX: "1px" }}
            options={options}
            value={(value && options?.length) ? value : ""}
            defaultValue={value}
            disabled={disabled}
            onChange={(event, newValue) => handleChange(newValue)}
            getOptionLabel={(option) => option?.name || ''}
            getOptionSelected = {(option, value) => option?.name === value?.name}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <Box {...optionProps}>
                        <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column", width: '100%' }} pl={2}
                            pr={2} >
                            <Box>
                                <Typography style={{
                                    fontWeight: "bold"
                                }}>
                                    {option.name}
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                marginTop: "5px",
                                gap: "16px"
                            }}>
                                {details && details.length && details.map(item => {

                                    let value = option[item.value];

                                    if(item.type === "date") {
                                        value = moment(option[item.value]).format(item.format);
                                    }

                                    return (
                                        <Typography style={{
                                            fontSize: "12px",
                                            color: "#64748b"
                                        }}>
                                            {item.title && `${item.title}:`} {value}
                                        </Typography>
                                    )
                                })}
                            </Box>
                        </Box>
                    </Box>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        padding: "0px"
                    }}
                />
            )}
            size="small"
        />
    )
}

export default DropdownWithDetails;