import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import { AnalyticApi, CompanyListApi } from "../redux/actions/AnalyticDashboard";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AnalyticalDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [analyticData, setAnalyticData] = useState(false);

    let Loader = useSelector(
        (state) =>
            state.analyticDashboard &&
            state.analyticDashboard.loading
    );

    let analyticDetails = useSelector(
        (state) =>
            state.analyticDashboard &&
            state.analyticDashboard.analyticDetails
    );

    console.log("analyticDetails", analyticDetails);

    const fetchAnalyticData = () => {
        const data = {
            url: BASE_URL + 'company/reports/analytics'
        }
        dispatch(AnalyticApi(data));
    }

    const fetchCompanyList = (status) => {
        navigate('/analytical-company-list', {
            state: status
        })
    }

    const fetchUserList = (status) => {
        navigate('/analytical-user-list', {
            state: status
        })
    }

    useEffect(() => {
        fetchAnalyticData();
    }, []);

    useEffect(() => {
        setAnalyticData(analyticDetails);
    }, [analyticDetails]);

    return (
        <>
            <Box>
                <Box
                    sx={{
                        bgcolor: "#F6F8FC",
                        borderRadius: "4px",
                        mb: 1,
                        display: "flex",
                        justifyContent: "start",
                        padding: '8px'
                    }}
                    className="header-card"
                >
                    <Grid
                        sx={{
                            width: "auto",
                        }}
                    >
                        Analytical Dashboard
                    </Grid>
                </Box>

                {Loader && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "auto",
                            width: "100%",
                        }}
                    >
                        <ProgressbarTransparent play />
                    </div>
                )}
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box
                            maxWidth="xl"
                            sx={{
                                overflow: "hidden",
                                bgcolor: "#F6F8FC",
                                width: "100%",
                                p: 5,
                                borderRadius: 2,
                                mt: 1,
                            }}
                        >
                            <Typography
                                variant="p"
                                component="div"
                                style={{ marginBottom: "16px", display: 'flex', alignItems: 'center', gap: '5px' }}
                                className="heading"
                            >
                                <StoreMallDirectoryOutlinedIcon />
                                Company
                            </Typography>
                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Card
                                        sx={{
                                            pt: 2,
                                            height: "100%",
                                            borderRadius: "8px",
                                        }}
                                        className="card"
                                    >
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Card
                                                    sx={{
                                                        p: 3,
                                                        px: 5,
                                                        textAlign: "center",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => fetchCompanyList('active')}
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: "16px" }}
                                                            className=""
                                                        >
                                                            Active Company
                                                        </Typography>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: "16px" }}
                                                            className=""
                                                        >
                                                            {analyticData.activeCompanyCount}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                                <Card
                                                    sx={{
                                                        p: 3,
                                                        px: 5,
                                                        textAlign: "center",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => fetchCompanyList('inactive')}
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: "16px" }}
                                                            className=""
                                                        >
                                                            In-Active Company
                                                        </Typography>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: "16px" }}
                                                            className=""
                                                        >
                                                            {analyticData.inActiveCompanyCount}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box
                            maxWidth="xl"
                            sx={{
                                overflow: "hidden",
                                bgcolor: "#F6F8FC",
                                width: "100%",
                                p: 5,
                                borderRadius: 2,
                                mt: 1,
                            }}
                        >
                            <Typography
                                variant="p"
                                component="div"
                                style={{ marginBottom: "16px", display: 'flex', alignItems: 'center', gap: '5px' }}
                                className="heading"
                            >
                                <StoreMallDirectoryOutlinedIcon />
                                Users
                            </Typography>
                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Card
                                        sx={{
                                            pt: 2,
                                            height: "100%",
                                            borderRadius: "8px",
                                        }}
                                        className="card"
                                    >
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Card
                                                    sx={{
                                                        p: 3,
                                                        px: 5,
                                                        textAlign: "center",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => fetchUserList('totalActiveUsers')}
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: "16px" }}
                                                            className=""
                                                        >
                                                            Active Users
                                                        </Typography>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: "16px" }}
                                                            className=""
                                                        >
                                                            {analyticData.totalActiveUsers}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                                <Card
                                                    sx={{
                                                        p: 3,
                                                        px: 5,
                                                        textAlign: "center",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => fetchUserList('totalInActiveUsers')}
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: "16px" }}
                                                            className=""
                                                        >
                                                            In-Active Users
                                                        </Typography>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            style={{ marginBottom: "16px" }}
                                                            className=""
                                                        >
                                                            {analyticData.totalInActiveUsers}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box
                            // maxWidth="xl"
                            sx={{
                                overflow: "hidden",
                                bgcolor: "#F6F8FC",
                                width: "100%",
                                p: 5,
                                borderRadius: 2,
                                mt: 1,
                            }}
                        >
                            <Typography
                                variant="p"
                                component="div"
                                style={{ marginBottom: "16px", display: 'flex', alignItems: 'center', gap: '5px' }}
                                className="heading"
                            >
                                <StoreMallDirectoryOutlinedIcon />
                                Status wise users
                            </Typography>
                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Card
                                        sx={{
                                            pt: 2,
                                            height: "100%",
                                            borderRadius: "8px",
                                        }}
                                        className="card"
                                    >
                                        <CardContent>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexWrap: "wrap",
                                                        gap: "5px"
                                                    }}
                                                >
                                                    <Grid container spacing={2} sx={{ mt: 1 }}>
                                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                                            <Card
                                                                sx={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => fetchUserList('activeAdmins')}
                                                            >
                                                                <CardContent>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        Active Admins
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        {analyticData.activeAdmins}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                                            <Card
                                                                sx={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => fetchUserList('inActiveAdmins')}
                                                            >
                                                                <CardContent>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        In-Active Admins
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        {analyticData.inActiveAdmins}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                                            <Card
                                                                sx={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => fetchUserList('activeAuditors')}
                                                            >
                                                                <CardContent>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        Active Auditors
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        {analyticData.activeAuditors}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                                            <Card
                                                                sx={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => fetchUserList('inActiveAuditors')}
                                                            >
                                                                <CardContent>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        In-Active Auditors
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        {analyticData.inActiveAuditors}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                                            <Card
                                                                sx={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => fetchUserList('activeViewers')}
                                                            >
                                                                <CardContent>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        Active Viewers
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        {analyticData.activeViewers}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                                            <Card
                                                                sx={{
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => fetchUserList('inActiveViewers')}
                                                            >
                                                                <CardContent>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        In-Active Viewers
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="p"
                                                                        component="div"
                                                                        style={{ marginBottom: "16px" }}
                                                                        className=""
                                                                    >
                                                                        {analyticData.inActiveViewers}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

            </Box >
        </>
    )
};

export default AnalyticalDashboard;