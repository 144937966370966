import React, { useEffect, useState } from "react";
import { Stack, Tabs, Tab, Button, Card, Grid, Box } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";
import SearchAppBar from "./SearchButton";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  AddCategorySuccess,
  AddProductSuccess,
} from "../../redux/actions/WasteManagement";
import ExportProduct from "../products/productTab/pages/Export";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Header = (props) => {
  const [value, setValue] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleActive = () => {
    props.setActiveChecked(true);
    props.setCheckedArchive(false);
  };
  const handleArchived = () => {
    props.setActiveChecked(false);
    props.setCheckedArchive(true);
  };

  return (
    <>
      <Card className="header-card">
        <Grid container spacing={1} alignItems="center" className="toolbar-container">
          <Grid item xs={12} sm={5} md={4} lg={4} className="colomn-padding">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs example"
              className="tabs-content-parent"
            >
              <Tab
                sx={{marginLeft: {xs:"0px", sm: "0px", md: "0px", lg: "8px"}}}
                label="Active"
                icon={<p className="active-tabs-content">{props.totalitems}</p>}
                iconPosition="end"
                onClick={handleActive}
                className={
                  props.checkedArchive === false
                    ? "active-bg"
                    : "Archived-tabs tabs-content"
                }
                // className="active-tabs tabs-content"
              />
              <Tab
                label="Archived"
                icon={
                  <p className="active-tabs-content">
                    {props.ArchivedListTotalItems}
                  </p>
                }
                iconPosition="end"
                onClick={handleArchived}
                className={
                  props.checkedArchive === true
                    ? "active-bg"
                    : "Archived-tabs tabs-content"
                }
                // className="Archived-tabs tabs-content"
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={4} className="colomn-padding">
            {/* <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
              <Button className="header-add-filter bg">
                <FilterAltOutlinedIcon />
                Filter
              </Button>
            </Stack> */}
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4} className="colomn-padding">
            <Stack direction="row" spacing={1} sx={{ float: "right" , display: {xs: "contents", sm: "flex", md: "flex"} }}>
              <SearchAppBar
                searchValue={props.searchValue}
                searchOnChange={props.searchOnChange}
                handleKeyDown={props.handleKeyDown}
              />
              <div style={{marginTop:"9px",marginRight:"8px"}}>
              <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                </Tooltip>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Header;

export const ViewHeader = ({ viewData }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (viewData) {
      const tableData =
        viewData?.products &&
        viewData?.products?.length > 0 &&
        viewData?.products?.map((item, i) => {
          return {
            title: item?.product?.title,
            description: item?.product?.description,
            CreatedOn: `${moment(item?.product?.createdAt).format("lll")}`,
            category: item?.product?.category?.title,
            uom: item?.product?.uom?.title,
            quantity: item?.quantity,
            price: item?.product?.price,
            totalPrice: item?.product?.price * item?.quantity,
            addedBy: viewData?.addedBy?.email,
            first_name: viewData?.addedBy?.first_name,
            store: viewData?.store?.name,
          };
        });
      if (tableData?.length > 0) {
        setData(tableData);
      }
    }
  }, [viewData]);
  return (
    <Box
      sx={{
        bgcolor: "#F6F8FC",
        width: "100%",
        borderRadius: "8px",
        p: 1,
      }}
      className="header-card"
    >
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ pl: 2 }}
            className="date-content"
          >
            <div>Created :</div>
            <strong>
              {moment(viewData?.createdAt).format("MMMM D, YYYY")}
            </strong>
            <div>Last Modified :</div>
            <strong>
              {moment(viewData?.updatedAt).format("MMMM D, YYYY")}
            </strong>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ float: "right", mr: 2 }}
            className="date-content"
          >
            {/* <SearchAppBar /> */}
            {/* <Button
              variant="outlined"
              color="error"
              className="button-responsive"
            >
              <DeleteOutlineIcon sx={{ mr: 1 }} /> Delete
            </Button> */}
            {/* <Button
              variant="outlined"
              color="primary"
              className="button-responsive export-button cancle-hover"
            >
              <ContentPasteGoOutlinedIcon sx={{ mr: 1 }} /> Export
            </Button> */}
            <CSVLink
              data={data ? data : ""}
              style={{ color: "white", textDecoration: "none" }}
              filename="CustomerLogData"
            >
              <Button className="header-add cancle-hover">
                <FileDownloadIcon sx={{ mr: 1 }} />
                Export
              </Button>
            </CSVLink>
            <Link
              to="/waste-management"
              className="submenu-item button-responsive"
            >
              <Button
                variant="outlined"
                className="done-btn button-responsive cancle-hover"
              >
                <DoneIcon sx={{ mr: 1 }} /> Done
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export const ProductHeader = ({
  title,
  open,
  setOpen,
  setOpenCat,
  productListData,
}) => {
  const dispatch = useDispatch();

  const handleAddProduct = () => {
    dispatch(AddProductSuccess(""));
    dispatch(AddCategorySuccess(""));
    if (title == "productlist") {
      setOpen(true);
    } else if (title == "categorylist" || title == "uomlist") {
      setOpenCat(true);
    }
  };

  const handleExport = (e) => {
    console.log("export", e);
  };

  return (
    <Box
      sx={{
        bgcolor: "#F6F8FC",
        width: "100%",
        borderRadius: "8px",
        p: 0,
      }}
      className="header-card"
    >
      <Grid container className="toolbar-container">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ pl: 2 }}
            
          >
            <div>
              <h4>
                {title == "productlist"
                  ? "Product List"
                  : title == "categorylist"
                  ? "Category List"
                  : title == "uomlist"
                  ? "UOM List"
                  : ""}
              </h4>
            </div>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} className="toolbar-small">
          <Stack
            direction="row"
            spacing={1}
            sx={{ float: "right", mr: 2, mt: 1}}
            
          >
            {/* <SearchAppBar /> */}
            {/* <Button
            variant="outlined"
            color="error"
            className="button-responsive"
          >
            <DeleteOutlineIcon sx={{ mr: 1 }} /> Delete
          </Button> */}
            {title == "productlist" ? (
              <ExportProduct productListData={productListData} />
            ) : (
              // <Button
              //   onClick={handleExport}
              //   variant="outlined"
              //   color="primary"
              //   className="button-responsive export-button cancle-hover"
              // >
              //   <ContentPasteGoOutlinedIcon sx={{ mr: 1 }} /> Export
              // </Button>
              ""
            )}
            <Button
              variant="outlined"
              className="done-btn button-responsive cancle-hover addButton"
              onClick={handleAddProduct}
            >
              <DoneIcon sx={{ mr: 1 }} />
              {title == "productlist"
                ? "Add Product"
                : title == "categorylist"
                ? "Add Category"
                : title == "uomlist"
                ? "Add Uom"
                : ""}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
