import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as moment from "moment";
import axios from "axios";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReactExport from "react-export-excel-xlsx-fix";
import { useRef } from "react";

const inspectionOption = [
  {
    title: "Cleaning",
    value: "cleaning",
    query:
      "Describe attached images based on very-clean, standard-clean, basic-clean, poor-clean category. Start with category in line one and then explain reason.",
    categories: [
      {
        title: "Very Clean",
        value: "very-clean",
      },
      {
        title: "Standard Clean",
        value: "standard-clean",
      },
      {
        title: "Basic Clean",
        value: "basic-clean",
      },
      {
        title: "Poor Clean",
        value: "poor-clean",
      },
      {
        title: "Unclassified",
        value: "unclassified",
      },
    ],
  },
  {
    title: "Arrangments",
    value: "Arrangments",
    categories: [
      {
        title: "Well Organized",
        value: "well-organized",
      },
      {
        title: "Standard Organized",
        value: "standard-organized",
      },
      {
        title: "Basic Organized",
        value: "basic-organized",
      },
      {
        title: "Poor Organized",
        value: "poor-organized",
      },
      {
        title: "Unclassified",
        value: "unclassified",
      },
    ],
    query:
      "Describe attached images based on well-organized, standard-organized, basic-organized, poor-organized category. Consider Product Placement and Organization, Accessibility and Aisle Spacing, Category Signage and Labeling, Cleanliness and Maintenance, Safety and Compliance. Start with category in line one and then explain reason.",
  },
];

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function ChecklistInspection() {
  // const loader = useSelector(
  //   (state) => state && state?.checklist && state?.checklist?.loading
  // );
  const btnUserWiseRef = useRef();

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD").split("/").join("")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD").split("/").join("")
  );
  const [selectedInspection, setSelectedInspection] = useState("");
  const [inspectionData, setInspectionData] = useState([]);
  const [failedInspectionData, setfailedInspectionData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const [displayInspectionData, setDisplayInspectionData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [inspectionWiseData, setInspectionWiseData] = useState([]);

  console.log("inspectionData", inspectionData);
  console.log("displayInspectionData", displayInspectionData);

  const handleInspection = (event, newValue) => {
    if (newValue) {
      setSelectedInspection(newValue);
      setInspectionData([]);
    }
  };

  const handleChangeTabs = (event, newValue) => {
    // console.log("event, newValue", newValue);
    // if (newValue) {
    setSelectedValue(newValue);
    // }
    const target = [];

    inspectionData &&
      inspectionData.length &&
      inspectionData.map((item) => {
        if (selectedInspection.categories[newValue].value === "unclassified") {
          if (item.status === "rejected") {
            target.push({ data: item.reason });
          } else {
            let isMatched = false;
            selectedInspection.categories.map((category) => {
              if (
                item.value.imgResult
                  .substring(0, 30)
                  .toLowerCase()
                  .includes(category.value)
              ) {
                isMatched = true;
              }
            });
            if (!isMatched) {
              target.push({ data: item.value });
            }
          }
        } else if (
          item.status !== "rejected" &&
          item.value.imgResult
            .substring(0, 30)
            .toLowerCase()
            .includes(selectedInspection.categories[newValue].value)
        ) {
          if (item.status !== "rejected") {
            target.push({ data: item.value });
          }
        }
      });

    setDisplayInspectionData(target);
  };

  const handleDateClick = () => {
    setLoader(true);
    const data = {};
    const id = localStorage.getItem("companyId");
    const checkListId = localStorage.getItem("checkListId");
    data.url =
      BASE_URL +
      `Imagegallery?company_id=${id}&_id=${checkListId}&startTimeFilter=${startDate}&endTimeFilter=${endDate}`;

    const token = localStorage.getItem("token");

    if (selectedInspection.query) {
      axios
        .get(data.url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("res", res.data.data);
          const formData = res.data.data;
          let newImgsArray = [];
          formData &&
            formData.length &&
            formData.map((items, i) => {
              if (items?.Forms) {
                items?.Forms?.map((formImgs) => {
                  formImgs.questionImages.map((img) => {
                    newImgsArray.push({
                      img: img,
                      formData: formImgs,
                      storeData: items,
                    });
                  });
                });
              }
            });

          Promise.allSettled(
            newImgsArray.map((img) => {
              return new Promise((resolve, reject) => {
                const body = {
                  model: "gpt-4-turbo",
                  messages: [
                    {
                      role: "user",
                      content: [
                        {
                          type: "text",
                          text: selectedInspection.query,
                        },
                        {
                          type: "image_url",
                          image_url: {
                            url: img.img.url,
                          },
                        },
                      ],
                    },
                  ],
                  max_tokens: 300,
                };
                axios
                  .post("https://api.openai.com/v1/chat/completions", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer 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`,
                    },
                  })
                  .then((response) => {
                    if (response?.data?.choices[0]?.message?.content) {
                      resolve({
                        imgResult: response?.data?.choices[0]?.message?.content,
                        imgData: img,
                      });
                    } else {
                      // setInspectionResp('Responce Not Found');
                    }
                    // setLodingPrompt(false);
                  })
                  .catch((error) => {
                    reject({ result: error, imgData: img });
                  });
              });
            })
          ).then((result) => {
            // const d = result.filter((r) => r.status === "fulfilled");
            setInspectionData(result);
            const target = [];

            result &&
              result.length &&
              result.map((item) => {
                if (
                  item.status !== "rejected" &&
                  item.value.imgResult
                    .substring(0, 30)
                    .toLowerCase()
                    .includes(selectedInspection.categories[0].value)
                ) {
                  if (item.status !== "rejected") {
                    target.push({ data: item.value });
                  }
                }
              });

            setDisplayInspectionData(target);

            const fd = result.filter((r) => r.status === "rejected");
            setfailedInspectionData(fd);
            if (fd.length) {
              toast.error(
                "Something went wrong! please try to contact your administrator.",
                5000
              );
            }
            setLoader(false);
          });
        });
    } else {
      toast.error("Please select inspection query");
      setLoader(false);
    }

    // dispatch(ChecklistImageApi(data));
  };

  useEffect(() => {
    let subArr = [];
    let all = "All Dates";

    selectedInspection &&
      selectedInspection.categories &&
      selectedInspection.categories.map((itemSelected) => {
        const formattedSD = itemSelected.title;
        // let allCsvColumns = [];
        let allCsvSubRows = [];

        let allColumnsList = [];
        allColumnsList.push(
          {
            value: "User Name",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Store Name",
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Form Title",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Submitted Time",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Inspection data",
            widthPx: 1000,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          },
          {
            value: "Img Url",
            widthPx: 500,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          }
        );

        const target = [];

        inspectionData &&
          inspectionData.length &&
          inspectionData.map((item) => {
            console.log("target itemSelected.value", itemSelected.value);
            console.log("target itemSelected ==== item ====", item);
            if (itemSelected.value === "unclassified") {
              if (item.status === "rejected") {
                // target.push({ data: item.reason });
                allCsvSubRows.push(
                  [{
                    value: item.reason.imgData.storeData.userName,
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: item.reason.imgData.storeData.storeName,
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: item.reason.imgData.formData.title,
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: moment(
                      item.reason.imgData.formData?.submittedTime
                    ).format("ll"),
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: item.reason.imgResult
                      ? item.reason.imgResult
                      : "Something went wrong! please try to contact your administrator.",
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: item.reason.imgData.img.url,
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  }]
                );
              } else {
                let isMatched = false;
                selectedInspection.categories.map((category) => {
                  if (
                    item.value.imgResult
                      .substring(0, 30)
                      .toLowerCase()
                      .includes(category.value)
                  ) {
                    isMatched = true;
                  }
                });
                if (!isMatched) {
                  // target.push({ data: item.value });
                  allCsvSubRows.push(
                    [{
                      value: item.value.imgData.storeData.userName,
                      widthPx: 130,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    },
                    {
                      value: item.value.imgData.storeData.storeName,
                      widthPx: 130,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    },
                    {
                      value: item.value.imgData.formData.title,
                      widthPx: 130,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    },
                    {
                      value: moment(
                        item.value.imgData.formData?.submittedTime
                      ).format("ll"),
                      widthPx: 130,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    },
                    {
                      value: item.value.imgResult
                        ? item.value.imgResult
                        : "Something went wrong! please try to contact your administrator.",
                      widthPx: 130,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    },
                    {
                      value: item.value.imgData.img.url,
                      widthPx: 130,
                      style: {
                        font: { sz: "24", bold: true },
                        alignment: { wrapText: true },
                      },
                    }]
                  );
                }
              }
            } else if (
              item.status !== "rejected" &&
              item.value.imgResult
                .substring(0, 30)
                .toLowerCase()
                .includes(itemSelected.value)
            ) {
              if (item.status !== "rejected") {
                // target.push({ data: item.value });
                allCsvSubRows.push([
                  {
                    value: item.value.imgData.storeData.userName,
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: item.value.imgData.storeData.storeName,
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: item.value.imgData.formData.title,
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: moment(
                      item.value.imgData.formData?.submittedTime
                    ).format("ll"),
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: item.value.imgResult
                      ? item.value.imgResult
                      : "Something went wrong! please try to contact your administrator.",
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                  {
                    value: item.value.imgData.img.url,
                    widthPx: 130,
                    style: {
                      font: { sz: "24", bold: true },
                      alignment: { wrapText: true },
                    },
                  },
                ]);
              }
            }
          });

        if (!allCsvSubRows.length) {
          allCsvSubRows.push(
            [{
              value: "",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            },
            {
              value: "",
              widthPx: 130,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            }]
          );
        }
        // console.log("target =====>", target);
        const dataSet = [
          {
            columns: allColumnsList,
            data: allCsvSubRows,
          },
        ];
        subArr.push({ formattedSD, dataSet });
      });

    if (subArr.length) {
      console.log("subArr =====>", subArr);
      // const dataSet = [
      //   {
      //     columns: allColumnsList,
      //     data: allCsvSubRows,
      //   },
      // ];
      // subArr.push({ formattedSD: all, dataSet });
      // setCSVNamSubmissionFlag(false);
      // Sort subArr by date in ascending order
      // subArr.sort((a, b) => new Date(b.formattedSD) - new Date(a.formattedSD));
      setInspectionWiseData(subArr);
    }
  }, [inspectionData]);
  console.log("inspectionData", inspectionData);

  return (
    <Box>
      <ToastContainer autoClose={1000} />
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            position: "absolute",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}
      <>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Stack
                direction="row"
                className="stack"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={startDate}
                        onChange={(newValue) => {
                          let dateSort = moment(newValue?.$d)
                            .format("YYYY-MM-DD")
                            .split("/")
                            .join("");
                          setStartDate(dateSort);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        className="date-pick"
                        maxDate={new Date()}
                      />
                    </LocalizationProvider>
                    <Typography className="date-to">to</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        size="small"
                        value={endDate}
                        onChange={(newValue) => {
                          let dateSort = moment(newValue?.$d)
                            .format("YYYY-MM-DD")
                            .split("/")
                            .join("");
                          setEndDate(dateSort);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        className="date-pick"
                        maxDate={new Date()}
                        minDate={startDate}
                      />
                    </LocalizationProvider>
                    <Autocomplete
                      fullWidth
                      options={inspectionOption}
                      style={{
                        width: "250px",
                      }}
                      getOptionLabel={(option) => option?.title || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          size="small"
                          sx={{ borderRadius: "8px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              // handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedInspection}
                      onChange={handleInspection}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.title}
                        </li>
                      )}
                      // disabled={reportType === "submission"}
                      clearable
                    // onClear={handleClearSelection}
                    />
                    <Button
                      className="header-add cancle-hover"
                      style={{ height: "46px", padding: "12px 16px" }}
                      onClick={(e) => handleDateClick(e)}
                    >
                      <CheckOutlinedIcon sx={{ mr: 1 }} />
                      Apply
                    </Button>
                  </Box>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {inspectionData.length &&
                    selectedInspection.categories.length && (
                      <Box
                        sx={{
                          width: "200px",
                        }}
                      >
                        <ExcelFile
                          element={
                            <Button
                              className="header-add cancle-hover"
                              style={{
                                whiteSpace: "nowrap",
                                width: "80%",
                              }}
                              ref={btnUserWiseRef}
                            >
                              Export Excel
                              <FileDownloadIcon />
                            </Button>
                          }
                          filename={
                            "PopprobeChecklistImageIspection_" + Date.now()
                          }
                        >
                          {inspectionWiseData.map((csvData, key) => (
                            <ExcelSheet
                              key={key}
                              dataSet={csvData.dataSet}
                              name={csvData.formattedSD}
                            />
                          ))}
                        </ExcelFile>
                      </Box>
                    )}
                  <div style={{ marginRight: "8px", marginLeft: "20px" }}>
                    <Tooltip
                      title={
                        <div style={{ maxWidth: "300px" }}>
                          <strong>Advance Checklist Wise Report:</strong>
                          <p>
                            Status: Display's selected checklist completion
                            status, completion percentage & average compliance
                            scores for the selected user and stores.
                          </p>
                        </div>
                      }
                      arrow
                      interactive
                    >
                      <InfoIcon
                        style={{
                          verticalAlign: "middle",
                          cursor: "pointer",
                          color: "#D76C00",
                        }}
                      />
                    </Tooltip>
                  </div>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            height: "80vh",
            mt: 1,
            p: 1,
            overflowX: "hidden",
            overflowY: "auto",
            borderRadius: "8px",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              padding: "12px 5px",
              backgroundColor: "#CBD4E1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#64748B",
              borderRadius: "8px",
            },
          }}
        >
          <Grid>
            <Tabs
              value={selectedValue}
              onChange={handleChangeTabs}
              variant="scrollable"
              scrollButtons={false}
              aria-label="scrollable prevent tabs"
              className="tabs-content-parent inspection-tab"
            >
              {inspectionData.length &&
                selectedInspection.categories.map((item, i) => {
                  return (
                    <Tab
                      label={item.title}
                      className={`${selectedValue === i ? "active-bg" : "tabs-content"
                        }`}
                      key={item.title}
                    />
                  );
                })}
            </Tabs>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            {/* {inspectionData && inspectionData.length
              ? inspectionData.map((item) => {
                  if (
                    selectedInspection.categories[selectedValue].value ===
                    "unclassified"
                  ) {
                    let isMatched = false;

                    selectedInspection.categories.map((category) => {
                      if (
                        item.value.imgResult
                          .substring(0, 30)
                          .toLowerCase()
                          .includes(category.value)
                      ) {
                        isMatched = true;
                      }
                    });
                    if (!isMatched) {
                      return (
                        <Grid
                          sx={{
                            border: "1px solid #64748b",
                            borderRadius: "10px",
                            padding: "10px",
                            background: "#fff",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "space-between",
                              padding: "10px",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                width: "calc(100% - 300px)",
                              }}
                            >
                              <Typography
                                align="left"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <strong>User Name: </strong>
                                {item.value.imgData.storeData.userName}
                              </Typography>
                              <Typography
                                align="left"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <strong>Store Name: </strong>
                                {item.value.imgData.storeData.storeName}
                              </Typography>
                              <Typography
                                align="left"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <strong>Form Title: </strong>
                                {item.value.imgData.formData.title}
                              </Typography>
                              <Typography
                                align="left"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <strong>Submitted Time: </strong>
                                {moment(
                                  item.value.imgData.formData?.submittedTime
                                ).format("ll")}
                              </Typography>
                              <Typography
                                align="left"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <strong>Inspection data: </strong>
                                <pre
                                  style={{
                                    wordWrap: "break-word",
                                    textWrap: "wrap",
                                    margin: "8px 0",
                                  }}
                                >
                                  {item.value.imgResult}
                                </pre>
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                border: "2px solid darkGray",
                                borderRadius: "8px",
                                padding: "10px",
                                columnGap: "10px",
                                width: "280px",
                                display: "flex",
                                // height: "200px",
                              }}
                            >
                              <img
                                height={250}
                                width={"100%"}
                                src={item.value.imgData.img.url}
                                alt={item.value.imgData.formData.title}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }
                  } else if (
                    item.value.imgResult
                      .substring(0, 30)
                      .toLowerCase()
                      .includes(
                        selectedInspection.categories[selectedValue].value
                      )
                  ) {
                    return (
                      <Grid
                        sx={{
                          border: "1px solid #64748b",
                          borderRadius: "10px",
                          padding: "10px",
                          background: "#fff",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            padding: "10px",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "calc(100% - 300px)",
                            }}
                          >
                            <Typography
                              align="left"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <strong>User Name: </strong>
                              {item.value.imgData.storeData.userName}
                            </Typography>
                            <Typography
                              align="left"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <strong>Store Name: </strong>
                              {item.value.imgData.storeData.storeName}
                            </Typography>
                            <Typography
                              align="left"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <strong>Form Title: </strong>
                              {item.value.imgData.formData.title}
                            </Typography>
                            <Typography
                              align="left"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <strong>Submitted Time: </strong>
                              {moment(
                                item.value.imgData.formData?.submittedTime
                              ).format("ll")}
                            </Typography>
                            <Typography
                              align="left"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <strong>Inspection data: </strong>
                              <pre
                                style={{
                                  wordWrap: "break-word",
                                  textWrap: "wrap",
                                  margin: "8px 0",
                                }}
                              >
                                {item.value.imgResult}
                              </pre>
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              border: "2px solid darkGray",
                              borderRadius: "8px",
                              padding: "10px",
                              columnGap: "10px",
                              width: "280px",
                              display: "flex",
                              // height: "200px",
                            }}
                          >
                            <img
                              height={250}
                              width={"100%"}
                              src={item.value.imgData.img.url}
                              alt={item.value.imgData.formData.title}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    );
                  }
                })
              : ""} */}
            {inspectionData && inspectionData.length ? (
              displayInspectionData.length ? (
                displayInspectionData.map((item) => {
                  return (
                    <Grid
                      sx={{
                        border: "1px solid #64748b",
                        borderRadius: "10px",
                        padding: "10px",
                        background: "#fff",
                        width: "100%",
                        margin: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          padding: "10px",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "calc(100% - 300px)",
                          }}
                        >
                          <Typography
                            align="left"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            <strong>User Name: </strong>
                            {item.data.imgData.storeData.userName}
                          </Typography>
                          <Typography
                            align="left"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            <strong>Store Name: </strong>
                            {item.data.imgData.storeData.storeName}
                          </Typography>
                          <Typography
                            align="left"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            <strong>Form Title: </strong>
                            {item.data.imgData.formData.title}
                          </Typography>
                          <Typography
                            align="left"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            <strong>Submitted Time: </strong>
                            {moment(
                              item.data.imgData.formData?.submittedTime
                            ).format("ll")}
                          </Typography>
                          <Typography
                            align="left"
                            style={{
                              fontSize: "16px",
                            }}
                          >
                            <strong>Inspection data: </strong>
                            <pre
                              style={{
                                wordWrap: "break-word",
                                textWrap: "wrap",
                                margin: "8px 0",
                              }}
                            >
                              {item.data.imgResult
                                ? item.data.imgResult
                                : "Something went wrong! please try to contact your administrator."}
                            </pre>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            border: "2px solid darkGray",
                            borderRadius: "8px",
                            padding: "10px",
                            columnGap: "10px",
                            width: "280px",
                            display: "flex",
                            // height: "200px",
                          }}
                        >
                          <img
                            height={250}
                            width={"100%"}
                            src={item.data.imgData.img.url}
                            alt={item.data.imgData.formData.title}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  sx={{
                    border: "1px solid #64748b",
                    borderRadius: "10px",
                    padding: "10px",
                    background: "#fff",
                    width: "100%",
                    margin: "10px",
                  }}
                >
                  No Images Found
                </Grid>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </>
    </Box>
  );
}

export default ChecklistInspection;
