/** @format */

import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  TextField,
  Grid,
  Container,
  Button,
  Stack,
  Select,
  MenuItem,
  Fab,
  LinearProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { createTheme } from "@mui/material/styles";
// import MUIRichTextEditor from "mui-rte";
import { useFormik } from "formik";
// import { convertToRaw } from "draft-js";
// import Blank from "../../../images/Blank.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
// import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
// import { AddImageSuccess, ImageAddApi } from "../../../redux/actions/User";
import { useDispatch } from "react-redux";
// import {
//   AddTrainingImageApi,
//   AddCertificateImageApi,
// } from "../../../redux/actions/LmsAction";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import Progressbar from "../../../GlobalProgress/Progressbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddChapter = () => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];
  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );
  const getImageKey = useSelector(
    (state) =>
      state.lms &&
      state.lms.trainingImage &&
      state.lms.trainingImage.data &&
      state.lms.trainingImage &&
      state.lms.trainingImage.data.data
  );
  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [image, setImage] = useState();
  const [uploadFileKey, setUploadFileKey] = useState("");
  const [contentState, setContentState] = useState("");

  const [textValue, setTextValue] = useState("");
  const [preview, setPreview] = useState();
  const pathEdit = location?.state?.path;
  const allData = location?.state?.row;
  const [textContentValue, setTextContentValue] = useState("");
  const reactQuillRef = React.useRef();

  useEffect(() => {
    if (getImageKey && getImageKey[0]?.fileKey) {
      setUploadFileKey(getImageKey[0]?.fileKey);
    }
  }, [getImageKey]);

  useEffect(() => {
    setImage("");
    setContentState("");
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string("Required").required("Required"),
    type: Yup.string("Required").required("Required"),
    summary: Yup.string("Required").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      summary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.type === 1) {
        const jasontext = JSON.stringify(textContentValue);
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getLength() === 1) {
          toast.error("Please enter content and try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          values.content = jasontext;
          newFunction(values);
        }
      } else if (!image) {
        toast.error("Please attach a file and try again.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        values.content = image;
        newFunction(values);
      }
    },
  });

  const newFunction = (values) => {
    if (pathEdit === "addchapter") {
      if (values) {
        values._id = uuidv4();
        const method =
          allData &&
          allData?.steps &&
          allData?.steps?.map((val, i) => {
            return val;
          });
        let newArray = method;
        newArray.push(values);
        allData.steps = newArray;
        navigate("/training/edit-training", {
          state: { editeddata: allData, training: "alltrainingreport" },
        });
      }
    } else {
      if (pathEdit === "newaddchapter") {
        if (values.content !== "") {
          if (allData?.length > 0) {
            values.id = allData?.length + 1;
            const method =
              allData &&
              allData?.map((val, i) => {
                return val;
              });
            let newArray = method;
            newArray.push(values);
            navigate("/training/add-training", {
              state: { steps: newArray, trainingPath: "alltrainingreport" },
            });
          } else {
            values.id = 1;
            let newArray = [];
            newArray.push(values);
            navigate("/training/add-training", {
              state: { steps: newArray, trainingPath: "alltrainingreport" },
            });
          }
        }
      }
    }
  };

  const handleCancle = () => {
    if (pathEdit === "addchapter") {
      navigate("/training/edit-training", {
        state: {
          editeddata: allData,
          canclee: "cancle",
          training: "alltrainingreport",
        },
      });
    } else {
      navigate("/training/add-training", {
        state: { steps: allData, trainingPath: "alltrainingreport" },
      });
    }
  };

  const onEditorChange = (event) => {
    const rteContent = convertToRaw(event.getCurrentContent());
    if (rteContent) {
      setTextValue(JSON.stringify(rteContent));
    } else {
      setTextValue("");
    }
  };

  const addImageUpload = (e) => {
    setContentState("");
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const maxSize = selectedFile.size;
      const fileName = selectedFile.name;

      switch (formik.values.type) {
        case 1: // Text
          // No restrictions for text content
          setImage(selectedFile);
          break;

        case 2: // Video
          if (!isVideoTypeAllowed(fileName)) {
            toast.error("Please upload only MP4, FLV, 3GP, and MKV files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (maxSize > 156 * 1024 * 1024) {
            // Allow video files up to 156 MB
            toast.error("We allow only up to 156MB video files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setImage(selectedFile);
          }
          break;

        case 3: // Audio
          if (!isAudioTypeAllowed(fileType)) {
            toast.error("Please upload only MP3 and recording files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (maxSize > 5 * 1024 * 1024) {
            toast.error("We allow only up to 5MB audio files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setImage(selectedFile);
          }
          break;
        case 4: // Image
          if (!isImageTypeAllowed(fileType)) {
            toast.error("Please attach only JPEG, JPG, and PNG files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (maxSize > 5 * 1024 * 1024) {
            toast.error("We allow only up to 5MB image files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            setImage(selectedFile);
          }
          break;
        case 5: // Doc
          if (!isDocumentTypeAllowed(fileType)) {
            toast.error("Please upload only Doc, Excel, PPT, and PDF files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (maxSize > 25 * 1024 * 1024) {
            toast.error("We allow only up to 25MB document files.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (/^[\'"$]/.test(fileName)) {
            toast.error(
              "File names starting with special characters are not allowed.",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          } else {
            setImage(selectedFile);
          }
          break;
        default:
          toast.error("Unsupported content type.", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    }
  };

  const isVideoTypeAllowed = (fileName) => {
    const allowedVideoExtensions = [
      ".mp4",
      ".flv",
      ".3gp",
      ".mkv",
      ".mov",
      ".wmv",
    ];
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    return allowedVideoExtensions.includes("." + fileExtension.toLowerCase());
  };

  const isAudioTypeAllowed = (fileType) => {
    return [
      "audio/mpeg",
      "audio/mp3",
      "audio/wav",
      "audio/ogg",
      "audio/aac",
    ].includes(fileType);
  };

  const isImageTypeAllowed = (fileType) => {
    return ["image/jpeg", "image/jpg", "image/png"].includes(fileType);
  };

  const isDocumentTypeAllowed = (fileType) => {
    return [
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(fileType);
  };

  const handleDeleteImage = (img) => {
    setPreview();
    if (image !== "") {
      setImage("");
    }
  };
  const handleEditor = (content, delta, source, editor) => {
    console.log("content", content);
    const sanitizedContent = editor.getText(content);
    console.log("Sanitized content:", sanitizedContent);
    setTextContentValue(content);
  };

  return (
    <Box>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Button
                  onClick={handleCancle}
                  className="cancle-button cancle-hover"
                >
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>

                <Button
                  className="header-add cancle-hover"
                  type="submit"
                  disabled={Loader}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Create
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        {Loader && <LinearProgress />}
        <Box
          sx={{
            bgcolor: "#F6F8FC",
            mt: 1,
            p: 2,
            borderRadius: "8px",
            pl: 3,
            height: "76vh",
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
              minHeight: "350px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={8} sm={12} xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Title</label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="date-input"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Content Type</label>
                  <Select
                    size="small"
                    name="type"
                    onChange={formik.handleChange}
                    value={formik.values.type}
                    sx={{ borderRadius: "8px" }}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  >
                    <MenuItem value={3}>Audio</MenuItem>
                    <MenuItem value={5}>Doc</MenuItem>
                    <MenuItem value={4}>Image</MenuItem>
                    <MenuItem value={1}>Text</MenuItem>
                    <MenuItem value={2}>Video</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Chapter Summary</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                name="summary"
                onChange={formik.handleChange}
                value={formik.values.summary}
                error={formik.touched.summary && Boolean(formik.errors.summary)}
                helperText={formik.touched.summary && formik.errors.summary}
                className="date-input"
              />
            </FormControl>
            {formik.values.type === 5 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                {image ? (
                  <Box className="content-image-box">
                    <h3>
                      {image?.name}
                      <p>{image?.size}</p>
                    </h3>
                    <h3>
                      <DeleteIcon
                        fontSize="medium"
                        className="chapter-upload-image"
                        color="error"
                        onClick={handleDeleteImage}
                      />
                    </h3>
                  </Box>
                ) : (
                  <Box className="content-training-box">
                    <input
                      // accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    />
                    <label
                      htmlFor="contained-button-file"
                      className="label-upload"
                    >
                      <Fab component="span" className="content-training">
                        <CloudUploadIcon sx={{ mr: 1 }} />
                      </Fab>
                      Browse file(Doc,Excel,PPT,PDF only)
                    </label>
                  </Box>
                )}
              </FormControl>
            ) : formik.values.type === 3 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                {image ? (
                  <Box className="content-image-box">
                    <h3>
                      {image?.name}
                      <p>{image?.size}</p>
                    </h3>
                    <h3>
                      <DeleteIcon
                        fontSize="medium"
                        className="chapter-upload-image"
                        color="error"
                        onClick={handleDeleteImage}
                      />
                    </h3>
                  </Box>
                ) : (
                  <Box className="content-training-box">
                    <input
                      accept="audio/*"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    />
                    <label
                      htmlFor="contained-button-file"
                      className="label-upload"
                    >
                      <Fab component="span" className="content-training">
                        <CloudUploadIcon />
                      </Fab>
                      Browse Audio file
                    </label>
                  </Box>
                )}
              </FormControl>
            ) : formik.values.type === 4 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                {image ? (
                  <Box className="content-image-box">
                    <h3>
                      {image?.name}
                      <p>{image?.size}</p>
                    </h3>
                    <h3>
                      <DeleteIcon
                        fontSize="medium"
                        className="chapter-upload-image"
                        color="error"
                        onClick={handleDeleteImage}
                      />
                    </h3>
                  </Box>
                ) : (
                  <Box className="content-training-box">
                    <input
                      accept=".jpg, .jpeg, .png, image"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    />
                    <label
                      htmlFor="contained-button-file"
                      className="label-upload"
                    >
                      <Fab component="span" className="content-training">
                        <CloudUploadIcon sx={{ mr: 1 }} />
                      </Fab>
                      Browse Image file (JPEG, JPG, PNG only)
                    </label>
                  </Box>
                )}
              </FormControl>
            ) : formik.values.type === 2 ? (
              <FormControl fullWidth sx={{ mb: 2 }}>
                {image ? (
                  <Box className="content-image-box">
                    <h3>
                      {image?.name}
                      <p>{image?.size}</p>
                    </h3>
                    <h3>
                      <DeleteIcon
                        fontSize="medium"
                        className="chapter-upload-image"
                        color="error"
                        onClick={handleDeleteImage}
                      />
                    </h3>
                  </Box>
                ) : (
                  <> <Box className="content-training-box">
                    {/* <input
                      accept="video/*"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    /> */}
                    <input
                      accept=".mp4, .flv, .3gp, .mkv, video/*"
                      className="file-upload"
                      id="contained-button-file"
                      type="file"
                      hidden
                      onChange={addImageUpload}
                    />
                    <label
                      htmlFor="contained-button-file"
                      className="label-upload"
                    >
                      <Fab component="span" className="content-training">
                        <CloudUploadIcon />
                      </Fab>
                      Browse Video file
                    </label>
                  </Box>
                    <label style={{ display: 'flex', justifyContent: 'center' }}>More than 25 MB videos cannot be uploaded</label>
                  </>
                )}
              </FormControl>
            ) : (
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Content</label>
                <ReactQuill
                  height={200}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={textContentValue}
                  onChange={handleEditor}
                  ref={reactQuillRef}
                />
              </FormControl>
            )}
          </Container>
        </Box>
      </form>
    </Box>
  );
};

export default AddChapter;
