/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  CardContent,
  Button,
  Grid,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import Chart from "react-apexcharts";
import ToggleSwitch from "../../../../helpers/ToggleSwitch";
import { useDispatch } from "react-redux";
import { LinechartIssueApi } from "../../../redux/actions/Issue";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

let options = {
  colors: ["#F39A4C", "#039855", "#BB251A"],
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: "top",
      },
      borderRadius: 4,
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
    offsetX: -6,
    style: {
      fontSize: "10px",
      colors: ["none"],
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    shared: false,
    intersect: true,
  },
  xaxis: {
    // categories: ['20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022'],
    lines: {
      show: true,
    },
    tickAmount: 10,
    axisBorder: {
      show: false,
    },
  },
  grid: {
    strokeDashArray: 0,
    position: "front",
    row: {
      opacity: 0.5,
    },
    column: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  legend: {
    show: false,
  },
};

const LineChart = ({ valueDropdown, newDate, startDate, endDate }) => {
  // var Bar = {
  //   series: [
  //     {
  //       data: [44, 55, 41, 64, 74, 92],
  //     },
  //     {
  //       data: [53, 32, 33, 52, 34, 87],
  //     },
  //     {
  //       data: [8, 2, 10, 23, 23, 89],
  //     },
  //   ],
  //   options: {
  //     colors: ["#039855", "#F39A4C", "#BB251A"],
  //     plotOptions: {
  //       bar: {
  //         horizontal: true,
  //         dataLabels: {
  //           position: "top",
  //         },
  //         borderRadius: 4,
  //       },
  //     },
  //     chart: {
  //       toolbar: {
  //         show: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //       offsetX: -6,
  //       style: {
  //         fontSize: "10px",
  //         colors: ["none"],
  //       },
  //     },
  //     stroke: {
  //       show: true,
  //       width: 3,
  //       colors: ["#fff"],
  //     },
  //     tooltip: {
  //       shared: false,
  //       intersect: true,
  //     },
  //     xaxis: {
  //       categories: [
  //         "20-08-2022",
  //         "20-08-2022",
  //         "20-08-2022",
  //         "20-08-2022",
  //         "20-08-2022",
  //         "20-08-2022",
  //       ],
  //       lines: {
  //         show: true,
  //       },
  //       tickAmount: 10,
  //       axisBorder: {
  //         show: false,
  //       },
  //     },
  //     grid: {
  //       strokeDashArray: 0,
  //       position: "front",
  //       row: {
  //         opacity: 0.5,
  //       },
  //       column: {
  //         opacity: 0.5,
  //       },
  //       xaxis: {
  //         lines: {
  //           show: true,
  //         },
  //       },
  //       yaxis: {
  //         lines: {
  //           show: false,
  //         },
  //       },
  //       padding: {
  //         top: 0,
  //         right: 0,
  //         bottom: 0,
  //         left: 0,
  //       },
  //     },
  //     legend: {
  //       show: false,
  //     },
  //   },
  // };
  // let options = {
  //   colors: ["#039855", "#F39A4C", "#BB251A"],
  //   plotOptions: {
  //           bar: {
  //             horizontal: true,
  //             dataLabels: {
  //               position: "top",
  //             },
  //             borderRadius: 4,
  //           },
  //         },
  //   chart: {
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //     offsetX: -6,
  //     style: {
  //       fontSize: "10px",
  //       colors: ["none"],
  //     },
  //   },
  //   stroke: {
  //     show: true,
  //     width: 1,
  //     colors: ["#fff"],
  //   },
  //   tooltip: {
  //     shared: false,
  //     intersect: true,
  //   },
  //   xaxis: {
  //     // categories: ['20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022', '20-08-2022'],
  //     lines: {
  //       show: true,
  //     },
  //     tickAmount: 10,
  //     axisBorder: {
  //       show: false,
  //     },
  //   },
  //   grid: {
  //     strokeDashArray: 0,
  //     position: "front",
  //     row: {
  //       opacity: 0.5,
  //     },
  //     column: {
  //       opacity: 0.5,
  //     },
  //     xaxis: {
  //       lines: {
  //         show: true,
  //       },
  //     },
  //     yaxis: {
  //       lines: {
  //         show: false,
  //       },
  //     },
  //     padding: {
  //       top: 0,
  //       right: 0,
  //       bottom: 0,
  //       left: 0,
  //     },
  //   },
  //   legend: {
  //     show: false,
  //   },
  // };
  const lineChartListing = useSelector(
    (state) =>
      state &&
      state?.issue &&
      state?.issue?.lineChartData &&
      state?.issue?.lineChartData?.data &&
      state?.issue?.lineChartData?.data?.data &&
      state?.issue?.lineChartData?.data?.data?.Category
  );
  const departmentListing = useSelector(
    (state) =>
      state &&
      state?.issue &&
      state?.issue?.lineChartData &&
      state?.issue?.lineChartData?.data &&
      state?.issue?.lineChartData?.data?.data &&
      state?.issue?.lineChartData?.data?.data?.Department
  );
  const storeListing = useSelector(
    (state) =>
      state &&
      state?.issue &&
      state?.issue?.lineChartData &&
      state?.issue?.lineChartData?.data &&
      state?.issue?.lineChartData?.data?.data &&
      state?.issue?.lineChartData?.data?.data?.Store
  );

  const dispatch = useDispatch();
  const [lineChartData, setLineChartData] = useState([]);
  const [optionOne, setOptionOne] = useState(options);
  const [selectMonth, setSelectMonth] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    if (valueDropdown === "department") {
      setLineChartData(departmentListing);
    }
    if (valueDropdown === "store") {
      setLineChartData(storeListing);
    }
    if (valueDropdown === "categoryData") {
      setLineChartData(lineChartListing);
    }
  }, [lineChartListing, departmentListing, storeListing, valueDropdown]);

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined && valueDropdown) {
      lineChartList(startDate, endDate);
    }
  }, [valueDropdown, startDate, endDate]);

  useEffect(() => {
    var today = new Date();
    var month = today.toLocaleString("default", { month: "long" });
    setSelectMonth(month);
  }, []);

  useEffect(() => {
    if (lineChartData) {
      let series = [
        {
          name: "In-Progress",
          data: [],
        },
        {
          name: "Open",
          data: [],
        },
        {
          name: "Resolved",
          data: [],
        },
      ];
      let nameData = [];
      for (let item of lineChartData) {
        nameData.push(item.Name);
        series[0].data.push(item.Inprogress);
        series[1].data.push(item.Open);
        series[2].data.push(item.Resolved);
      }
      setOptionOne((options) => ({
        ...options,
        xaxis: {
          ...options.xaxis,
          categories: nameData,
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
      }));
      setCategoryData(series);
    }
  }, [lineChartData]);

  const lineChartList = (startDate, endDate) => {
    if (startDate !== undefined && endDate !== undefined) {
      const id = localStorage.getItem("companyId");
      const data = {
        url:
          BASE_URL +
          `IssueChart?company_id=${id}&moduleName=issue-log&issueStartTime=${startDate}&issueEndTime=${endDate}`,
      };
      dispatch(LinechartIssueApi(data));
    }
  };

  return (
    <Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card sx={{ p: 2, m: 1, borderRadius: "8px" }}>
            <div style={{float:"right",marginRight:"13px",marginTop:"10px"}}>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px"}}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                    </div>
            <CardContent sx={{ p: 4 }}>
              <Typography variant="p" component="div" className="heading">
                Issues reported by categories
              </Typography>

              <Box className="line-chart-height">
                <Chart
                  options={optionOne}
                  series={categoryData}
                  className="chart-width"
                  type="bar"
                  height={2000}
                />
              </Box>
              <Box
                direction="row"
                className="graph-content"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box
                  sx={{ display: "flex" }}
                  className="graph-content tablet-view"
                >
                  <Box sx={{ mx: 2 }} className="graph-content">
                    <Stack
                      className="stack-align"
                      direction="row"
                      spacing={2}
                      sx={{
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        Resolved
                      </Typography>
                      {/* <ToggleSwitch /> */}
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: "#039855",
                        height: "4px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box>
                  <Box sx={{ mx: 2 }} className="graph-content">
                    <Stack
                      className="stack-align"
                      direction="row"
                      spacing={2}
                      sx={{
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        Open
                      </Typography>
                      {/* <ToggleSwitch /> */}
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: "#F39A4C",
                        height: "4px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box>
                  <Box sx={{ mx: 2 }} className="graph-content">
                    <Stack
                      className="stack-align"
                      direction="row"
                      spacing={2}
                      sx={{
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        className="graph-bottom"
                      >
                        In-Progress
                      </Typography>

                      {/* <ToggleSwitch /> */}
                    </Stack>
                    <Divider
                      sx={{
                        backgroundColor: "#1465B4",
                        height: "4px",
                        borderRadius: "50px",
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  className="graph-content value-btn"
                >
                  <Stack
                    className="stack-align"
                    direction="row"
                    spacing={2}
                    sx={{
                      alignItems: "center",
                      borderRadius: "5px",
                    }}
                  >
                    {/* <Typography
                      variant="p"
                      component="div"
                      className="graph-bottom"
                    >
                      Value
                    </Typography> */}
                    {/* <ToggleSwitch /> */}
                  </Stack>
                  <Link to="/issues/list" className="submenu-item">
                    <Button
                      variant="outlined"
                      size="small"
                      className="graph-button graph-content"
                    >
                      Show Table
                    </Button>
                  </Link>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LineChart;
