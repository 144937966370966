/** @format */

import JwtAuthService from "../apiConfig/ApiConfig";
import {
  LIST_USER_REQUEST,
  LIST_USER_SUCCESS,
  LIST_USER_ERROR,
  SUBROLE_USER_REQUEST,
  SUBROLE_USER_SUCCESS,
  SUBROLE_USER_ERROR,
  INSPECTION_REQUEST,
  INSPECTION_SUCCESS,
  INSPECTION_ERROR,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_ERROR,
  STORE_USER_REQUEST,
  STORE_USER_SUCCESS,
  STORE_USER_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_ERROR,
  USER_ACTIVITY_REQUEST,
  USER_ACTIVITY_SUCCESS,
  USER_ACTIVITY_ERROR,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_ERROR,
  ADD_IMAGE_REQUEST,
  ADD_IMAGE_SUCCESS,
  ADD_IMAGE_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  APPROVE_USER_REQUEST,
  APPROVE_USER_SUCCESS,
  APPROVE_USER_ERROR,
  Top_PERFORMER_REQUEST,
  Top_PERFORMER_SUCCESS,
  Top_PERFORMER_ERROR,
  RECENTLY_DATA_REQUEST,
  RECENTLY_DATA_SUCCESS,
  RECENTLY_DATA_ERROR,
  USER_PERMISSION_UPDATE_REQUEST,
  USER_PERMISSION_UPDATE_SUCCESS,
  USER_PERMISSION_UPDATE_ERROR,
  LIST_USER_CHECKLIST_REQUEST,
  LIST_USER_CHECKLIST_SUCCESS,
  LIST_USER_CHECKLIST_ERROR,
  LIST_USER_CHECKLIST_CLEAR,
  ADD_DOC_IMAGE_REQUEST,
  ADD_DOC_IMAGE_SUCCESS,
  ADD_DOC_IMAGE_ERROR,
  ADD_DOC_IMAGE_DEFAULT,
  DELETE_DOC_IMAGE,
} from "../types/Types";
import { toast } from "react-toastify";

export const ListUsers = () => ({
  type: LIST_USER_REQUEST,
});

export const ListUsersSuccess = (companyid) => ({
  type: LIST_USER_SUCCESS,
  payload: companyid,
});

export const ListUsersFailed = (error) => ({
  type: LIST_USER_ERROR,
  payload: error,
});

export const ListUserApi = (data) => (dispatch) => {
  try {
    dispatch(ListUsers(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ListUsersSuccess(data));
      } else {
        dispatch(ListUsersFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(ListUsersFailed(error));
  }
};

export const ListUsersChecklist = () => ({
  type: LIST_USER_CHECKLIST_REQUEST,
});

export const ListUsersChecklistSuccess = (data, key) => ({
  type: LIST_USER_CHECKLIST_SUCCESS,
  payload: { data, key },
});

export const ListUsersChecklistFailed = (error) => ({
  type: LIST_USER_CHECKLIST_ERROR,
  payload: error,
});

export const ListUsersChecklistClear = (error) => ({
  type: LIST_USER_CHECKLIST_CLEAR,
  payload: error,
});

export const ListUserChecklistApi = (data, key) => (dispatch) => {
  try {
    dispatch(ListUsersChecklist(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ListUsersChecklistSuccess(data, key));
      } else {
        toast.error(`${response?.response?.data?.message}`);
        dispatch(ListUsersChecklistFailed(response?.statusText));
      }
    });
  } catch (error) {
    toast.error(`${response?.response?.data?.message}`);
    dispatch(ListUsersChecklistFailed(error));
  }
};

export const UserSubRole = () => ({
  type: SUBROLE_USER_REQUEST,
});

export const UserSubRoleSuccess = (companyid) => ({
  type: SUBROLE_USER_SUCCESS,
  payload: companyid,
});

export const UserSubRoleFailed = (error) => ({
  type: SUBROLE_USER_ERROR,
  payload: error,
});

export const UserInspection = () => ({
  type: INSPECTION_REQUEST,
});

export const UserInspectionSuccess = (companyid) => ({
  type: INSPECTION_SUCCESS,
  payload: companyid,
});

export const UserInspectionFailed = (error) => ({
  type: INSPECTION_ERROR,
  payload: error,
});

export const UserDelete = () => ({
  type: DELETE_USERS_REQUEST,
});

export const UserDeleteSuccess = (companyid) => ({
  type: DELETE_USERS_SUCCESS,
  payload: companyid,
});

export const UserDeleteFailed = (error) => ({
  type: DELETE_USERS_ERROR,
  payload: error,
});

export const TopPerFormer = () => ({
  type: Top_PERFORMER_REQUEST,
});

export const TopPerFormerSuccess = (companyid) => ({
  type: Top_PERFORMER_SUCCESS,
  payload: companyid,
});

export const TopPerFormerFailed = (error) => ({
  type: Top_PERFORMER_ERROR,
  payload: error,
});

export const Recently = () => ({
  type: RECENTLY_DATA_REQUEST,
});

export const RecentlySuccess = (companyid) => ({
  type: RECENTLY_DATA_SUCCESS,
  payload: companyid,
});

export const RecentlyFailed = (error) => ({
  type: RECENTLY_DATA_ERROR,
  payload: error,
});
export const UserSubRoleApi = (data) => (dispatch) => {
  try {
    dispatch(UserSubRole(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(UserSubRoleSuccess(data));
      } else {
        dispatch(UserSubRoleFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(UserSubRoleFailed(error));
  }
};
export const UserInspectionApi = (data) => (dispatch) => {
  try {
    dispatch(UserInspection(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(UserInspectionSuccess(data));
      } else {
        dispatch(UserInspectionFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(UserInspectionFailed(error));
  }
};
export const UserTopFormerApi = (data) => (dispatch) => {
  try {
    dispatch(TopPerFormer());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(TopPerFormerSuccess(data));
      } else {
        dispatch(TopPerFormerFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(TopPerFormerFailed(error));
  }
};

export const RecentlyApi = (data) => (dispatch) => {
  try {
    dispatch(Recently());
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(RecentlySuccess(data));
      } else {
        dispatch(RecentlyFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(RecentlyFailed(error));
  }
};

export const UserDeleteApi = (data) => (dispatch) => {
  try {
    dispatch(UserDelete(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(UserDeleteSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(UserDeleteFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UserDeleteFailed(error));
    toast.error(error);
  }
};

export const UserStore = () => ({
  type: STORE_USER_REQUEST,
});

export const UserStoreSuccess = (companyid) => ({
  type: STORE_USER_SUCCESS,
  payload: companyid,
});

export const UserStoreFailed = (error) => ({
  type: STORE_USER_ERROR,
  payload: error,
});

export const UserStoreApi = (data) => (dispatch) => {
  try {
    dispatch(UserStore(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(UserStoreSuccess(data));
      } else {
        dispatch(UserStoreFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(UserStoreFailed(error));
  }
};

export const ChangePasswordUser = (companyid) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload: companyid,
});

export const ChangePasswordUserSuccess = (companyid) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: companyid,
});

export const ChangePasswordUserFailed = (error) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: error,
});

export const ChangePasswordUserApi = (data) => (dispatch) => {
  try {
    dispatch(ChangePasswordUser(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ChangePasswordUserSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(ChangePasswordUserFailed(response?.response?.data?.message));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ChangePasswordUserFailed(error));
  }
};

export const UserDetails = (data) => ({
  type: USER_DETAILS_REQUEST,
  payload: data,
});

export const UserDetailsSuccess = (data) => ({
  type: USER_DETAILS_SUCCESS,
  payload: data,
});

export const UserDetailsFailed = (error) => ({
  type: USER_DETAILS_ERROR,
  payload: error,
});

export const UserDetailsApi = (data) => (dispatch) => {
  try {
    dispatch(UserDetails(data));
    JwtAuthService.GetApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        // toast.success(`${response?.data?.message}`);
        dispatch(UserDetailsSuccess(data));
      } else {
        dispatch(UserDetailsFailed(response?.statusText));
        // toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UserDetailsFailed(error));
  }
};

export const UserActivity = (data) => ({
  type: USER_ACTIVITY_REQUEST,
  payload: data,
});

export const UserActivitySuccess = (data) => ({
  type: USER_ACTIVITY_SUCCESS,
  payload: data,
});

export const UserActivityFailed = (error) => ({
  type: USER_ACTIVITY_ERROR,
  payload: error,
});

export const UserActivityApi = (data) => (dispatch) => {
  try {
    dispatch(UserActivity(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(UserActivitySuccess(data));
      } else {
        dispatch(UserActivityFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(UserActivityFailed(error));
  }
};

export const AddUser = (companyid) => ({
  type: ADD_USER_REQUEST,
  payload: companyid,
});

export const AddUserSuccess = (companyid) => ({
  type: ADD_USER_SUCCESS,
  payload: companyid,
});

export const AddUserFailed = (error) => ({
  type: ADD_USER_ERROR,
  payload: error,
});

export const UpdateUsers = (companyid) => ({
  type: UPDATE_USERS_REQUEST,
  payload: companyid,
});

export const UpdateUsersSuccess = (companyid) => ({
  type: UPDATE_USERS_SUCCESS,
  payload: companyid,
});

export const UpdateUsersFailed = (error) => ({
  type: UPDATE_USERS_ERROR,
  payload: error,
});

export const AddUserApi = (data) => (dispatch) => {
  try {
    dispatch(AddUser(data));
    JwtAuthService.PostApiService(data).then((resp) => {
      if (resp?.status == 200) {
        const data = resp;
        dispatch(AddUserSuccess(data));
        dispatch(AddDefaultDocImage([]));
      } else {
        dispatch(AddUserFailed(resp?.response));
        toast.error(`${resp?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AddUserFailed(error));
  }
};

export const UpdateUserApi = (data) => (dispatch) => {
  try {
    dispatch(UpdateUsers(data));
    JwtAuthService.PutApiService(data).then((resp) => {
      if (resp?.status == 200) {
        const data = resp;
        dispatch(UpdateUsersSuccess(data));
        dispatch(AddDefaultDocImage([]));
        // toast.success(`${data?.data?.message}`);
      } else {
        dispatch(UpdateUsersFailed(resp?.response));
        toast.error(`${resp?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UpdateUsersFailed(error));
  }
};

export const AddImage = (companyid) => ({
  type: ADD_IMAGE_REQUEST,
  payload: companyid,
});

export const AddImageSuccess = (companyid) => ({
  type: ADD_IMAGE_SUCCESS,
  payload: companyid,
});

export const AddImageFailed = (error) => ({
  type: ADD_IMAGE_ERROR,
  payload: error,
});

export const ImageAddApi = (data) => (dispatch) => {
  try {
    dispatch(AddImage(data));
    JwtAuthService.ImageApiService(data).then((resp) => {
      if (resp?.status === 200) {
        const data = resp;
        dispatch(AddImageSuccess(data));
        console.log("response", data);
        toast.success(`${data?.data?.message}`);
      } else {
        dispatch(AddImageFailed(resp?.response));
        toast.error(`${resp?.response?.data?.message}`);
        console.log("else block", resp?.response?.data?.message);
      }
    });
  } catch (error) {
    dispatch(AddImageFailed(error));
    console.log("catch", error);
  }
};

export const AddDocImage = (companyid) => ({
  type: ADD_DOC_IMAGE_REQUEST,
  payload: companyid,
});

export const AddDocImageSuccess = (companyid) => ({
  type: ADD_DOC_IMAGE_SUCCESS,
  payload: companyid,
});

export const AddDocImageFailed = (error) => ({
  type: ADD_DOC_IMAGE_ERROR,
  payload: error,
});

export const AddDefaultDocImage = (data) => ({
  type: ADD_DOC_IMAGE_DEFAULT,
  payload: data,
});
export const DeletDocImage = (data) => ({
  type: DELETE_DOC_IMAGE,
  payload: data,
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const ImageDocAddApi = (data, imgKey, proofType) => (dispatch) => {
  try {
    dispatch(AddDocImage(data));
    JwtAuthService.ImageApiService(data).then((resp) => {
      if (resp?.status === 200) {
        const data = resp;
        dispatch(
          AddDocImageSuccess({
            data: {
              url: data?.data?.data?.url,
              description:
                capitalizeFirstLetter(imgKey.split("_").join(" ")) +
                " of the " +
                capitalizeFirstLetter(proofType.split("_").join(" ")),
            },
            key: imgKey,
            type: proofType,
          })
        );
      } else {
        dispatch(
          AddDocImageFailed({
            data: resp?.response?.data?.message,
            key: imgKey,
            type: proofType,
          })
        );
        toast.error(`${resp?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(AddImageFailed(error));
  }
};

export const EditUser = (companyid) => ({
  type: EDIT_USER_REQUEST,
  payload: companyid,
});

export const EditUserSuccess = (companyid) => ({
  type: EDIT_USER_SUCCESS,
  payload: companyid,
});

export const EditUserFailed = (error) => ({
  type: EDIT_USER_ERROR,
  payload: error,
});

export const EditUserApi = (data) => (dispatch) => {
  try {
    dispatch(EditUser(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(EditUserSuccess(data));
      } else {
        dispatch(EditUserFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(EditUserFailed(error));
  }
};

export const DeleteUser = () => ({
  type: DELETE_USER_REQUEST,
});

export const DeleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});

export const DeleteUserFailed = (error) => ({
  type: DELETE_USER_ERROR,
  payload: error,
});

export const DeleteUserApi = (data) => (dispatch) => {
  try {
    dispatch(DeleteUser(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(DeleteUserSuccess(data));
      } else {
        dispatch(DeleteUserFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteUserFailed(error));
  }
};

export const ApproveUser = (data) => ({
  type: APPROVE_USER_REQUEST,
  payload: data,
});

export const ApproveUserSuccess = (data) => ({
  type: APPROVE_USER_SUCCESS,
  payload: data,
});

export const ApproveUserFailed = (error) => ({
  type: APPROVE_USER_ERROR,
  payload: error,
});

export const ApproveUserApi = (data) => (dispatch) => {
  try {
    dispatch(ApproveUser());
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(ApproveUserSuccess(data));
        // toast.success(`${response?.data?.message}`);
      } else {
        dispatch(ApproveUserFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ApproveUserFailed(error));
  }
};

export const UserPermissionRequest = (data) => ({
  type: USER_PERMISSION_UPDATE_REQUEST,
  payload: data,
});

export const UserPermissionSuccess = (data) => ({
  type: USER_PERMISSION_UPDATE_SUCCESS,
  payload: data,
});

export const UserPermissionFailed = (error) => ({
  type: USER_PERMISSION_UPDATE_ERROR,
  payload: error,
});

export const UserPermissionApi = (data) => (dispatch) => {
  try {
    dispatch(UserPermissionRequest());
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status == 200) {
        const data = response;
        dispatch(UserPermissionSuccess(data));
        toast.success(`${response?.data?.message}`);
      } else {
        dispatch(UserPermissionFailed(response?.statusText));
        toast.error(`${response?.response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(UserPermissionFailed(error));
  }
};
