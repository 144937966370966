import { Box, Button, Grid, Stack, Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InfoIcon from "@mui/icons-material/Info";

const CreateProductHeader = (props) => {
    const isEdit = localStorage.getItem('userId');
    const location = useLocation();

    return <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
        className="header-card"
    >
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={1} sx={{ float: "right", height: "42px", borderRadius: "8px" }}>
                    <Link
                        to={
                            location?.pathname === '/inventory-product/add-product-item' ? "/inventory-product/view/product-items" : "/product-list"
                        }
                        className="submenu-item"
                    >
                        <Button className="cancle-button cancle-hover" onClick={() => props.handleCancel()}>
                            <CloseOutlinedIcon sx={{ mr: 1 }} />
                            Cancel
                        </Button>
                    </Link>
                    {/* <Link to="/checklist/all-checklist" className="submenu-item"> */}
                    <Button
                        className="header-add cancle-hover"
                        type="submit"
                        style={{ marginRight: 5, height: "42px", borderRadius: "8px" }}
                        onClick={() => props.handleFinalSubmit()}
                    >
                        <AddOutlinedIcon sx={{ mr: 1 }} />
                        {isEdit && location?.pathname.includes('edit') ? "Update" : "Create"}
                    </Button>
                    <div style={{ marginTop: "8px", marginRight: "8px" }}>
                        <Tooltip
                            title={
                                <div style={{ maxWidth: "300px" }}>
                                    <strong>Create Product Page:</strong>
                                    <p>
                                    </p>
                                </div>
                            }
                            arrow
                            interactive
                        >
                            <InfoIcon
                                style={{
                                    verticalAlign: "middle",
                                    cursor: "pointer",
                                    color: "#D76C00",
                                }}
                            />
                        </Tooltip>
                    </div>
                </Stack>
            </Grid>
        </Grid>
    </Box>
}

export default CreateProductHeader;