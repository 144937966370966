/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  CardContent,
  Stack,
  Grid,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import User from "../../../assets/icons/svg-icons/User.svg";
import {
  broadcastMessageApi,
  upcomingScheduleApi,
} from "../../redux/actions/dashboardChartAction";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Calendar } from "react-multi-date-picker";
import * as moment from "moment";
import InfoIcon from "@mui/icons-material/Info";

const MessageScheduleChart = ({ companyId, frequency }) => {
  const dispatch = useDispatch();

  const broadcastMessageRes = useSelector(
    (state) =>
      state &&
      state?.dashboardReducer &&
      state?.dashboardReducer?.message &&
      state?.dashboardReducer?.message?.data &&
      state?.dashboardReducer?.message?.data?.data?.broadcast
  );

  const misedCheckList = useSelector(
    (state) =>
      state &&
      state?.MissedCheckLIstData &&
      state?.MissedCheckLIstData?.MissedChecklist &&
      state?.MissedCheckLIstData?.MissedChecklist?.data &&
      state?.MissedCheckLIstData?.MissedChecklist?.data?.MissedChecklistData
  );

  const upcomingScheduleRes = useSelector(
    (state) =>
      state &&
      state?.dashboardReducer &&
      state?.dashboardReducer?.schedule &&
      state?.dashboardReducer?.schedule?.data &&
      state?.dashboardReducer?.schedule?.data?.data?.assignments
  );
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const [upcomingSchedule, setUpcomingSchedule] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);

  const payloadRequest = {
    body: {
      pagination: {
        page: 1,
        per_page: 6,
      },
    },
  };

  const payloadRequest1 = {
    body: {
      pagination: {
        page: 1,
        per_page: 5,
      },
      sort: {
        sort_as: "updatedAt",
      },
      FilterChecklist: "",
      search: "",
      archived: false,
    },
  };

  useEffect(() => {
    broadcastMessageData(companyId);
    // upcomingChecklistData(companyId);
  }, []);

  useEffect(() => {
    upcomingChecklistData(companyId);
  }, [frequency]);

  useEffect(() => {
    if (broadcastMessageRes) {
      let splice = broadcastMessageRes.splice(0, 5);
      setBroadcastMessage(splice);
    }
  }, [broadcastMessageRes]);

  useEffect(() => {
    if (upcomingScheduleRes) {
      let dates = [];
      for (let item of upcomingScheduleRes) {
        // dateSort.setDate(dateSort.getDate() - 1);
        let day = item.starts_at.slice(6, 8);
        let month = item.starts_at.slice(4, 6);
        let year = item.starts_at.slice(0, 4);
        let sortedDate = `${month}-${day}-${year}`;
        const dateSort = new Date(sortedDate);

        dates.push(dateSort);
      }
      setSelectedDates(dates);
      setUpcomingSchedule(upcomingScheduleRes);
    }
  }, [upcomingScheduleRes]);

  const broadcastMessageData = (id) => {
    payloadRequest.url = BASE_URL + `broadcast/index?company_id=${id}`;
    dispatch(broadcastMessageApi(payloadRequest));
  };

  const upcomingChecklistData = (id) => {
    payloadRequest1.url = BASE_URL + `formsAssignment/admin?company_id=${id}&frequency=${frequency ? frequency : ""}`;
    dispatch(upcomingScheduleApi(payloadRequest1));
  };

  const roleToolTip = (item) => {
    let splits = item?.join(", ");
    return splits;
  };
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Card
          sx={{ pt: 2, height: "100%", borderRadius: "8px" }}
          className="card"
        >
          <div align="right" style={{marginRight:"15px"}}>
          <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Message:</strong>
                            <p>
                            This section displays the overview of latest
                             broadcast messages sent to users
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          <CardContent>
            <Typography
              variant="p"
              component="div"
              style={{ marginBottom: "16px" }}
              className="heading"
            >
              Messages
            </Typography>
            <Card
              className="card"
              sx={{
                height: "100%",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Stack
                  direction="column"
                  sx={{ mt: 2, px: 3 }}
                  className="due-check"
                >
                  {broadcastMessage &&
                    broadcastMessage.length > 0 &&
                    broadcastMessage.map((item) => {
                      // let sortedString = item?.message.slice(19, -6);
                      let sortedString =
                        item?.message &&
                        ((JSON.parse(item.message).blocks &&
                          JSON.parse(item.message).blocks[0].text.replace(/<[^>]*>/g, '')) || // Use regex to remove <p> tags
                          (JSON.parse(item.message).ops &&
                            JSON.parse(item.message).ops[0].insert) ||
                          "Content not available");
                      return (
                        <Tooltip
                          placement="right"
                          title={roleToolTip(item?.role)}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "green",
                                maxWidth: "170px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              },
                            },
                          }}
                        >
                          <List className="list-data ">
                            <ListItem style={{ padding: "0px" }}>
                              <ListItemText
                                primary={`${item?.subject}: ${sortedString}`}
                                secondary={`Sent on:${moment(
                                  item.updatedAt
                                ).format("lll")}`}
                              />
                            </ListItem>
                          </List>
                        </Tooltip>
                      );
                    }) || "No Results Found!"}
                  {/* <Link to="/checklist/all-checklist" className="message-link-2">
                    View all
                  </Link> */}
                </Stack>
              </CardContent>
            </Card>
          </CardContent>
          {/* <Link href="#" className="message-link">Link</Link> */}
        </Card>
      </Grid>

      {/* Missed checkLict */}

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Card
          sx={{ pt: 2, height: "100%", borderRadius: "8px" }}
          className="card"
        >
          <div align="right" style={{marginRight:"15px"}}>
              <Tooltip
                      title={
                        <div style={{ maxWidth: "300px" }}>
                          <strong>Missed checklist :</strong>
                          <p>This section displays the checklist that 
                            was missed on the previous day</p>
                        </div>
                      }
                      arrow
                      interactive
                    >
                      <InfoIcon
                        style={{
                          marginLeft: "8px",
                          verticalAlign: "middle",
                          cursor: "pointer",
                          color: "#D76C00",
                        }}
                      />
                    </Tooltip>
                    </div>
          <CardContent>
            <Typography
              variant="p"
              component="div"
              style={{ marginBottom: "16px" }}
              className="heading"
            >
              Missed Checklist
            </Typography>
            <Card
              className="card"
              sx={{
                height: "100%",
                boxShadow: "none",
                borderRadius: "8px",
              }}
            >
              <CardContent>
                <Stack
                  direction="column"
                  sx={{ mt: 2, px: 3 }}
                  className="due-check"
                >
                  {misedCheckList &&
                    misedCheckList.length > 0 &&
                    misedCheckList.map((item) => {
                      return (
                        <List
                          className={
                            "list-data daily-hover list-data hourly-hover list-data monthly-hover list-data nofreq-hover list-data weekly-hover"
                          }
                        >
                          <ListItem style={{ padding: "0px" }}>
                            <ListItemText
                              primary={item?.UserName}
                              secondary={
                                <p
                                  style={{ margin: "0px", fontWeight: "bold" }}
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {moment(item?.Date).format("ll")}
                                  </span>{" "}
                                  {`missed count:${item?.Pending}`}
                                </p>
                              }
                            />
                          </ListItem>
                        </List>
                      );
                    }) || "No Results Found!"}
                  {/* <Link to="/checklist/all-checklist" className="message-link-2">
                    View all
                  </Link> */}
                </Stack>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </Grid>

      {/* Recent Assigned Checklist */}

      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Card
          sx={{ pt: 2, height: "100%", borderRadius: "8px" }}
          className="card"
        >
          <div align="right" style={{marginRight:"15px"}}>
          <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Recent assigned checklist:</strong>
                            <p>This section displays the checklists
                              that were assigned recently.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
                      </div>
          <CardContent>
            <Typography
              variant="p"
              component="div"
              style={{ marginBottom: "16px" }}
              className="heading"
            >
              Recent Assigned Checklist
            </Typography>
            <Card
              className="card"
              sx={{
                height: "100%",
                boxShadow: "none",
                borderRadius: "8px",
              }}
            >
              <CardContent>
                <Stack
                  direction="column"
                  sx={{ mt: 2, px: 3 }}
                  className="due-check"
                >
                  {upcomingSchedule &&
                    upcomingSchedule.length > 0 &&
                    upcomingSchedule.map((item) => {
                      return (
                        <List
                          className={
                            (item?.frequency === 3 &&
                              "list-data daily-hover") ||
                            (item?.frequency === 2 &&
                              "list-data hourly-hover") ||
                            (item?.frequency === 5 &&
                              "list-data monthly-hover") ||
                            (item?.frequency === 1 &&
                              "list-data nofreq-hover") ||
                            (item?.frequency === 4 && "list-data weekly-hover")
                          }
                        >
                          <Tooltip
                            placement="right"
                            title={
                              (item?.frequency === 3 && "Daily") ||
                              (item?.frequency === 2 && "Hourly") ||
                              (item?.frequency === 5 && "Monthly") ||
                              (item?.frequency === 1 && "No frequency") ||
                              (item?.frequency === 4 && "Weekly")
                            }
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: `${
                                    (item?.frequency === 3 && "lightgreen") ||
                                    (item?.frequency === 2 &&
                                      "rgb(190, 26, 26)") ||
                                    (item?.frequency === 5 && "lightskyblue") ||
                                    (item?.frequency === 1 &&
                                      "rgb(255, 120, 120)") ||
                                    (item?.frequency === 4 && "lightsalmon")
                                  }`,
                                  "& .MuiTooltip-arrow": {
                                    color: "white",
                                  },
                                },
                              },
                            }}
                          >
                            <ListItem
                              style={{ padding: "0px" }}
                              // secondaryAction={
                              //   <IconButton edge="end" aria-label="delete">
                              //     <ChevronRightIcon />
                              //   </IconButton>
                              // }
                            >
                              <ListItemText
                                primary={item?.title}
                                secondary={
                                  <p style={{ margin: "0px" }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      Start:
                                    </span>
                                    {moment(item.starts_at).format("ll")}{" "}
                                    {item?.startTime}{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      End:
                                    </span>
                                    {moment(item.expires_at).format("ll")}{" "}
                                    {item?.endTime}
                                  </p>
                                }
                              />
                            </ListItem>
                          </Tooltip>
                        </List>
                      );
                    }) || "No Results Found!"}
                  {/* <Link to="/checklist/all-checklist" className="message-link-2">
                    View all
                  </Link> */}
                </Stack>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MessageScheduleChart;
