import { Box, Button, Grid, Stack, Modal, FormControl,Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as moment from "moment";

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const MenuProps = {
  disableScrollLock: true,
};

const TemplateHeader = (props) => {
  const { onFilter, permission } = props;
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [name, setName] = useState("");
  const templateScreenFilter = location.state;
  const templateFilter = localStorage.getItem("TemplateFilterData") || "{}";
  const templateFilterData = JSON.parse(templateFilter);
  const [start_date, setstart_date] = useState(
      moment(
       /* issuesScreenFilter?.startDate || moment().subtract(1, "months")
      ).format("YYYY-MM-DD") */
      templateScreenFilter?.startDate || new Date()).format("YYYY-MM-DD")
    );
    const [end_date, setend_date] = useState(
      moment(templateScreenFilter?.endDate || new Date()).format("YYYY-MM-DD")
    );
    const [newDate, setNewDate] = useState({ start_date, end_date });

  useEffect(() => {
    if (templateFilterData) {
      setName(templateFilterData.Name || "");
    }
  }, []);

    const modalopenFilterhandleClose = () => {
        setOpenFilterModal(false);
    }

    const handleNewCreate = () => {
        localStorage.setItem("addTemplate", "");
        localStorage.setItem("questions", "");
        localStorage.setItem("userId", "");
    };
    
    const handleFilter = () => {
    const searchData = {
      search: name,
      start_date,
      end_date,
    }

    //console.log("Check form data and call APIs", searchData);
    //localStorage.setItem("templateFilterData", JSON.stringify(searchData));
    onFilter(searchData);
  };
  return (
    <Box sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }} className="header-card">
      <Grid container spacing={1} alignItems="center" sx={{ padding: "0px 8px" }}>
        <Grid item xs={12} lg={7} md={4} sm={12} alignItems="left">
          <Stack direction="row" spacing={1} alignItems="left" justifyContent="flex-start">
            <Stack direction="row" alignItems="left">
              <Button
                className="header-add cancle-hover"
                style={{ height: "42px", borderRadius: "8px", padding: "12px 16px" }}
                onClick={() => setOpenFilterModal(true)}
              >
                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                Filter
              </Button>
            </Stack>
          </Stack>
        </Grid>
        {permission?.inventory?.manageScheduler &&
        <Grid item xs={12} lg={5} md={8} sm={12}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Stack direction="row" alignItems="center">
              <Link 
              to="/template/create" 
              className="submenu-item">
                <Button
                  className="header-add cancle-hover"
                  sx={{
                    whiteSpace: "nowrap",
                    ml: 1,
                    height: "42px",
                    borderRadius: "8px",
                  }}
                  onClick={handleNewCreate}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Add Template
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Grid>
      }
      </Grid>
      <Modal
        open={openFilterModal}
        onClose={modalopenFilterhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box
          sx={{
            ...downloadAllstyle,
            p: 0,
            width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
            margin: "0 auto",
            maxHeight: "90vh",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setOpenFilterModal(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box sx={{ pt: 2, px: 4 }}>
            <Box sx={{ display: "flex", gap: "16px" }}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Name</label>
                <TextField
                  size="small"
                  name="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  MenuProps={MenuProps}
                  placeholder="Search by template name"
                  sx={{
                    borderRadius: "8px",
                    textAlign: "left",
                    marginTop: "10px",
                  }}
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: { xs: "nowrap", sm: "nowrap" },
              }}
            >
            <LocalizationProvider dateAdapter={AdapterDayjs} style={{display: "flex !important"}}>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  size="small"
                  value={start_date}
                  onChange={(newValue) => {
                    let dateSort = moment(newValue?.$d)
                      .format("YYYY-MM-DD")
                      .split("/")
                      .join("");
                    setstart_date(dateSort);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  className="date-pick date-pick-submision date-width"
                  maxDate={new Date()}
                />
            </LocalizationProvider>
              <Typography className="date-to" > to </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} style={{display: "flex !important"}}>
                <DatePicker
                  // label="End Date"
                  inputFormat="DD/MM/YYYY"
                  size="small"
                  value={end_date}
                  onChange={(newValue) => {
                    let dateSort = moment(newValue?.$d)
                      .format("YYYY-MM-DD")
                      .split("/")
                      .join("");
                    setend_date(dateSort);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  className="date-pick date-pick-submision date-width"
                  maxDate={new Date()}
                  minDate={start_date}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          
          <Box
            sx={{
              display: "flex",
              position: "sticky",
              bottom: 0,
              width: "100%",
              gap: "30px",
              px: 3,
              pt: 1,
              pb: 2,
              background: "#fff",
            }}
          >
          <Button
            className="cancle-button cancle-hover"
            style={{ height: "46px", padding: "12px 16px", width: "47%", background: "#fff" }}
            onClick={() => {
              setName("");
              setNewDate("");
              const dates = {
                start_date: moment(new Date()).format("YYYY-MM-DD"),
                end_date: moment(new Date()).format("YYYY-MM-DD"),
              };
              setstart_date(dates.start_date);
              setend_date(dates.end_date);
              setNewDate(dates);
              //const searchData = { Name: "" };
              //onFilter(searchData);
            }}
          >
            Clear
          </Button>
          <Button
            className="header-add cancle-hover"
            style={{ height: "46px", padding: "12px 16px", width: "47%" }}
            onClick={() => {
              modalopenFilterhandleClose();
              handleFilter();
            }}
          >
            Apply
          </Button>
        </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default TemplateHeader;