/** @format */

import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  CardContent,
  Button,
  Grid,
  Divider,
  Stack,
  Box,
  TextField,
} from "@mui/material";
import ChartNumber from "./pages/ChartNumber";
import ChartTitle from "./pages/ChartTitle";
import LineChart from "./pages/LineChart";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as moment from "moment";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useSelector } from "react-redux";
import Progressbar from "../../GlobalProgress/Progressbar";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const IssuesOverview = () => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD").split("/").join("")
  );
  const [endDate, setEndDate] = useState(new Date());
  const [startDateConvert, setStartDateConvert] = useState();
  const [endDateConvert, setEndDateConvert] = useState();
  const [newDate, setNewDate] = useState({});
  const [valueDropdown, setValueDropdown] = useState("categoryData");
  const [newLoader, setNewLoader] = useState(true);
  const loader = useSelector(
    (state) => state && state?.issue && state?.issue?.loading
  );

  useEffect(() => {
    // let dateSortstart = moment(startDate)
    //   .format("YYYY-MM-DD")
    //   .split("/")
    //   .join("");
    setStartDateConvert(startDate);
  
    let dateSortend = moment(endDate?.$d)
      .format("YYYY-MM-DD")
      .split("/")
      .join("");
    setEndDateConvert(dateSortend);
    // handleDateClick();
  }, []);

  const handleDateClick = () => {
    let dateSortstart = moment(startDate?.$d)
      .format("YYYY-MM-DD")
      .split("/")
      .join("");
    setStartDateConvert(dateSortstart);
    let dateSortend = moment(endDate?.$d)
      .format("YYYY-MM-DD")
      .split("/")
      .join("");
    setEndDateConvert(dateSortend);
    setNewLoader(true);

    const dates = {
      firstdate: startDateConvert,
      seconddate: endDateConvert,
    };
    setNewDate(dates);
    setNewLoader(false);
  };

  const handleChangeDropdown = (event) => {
    setValueDropdown(event.target.value);
  };

  return (
    <>
      <Box className="issue-card">
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <Box
            sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
            className="header-card"
          >
            <Grid container spacing={1} sx={{flexWrap: "nowrap !important", width: "98%"}}>
              <Grid item xs={12} lg={10} md={10} sm={12}>
                <Stack
                  direction="row"
                  className="stack"
                  style={{ marginLeft: "8px", gap:"8px", width:"50%"}}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // label="Start Date"
                      inputFormat="DD/MM/YYYY"
                      size="small"
                      value={startDate}
                      onChange={(newValue) => {
                        let dateSort = moment(newValue?.$d)
                          .format("YYYY-MM-DD")
                          .split("/")
                          .join("");
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      className="date-pick"
                      maxDate={new Date()}
                    />
                  </LocalizationProvider>
                  <Typography className="date-to">to</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // label="End Date"
                      inputFormat="DD/MM/YYYY"
                      size="small"
                      value={endDate}
                      onChange={(newValue) => {
                        let dateSort = moment(newValue?.$d)
                          .format("YYYY-MM-DD")
                          .split("/")
                          .join("");
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      className="date-pick"
                      maxDate={new Date()}
                      minDate={startDate}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={2}
                    md={2}
                    sm={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginRight: "1px",
                      gap: "5px",
                    }}
                  >
                  <Button
                    className="header-add cancle-hover"
                    style={{ height: "40px", padding: "12px 21px !important"}}
                    onClick={(e) => handleDateClick(e)}
                  >
                    <CheckOutlinedIcon sx={{ mr: 1 }} />
                    Apply
                  </Button>          
                    <select
                      className="overview-select dash-select-overview"
                      onChange={handleChangeDropdown}
                      value={valueDropdown}
                    >
                      <option value="categoryData">Category</option>
                      <option value="store">Store</option>
                      <option value="department">Department</option>
                    </select>
                  </Grid>            
              {/* <Grid
                item
                xs={12}
                lg={2}
                md={2}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  marginRight:"4px",
                }}
              >
                <select
                  className="overview-select dash-select-overview"
                  onChange={handleChangeDropdown}
                  value={valueDropdown}
                >
                  <option value="categoryData">Category</option>
                  <option value="store">Store</option>
                  <option value="department">Department</option>
                </select>
              </Grid> */}
              <div
                style={{
                  position: "absolute",
                  marginTop: "15px",
                  marginRight: "20px",
                  right: "0",
                }}
              >
                <Tooltip
                  title={
                    <div style={{ maxWidth: "300px" }}>
                      <strong>Advance Checklist Wise Report:</strong>
                      <p>
                        Status: Display's selected checklist completion status,
                        completion percentage & average compliance scores for
                        the selected user and stores.
                      </p>
                      <p>
                        Percentage: Display's selected checklist completion
                        percentage & average compliance scores for the selected
                        user and stores.
                      </p>
                    </div>
                  }
                  arrow
                  interactive
                >
                  <InfoIcon
                    style={{
                      marginLeft: "8px",
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: "#D76C00",
                    }}
                  />
                </Tooltip>
              </div>
            </Grid>
          </Box>
        )}
        <ChartNumber
          startDate={startDateConvert}
          endDate={endDateConvert}
          loader={loader}
        />
        <ChartTitle
          startDate={startDateConvert}
          endDate={endDateConvert}
          loader={loader}
        />
        <LineChart
          valueDropdown={valueDropdown}
          newDate={newDate}
          startDate={startDateConvert}
          endDate={endDateConvert}
          loader={loader}
        />
      </Box>
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </>
  );
};

export default IssuesOverview;
