/** @format */

import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Stack,
  ListItemIcon,
  Checkbox,
  ListItemText,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@material-ui/core/Fab";
import Blank from "../../images/Blank.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { UserStoreApi } from "../../redux/actions/User";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { AddStoreActionApi, ListStoreApi } from "../../redux/actions/Store";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Main } from "./Map";
import Geocode from "react-geocode";
import { toast, ToastContainer } from "react-toastify";
import { CompanyGetApi } from "../../redux/actions/CreateCompany";
import { MainMap } from "./MyMap";
import { useNavigate } from "react-router-dom";
import { logDOM } from "@testing-library/react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const MenuProps = {
  disableScrollLock: true,
};

const steps = ["Step 1", "Step 2"];

const AddStore = () => {
  const questions = [
    {
      id: 1,
      title: "Store name?",
      answer: "",
      type: "text",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    // {
    //   id: 2,
    //   title: "Store unique id?",
    //   answer: "",
    //   type: "text",
    //   startTime: null,
    //   endTime: null,
    //   gps: null,
    //   validations: null,
    // },
    {
      id: 2,
      title: "Store gps location?",
      answer: {},
      type: "gps",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    // {
    //   id: 4,
    //   title: "Store radius?",
    //   answer: {},
    //   type: "gps",
    //   startTime: null,
    //   endTime: null,
    //   gps: null,
    //   validations: null,
    // },
    {
      id: 3,
      title: "Store region?",
      answer: {},
      type: "single_select",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    {
      id: 4,
      title: "Store sub region?",
      answer: {},
      type: "single_select",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    {
      id: "",
      title: "Store channel?",
      answer: {},
      type: "single_select",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
    {
      id: "",
      title: "Store sub channel?",
      answer: {},
      type: "single_select",
      startTime: null,
      endTime: null,
      gps: null,
      validations: null,
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setDisabled] = useState(true);
  const [newField, setNewField] = useState({});
  const addStoreRes = useSelector(
    (state) =>
      state &&
      state?.store &&
      state?.store?.addStore &&
      state?.store?.addStore?.data &&
      state?.store?.addStore?.data
  );

  const companyQuesData = useSelector(
    (state) =>
      state.company &&
      state.company.companyData &&
      state.company.companyData.data &&
      state.company.companyData.data.data &&
      state.company.companyData.data.data
  );
  const companyDataList = useSelector(
    (state) =>
      state.company &&
      state.company.companyData &&
      state.company.companyData.data &&
      state.company.companyData.data.data &&
      state.company.companyData.data.data.list
  );

  const [dynamicQuesId, setDynamicQuesId] = useState(questions);
  const [images, setImages] = useState("");
  const [long, setLong] = useState("");
  const [lat, setLat] = useState("");
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openManual, setOpenManual] = useState(false);
  const [storeLocation, setStoreLocation] = useState("");
  const [latLng, setLatLng] = useState({ lat: 22.7378919, lng: 75.8618264 });
  const [regionSelectedData, setRegionSelectedData] = useState([]);
  const [regionSelected, setRegionSelected] = useState("");
  const [subRegionSelectedData, setSubRegionSelectedData] = useState([]);
  const [subRegionSelected, setSubRegionSelected] = useState("");
  const [channelSelectedData, setChannelSelectedData] = useState([]);
  const [channelId, setChannelId] = useState("");
  const [subchannelId, setSubChannelId] = useState("");
  const [channelSelected, setChannelSelected] = useState("");
  const [subChannelSelectedData, setSubChannelSelectedData] = useState([]);
  const [subChannelSelected, setSubChannelSelected] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitudeManual, setLatitudeManual] = useState("");
  const [longitudeManual, setLongitudeManual] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [questionSelected, setQuestionSelected] = useState({});
  const [storeName, setStoreName] = useState("");
  const [uniqueId, setuniqueId] = useState("");
  const [radius, setradius] = useState("");
  const [newSelectedQue, setnewSelectedQue] = useState([]);
  const [storeList, setStoreList] = useState(
    JSON.parse(localStorage.getItem("storeData"))
  );
  const mapRef = useRef(null);
  const [enableSubRegion, setEnableSubRegion] = useState(true);
  const [storeChannelSelected, setStoreChannelSelected] = useState(false);
  const [position, setPosition] = useState({
    lat: 41,
    lng: -71,
  });

  useEffect(() => {
    let newObj = questionSelected?.questionsname;
    if (newObj) {
      const newObjValue = {
        id: questionSelected?.items?.id,
        title: questionSelected?.items?.title,
        answer: newObj,
        type: questionSelected?.items?.type?.key,
        startTime: null,
        endTime: null,
        gps: null,
        validations: null,
      };
      setnewSelectedQue((oldData) => [...oldData, newObjValue]);
    }
  }, [questionSelected]);

  useEffect(() => {
    getCompanyData();
  }, []);

  useEffect(() => {
    let newQuestionData = companyQuesData?.questions;
    setQuestionList(newQuestionData);
  }, [companyQuesData]);

  useEffect(() => {
    if (addStoreRes) {
      if (addStoreRes && !addStoreRes?.error) {
        toast.success(addStoreRes?.message, { autoClose: 700 });
        setTimeout(() => {
          navigate("/store");
        }, 1200);
      }
    }
  }, [addStoreRes]);
  useEffect(() => {
    if (latitude && longitude) {
      setNewField({
        store: storeName,
        uniqueId: uniqueId,
        radius: radius,
        // gpsLocation: "",
        latitude: latitude,
        longitude: longitude,
        storeRegion: regionSelected.title ? regionSelected.title : "",
        storeSubRegion: subRegionSelected.title ? subRegionSelected.title : "",
        storeChannel: channelSelected.title ? channelSelected.title : "",
        storeSubChannel: subChannelSelected.title
          ? subChannelSelected.title
          : "",
      });
    }
  }, [
    latitude,
    longitude,
    storeName,
    uniqueId,
    radius,
    regionSelected,
    subRegionSelected,
    channelSelected,
    subChannelSelected,
  ]);

  // useEffect(() => {
  //     if (subRegionSelectedData && companyDataList) {
  //         for (let item of companyDataList) {
  //             if (item.title === "List of Channels" || item.title === "Channels") {
  //                 setChannelSelectedData(item?.items);
  //                 // setDisabled(false)
  //             } else if (item.title === "List of Sub channels" || item.title === "Sub Channel") {
  //                 setSubChannelSelectedData(item?.items)
  //             }
  //         }
  //     }
  // }, [subRegionSelectedData]);

  // useEffect(() => {
  //     if (regionSelected && companyDataList) {
  //         let titles = `Regions of ${regionSelected.title}`
  //         let titles1 = `Sub Region of ${regionSelected.title}`
  //         let titles2 = `Sub Regions of ${regionSelected.title}`
  //         for (let item of companyDataList) {
  //             setDisabled(false)
  //                 setSubRegionSelectedData(item?.items);
  //         }
  //     }
  // }, [regionSelected]);
  //regin
  useEffect(() => {
    if (companyQuesData) {
      for (let item of companyQuesData.questions) {
        if (item?.is_store === true) {
          for (let items of companyDataList) {
            if (
              item?.attached_list === items?.id &&
              item?.title === "Store region?"
            ) {
              setRegionSelectedData(items?.items);
            }
          }
        }
        // if (item?.title == "Store region?") {
        //     setRegionSelectedData(item?.type?.validations[0]?.answer);
        // }
      }
      // setDynamicQuesId(dynamicQuesId);
    }
  }, [companyQuesData, companyDataList]);

  useEffect(() => {
    if (companyQuesData) {
      for (let item of companyQuesData.questions) {
        if (item?.is_store === true && item?.title === "Store sub region?") {
          setDisabled(false);
          let newlist = item?.dependency_list?.items;
          if (newlist?.length > 0) {
            for (let list of newlist) {
              if (list?.title === regionSelected?.title) {
                for (let items of companyDataList) {
                  if (items?.id === list?.selected_list) {
                    if (items?.items?.length > 0) {
                      setSubRegionSelectedData(items?.items);
                      setDisabled(false);
                    } else {
                      toast.error(
                        `No items are available for List ${regionSelected?.title}. Consider assigning a list for ${regionSelected?.title} by editing question Q.3 in Kpi Builder`
                      );
                      setDisabled(false);
                    }
                  }
                  if (
                    list?.selected_list === null &&
                    list?.selected_list === "" &&
                    list?.selected_list === undefined &&
                    !list?.selected_list
                  ) {
                    setSubRegionSelectedData([]);
                    toast.error(
                      `No items are available for List ${regionSelected?.title}. Consider assigning a list for ${regionSelected?.title} by editing question Q.3 in Kpi Builder`
                    );
                  }
                }
              }
            }
          } else {
            setSubRegionSelectedData(item?.type?.validations[0]?.answer);
          }
        }
      }
      // setDynamicQuesId(dynamicQuesId);
    }
  }, [regionSelected]);
  useEffect(() => {
    if (companyQuesData) {
      for (let item of companyQuesData.questions) {
        if (item?.is_store === true) {
          for (let items of companyDataList) {
            if (
              item?.attached_list === items?.id &&
              item?.title === "Store channel?"
            ) {
              setChannelSelectedData(
                items?.items ? items?.items : item?.type?.validations[0]?.answer
              );
              setChannelId(item?.id);
            }
          }
        }
        // if (item?.title == "Store region?") {
        //     setRegionSelectedData(item?.type?.validations[0]?.answer);
        // }
      }
    }
  }, [companyQuesData, companyDataList]);

  useEffect(() => {
    if (companyQuesData) {
      for (let item of companyQuesData.questions) {
        if (item?.is_store === true && item?.title === "Store sub channel?") {
          let newlist = item?.dependency_list?.items;
          if (newlist?.length > 0) {
            for (let list of newlist) {
              if (channelSelected?.title) {
                for (let items of companyDataList) {
                  if (items?.id === list?.selected_list) {
                    if (items?.items?.length > 0) {
                      setSubChannelId(item?.id);
                      setSubChannelSelectedData(
                        items?.items
                          ? items?.items
                          : item?.type?.validations[0]?.answer
                      );
                    } else {
                      toast.error(
                        `No items are available for List ${channelSelected?.title}. Consider assigning a list for ${channelSelected?.title} by editing question Q.3 in Kpi Builder`
                      );
                    }
                  }
                  if (
                    list?.selected_list === null &&
                    list?.selected_list === ""
                  ) {
                    setSubChannelSelectedData([]);
                    toast.error(
                      `No items are available for List ${channelSelected?.title}. Consider assigning a list for ${channelSelected?.title} by editing question Q.3 in Kpi Builder`
                    );
                    // setDisabled(true)
                  }
                }
              }
            }
          } else {
            setSubChannelSelectedData(item?.type?.validations[0]?.answer);
            setSubChannelId(item?.id);
          }
        }
      }
      // setDynamicQuesId(dynamicQuesId);
    }
  }, [companyQuesData, companyDataList, channelSelected]);

  const getCompanyData = () => {
    const cid = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `companies/${cid}` };
    dispatch(CompanyGetApi(data));
  };

  const validationSchema = yup.object().shape({
    // store: yup.string().notOneOf(
    //   storeList.map((item) => item.name),
    //   "Store Name already exists"
    // ),
    store: yup
    .string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Store Name cannot contain special characters") 
    .notOneOf(
      storeList.map((item) => item.name),
      "Store Name already exists"
    ),
    // gpsLocation: yup.string().required("This is required"),
    latitude: yup.string().required("This is required"),
    longitude: yup.string().required("This is required"),
    storeRegion: yup.string().required("This is required"),
    storeSubRegion: yup.string().required("This is required"),
    storeChannel: yup.string().required("This is required"),
    storeSubChannel: yup.string().required("This is required"),
    uniqueId: yup.string().required("This is required"),
    radius: yup.string().required("This is required"),
  });

  const formik = useFormik({
    initialValues: newField || {
      store: "",
      uniqueId: "",
      radius: radius ? Number(radius) : 0,
      latitude: "",
      longitude: "",
      storeRegion: "",
      storeSubRegion: "",
      storeChannel: "",
      storeSubChannel: "",
    },

    enableReinitialize: true,
    validationSchema: validationSchema,

    // onSubmit: (values) => {
    //   console.log("values--->", values);
    //   uniqueId: values?.radius;
    //   radius: values?.radius;
    //   dynamicQuesId[0].answer = values.store ? values.store : storeName;
    //   let newArr = [...dynamicQuesId, ...newSelectedQue];
    //   const questions = { questions: [...newArr] };
    //   const allData = {
    //     body: questions,
    //   };
    //   if (allData) {
    //     const id = localStorage.getItem("companyId");
    //     allData.url = BASE_URL + `stores?company_id=${id}`;
    //     dispatch(AddStoreActionApi(allData));
    //   }
    // },

    onSubmit: (values) => {
      const newQuestions = dynamicQuesId.map((question) => {
        if (question.title === "Store unique id?") {
          return {
            ...question,
            answer: values.uniqueId,
          };
        } else if (question.title === "Store radius?") {
          return {
            ...question,
            answer: values.radius,
          };
        } else if (question.title === "Store name?") {
          return {
            ...question,
            answer: values.store || storeName,
          };
        } else {
          return question;
        }
      });

      const questions = { questions: [...newQuestions, ...newSelectedQue] };
      const allData = {
        body: {
          ...questions,
          uniqueId: values.uniqueId,
          radius: values.radius,
        },
      };

      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `stores?company_id=${id}`;
        dispatch(AddStoreActionApi(allData));
      }
    },
  });

  const handleChangeRegion = (e) => {
    setEnableSubRegion(false);
    setRegionSelected(e.target.value);
    dynamicQuesId[2].answer = e.target.value;
  };

  const handleChangeSubRegion = (e) => {
    setSubRegionSelected(e.target.value);
    dynamicQuesId[3].answer = e.target.value;
  };

  const handleChangeStoreChannel = (e) => {
    // delete e.target.value.customFields
    // delete e.target.value.is_fixed
    setChannelSelected(e.target.value);
    dynamicQuesId[4].id = channelId;
    dynamicQuesId[4].answer = e.target.value;
  };

  const handleChangeStoreSubChannel = (e) => {
    // delete e.target.value.customFields
    // delete e.target.value.is_fixed
    setSubChannelSelected(e.target.value);
    dynamicQuesId[5].answer = e.target.value;
    dynamicQuesId[5].id = subchannelId;
    let finalPayload = dynamicQuesId;
    setDynamicQuesId(finalPayload);
  };

  function handleMapClick(event) {
    if (event) {
      setLatitude(event.latLng.lat());
      setLongitude(event.latLng.lng());
      setLatLng({ lat: event.latLng.lat(), lng: event.latLng.lng() });
      dynamicQuesId[1].answer = {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      };
    }
  }

  useEffect(() => {
    if (formik.values.store) {
      setStoreName(formik.values.store);
    }
  }, [formik.values.store]);
  useEffect(() => {
    if (formik.values.uniqueId) {
      setuniqueId(formik.values.uniqueId);
    }
  }, [formik.values.uniqueId]);
  useEffect(() => {
    if (formik.values.radius) {
      setradius(formik.values.radius);
    }
  }, [formik.values.radius]);
  function handleMapClickManual(event) {
    if (event) {
      setLatitudeManual(event.latitude);
      setLongitudeManual(event.longitude);
      setLatLng({ lat: event.latitude, lng: event.longitude });
      dynamicQuesId[1].answer = {
        latitude: event.latitude,
        longitude: event.longitude,
      };
    }
  }

  function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleQuestionsname = (e, items) => {
    const value = e.target.value;
    setQuestionSelected({
      ...questionSelected,
      [e.target.name]: value,
      items,
    });
    // setQuestionSelected({[e.target.name]: e.target.value})
  };
  function handleButtonClick() {
    formik.setFieldValue("latitude", lat);
    formik.setFieldValue("longitude", long);
    setLatitude(lat);
    setLongitude(long);
    if (lat && long) {
      setLatitudeManual(lat);
      setLongitudeManual(long);
      setLatLng({ lat: lat, lng: long });
      dynamicQuesId[1].answer = {
        latitude: lat,
        longitude: long,
      };
    }
  }
  const onPlaceChanged = () => {
    const place = autocomplete.getPlace();
    if (!place.geometry) {
      console.log("No details available for input: ", place.name);
      return;
    }
    setLatLng({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "8px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Link to="/store" className="submenu-item">
                  <Button className="cancle-button cancle-hover">
                    <CloseOutlinedIcon sx={{ mr: 1 }} />
                    Cancel
                  </Button>
                </Link>
                <Button
                  className="header-add cancle-hover"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Confirm
                </Button>
                <Tooltip
                        title={
                          <div style={{ maxWidth: "300px" }}>
                            <strong>Advance Checklist Wise Report:</strong>
                            <p>
                              Status: Display's selected checklist completion
                              status, completion percentage & average compliance
                              scores for the selected user and stores.
                            </p>
                            <p>
                              Percentage: Display's selected checklist
                              completion percentage & average compliance scores
                              for the selected user and stores.
                            </p>
                          </div>
                        }
                        arrow
                        interactive
                      >
                        <InfoIcon
                          style={{
                            marginLeft: "8px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "#D76C00",
                          }}
                        />
                      </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
          <Container
            maxWidth="sm"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box>
                {/* Store Name  */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Store Name</label>
                  <TextField
                    id="store"
                    variant="outlined"
                    size="small"
                    name="store"
                    placeholder="Enter Store Name"
                    onChange={(e) => {
                      setStoreName(e.target.value);
                      formik.handleChange(e);
                    }}
                    className="issueinput-fields"
                    onBlur={formik.handleBlur}
                    value={storeName}
                    error={formik.touched.store && Boolean(formik.errors.store)}
                  />
                  <small className="error">
                    {formik.errors.store ? formik.errors.store : null}
                  </small>
                </FormControl>
                {/*---------------------------------store unique ID---------------------------- */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Store Unique ID</label>
                  <TextField
                    id="unique-id"
                    variant="outlined"
                    size="small"
                    name="uniqueId" // Set the name to "uniqueId"
                    placeholder="Enter Store ID"
                    onChange={(e) => {
                      setuniqueId(e.target.value);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur} // Bind the onBlur event handler
                    value={uniqueId}
                    error={
                      formik.touched.uniqueId && Boolean(formik.errors.uniqueId)
                    }
                    inputProps={{
                      pattern: "^[a-zA-Z0-9]*$",
                      title: "Store ID must be alphanumeric",
                    }}
                  />
                  <small className="error">
                    {formik.touched.uniqueId && formik.errors.uniqueId}
                  </small>
                </FormControl>

                {/* ============================================================================================= */}

                {/* ----------------------------Open Map on button click started ------------------------------ */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Store GPS Location</label>
                  {/* <MainMap /> */}

                  <Grid container spacing={1} className="display">
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <Button
                        variant="outlined"
                        className="add-field cancle-hover"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <AddIcon sx={{ mx: 1 }} />
                        Choose From Map
                      </Button>

                      <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogContent style={{ position: "relative" }}>
                          <Box sx={{ textAlign: "center" }}>
                            <IconButton
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                              onClick={() => setOpen(false)}
                            >
                              <CloseIcon />
                            </IconButton>{" "}
                            <br />
                            <p>Click on Pin</p>
                          </Box>
                          <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Box
                              style={{
                                border: "1px solid lightgrey",
                                borderRadius: "5px",
                                width: "40%",
                                margin: "5px 0px 20px -15px",
                              }}
                            >
                              <MainMap
                                handleLoad={handleLoad}
                                handleCenter={handleCenter}
                                position={position}
                                latLng={latLng}
                                handleMapClick={handleMapClick}
                                onPlaceChanged={onPlaceChanged}
                              />
                            </Box>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              handleMapClickManual(formik.values);
                              setOpen(false);
                            }}
                          >
                            SAVE
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    {/* Input Manually  */}
                    <Grid item md={6} sx={12} sm={12} lg={6}>
                      <Button
                        variant="outlined"
                        className="add-field cancle-hover"
                        onClick={() => {
                          setOpenManual(true);
                        }}
                      >
                        <AddIcon sx={{ mx: 1 }} />
                        Input Manually
                      </Button>
                      <Dialog
                        open={openManual}
                        onClose={() => setOpenManual(false)}
                      >
                        <DialogContent style={{ position: "relative" }}>
                          <IconButton
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                            onClick={() => setOpenManual(false)}
                            className="cross-icon"
                          >
                            <CloseIcon />
                          </IconButton>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={5}>
                              <label>Latitude</label>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                className="issueinput-fields"
                                size="small"
                                name="latitude"
                                placeholder="Enter Latitude"
                                onChange={(e) => setLat(e.target.value)}
                                // onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                value={
                                  lat === "" ? formik.values.latitude : lat
                                }
                                error={
                                  formik.touched.latitude &&
                                  Boolean(formik.errors.latitude)
                                }
                              />
                              <small className="error">
                                {formik.errors.latitude &&
                                formik.touched.latitude
                                  ? formik.errors.latitude
                                  : null}
                              </small>
                            </Grid>
                            <Grid item xs={5}>
                              <label>Longitude</label>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                className="issueinput-fields"
                                size="small"
                                name="longitude"
                                placeholder="Enter Longitude"
                                onChange={(e) => setLong(e.target.value)}
                                // onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                value={
                                  long === "" ? formik.values.longitude : long
                                }
                                error={
                                  formik.touched.longitude &&
                                  Boolean(formik.errors.longitude)
                                }
                              />
                              <small className="error">
                                {formik.errors.longitude &&
                                formik.touched.longitude
                                  ? formik.errors.longitude
                                  : null}
                              </small>
                            </Grid>
                          </Grid>
                          {/* <MainMap latLng={latLng} handleMapClick={handleMapClick} /> */}
                        </DialogContent>

                        <DialogActions>
                          <Button
                            className="issue-modal-cancelbtn"
                            onClick={() => {
                              setLat("");
                              setLong("");
                              setOpenManual(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="issue-modal-savebtn"
                            onClick={() => {
                              handleButtonClick();
                              setOpenManual(false);
                            }}
                          >
                            SAVE
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} columns={16} sx={{ mt: 1 }}>
                    {/* latitude */}
                    {formik.values.latitude !== undefined && (
                      <Grid item xs={8}>
                        <label>Latitude:</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          className="issueinput-fields"
                          size="small"
                          // name='latitude'
                          disabled
                          placeholder="Enter Latitude"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.latitude}
                          error={
                            formik.touched.latitude &&
                            Boolean(formik.errors.latitude)
                          }
                        />
                        <small className="error">
                          {formik.errors.latitude
                            ? formik.errors.latitude
                            : null}
                        </small>
                      </Grid>
                    )}
                    {/* longitude */}
                    {formik.values.longitude !== undefined && (
                      <Grid item xs={8}>
                        <label>Longitude:</label>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          // name='longitude'
                          className="issueinput-fields"
                          disabled
                          placeholder="Enter Longitude"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.longitude}
                          error={
                            formik.touched.longitude &&
                            Boolean(formik.errors.longitude)
                          }
                        />
                        <small className="error">
                          {formik.errors.longitude
                            ? formik.errors.longitude
                            : null}
                        </small>
                      </Grid>
                    )}
                  </Grid>
                  {lat === "" || (long === "" && <span>this is required</span>)}
                </FormControl>

                {/* ==============================   store radius ============================================= */}

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Store Radius</label>
                  <TextField
                    id="radius"
                    variant="outlined"
                    size="small"
                    name="radius" // Set the name to "radius"
                    placeholder="Enter Store Radius"
                    type="number"
                    step="any"
                    onChange={(e) => {
                      setradius(e.target.value);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur} // Bind the onBlur event handler
                    value={radius} // Bind the value to formik.values.radius
                    error={
                      formik.touched.radius && Boolean(formik.errors.radius)
                    }
                  />
                  <small className="error">
                    {formik.touched.radius && formik.errors.radius}
                  </small>
                </FormControl>

                {/* ==============================   store radius ============================================= */}
                {/* ----------------------------Open Map on button click started ------------------------------ */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Store Region</label>
                  <Select
                    size="small"
                    sx={{ borderRadius: "8px" }}
                    name="storeRegion"
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          maxHeight:
                            regionSelectedData?.length === 1 ? "60px" : "200px",
                        },
                      },
                    }}
                    onChange={handleChangeRegion}
                    value={regionSelected}
                    error={
                      formik.touched.storeRegion &&
                      Boolean(formik.errors.storeRegion)
                    }
                  >
                    {regionSelectedData &&
                      regionSelectedData.length > 0 &&
                      regionSelectedData?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option?.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <small className="error">
                    {formik.errors.storeRegion && formik.touched.storeRegion
                      ? formik.errors.storeRegion
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>
                    Select Sub-Region(
                    <label style={{ color: "red" }}>
                      Select Store Region First
                    </label>
                    )
                  </label>
                  <Select
                    size="small"
                    name="storeSubRegion"
                    sx={{ borderRadius: "8px" }}
                    disabled={enableSubRegion}
                    fullWidth
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                          maxHeight: 200,
                          overflowY: "auto",
                        },
                      },
                    }}
                    onChange={handleChangeSubRegion}
                    value={subRegionSelected}
                    error={
                      formik.touched.storeSubRegion &&
                      Boolean(formik.errors.storeSubRegion)
                    }
                  >
                    <MenuItem value="" className="select-item" disabled>
                      {enableSubRegion
                        ? "Please select store region first"
                        : "Select a store sub-region"}
                    </MenuItem>
                    {subRegionSelectedData &&
                      subRegionSelectedData.length > 0 &&
                      subRegionSelectedData.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {/* {enableSubRegion && !subRegionSelected && (
                    <Typography variant="caption" color="error">
                      Please Select Store Region First
                    </Typography>
                  )} */}
                  <small className="error">
                    {formik.errors.storeSubRegion &&
                    formik.touched.storeSubRegion
                      ? formik.errors.storeSubRegion
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Store Channel</label>
                  <Select
                    size="small"
                    name="storeChannel"
                    sx={{ borderRadius: "8px" }}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                        },
                      },
                    }}
                    onChange={(e) => {
                      handleChangeStoreChannel(e);
                      setStoreChannelSelected(true); // Update state variable
                    }}
                    onBlur={() => setStoreChannelSelected(true)} // Update state variable on blur
                    value={channelSelected}
                    error={
                      formik.touched.storeChannel &&
                      Boolean(formik.errors.storeChannel)
                    }
                  >
                    {channelSelectedData &&
                      channelSelectedData?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <small className="error">
                    {formik.errors.storeChannel && formik.touched.storeChannel
                      ? formik.errors.storeChannel
                      : null}
                  </small>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>
                    Store Sub-Channel
                    <label style={{ color: "red" }}>
                      (Select Store Channel First)
                    </label>
                  </label>
                  <Select
                    size="small"
                    name="storeSubChannel"
                    disabled={storeChannelSelected === false}
                    sx={{ borderRadius: "8px" }}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          bgcolor: "#FFFFFF",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                          },
                        },
                      },
                    }}
                    onChange={handleChangeStoreSubChannel}
                    value={subChannelSelected}
                    error={
                      formik.touched.storeSubChannel &&
                      Boolean(formik.errors.storeSubChannel)
                    }
                  >
                    {subChannelSelectedData &&
                      subChannelSelectedData.length > 0 &&
                      subChannelSelectedData?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option}
                            className="select-item"
                          >
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <small
                    className="error"
                    style={{ fontSize: "0.8rem", fontWeight: "normal" }}
                  >
                    {formik.errors.storeSubChannel &&
                    formik.touched.storeSubChannel
                      ? formik.errors.storeSubChannel
                      : null}
                  </small>
                  <small className="error">
                    {formik.errors.latitude && formik.touched.latitude
                      ? formik.errors.latitude
                      : null}
                  </small>
                </FormControl>

                {/* <MenuItem value="" className="select-item" disabled>
                  {enableSubRegion ? "" : "Select a store sub-region"}
                </MenuItem> */}
              </Box>
            </Box>
          </Container>
        </Box>
      </form>
      {/* <Stepper activeStep={activeStep} sx={{ marginBottom: "10px" }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper> */}
      {
        activeStep === steps.length
          ? ""
          : // <React.Fragment>
            //   <Typography sx={{ mt: 2, mb: 1 }}>
            //     All steps completed - Click on Confirm
            //   </Typography>
            //   <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            //     <Box sx={{ flex: "1 1 auto" }} />
            //     <Button onClick={handleReset}>Reset</Button>
            //   </Box>
            // </React.Fragment>
            ""
        // <React.Fragment>
        //   {activeStep === 0 ? (
        //     <Box>
        //       <FormControl fullWidth sx={{ mb: 2 }}>
        //         <label>Store Name</label>
        //         <TextField
        //           id="outlined-basic"
        //           variant="outlined"
        //           size="small"
        //           name="store"
        //           placeholder="Enter Store Name"
        //           onChange={formik.handleChange}
        //           className="issueinput-fields"
        //           onBlur={formik.handleBlur}
        //           value={storeName}
        //           error={
        //             formik.touched.store && Boolean(formik.errors.store)
        //           }
        //         />
        //         <small className="error">
        //           {formik.errors.store && formik.touched.store
        //             ? formik.errors.store
        //             : null}
        //         </small>
        //       </FormControl>
        //       <FormControl fullWidth sx={{ mb: 2 }}>
        //         <label>Store GPS Location</label>

        //         <Grid container spacing={1} className="display">
        //           <Grid item md={6} sx={12} sm={12} lg={6}>
        //             <Button
        //               variant="outlined"
        //               className="add-field cancle-hover"
        //               onClick={() => {
        //                 setOpen(true);
        //               }}
        //             >
        //               <AddIcon sx={{ mx: 1 }} />
        //               Choose From Map
        //             </Button>

        //             <Dialog open={open} onClose={() => setOpen(false)}>
        //               <DialogContent style={{ position: "relative" }}>
        //                 <IconButton
        //                   style={{
        //                     position: "absolute",
        //                     top: "0",
        //                     right: "0",
        //                   }}
        //                   onClick={() => setOpen(false)}
        //                 >
        //                   <CloseIcon />
        //                 </IconButton>

        //                 <Grid item xs={12} sm={12} md={12} lg={4}>
        //                   <Box
        //                     style={{
        //                       border: "1px solid lightgrey",
        //                       borderRadius: "5px",
        //                       width: "40%",
        //                       margin: "5px 0px 20px -15px",
        //                     }}
        //                   >
        //                     <MainMap
        //                       latLng={latLng}
        //                       handleMapClick={handleMapClick}
        //                     />
        //                   </Box>
        //                 </Grid>
        //               </DialogContent>

        //               <DialogActions>
        //                 <Button
        //                   onClick={() => {
        //                     setOpen(false);
        //                   }}
        //                 >
        //                   Cancel
        //                 </Button>
        //                 <Button
        //                   onClick={() => {
        //                     setOpen(false);
        //                   }}
        //                 >
        //                   SAVE
        //                 </Button>
        //               </DialogActions>
        //             </Dialog>
        //           </Grid>
        //           {/* Input Manually  */}
        //           <Grid item md={6} sx={12} sm={12} lg={6}>
        //             <Button
        //               variant="outlined"
        //               className="add-field cancle-hover"
        //               onClick={() => {
        //                 setOpenManual(true);
        //               }}
        //             >
        //               <AddIcon sx={{ mx: 1 }} />
        //               Input Manually
        //             </Button>
        //             <Dialog
        //               open={openManual}
        //               onClose={() => setOpenManual(false)}
        //             >
        //               <DialogContent style={{ position: "relative" }}>
        //                 <IconButton
        //                   style={{
        //                     position: "absolute",
        //                     top: "0",
        //                     right: "0",
        //                   }}
        //                   onClick={() => setOpenManual(false)}
        //                   className="cross-icon"
        //                 >
        //                   <CloseIcon />
        //                 </IconButton>
        //                 <Grid
        //                   container
        //                   rowSpacing={1}
        //                   columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        //                 >
        //                   <Grid item xs={5}>
        //                     <label>Latitude</label>
        //                     <TextField
        //                       id="outlined-basic"
        //                       variant="outlined"
        //                       className="issueinput-fields"
        //                       size="small"
        //                       name="latitude"
        //                       placeholder="Enter Latitude"
        //                       onChange={formik.handleChange}
        //                       onBlur={formik.handleBlur}
        //                       value={formik.values.latitude}
        //                       error={
        //                         formik.touched.latitude &&
        //                         Boolean(formik.errors.latitude)
        //                       }
        //                     />
        //                     <small className="error">
        //                       {formik.errors.latitude &&
        //                       formik.touched.latitude
        //                         ? formik.errors.latitude
        //                         : null}
        //                     </small>
        //                   </Grid>
        //                   <Grid item xs={5}>
        //                     <label>Longitude</label>
        //                     <TextField
        //                       id="outlined-basic"
        //                       variant="outlined"
        //                       className="issueinput-fields"
        //                       size="small"
        //                       name="longitude"
        //                       placeholder="Enter Longitude"
        //                       onChange={formik.handleChange}
        //                       onBlur={formik.handleBlur}
        //                       value={formik.values.longitude}
        //                       error={
        //                         formik.touched.longitude &&
        //                         Boolean(formik.errors.longitude)
        //                       }
        //                     />
        //                     <small className="error">
        //                       {formik.errors.longitude &&
        //                       formik.touched.longitude
        //                         ? formik.errors.longitude
        //                         : null}
        //                     </small>
        //                   </Grid>
        //                 </Grid>
        //               </DialogContent>

        //               <DialogActions>
        //                 <Button
        //                   className="issue-modal-cancelbtn"
        //                   onClick={() => {
        //                     setOpenManual(false);
        //                   }}
        //                 >
        //                   Cancel
        //                 </Button>
        //                 <Button
        //                   className="issue-modal-savebtn"
        //                   onClick={() => {
        //                     setOpenManual(false), handleMapClickManual;
        //                   }}
        //                 >
        //                   SAVE
        //                 </Button>
        //               </DialogActions>
        //             </Dialog>
        //           </Grid>
        //         </Grid>

        //         <Grid container spacing={2} columns={16} sx={{ mt: 1 }}>
        //           {formik.values.latitude !== undefined && (
        //             <Grid item xs={8}>
        //               <label>Latitude:</label>
        //               <TextField
        //                 id="outlined-basic"
        //                 variant="outlined"
        //                 className="issueinput-fields"
        //                 size="small"
        //                 disabled
        //                 placeholder="Enter Latitude"
        //                 onChange={formik.handleChange}
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.latitude}
        //                 error={
        //                   formik.touched.latitude &&
        //                   Boolean(formik.errors.latitude)
        //                 }
        //               />
        //               <small className="error">
        //                 {formik.errors.latitude &&
        //                 formik.touched.latitude
        //                   ? formik.errors.latitude
        //                   : null}
        //               </small>
        //             </Grid>
        //           )}
        //           {formik.values.longitude !== undefined && (
        //             <Grid item xs={8}>
        //               <label>Longitude:</label>
        //               <TextField
        //                 id="outlined-basic"
        //                 variant="outlined"
        //                 size="small"
        //                 className="issueinput-fields"
        //                 disabled
        //                 placeholder="Enter Longitude"
        //                 onChange={formik.handleChange}
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.longitude}
        //                 error={
        //                   formik.touched.longitude &&
        //                   Boolean(formik.errors.longitude)
        //                 }
        //               />
        //               <small className="error">
        //                 {formik.errors.longitude &&
        //                 formik.touched.longitude
        //                   ? formik.errors.longitude
        //                   : null}
        //               </small>
        //             </Grid>
        //           )}
        //         </Grid>
        //       </FormControl>
        //       <FormControl fullWidth sx={{ mb: 2 }}>
        //         <label>Store Region</label>
        //         <Select
        //           size="small"
        //           sx={{ borderRadius: "8px" }}
        //           name="storeRegion"
        //           MenuProps={{
        //             disableScrollLock: true,
        //             PaperProps: {
        //               sx: {
        //                 bgcolor: "#FFFFFF",
        //                 "& .MuiMenuItem-root": {
        //                   padding: 1,
        //                 },
        //                 height: "200px",
        //               },
        //             },
        //           }}
        //           onChange={handleChangeRegion}
        //           value={regionSelected}
        //         >
        //           {regionSelectedData &&
        //             regionSelectedData.length > 0 &&
        //             regionSelectedData?.map((option, i) => {
        //               return (
        //                 <MenuItem
        //                   key={i}
        //                   value={option}
        //                   className="select-item"
        //                 >
        //                   {option?.title}
        //                 </MenuItem>
        //               );
        //             })}
        //         </Select>
        //       </FormControl>

        //       <FormControl fullWidth sx={{ mb: 2 }}>
        //         <label>
        //           Store Sub-Region{" "}
        //           {enable ? (
        //             <span style={{ color: "red" }}>
        //               (Please select store region first)
        //             </span>
        //           ) : (
        //             ""
        //           )}
        //         </label>
        //         <Select
        //           size="small"
        //           name="storeSubRegion"
        //           sx={{ borderRadius: "8px" }}
        //           disabled={enable}
        //           MenuProps={{
        //             disableScrollLock: true,
        //             PaperProps: {
        //               sx: {
        //                 bgcolor: "#FFFFFF",
        //                 "& .MuiMenuItem-root": {
        //                   padding: 1,
        //                 },
        //                 height: "200px",
        //               },
        //             },
        //           }}
        //           onChange={handleChangeSubRegion}
        //           value={subRegionSelected}
        //         >
        //           {subRegionSelectedData &&
        //             subRegionSelectedData.length > 0 &&
        //             subRegionSelectedData?.map((option, i) => {
        //               return (
        //                 <MenuItem
        //                   key={i}
        //                   value={option}
        //                   className="select-item"
        //                 >
        //                   {option.title}
        //                 </MenuItem>
        //               );
        //             })}
        //         </Select>
        //       </FormControl>

        //       <FormControl fullWidth sx={{ mb: 2 }}>
        //         <label>Store Channel</label>
        //         <Select
        //           size="small"
        //           name="storeChannel"
        //           sx={{ borderRadius: "8px" }}
        //           MenuProps={{
        //             disableScrollLock: true,
        //             PaperProps: {
        //               sx: {
        //                 bgcolor: "#FFFFFF",
        //                 "& .MuiMenuItem-root": {
        //                   padding: 1,
        //                 },
        //                 height: "200px",
        //               },
        //             },
        //           }}
        //           onChange={handleChangeStoreChannel}
        //           value={channelSelected}
        //         >
        //           {channelSelectedData &&
        //             channelSelectedData?.map((option, i) => {
        //               return (
        //                 <MenuItem
        //                   key={i}
        //                   value={option}
        //                   className="select-item"
        //                 >
        //                   {option.title}
        //                 </MenuItem>
        //               );
        //             })}
        //         </Select>
        //         <small className="error">
        //           {formik.errors.storeChannel &&
        //           formik.touched.storeChannel
        //             ? formik.errors.storeChannel
        //             : null}
        //         </small>
        //       </FormControl>

        //       <FormControl fullWidth sx={{ mb: 2 }}>
        //         <label>Store Sub-Channel</label>
        //         <Select
        //           size="small"
        //           name="storeSubChannel"
        //           sx={{ borderRadius: "8px" }}
        //           MenuProps={{
        //             disableScrollLock: true,
        //             PaperProps: {
        //               sx: {
        //                 bgcolor: "#FFFFFF",
        //                 "& .MuiMenuItem-root": {
        //                   padding: 1,
        //                 },
        //                 height: "200px",
        //               },
        //             },
        //           }}
        //           onChange={handleChangeStoreSubChannel}
        //           value={subChannelSelected}
        //         >
        //           {subChannelSelectedData &&
        //             subChannelSelectedData.length > 0 &&
        //             subChannelSelectedData?.map((option, i) => {
        //               return (
        //                 <MenuItem
        //                   key={i}
        //                   value={option}
        //                   className="select-item"
        //                 >
        //                   {option.title}
        //                 </MenuItem>
        //               );
        //             })}
        //         </Select>
        //       </FormControl>
        //     </Box>
        //   ) : activeStep === 1 ? (
        //     <Box>
        //       {questionList &&
        //         questionList.length > 0 &&
        //         questionList?.map((items, i) => {
        //           return (
        //             <Box key={i}>
        //               {items.is_store === true &&
        //               items.id !== 1 &&
        //               items.id !== 2 &&
        //               items.id !== 3 &&
        //               items.id !== 4 &&
        //               items.title === "Store Channel?" &&
        //               items.title === "Store sub channel?" ? (
        //                 <FormControl fullWidth sx={{ mb: 2 }}>
        //                   <label>{items.title}</label>
        //                   <Select
        //                     size="small"
        //                     name="questionsname"
        //                     sx={{ borderRadius: "8px" }}
        //                     MenuProps={{
        //                       disableScrollLock: true,
        //                       PaperProps: {
        //                         sx: {
        //                           bgcolor: "#FFFFFF",
        //                           "& .MuiMenuItem-root": {
        //                             padding: 1,
        //                           },
        //                           height: "200px",
        //                         },
        //                       },
        //                     }}
        //                     onChange={(e) =>
        //                       handleQuestionsname(e, items)
        //                     }
        //                     value={questionSelected?.questionsname}
        //                   >
        //                     {items?.type &&
        //                       items?.type.validations &&
        //                       items?.type.validations[0]?.answer &&
        //                       items?.type?.validations[0]?.answer
        //                         ?.length > 0 &&
        //                       items?.type?.validations[0]?.answer?.map(
        //                         (option, i) => {
        //                           return (
        //                             <MenuItem
        //                               key={i}
        //                               value={option}
        //                               className="select-item"
        //                             >
        //                               {option.title}
        //                             </MenuItem>
        //                           );
        //                         }
        //                       )}
        //                   </Select>
        //                 </FormControl>
        //               ) : i === 0 ? (
        //                 "No more Question"
        //               ) : (
        //                 ""
        //               )}
        //             </Box>
        //           );
        //         })}
        //     </Box>
        //   ) : (
        //     "No Questions"
        //   )}
        //   <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        //     <Button
        //       color="inherit"
        //       disabled={activeStep === 0}
        //       onClick={handleBack}
        //       sx={{ mr: 1 }}
        //     >
        //       Back
        //     </Button>
        //     <Box sx={{ flex: "1 1 auto" }} />
        //     <Button onClick={handleNext}>
        //       {activeStep === steps.length - 1 ? "Done" : "Next"}
        //     </Button>
        //   </Box>
        // </React.Fragment>
      }
    </>
  );
};

export default AddStore;
