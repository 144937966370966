import { Box, Button, Card, Grid, Hidden, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import InfoIcon from "@mui/icons-material/Info";
import User from "../../assets/icons/svg-icons/User.svg";
import { useEffect, useState } from "react";
import moment from "moment/moment";
//import { useLocation } from "react-router-dom";
import  jsPDF from "jspdf";
import 'jspdf-autotable';


const ViewLogEntrySubmission = () => {
    const logEntrySubmissionData = localStorage.getItem("logEntrySubmissionData")
    const parsedLogEntrySubmissionData = JSON.parse(logEntrySubmissionData)

    const [submissionData, setSubmissionData] = useState({});

    const PrintDocumentDynamik = () => {
        const pdf_url = submissionData?.pdf_url;
        if (pdf_url) {
            window.open(pdf_url, "_blank");
        } else {
            console.error("PDF URL not available");
            alert("PDF URL is not available to display.");
        }
    };
    
    // Default export is a4 paper, portrait, using millimeters for units
    const PrintDocument = () => {
        const doc = new jsPDF();
        const centerx = doc.internal.pageSize.width / 2;
        doc.setFontSize(13);
        doc.setFont("helvetica", "bold");
        doc.text( `${submissionData?.companyName?.toUpperCase() || "--"}`, centerx , 16 , { align : "center"} );
        doc.setFontSize(11);
        doc.text(`${submissionData?.logEntryObject?.logEntryTitle.charAt(0).toUpperCase() + submissionData?.logEntryObject?.logEntryTitle.slice(1) || "--"}`, centerx , 23 , { align : "center"} );
        
        let currentY = 27;
        doc.setFontSize(9);
        doc.setFont("helvetica", "normal");
        doc.text(`User Name: ${submissionData?.user?.name || "--"}`, 13, currentY + 10);
        doc.text(`Store: ${submissionData?.store?.title || "--"}`, 60, currentY + 10);
        doc.text(`Date: ${moment(submissionData?.createdAt).format("lll")}`, 60, currentY + 15);
        
        doc.text(`Token: ${submissionData?.uniqueTokenNumber || "--"}`, 13, currentY + 15);
        doc.text(`Updated On: ${moment(submissionData?.updatedAt).format("lll")}`, 115, currentY + 15);

        // Define the table columns and rows
        //const columns = ["Details"];
        const rows = [];
        submissionData && submissionData?.questions?.forEach((element, i) => {
            const submissionArray = [];
            let result = "-"
            let mediaResult = [];
            if (element.answer?.type != "boolean" && element.answer?.answer?.value) {
                result = element.answer?.answer?.value
            } else if (element.answer?.answer?.title) {
                result = element.answer?.answer?.title;
            } else if (element?.answer?.answer?.length) {
                result = element.answer.answer.map(ans => ans.title);
            } else if (element?.answer?.answer.date && element?.answer?.answer?.time) {
                result = `${element?.answer?.answer?.date} ${element?.answer?.answer?.time}`
            } else if (element?.answer?.answer?.answer?.length) {
                mediaResult = element.answer.answer.answer.map(ans => `https://popprobe-saas.s3.us-west-2.amazonaws.com/${ans}`).join(", ");
            }

            submissionArray.push(i + 1);
            submissionArray.push(element?.title.charAt(0).toUpperCase() + element?.title.slice(1));
            //submissionArray.push(element?.type?.title);
            //submissionArray.push(moment(element?.answer?.startTime).format("LTS"));
            //submissionArray.push(moment(element?.answer?.endTime).format("LTS"));
            result === "-" ? submissionArray.push(mediaResult.length ? mediaResult : "-") : submissionArray.push(result);
            rows?.push(submissionArray);
        }); 

        // Use jsPDF autotable plugin
        doc.autoTable({
            head: [[{ content: "Details", colSpan: 3, styles: { halign: "center", fillColor: [22, 160, 133], textColor: 255, fontSize: 12 } }],], // Column titles
            body: rows,      // Table data
            startY: 50,     // Start position after the text content
            theme: "grid",   // Styling theme (grid, plain, striped)
            headStyles: {
                fillColor: [22, 160, 133], // Background color for header (e.g., teal)
                textColor: 255,            // Text color for header (white)
                fontSize: 10,              // Font size for header
                halign: "center",          // Center-align text in header
            },

            bodyStyles: {
                fontSize: 9,               // Font size for body rows
                textColor: [0, 0, 0],      // Text color for body rows
                valign: "middle",          // Vertical alignment for body rows
                halign: "left",            // Horizontal alignment for body rows
                cellPadding: 3,
            },

            columnStyles: {
                0: { halign: "center", cellWidth: 15 },      // Set specific width for first column
                1: { cellWidth: 100 },      // Set specific width for second column
                //2: { cellWidth: 25 },      // Adjust column widths as needed
                //3: { cellWidth: 30 },
                //4: { cellWidth: 30 },
                5: { cellWidth: 50 },      // Wider column for the "Answer" field
            },
            
            margin: { top: 10, left: 10, right: 10 }, // Table margins
            pageBreak: "auto",            // Automatically handle page breaks
            styles: {
                cellPadding: 3,           // Add padding inside cells
                overflow: "linebreak",    // Ensure long text wraps within cells
            },
            didParseCell: (data) => {
                console.log("data.cell.raw didParseCell", data.cell.raw);
                if (typeof data.cell.raw === "string" && data.cell.raw.includes("http") && data.cell.section === 'body') {
                    data.cell.text = ['']; // Display only "Visit"
                    data.cell.styles.cellPadding = 2; // Optional: Adjust padding if needed
                    data.cell.styles.fontColor = [0, 0, 255]; // Optional: Set link color to blue
                    data.cell.styles.textDecoration = 'underline'; // Optional: Underline link
                    // Attach the hyperlink
                    data.cell.raw = rows[data.row.index][2]; // Add URL for linking
                }
            },
            didDrawCell: (data) => {
                console.log("data.cell.raw didDrawCell", data.cell.raw);
                if (typeof data.cell.raw === "string" && data.cell.raw.includes("http") && data.cell.section === 'body') {
                    const { x, y, height } = data.cell;

                    const textHeight = 4; // Approximate height of a single line of text
                    const textStartY = y + (height + textHeight) / 2 - 1; 

                    doc.textWithLink("Click to open image/media file", x + 3, textStartY, { url: data.cell.raw });
                    // doc.textWithLink("Click to open image/media file", x + 5, y + 7.5, { url: data.cell.raw });
                }
            },
        });

        // Footertext
        const footerText = "Copyright © HOPE RESEARCH GROUP. All Rights Reserved.";
        doc.setFontSize(10);
        doc.setTextColor(150); // Lighter gray color 
        doc.setFont("helvetica", "normal");
        doc.text(footerText, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: "center" });

        /* Footer logo
        const logoImage = "data:image/png;base64,..."; // Replace with your Base64-encoded logo string
        const logoWidth = 20;
        const logoHeight = 10;
        doc.addImage(logoImage, "PNG", doc.internal.pageSize.width - 35, doc.internal.pageSize.height - 20, logoWidth, logoHeight);*/

        // Save the PDF
        doc.save(`Pp-log-${submissionData?.uniqueTokenNumber}-${submissionData?.createdAt}.pdf`);
    }
    useEffect(() => {
        setSubmissionData(parsedLogEntrySubmissionData);
    }, [])
 console.log("submissionData", submissionData);
    return (
        <>
            <Box
                sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
                className="header-card1"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                        <Grid style={{ float: "right", marginRight: "10px", marginBottom: "10px"}}>
                        <Button
                            // onClick={PrintDocument}
                            onClick={PrintDocumentDynamik}
                            className="header-add cancle-hover"
                            style={{ marginBottom: "3px" , width: "110px" }}
                        >
                        Print
                        </Button>
                    </Grid>
                            <Link to="/log-entry/view-log-entries-submissions" className="submenu-item">
                                <Button
                                    className="header-add cancle-hover"
                                    style={{ marginBottom: "3px" }}
                                >
                                    <DoneIcon sx={{ mr: 1 }} />
                                    Done
                                </Button>
                            </Link>
                            <div style={{ marginTop: "4px", marginRight: "10px" }}>
                                <Tooltip
                                    title={
                                        <div style={{ maxWidth: "600px" }}>
                                            <p>
                                                View the details of the log entry submision.
                                            </p>
                                        </div>
                                    }
                                    arrow
                                    interactive
                                >
                                    <InfoIcon
                                        style={{
                                            verticalAlign: "middle",
                                            cursor: "pointer",
                                            color: "#D76C00",
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <div id="Print">
            <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
                <Typography
                    variant="h5"
                    component="h4"
                    sx={{ textAlign: "left", p: 1, fontWeight: "bold" }}
                >
                    User Information
                </Typography>
                <Card className="user-information">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <img src={User} alt="avatar" className="submission-avatar" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                            <Table aria-label="simple table" className="details-table">
                                <TableBody style={{
                                    width: "100%"
                                }}>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                User Name:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value">
                                            <Typography variant="p" component="b">
                                                {submissionData?.user?.name
                                                    ? submissionData?.user?.name
                                                    : "--"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                User Role:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value">
                                            <Typography variant="p" component="b">
                                                {submissionData?.user?.subRole ? submissionData?.user?.subRole : "--"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Submitted On:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value">
                                            <Typography variant="p" component="b">
                                                {moment(submissionData?.createdAt).format("lll")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Updated On:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value">
                                            <Typography variant="p" component="b">
                                                {moment(submissionData?.updatedAt).format("lll")}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Log Entry Title:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value" colspan="3">
                                            <Typography variant="p" component="b">
                                                {submissionData?.logEntryObject?.logEntryTitle}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Description:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value" colspan="3">
                                            <Typography variant="p" component="b">
                                                {submissionData?.logEntryObject?.description}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="list-value user-location">
                                            <Typography variant="p" className="detail-head">
                                                Token:
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="user-location detail-value" colspan="3">
                                            <Typography variant="p" component="b">
                                                {submissionData?.uniqueTokenNumber}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
            <div style={{ maxWidth: '100%', margin: 'auto' }}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" align="left">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">S.No.</TableCell>
                                <TableCell align="center" sx={{ width: "300px" }}>
                                    Question
                                </TableCell>
                                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                    Question Type
                                </TableCell>
                                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                    Start Time
                                </TableCell>
                                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                    End Time
                                </TableCell>
                                <TableCell align="center">Answer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {submissionData &&
                                submissionData?.questions?.map((row, i) => {
                                    let result = "-"
                                    let mediaResult = [];
                                    if (row.answer?.type != "boolean" && row.answer?.answer?.value) {
                                        result = row.answer?.answer?.value
                                    } else if (row.answer?.answer?.title) {
                                        result = row.answer?.answer?.title;
                                    } else if (row?.answer?.answer?.length) {
                                        result = row.answer.answer.map(ans => ans.title);
                                    } else if (row?.answer?.answer.date && row?.answer?.answer?.time) {
                                        result = `${row.answer.answer.date} ${row.answer.answer.time}`
                                    } else if (row?.answer?.answer?.answer?.length) {
                                        mediaResult = row.answer.answer.answer.map(ans => `https://popprobe-saas.s3.us-west-2.amazonaws.com/${ans}`);
                                    }

                                    return (<TableRow>
                                        <TableCell align="center" sx={{ fontWeight: "600" }}>
                                            {i + 1}.
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="p">{row?.title}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="p">{row?.type?.title}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                variant="p"
                                                component="p"
                                                sx={{ whiteSpace: "nowrap" }}
                                            >
                                                {moment(row?.answer?.startTime).format("LTS")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                variant="p"
                                                component="p"
                                                sx={{ whiteSpace: "nowrap" }}
                                            >
                                                {moment(row?.answer?.endTime).format("LTS")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {result === "-" ?
                                                mediaResult.length && <ul style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}>
                                                    {mediaResult.map(item =>
                                                        <li style={{
                                                            whiteSpace: "nowrap",
                                                            width: "250px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}>
                                                            - <a href={item} target="_blank">{item}</a>
                                                        </li>
                                                    )}
                                                </ul> :
                                                Array.isArray(result) ?
                                                    <ul style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center"
                                                    }}>
                                                        {result.map(item =>
                                                            <li>- {item}</li>
                                                        )}
                                                    </ul>
                                                    : result}
                                        </TableCell>
                                    </TableRow>)
                                }
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div >
            </div>
        </>
    )
}

export default ViewLogEntrySubmission;