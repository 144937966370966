import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactExport from "react-export-excel-xlsx-fix";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useSelector } from "react-redux";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const MasterQRCodeListHeader = () => {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const btnExportRef = useRef();

  const templateData = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.QRCodeListData &&
        state.inventory.QRCodeListData.data &&
        state.inventory.QRCodeListData.data.data) ||
      []
  );
  console.log("QRCodeListData", templateData);

  const [exportMasterbarCodeData, setExportMasterbarCodeData] = useState([]);

  const exportBarCodeList = () => {
    let subArr = [];

    let allColumnsList = [];
    let allCsvSubRows = [];
    const formattedSD = "Master QR Code list";

    allColumnsList.push(
      {
        value: "S.no.",
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
          fill: {
            patternType: "solid",
            bgColor: { rgb: "FFFFAA00" },
            fgColor: { rgb: "FFFFAA00" },
          },
        },
      },
      {
        value: "Name",
        widthPx:150,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Description",
        widthPx: 150,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Created At",
        widthPx: 150,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Updated At",
        widthPx: 150,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Status",
        widthPx: 200,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
      {
        value: "Barcode link",
        widthPx: 200,
        style: {
          font: { sz: "24", bold: true },
          alignment: { wrapText: true },
        },
      },
    );

    templateData?.finalData.map((item, index) => {
      const csvRow = [];

      csvRow.push(
        {
          value: `${index + 1 || "N/A"}`,
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.title || "N/A",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.description || "N/A",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.createdAt ? moment(item.createdAt).format("MMMM D, YYYY, h:mm:ss A") : "N/A",
          widthPx: 150,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.updatedAt ? moment(item.updatedAt).format("MMMM D, YYYY, h:mm:ss A") : "N/A",
          widthPx: 150,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.barcodeScanned ? "Scanned" : " Not Scanned",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
        {
          value: item?.barcode || "N/A",
          widthPx: 130,
          style: {
            font: { sz: "24", bold: true },
            alignment: { wrapText: true },
          },
        },
      )

      /*item.questions.map((question) => {
          let result = "-";
          if (question?.answer?.type != "boolean" && question?.answer?.answer?.value) {
            result = `${question?.answer?.answer?.value}`
          } else if (question?.answer?.answer?.title) {
              result = `${question?.answer?.answer?.title}`;
          } else if (question?.answer?.answer?.length) {
            result = question.answer.answer.map(ans => ans.title).join(',');
          } else if (question?.answer?.answer.date && question?.answer?.answer?.time) {
            result = `${question.answer.answer.date} ${question.answer.answer.time}`
          } else if (question?.answer?.answer?.answer?.length) {
            const mediaresult = question.answer.answer.answer.map(ans => `https://popprobe-saas.s3.us-west-2.amazonaws.com/${ans}`);
            result = mediaresult.join(',');
          }
  
          if (index === 0) {
            allColumnsList.push({
              value: question.title,
              widthPx: 200,
              style: {
                font: { sz: "24", bold: true },
                alignment: { wrapText: true },
              },
            });
          }
  
          csvRow.push({
            value: result || "N/A",
            widthPx: 130,
            style: {
              font: { sz: "24", bold: true },
              alignment: { wrapText: true },
            },
          })
        });*/

        allCsvSubRows.push(csvRow);
      })

      const dataSet = [
        {
          columns: allColumnsList,
          data: allCsvSubRows,
        },
      ];
      console.log("dataSet", dataSet);
      subArr.push({ formattedSD, dataSet });

      if (subArr.length) {
        setExportMasterbarCodeData(subArr);
        setTimeout(() => {
          btnExportRef.current.click();
        }, 1000);
      }
    }

  const modalopenFilterhandleClose = () => {
    setOpenFilterModal(false);
  };

  const handleNewCreate = () => {
    localStorage.setItem("addMasterBarCodeLocal", "");
    localStorage.setItem("questionsListLocal", "");
    localStorage.setItem("masterBarCodeLocalStorageData", "");
  };

  return (
    <Box
      sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
      className="header-card"
    >
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{
          padding: "0px 8px",
        }}
      >
        <Grid item xs={12} lg={7} md={4} sm={12} alignItems="left">
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            Master BarCode List
          </Typography>
          {/* <Stack
                        direction="row"
                        spacing={1}
                        alignItems="left"
                        justifyContent="flex-start"
                    >
                        <Stack direction="row" alignItems="left">
                            <Button
                                className="header-add cancle-hover"
                                style={{ height: "42px", borderRadius: "8px", padding: "12px 16px" }}
                                onClick={(e) => setOpenFilterModal(true)}
                            >
                                <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                                Filter
                            </Button>
                        </Stack>
                    </Stack> */}
        </Grid>
        <Grid item xs={12} lg={5} md={8} sm={12}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Stack
              direction="row"
              spacing={1}
              alignItems="left"
              justifyContent="flex-start"
              sx={{
                marginRight: "1px",
                marginLeft: "10px",
              }}
            >
              <Stack direction="row" alignItems="left">
                <Box>
                  <ExcelFile
                    element={
                      <Button
                        className="header-add cancle-hover"
                        style={{
                          whiteSpace: "nowrap",
                          width: "80%",
                          display: "none",
                        }}
                        ref={btnExportRef}
                      // onClick={() => getIssues()}
                      >
                        Export Excel
                        <FileDownloadIcon
                          style={{
                            marginLeft: "5px",
                          }}
                        />
                      </Button>
                    }
                    filename={"PopprobeMasterBarCodeList_" + Date.now()}
                  >
                    {exportMasterbarCodeData.map((csvData, key) => (
                      <ExcelSheet
                        key={key}
                        dataSet={csvData.dataSet}
                        name={csvData.formattedSD}
                      />
                    ))}
                  </ExcelFile>
                  <Button
                    className="header-add cancle-hover"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => exportBarCodeList()}
                  >
                    Export Excel
                    <FileDownloadIcon
                      style={{
                        marginLeft: "5px",
                      }}
                    />
                  </Button>
                </Box>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Link to="/master-barcode-list/create" className="submenu-item">
                <Button
                  className="header-add cancle-hover"
                  sx={{
                    whiteSpace: "nowrap",
                    ml: 1,
                    height: "42px",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleNewCreate()}
                >
                  <AddOutlinedIcon sx={{ mr: 1 }} />
                  Add New
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MasterQRCodeListHeader;
